import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import defaultTheme from "theme";
import ColumnarChartComponent from "./ColumnarChartComponent";
import AppLogo from "components/labels/AppLogo";
import {
  SyncComponent,
  store,
  isAppInViewMode,
  getTokenForViewMode,
  getAppVersion,
  userSelector,
  isInternalUserSelector,
  getHashParams,
  recapDataSelector,
  fetchPortfolios,
  shareCapabilities,
  siteConfigSelector,
  currentPortfolioSelector,
  fetchNetWorthDataForPortfolio,
  getRecapChartOptionForPortfolio,
  getRecapChartTypeForPortfolio,
  getRecapChartTimeRangeForPortfolio,
  fetchPercentageAllocationData,
  percentageAllocationDataFetchStatusSelector,
  recapChartTypes,
  RECAP_CATEGORY_TYPE_NETWORTH
} from "@kubera/common";
import i18n from "i18next";
import { configureLogger } from "logger/ConfigureLogger";
import lrConditionalCall from "utilities/lrConditionalCall";
import LogRocket from "logrocket";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";
import ChartsModalComponent from "components/recap/ChartsModalComponent";
import { hashParams, modalValues } from "routes";
import { useLocation } from "react-router-dom";
import RecapLoaderComponent from "./RecapLoaderComponent";

const Container = styled.div`
  display: flex;
  flex: 1;
  color: ${props => props.theme.dashboardComponentContainerCLR};
  background: ${props => props.theme.dashboardBackgroundColor};
  height: 100%;
  overflow-x: scroll;
  overscroll-behavior: none;
  -webkit-overscroll-behavior: none;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 50px;
    background: ${props => props.theme.desktopBackgroundScrollbarTrack};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.desktopBackgroundScrollbarThumb};
    border-radius: 50px;
    border: 3px ${props => props.theme.desktopBackgroundScrollbarTrack} solid;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.desktopBackgroundScrollbarThumbHover};
  }
  ::-webkit-scrollbar-corner {
    background: ${props => props.theme.desktopBackgroundScrollbarTrack};
  }

  ::placeholder {
    color: ${props => (props.theme.mode === "dark" ? props.theme.placeholderColor : null)};
  }

  @media print {
    color: ${defaultTheme.dashboardComponentContainerCLR};
    background: ${defaultTheme.dashboardBackgroundColor};
  }
`;

const RecapExpandedComponent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 60px;
  padding-right: 30px;
  margin-bottom: 60px;
`;

const RecapExpandedAsideComponent = styled.div`
  position : fixed;
  left: 0;
  min-width : 60px;
  max-width : 60px
  height : calc(100vh - 15px);
  width : 100%;
  margin-bottom : 60px
  z-index : 555;
  background: ${props => props.theme.dashboardBackgroundColor};
  @supports (-moz-appearance:none) {
    height : 100%
}

  @media print {
    background: #fff;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const Logo = styled(AppLogo)`
  position: sticky;
  left: 60px;
  top: 0;
  margin-bottom: 20px;
  width: 150px;
`;

const trackSession = (userId, user, sessionURL = window.location.href) => {
  if (isAppInViewMode() === false) {
    window.Beacon("identify", {
      name: user.name,
      email: user.email,
      user_id: user.id,
      created_at: user.tsCreated,
      sessionURL: sessionURL,
      appVersion: getAppVersion()
    });
  }

  Sentry.configureScope(function(scope) {
    scope.setUser({
      id: userId
    });
    scope.setExtra("sessionURL", sessionURL);
    scope.setExtra("appVersion", getAppVersion());
  });
};

const setupReporting = (user, isInternalUser) => {
  // Enable LR sessions only for users who are not being shown the
  // subscription paywall
  if (isAppInViewMode() === false && !user === true) {
    return;
  }

  const userId = isAppInViewMode() ? getTokenForViewMode() : user.id;
  configureLogger();

  const isLrExecuted = lrConditionalCall(() => {
    LogRocket.identify(userId, {
      displayName: userId,
      appVersion: getAppVersion()
    });
    LogRocket.getSessionURL(function(sessionURL) {
      trackSession(userId, user, sessionURL);
    });
  }, isInternalUser);

  if (!isLrExecuted) {
    trackSession(userId, user);
  }
};

const RecapComponentExpanded = props => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPortfolio = useSelector(currentPortfolioSelector);
  const recapData = useSelector(recapDataSelector);
  const [noOfColumns, setNoOfColumns] = useState(6);
  const user = useSelector(userSelector);
  const isInternalUser = useSelector(isInternalUserSelector);
  const isInitialized = useRef(false);
  const siteConfig = useSelector(siteConfigSelector);
  const recapDisabled =
    siteConfig.capability &&
    JSON.parse(siteConfig.capability).includes(shareCapabilities.RECAP) === false &&
    JSON.parse(siteConfig.capability).includes(shareCapabilities.ALL) === false;
  const selectedChartOptions = useSelector(getRecapChartOptionForPortfolio);
  const selectedChartType = useSelector(getRecapChartTypeForPortfolio);
  const selectedTimeRange = useSelector(getRecapChartTimeRangeForPortfolio);
  const fectchingPercentageAllocationData = useSelector(percentageAllocationDataFetchStatusSelector);
  const [isForceUpdateDone, setIsForceUpdateDone] = useState(false);

  const getModalComponent = useCallback(() => {
    const urlHashParams = getHashParams(location);
    const modalValue = urlHashParams[hashParams.MODAL];
    switch (modalValue) {
      case modalValues.RECAP_CHARTS:
        return <ChartsModalComponent reportId={urlHashParams[hashParams.REPORT_ID]} />;
      default:
        return null;
    }
    // eslint-disable-next-line
  }, [location]);
  const modalComponent = getModalComponent(props);

  useEffect(() => {
    dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id, false, false, undefined, true));
  }, []);
  useEffect(() => {
    console.log("fectchingPercentageAllocationData", fectchingPercentageAllocationData);
    if (
      selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION &&
      recapData.data?.[selectedTimeRange]?.[selectedChartOptions]?.totals
    ) {
      dispatch(fetchPercentageAllocationData(selectedChartOptions, selectedTimeRange));
    }
  }, [fectchingPercentageAllocationData, JSON.stringify(recapData)]);

  useEffect(() => {
    dispatch(fetchPortfolios());
  }, [dispatch]);

  useEffect(() => {
    document.title = i18n.t("recapExpandedPageTitle");
    if (isInitialized.current || !user || !user.id) {
      return () => null;
    }

    setupReporting(user, isInternalUser);
    isInitialized.current = true;
  }, [user, isInternalUser]);

  if (recapDisabled === true) {
    return null;
  }

  const isRecapDataPresent =
    recapData.data?.[selectedTimeRange]?.[selectedChartOptions]?.[selectedChartType]?.[
      RECAP_CATEGORY_TYPE_NETWORTH
    ]?.[0]?.values?.length > 0;

  /**
   * @TODO: This is a temporary fix to force update the component
   * Ideally recapData reference should update after every recap data calculation is done
   */
  if (!isForceUpdateDone && (isRecapDataPresent && !fectchingPercentageAllocationData)) {
    setIsForceUpdateDone(true);
  } else if (!isRecapDataPresent && isForceUpdateDone) {
    return setIsForceUpdateDone(false);
  }

  const renderRecapGrid = () => {
    if (!isRecapDataPresent || fectchingPercentageAllocationData) {
      return <RecapLoaderComponent noOfColumns={6} />;
    } else {
      return <ColumnarChartComponent noOfColumns={noOfColumns} setNoOfColumns={setNoOfColumns} />;
    }
  };

  return (
    <Container id="recapContainer">
      <SyncComponent store={store} />
      <RecapExpandedAsideComponent />
      <RecapExpandedComponent>
        <LogoContainer>
          <Logo regularSize={true} />
        </LogoContainer>
        {renderRecapGrid()}
      </RecapExpandedComponent>
      {modalComponent}
    </Container>
  );
};
export default React.memo(RecapComponentExpanded);

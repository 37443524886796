import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import {
  accountCardDetailsSelector,
  upgradeSubscription,
  updateSubscriptionCard,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR,
  detachSubscriptionCard,
  userDiscountSelector,
  showToastTip
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { useConfirmCardSetup } from "utilities/CustomHooks";

import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement from "./AccountCardElement";
import AccountSubscriptionOptions from "./AccountSubscriptionOptions";
import useDefaultSelectedFrequency from "./useDefaultSelectedFrequency";
import UpdateSubscriptionMessage from "./UpdateSubscriptionMessage";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: #e3e3e3;
  border: 1px solid #e1e1e1;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const CardContainer = styled(AccountCardElement)`
  width: 100%;
  margin-bottom: 25px;

  .StripeElement {
    background: #fff;
  }

  & path {
    fill: ${props => props.theme.mobileTxtColor};
  }
`;

const CardDetails = styled.div`
  font-size: 16px;
  line-height: 125%;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  color: #342a2a80;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 156px;
  height: 50px;
  margin-right: 18px;
  color: #fff;
  background: ${props => props.theme.mobileColorBtn};
  font-size: 16px;
  line-height: 110%;
`;

const CancelBtn = styled(SecondaryButton)`
  width: 156px;
  height: 50px;
  font-size: 16px;
  line-height: 110%;
`;

const options = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CONFIRM_CARD_OPTIONS = {
  usePaymentMethodId: false
};
const AccountUpdateSubscription = ({
  setUpdateMode = () => null,
  setIsSubmitted = () => null,
  captureError = () => null
}) => {
  const dispatch = useDispatch();
  const confirmCardSetupWithPaymentMethodId = useConfirmCardSetup();
  const confirmCardSetupWithCardElement = useConfirmCardSetup(CONFIRM_CARD_OPTIONS);

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const userDiscount = useSelector(userDiscountSelector);
  const defaultSelectedFrequency = useDefaultSelectedFrequency();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateCardMode, setUpdateCardMode] = useState(!!accountCardDetails.cardLast4 === false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(defaultSelectedFrequency);

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      const updatedSubscription = await dispatch(updateSubscriptionCard(paymentMethodId)).catch(err => {
        setIsLoading(false);
        if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
          setIsDeclinedError(true);
        }
      });
      if (updatedSubscription) {
        await dispatch(upgradeSubscription(selectedFrequencyValue)).catch(() => {
          dispatch(showToastTip("TIP", i18n.t("somethingWentWrong"), null, 3000));
        });
        setUpdateMode(false);
      }
    },
    [dispatch, selectedFrequencyValue, setUpdateMode]
  );

  const handleUpdateClick = useCallback(
    async e => {
      e.preventDefault();

      setIsLoading(true);

      const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
        setIsLoading(false);
      });
      if (!isUpdateCardMode) {
        confirmCardSetupWithPaymentMethodId({
          paymentIntent,
          onResolve: async result => {
            if (result.error) {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
              setPaymentErrorMsg(result.error.message);
              setIsLoading(false);
              setIsDeclinedError(true);
            } else if (result.setupIntent.status === "succeeded") {
              await dispatch(upgradeSubscription(selectedFrequencyValue)).catch(() => {
                dispatch(showToastTip("TIP", i18n.t("somethingWentWrong"), null, 3000));
              });
              setUpdateMode(false);
            } else {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
              setPaymentErrorMsg(result.setupIntent.last_setup_error);
              setIsDeclinedError(true);
            }
          }
        });
      } else if (paymentIntent) {
        await dispatch(detachSubscriptionCard(selectedFrequencyValue));
        confirmCardSetupWithCardElement({
          paymentIntent,
          onResolve: result => {
            if (result.error) {
              setIsLoading(false);
              if (selectedFrequencyValue !== defaultSelectedFrequency || !!userDiscount) {
                setPaymentErrorMsg(result.error.message);
                setIsDeclinedError(true);
              } else {
                setUpdateMode(false);
              }
            } else if (result.setupIntent.status === "succeeded") {
              onSubscriptionChange(result.setupIntent.payment_method);
            } else {
              console.log("result.setupIntent.status", result.setupIntent.status);
              setIsDeclinedError(true);
            }
          }
        });
      }

      setIsSubmitted(true);
    },
    [
      dispatch,
      selectedFrequencyValue,
      isUpdateCardMode,
      confirmCardSetupWithPaymentMethodId,
      confirmCardSetupWithCardElement,
      onSubscriptionChange,
      setIsSubmitted,
      setUpdateMode,
      captureError,
      defaultSelectedFrequency,
      userDiscount
    ]
  );

  const handleRemoveCardAction = e => {
    e.preventDefault();
    setUpdateCardMode(true);
  };

  const onCancelAction = e => {
    e.preventDefault();
    if (!isUpdateCardMode || !!accountCardDetails.cardLast4 === false) {
      setUpdateMode(false);
    } else {
      setUpdateCardMode(false);
    }
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const handleRadioButtonSelection = value => {
    setSelectedFrequencyValue(value);
  };

  return (
    <>
      <form onSubmit={handleUpdateClick}>
        <AccountSubscriptionOptions
          selectedFrequencyValue={selectedFrequencyValue}
          handleRadioButtonSelection={handleRadioButtonSelection}
        />
        {!isUpdateCardMode ? (
          <Container>
            <CardDetails>
              <CardInfo>
                {accountCardDetails.cardBrand} xxxx {accountCardDetails.cardLast4} • Exp{" "}
                {accountCardDetails.cardExpiryMonth}/{accountCardDetails.cardExpiryYear}
              </CardInfo>
            </CardDetails>
            <ChangeAction title="✕" data-cy="removeCardBtn" onClick={handleRemoveCardAction} />
          </Container>
        ) : (
          <CardContainer options={options} />
        )}
        <ActionButtonContainer>
          <SubscribeBtn
            title={i18n.t("update")}
            data-cy="subscribeNowButton"
            onClick={handleUpdateClick}
            isLoading={isLoading}
          />
          <CancelBtn title={i18n.t("cancel")} data-cy="cancelButton" onClick={onCancelAction} />
        </ActionButtonContainer>
        <UpdateSubscriptionMessage accountPlan={selectedFrequencyValue} />
      </form>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </>
  );
};

export default AccountUpdateSubscription;

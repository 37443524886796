import axios from "axios";

import { ApiError, apiErrorCodes } from "../api/ApiResponse";

const RacePromiseWrapper = (promises, waitTimeout = 20000) => {
  const cancelToken = axios.CancelToken.source();

  const cancelApiCalls = message => {
    cancelToken.cancel(
      new ApiError({
        errorCode: apiErrorCodes.NO_RESPONSE,
        errorMessage: message
      })
    );
  };

  const intervalId = setInterval(() => {
    if (navigator.onLine === false) {
      cancelApiCalls("No internet connection");
    }
  }, 5000);

  const timeoutId = setTimeout(() => {
    cancelApiCalls("Request timed out");
  }, waitTimeout);

  return Promise.all(promises.map(promise => promise(cancelToken))).finally(() => {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
  });
};

export default RacePromiseWrapper;

import React, { useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import i18n from "i18next";
import {
  createSubscription,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR,
  isMobile,
  accountCardDetailsSelector,
  detachSubscriptionCard,
  isBlackUserSelector,
  hasLinkedPortfolioAsCustodianSelector,
  SUBSCRIPTION_PLANS,
  subscribeBtnSuffixSelector
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { useConfirmCardSetup } from "utilities/CustomHooks";
import DeferredPromise from "utilities/DeferredPromise";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement from "./AccountCardElement";
import MobileAccountCardPayment from "./MobileAccountCardPayment";
import NFTDiscountMessage from "./NFTDiscountMessage";
import AccountSubscriptionOptions from "./AccountSubscriptionOptions";
import useDefaultSelectedFrequency from "./useDefaultSelectedFrequency";

const isMobileDevice = isMobile();

const Container = styled.div``;

const InputCardContainer = styled(AccountCardElement)`
  width: 396px;
  margin-bottom: 20px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: ${props => (props.hasBtnTextSuffix ? "216px" : "136px")};
  height: 43px;
  margin-bottom: 12px;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 400px;
  height: 45px;
  background: ${props => props.theme.cardContainerBG};
  border: ${props => props.theme.cardContainerBR};
  padding: 11px 20px;
  box-sizing: border-box;
  margin-bottom: 25px;
  overflow: hidden;
`;

const CardDetails = styled.div`
  font-size: 14px;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: -36px;
  transform: translateY(-50%);
  font-size: 10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  color: #342a2a80;
  width: 100px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

const FormContainer = styled.form`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 31px;
`;

const ConfirmPersonalDialog = styled(ConfirmationDialog)`
  width: 680px;

  > div {
    margin: 50px 56px 50px 50px;
  }

  p {
    margin: 0;
  }
`;

const ConfirmPersonalDialogDescStyle = css`
  font-size: 14px;
  margin-bottom: 23px;
`;

let confirmPersonalDialogShownDeferred = new DeferredPromise();
const CONFIRM_CARD_OPTIONS = {
  usePaymentMethodId: false
};
const AccountCardPayment = ({
  setThanksModalVisible = () => null,
  setIsSubmitted = () => null,
  captureError = () => null,
  invoiceUrlRef
}) => {
  const dispatch = useDispatch();
  const optionsRef = useRef();

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const confirmCardSetup = useConfirmCardSetup(
    accountCardDetails && accountCardDetails.cardLast4 ? undefined : CONFIRM_CARD_OPTIONS
  );

  const isBlackUser = useSelector(isBlackUserSelector);
  const hasLinkedPortfolioAsCustodian = useSelector(hasLinkedPortfolioAsCustodianSelector);
  const defaultSelectedFrequency = useDefaultSelectedFrequency();
  const subscribeBtnSuffix = useSelector(subscribeBtnSuffixSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(defaultSelectedFrequency);
  const [showConfirmPersonalDialog, setShowConfirmPersonalDialog] = useState(false);

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      const { payload = {} } = await dispatch(createSubscription(paymentMethodId, selectedFrequencyValue, null)).catch(
        err => {
          setIsLoading(false);
          if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
            setIsDeclinedError(true);
          }
        }
      );

      if (payload.paymentAuth && payload.paymentAuth.invoiceUrl) {
        invoiceUrlRef.current = payload.paymentAuth.invoiceUrl;
      }
    },
    [dispatch, selectedFrequencyValue, invoiceUrlRef]
  );

  const handleSubscribeAction = async e => {
    e.preventDefault();

    if (isBlackUser && hasLinkedPortfolioAsCustodian && selectedFrequencyValue !== SUBSCRIPTION_PLANS.YEARLY_BLACK) {
      setShowConfirmPersonalDialog(true);
      const allowUpdate = await confirmPersonalDialogShownDeferred;

      confirmPersonalDialogShownDeferred = new DeferredPromise();
      if (!allowUpdate) return;
    }

    if (optionsRef.current && !(await optionsRef.current.onSubmitShowModals())) {
      return;
    }

    setIsLoading(true);
    const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
      setIsLoading(false);
    });

    if (paymentIntent) {
      confirmCardSetup({
        paymentIntent,
        onResolve: result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsLoading(false);
            setIsDeclinedError(true);
          } else if (result.setupIntent.status === "succeeded") {
            onSubscriptionChange(result.setupIntent.payment_method);
            setThanksModalVisible(true);
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
            setPaymentErrorMsg(result.setupIntent.last_setup_error);
            setIsDeclinedError(true);
          }
        }
      });
    }

    setIsSubmitted(true);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const handleRemoveCardAction = useCallback(
    e => {
      e.preventDefault();
      setIsRemoveLoading(true);
      dispatch(detachSubscriptionCard(selectedFrequencyValue));
    },
    [dispatch, selectedFrequencyValue]
  );

  const handleRadioButtonSelection = value => {
    setSelectedFrequencyValue(value);
  };

  const handleDialogPositiveButtonClick = () => {
    confirmPersonalDialogShownDeferred.resolve(true);
    setShowConfirmPersonalDialog(false);
  };

  const handleDialogNegativeButtonClick = () => {
    confirmPersonalDialogShownDeferred.resolve(false);
    setShowConfirmPersonalDialog(false);
  };

  return (
    <Container>
      <FormContainer onSubmit={handleSubscribeAction}>
        <AccountSubscriptionOptions
          ref={optionsRef}
          selectedFrequencyValue={selectedFrequencyValue}
          handleRadioButtonSelection={handleRadioButtonSelection}
        />
        {accountCardDetails && accountCardDetails.cardLast4 ? (
          <CardContainer>
            <CardDetails>
              <CardInfo>
                {accountCardDetails.cardBrand} **** {accountCardDetails.cardLast4} • Exp{" "}
                {accountCardDetails.cardExpiryMonth}/{accountCardDetails.cardExpiryYear}
              </CardInfo>
            </CardDetails>
            <ChangeAction
              title={i18n.t("removeCard")}
              data-cy="removeCardBtn"
              onClick={handleRemoveCardAction}
              isLoading={isRemoveLoading}
            />
          </CardContainer>
        ) : (
          <InputCardContainer options={options} />
        )}
        <SubscribeBtn
          title={i18n.t("subscribe") + subscribeBtnSuffix}
          data-cy="subscribeButton"
          hasBtnTextSuffix={!!subscribeBtnSuffix}
          onClick={handleSubscribeAction}
          isLoading={isLoading}
        />
        <NFTDiscountMessage accountPlan={selectedFrequencyValue} />
      </FormContainer>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
      {showConfirmPersonalDialog && (
        <ConfirmPersonalDialog
          descriptionStyle={ConfirmPersonalDialogDescStyle}
          title={i18n.t("blackPaywalls.continueBlackToPersonal.title")}
          description={i18n.t("blackPaywalls.continueBlackToPersonal.desc")}
          positiveButtonTitle={`${i18n.t("yes")}, ${i18n.t("remove")}`}
          negativeButtonTitle={i18n.t("goBackLink")}
          handleNegativeButtonClick={handleDialogNegativeButtonClick}
          handlePositiveButtonClick={handleDialogPositiveButtonClick}
        />
      )}
    </Container>
  );
};

export default isMobileDevice ? MobileAccountCardPayment : AccountCardPayment;

import React, { useEffect } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";

import { updateUserPreferences, userPreferencesSelector, blackFeatureTriggeredActionSelector } from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Dialog = styled(ConfirmationDialog)`
  width: 655px;
  padding-left: 15px;

  ol {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    row-gap: 10px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 21px;
    margin-bottom: 0;
  }

  button {
    margin-bottom: 5px;

    &[data-cy="positiveButton"] {
      width: 170px;
    }

    &[data-cy="negativeButton"] {
      min-width: 119px;
      margin-left: 17px;
    }
  }
`;

const BlackHeadsUpDialog = ({ onPositiveButtonClick, onMoreDetailsClick, featurePaywall }) => {
  const dispatch = useDispatch();

  const userPreferences = useSelector(userPreferencesSelector);
  const blackFeatureTriggeredAction = featurePaywall
    ? featurePaywall
    : useSelector(blackFeatureTriggeredActionSelector);

  useEffect(() => {
    if (!userPreferences.isBlackHeadsUpShown[blackFeatureTriggeredAction]) {
      return () => {
        dispatch(
          updateUserPreferences({
            isBlackHeadsUpShown: {
              ...userPreferences.isBlackHeadsUpShown,
              [blackFeatureTriggeredAction]: true
            }
          })
        );
      };
    }

    onPositiveButtonClick();

    // eslint-disable-next-line
  }, [userPreferences, blackFeatureTriggeredAction]);

  if (userPreferences.isBlackHeadsUpShown[blackFeatureTriggeredAction]) {
    return null;
  }

  return (
    <Dialog
      title={i18n.t("blackPaywalls.headsUpTitle")}
      description={i18n.t(`blackPaywalls.headsUpDesc_${blackFeatureTriggeredAction}`)}
      positiveButtonTitle={i18n.t("gotIt")}
      negativeButtonTitle={i18n.t("moreDetails")}
      handlePositiveButtonClick={onPositiveButtonClick}
      handleNegativeButtonClick={onMoreDetailsClick}
    />
  );
};

export default BlackHeadsUpDialog;

import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import TextInput from "components/inputs/TextInput";
import { connect } from "react-redux";
import { SHOW_DETAIL_TYPE } from "components/beneficiary/BeneficiaryComponentExports";
import {
  withRouter,
  beneficiarySelector,
  beneficiariesSelector,
  updateBeneficiaries,
  userCountryCodeSelector,
  isSharedPortfolioUserSelector,
  portfolioTreeReverseLinkedIdMapSelector,
  currentPortfolioSelector
} from "@kubera/common";
import ContextMenu from "components/contextmenu/ContextMenu";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import Loader from "components/loader/Loader";
import LinkedBeneficiaryDetails from "./LinkedBeneficiaryDetails";

const TextEntry = styled(TextInput)`
  width: 314px;
  height: 45px; 
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  border: ${props => (props.error ? "solid 1px rgba(255, 0, 0, 0.4);" : "solid 1px rgba(0, 0, 0, 0.4);")}
  background: ${props => props.theme.popoverBackgroundColor};
  text-indent: 5px;
  padding: 15px 15px 15px 10px;
  outline: 0;
  box-sizing: border-box;
  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

const TextEntryWrapper = styled.div``;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  background: ${props => props.theme.modifyBeneficiaryContainerBoxBG};
  border: ${props => props.theme.modifyBeneficiaryContainerBoxBR};
  box-sizing: border-box;
  margin-top: 60px;
  padding: 50px;

  & input {
    background: #fff;

    ::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const TitleBox = styled.div`
  display: "block";
`;

const Title = styled.div`
  width: 210px;
  line-height: 36px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.45px;
  display: inline-block;
`;

const ColumnCaption = styled.div`
  width: 64px;
  height: 11px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  padding-top: ${props => (props.error ? "17px" : "20px")}
  padding-bottom: 5px;
`;

const ActionBar = styled.div`
  margin-top: 5px;
`;

const IntroActionBar = styled.div`
  margin-top: 35px;
`;

const SubAccountUserText = styled.div`
  margin-top: 35px;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
`;

const SaveButton = styled.div`
  width: 144px;
  height: 44px;
  line-height: 44px;
  background-color: ${props => props.theme.primaryButtonBackgroundColor};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${props => props.theme.primaryButtonColor};
  display: table-cell;
  cursor: pointer;
  text-align: center;
  float: left;
`;

const SaveActionLink = styled.div`
  display: inline;
  font-size: 14px;
  line-height: 19.6px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
`;

const CancelButton = styled.div`
  margin-left: 20px;
  width: 134px;
  height: 44px;
  line-height: 44px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  display: table-cell;
  cursor: pointer;
  text-align: center;
  float: left;
  background: ${props => props.theme.backgroundSecondaryBtn};
  border: 1px solid ${props => props.theme.borderColorSecondaryBtn};
  box-sizing: border-box;
  color: ${props => props.theme.txtColorSecondaryBtn};
`;

const ErrorText = styled.p`
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

const Note = styled.div`
  padding-top: ${props => (props.error ? "14px" : "16px")};
  padding-bottom: 32px;
  font-size: 11px;
`;

const Content = styled.div`
  width: 316px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19.6px;
  letter-spacing: normal;
  padding-top: 10px;
  white-space: pre-wrap;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const SaveActionLinkContentContainer = styled(Content)`
  padding-top: 0;
  margin-top: -15px;
`;

const ContentLink = styled.a`
  display: inline;
  color: ${props => props.theme.linkColor};
  text-decoration-line: underline;
`;
const BigText = styled.p`
  font-feature-settings: "ss01" on;
  font-size: 18px;
  margin-top: 14px;
  margin-bottom: 20px;
`;

const AlertContainerBox = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 680px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  margin-top: 164px;
  padding: 50px;
  box-sizing: border-box;
`;

const AlertTitle = styled.div`
  width: 271px;
  height: 23px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const AlertSubTitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding-top: 5px;
`;

const AlertButtonBlack = styled.div`
  width: 167px;
  height: 44px;
  line-height: 44px;
  background-color: #000000;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: table-cell;
  cursor: pointer;
  text-align: center;
  float: left;
  box-sizing: border-box;
`;

const AlertButtonWhite = styled(AlertButtonBlack)`
  width: 137px;
  background-color: #ffffff;
  margin-left: 20px;
  border: solid 1px #000000;
  color: #000000;
`;

const AlertActionBar = styled.div`
  padding-top: 30px;
`;

const UserInfo = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
`;

const UPDATE_CONFIRM_STATUS_NONE = 0;
const UPDATE_CONFIRM_STATUS_ONLY_HERE = 1;
const UPDATE_CONFIRM_STATUS_EVERYWHERE = 2;

class ModifyBeneficiaryComponent extends React.Component {
  constructor(props) {
    super(props);
    let caption = i18n.t("beneficiary.beneficiaryAddTitle");
    let showIntro = false;
    if (props.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_ADD) {
      showIntro = true;
    } else if (props.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_EDIT) {
      showIntro = false;
    } else {
      caption = i18n.t("beneficiary.trustedAngelAddTitle");
      showIntro = false;
    }
    this.state = {
      caption: caption,
      portfolioId: props.portfolioId,
      viewType: props.viewType,
      name: "",
      email: "",
      emailErrTxt: "",
      showIntro: showIntro,
      existingContact: false,
      isModalOpen: false,
      isLoading: false
    };

    this.handleNamechange = this.handleNamechange.bind(this);
    this.handleEmailchange = this.handleEmailchange.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showContactMenu = this.showContactMenu.bind(this);
    this.handleContactSelection = this.handleContactSelection.bind(this);
    this.hideContactMenu = this.hideContactMenu.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.contactMenuRef = React.createRef();
    this.nameInput = React.createRef();

    addKeyboardEventListener(this.handleKeyDown);
  }

  showContactMenu(value) {
    if (!this.nameInput.current) {
      console.log("Invalid current");
      return;
    }

    const targetPosition = this.nameInput.current.getBoundingClientRect();

    if (!this.state.contacts || this.state.contacts.length === 0) {
      this.hideContactMenu();
      return;
    }

    const seed = value.toLowerCase();
    var re = new RegExp("(\\b" + seed + "(?:(?!\\b).)*)", "i");
    const matchingContacts = this.state.contacts.filter(function(contact) {
      const match = contact.name.search(re);
      return match > -1;
    });

    if (matchingContacts.length === 0) {
      this.hideContactMenu();
      return;
    }

    this.contactMenuRef.current.show(
      [matchingContacts],
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      this.nameInput.current
    );
  }

  hideContactMenu() {
    if (this.contactMenuRef.current.isVisible() === true) {
      this.contactMenuRef.current.dismiss();
    }
  }

  handleContactSelection(contact) {
    this.contactMenuRef.current.dismiss();
    this.setState({ name: contact.name, email: contact.email });
  }

  componentDidMount() {
    this.updateValues();
    this.populateContacts();
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.props.onCancel();
    }
    if (event.key === "Enter") {
      this.handleSaveButtonClick();
    }
    return false;
  }

  componentDidUpdate(oldProps) {}

  handleNameKeyUp(event) {}

  populateContacts() {
    const beneficiaries = this.props.beneficiaries;
    const contacts = [];

    if (!beneficiaries) {
      console.log("beneficiaries EMPTY");
      return;
    }

    let index = 0;
    const currentOtherContact = this.getValues(true);

    for (const beneficiary of beneficiaries) {
      if (beneficiary.name && beneficiary.name.length > 0) {
        const contact = {
          name: beneficiary.name,
          email: beneficiary.email,
          id: index,
          label: beneficiary.name,
          description: beneficiary.email
        };

        if (currentOtherContact.name !== contact.name || currentOtherContact.email !== contact.email) {
          const isExists = contacts.filter(function(item) {
            return item.name === contact.name && item.email === contact.email;
          })[0];

          if (isExists === undefined) {
            contacts.push(contact);
            index = index + 1;
          }
        }
      }

      if (beneficiary.angelName && beneficiary.angelName.length > 0) {
        const contact = {
          name: beneficiary.angelName,
          email: beneficiary.angelEmail,
          id: index,
          label: beneficiary.angelName,
          description: beneficiary.angelEmail
        };

        if (currentOtherContact.name !== contact.name || currentOtherContact.email !== contact.email) {
          const isExists = contacts.filter(function(item) {
            return item.name === contact.name && item.email === contact.email;
          })[0];

          if (isExists === undefined) {
            contacts.push(contact);
            index = index + 1;
          }
        }
      }
    }
    this.setState({ contacts: contacts });
  }

  getBeneficiaryValues() {
    const beneficiary = this.props.beneficiary;
    const name = beneficiary && beneficiary.name ? beneficiary.name : "";
    const email = beneficiary && beneficiary.email ? beneficiary.email : "";
    return { name, email };
  }

  getTrustedAngelValues() {
    const beneficiary = this.props.beneficiary;
    const name = beneficiary && beneficiary.angelName ? beneficiary.angelName : "";
    const email = beneficiary && beneficiary.angelEmail ? beneficiary.angelEmail : "";

    return { name, email };
  }

  getValues(other = false) {
    if (
      this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_ADD ||
      this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_EDIT
    ) {
      if (other === false) {
        return this.getBeneficiaryValues();
      } else {
        return this.getTrustedAngelValues();
      }
    } else {
      if (other === false) {
        return this.getTrustedAngelValues();
      } else {
        return this.getBeneficiaryValues();
      }
    }
  }

  updateValues() {
    const values = this.getValues();
    this.setState({ name: values.name, email: values.email });
  }

  handleNamechange = e => {
    const value = e.target.value;

    this.setState({ name: value });
    if (value.length === 0) {
      this.hideContactMenu();
      return;
    }
    this.showContactMenu(value);
  };

  handleEmailchange = e => {
    this.setState({ email: e.target.value.trim() });
  };

  handleCancel = e => {
    this.props.onCancel();
  };

  handleSaveButtonClick = async () => {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ emailErrTxt: "", isLoading: true });
    let isErrEmail = false;
    const errRequiredTxt = i18n.t("required");
    const errWrongFormatTxt = i18n.t("wrongFormat");
    if (this.state.email.trim().length === 0) {
      this.setState({ emailErrTxt: errRequiredTxt });
      isErrEmail = true;
    } else {
      let emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
      if (!emailRegex.test(this.state.email.trim())) {
        this.setState({ emailErrTxt: errWrongFormatTxt });
        isErrEmail = true;
      }
    }

    this.setState({
      isLoading: false
    });

    if (isErrEmail) {
      return;
    }

    const values = this.getValues();
    if (values.name === this.state.name.trim() && values.email === this.state.email.trim()) {
      console.log("No change detected");
    } else {
      if (this.saveBeneficiaryDetails() === false) {
        return;
      }
    }

    this.props.onSave();
  };

  saveBeneficiaryDetails(updateConfirmStatus = UPDATE_CONFIRM_STATUS_NONE) {
    let currentBeneficiary = this.props.beneficiary;
    let beneficiary = {};
    if (!currentBeneficiary) {
      beneficiary["portfolioId"] = this.state.portfolioId;
    } else {
      const currentBeneficiary = this.props.beneficiary;
      beneficiary = Object.assign({}, currentBeneficiary);
    }
    let existingContact = false;
    if (
      this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_ADD ||
      this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_EDIT
    ) {
      existingContact = this.isMatchingWithTrustedAngel(beneficiary);
      beneficiary["name"] = this.state.name.trim();
      beneficiary["email"] = this.state.email.trim();
    } else {
      existingContact = this.isMatchingWithBeneficiary(beneficiary);
      beneficiary["angelName"] = this.state.name.trim();
      beneficiary["angelEmail"] = this.state.email.trim();
    }

    this.setState({ existingContact: existingContact });
    if (existingContact === true) {
      return false;
    }

    const multipleUpdatePossible = this.isMultiplePortfolioAffected();
    if (multipleUpdatePossible === true) {
      if (updateConfirmStatus === UPDATE_CONFIRM_STATUS_NONE) {
        this.confirmUpdate();
        return false;
      } else if (updateConfirmStatus === UPDATE_CONFIRM_STATUS_EVERYWHERE) {
        this.updateAllBeneficiries([beneficiary]);
        return true;
      }
    }

    this.props.updateBeneficiaries([beneficiary]);
    return true;
  }

  updateEveryWhere() {
    this.saveBeneficiaryDetails(UPDATE_CONFIRM_STATUS_EVERYWHERE);
    this.props.onSave();
  }

  updateOnlyHere() {
    this.saveBeneficiaryDetails(UPDATE_CONFIRM_STATUS_ONLY_HERE);
    this.props.onSave();
  }

  isMatchingWithTrustedAngel(beneficiary) {
    if (
      beneficiary.angelName &&
      beneficiary.angelName === this.state.name.trim() &&
      beneficiary.angelEmail &&
      beneficiary.angelEmail === this.state.email.trim()
    ) {
      return true;
    }
    return false;
  }

  isMatchingWithBeneficiary(beneficiary) {
    if (
      beneficiary.name &&
      beneficiary.name === this.state.name.trim() &&
      beneficiary.email &&
      beneficiary.email === this.state.email.trim()
    ) {
      return true;
    }
    return false;
  }

  isMultiplePortfolioAffected() {
    const beneficiaries = this.props.beneficiaries;

    if (!beneficiaries) {
      return false;
    }
    const name = this.state.name.trim();
    const email = this.state.email.trim();

    for (const beneficiary of beneficiaries) {
      if (beneficiary.portfolioId !== this.state.portfolioId) {
        if (beneficiary.name === name && beneficiary.email !== email) {
          return true;
        }
        if (beneficiary.angelName === name && beneficiary.angelEmail !== email) {
          return true;
        }
      }
    }
    return false;
  }

  updateAllBeneficiries(beneficiriesToUpdate) {
    const beneficiaries = this.props.beneficiaries;

    if (!beneficiaries) {
      return false;
    }

    const name = this.state.name.trim();
    const email = this.state.email.trim();

    for (const beneficiary of beneficiaries) {
      if (beneficiary.portfolioId !== this.state.portfolioId) {
        if (beneficiary.name === name && beneficiary.email === email) {
          const item = Object.assign({}, beneficiary);
          item["name"] = name;
          item["email"] = email;
          beneficiriesToUpdate.push(item);
        }

        if (beneficiary.angelName === name && beneficiary.angelEmail === email) {
          const item = Object.assign({}, beneficiary);
          item["angelName"] = name;
          item["angelEmail"] = email;
          beneficiriesToUpdate.push(item);
        }
      }
    }

    if (beneficiriesToUpdate.length > 0) {
      this.props.updateBeneficiaries(beneficiriesToUpdate);
    }

    return true;
  }

  handleAddButtonClick = () => {
    this.setState({ showIntro: false });
  };

  handleOverlayDismiss = () => {
    this.dismiss();
  };

  dismiss = () => {};

  confirmUpdate() {
    this.setState({
      isModalOpen: true
    });
  }

  onCloseModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    const emailValid = this.state.emailErrTxt === "";

    const { reversePortfolioLinkedIdMap, currentPortfolio } = this.props;

    return (
      <Container>
        {this.state.showIntro === false ? (
          <ContainerBox>
            <Title>{this.state.caption}</Title>
            <ColumnCaption>{i18n.t("fullname").toUpperCase()}</ColumnCaption>
            <TextEntryWrapper ref={this.nameInput}>
              <TextEntry
                data-private
                data-cy="fullNameBeneficiary"
                placeholder={i18n.t("fullname")}
                value={this.state.name}
                onChange={this.handleNamechange}
                autoFocus
                autocomplete="off"
              />
            </TextEntryWrapper>
            <ContextMenu dataPrivate ref={this.contactMenuRef} onSelection={this.handleContactSelection}></ContextMenu>
            <ColumnCaption>{i18n.t("email").toUpperCase()}</ColumnCaption>
            <TextEntry
              data-private
              data-cy="emailBeneficiary"
              error={!emailValid}
              placeholder={i18n.t("email")}
              value={this.state.email}
              onChange={this.handleEmailchange}
              onKeyDown={this.handleKeyDown}
              autocomplete="off"
            />
            <ErrorText>{this.state.emailErrTxt}</ErrorText>
            <Note>
              {this.props.viewType === 3
                ? i18n.t("beneficiary.addNoteAngel")
                : i18n.t("beneficiary.addNoteBeneficiary")}
            </Note>
            <ActionBar>
              <SaveButton data-cy="saveBeneficiary" onClick={this.handleSaveButtonClick}>
                {!this.state.isLoading ? i18n.t("save") : <Loader />}
              </SaveButton>
              <CancelButton onClick={this.handleCancel}>{i18n.t("cancel")} </CancelButton>
            </ActionBar>
            {this.state.existingContact && this.state.viewType === SHOW_DETAIL_TYPE.TRUSTED_ANGEL_EDIT ? (
              <ErrorText>{i18n.t("beneficiary.matchingBeneficiary")}</ErrorText>
            ) : null}
            {this.state.existingContact &&
            (this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_ADD ||
              this.state.viewType === SHOW_DETAIL_TYPE.BENEFICIARY_EDIT) ? (
              <ErrorText>{i18n.t("beneficiary.matchingTrustedAngel")}</ErrorText>
            ) : null}
          </ContainerBox>
        ) : (
          <ContainerBox>
            <TitleBox>
              <Title>{i18n.t("beneficiary.title")}</Title>
              <BigText>{`${i18n.t("beneficiary.bigText")} `}</BigText>
            </TitleBox>
            {!reversePortfolioLinkedIdMap[currentPortfolio.id] ? (
              <Content>
                {i18n.t("beneficiary.intro")}
                <ContentLink
                  href="https://help.kubera.com/article/10-ensure-safe-transfer-portfolio-to-beneficiary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n.t("beneficiary.introLink")}
                </ContentLink>
              </Content>
            ) : (
              <LinkedBeneficiaryDetails />
            )}
            <IntroActionBar>
              {this.props.isSharedPortfolioAccountUser === false &&
                (!reversePortfolioLinkedIdMap[currentPortfolio.id] ? (
                  <SaveButton data-cy="addEditBeneficiaryButton" onClick={this.handleAddButtonClick}>
                    {i18n.t("beneficiary.addBeneficiaryButtonCaption")}
                  </SaveButton>
                ) : (
                  <SaveActionLinkContentContainer>
                    {i18n.t("beneficiary.addBeneficiaryButtonCaptionWhenLinked")}
                    <SaveActionLink data-cy="addEditBeneficiaryButton" onClick={this.handleAddButtonClick}>
                      {i18n.t("beneficiary.addBeneficiaryButtonCaption")}
                    </SaveActionLink>
                  </SaveActionLinkContentContainer>
                ))}
              {this.props.isSharedPortfolioAccountUser === true && (
                <SubAccountUserText>{i18n.t("beneficiary.subAccountUserText")}</SubAccountUserText>
              )}
            </IntroActionBar>
          </ContainerBox>
        )}
        {this.state.isModalOpen && (
          <DialogOverlay onDismiss={this.onCloseModal}>
            <AlertContainerBox>
              <AlertTitle>{i18n.t("beneficiary.multipleUpdateConfirmTitle")}</AlertTitle>
              <AlertSubTitle>
                {i18n.t("beneficiary.multipleUpdateConfirmSubTitlePart1")}
                &nbsp;<UserInfo>{this.state.name}</UserInfo>&nbsp;
                {i18n.t("beneficiary.multipleUpdateConfirmSubTitlePart2")}
              </AlertSubTitle>
              <AlertActionBar>
                <AlertButtonBlack
                  onClick={() => {
                    this.onCloseModal();
                    this.updateEveryWhere();
                  }}
                >
                  {i18n.t("beneficiary.updateEveryWhere")}
                </AlertButtonBlack>
                <AlertButtonWhite
                  onClick={() => {
                    this.onCloseModal();
                    this.updateOnlyHere();
                  }}
                >
                  {i18n.t("beneficiary.updateOnlyHere")}
                </AlertButtonWhite>
              </AlertActionBar>
            </AlertContainerBox>
          </DialogOverlay>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  beneficiary: beneficiarySelector(state, props.portfolioId),
  beneficiaries: beneficiariesSelector(state),
  userCountryCode: userCountryCodeSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  reversePortfolioLinkedIdMap: portfolioTreeReverseLinkedIdMapSelector(state)
});

const mapDispatchToProps = {
  updateBeneficiaries: updateBeneficiaries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModifyBeneficiaryComponent));

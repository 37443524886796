import React, { useMemo, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";

import { replaceLinksWithAnchorTags } from "@kubera/common";

import { TextAreaStyle } from "./TextArea";

const InputTextDiv = styled.div`
  ${TextAreaStyle};

  color: ${props => props.isShowingPlaceholder && "rgba(0, 0, 0, 0.4)"};

  a {
    color: ${props => props.theme.linkColor};
  }

  white-space: pre-wrap;
`;

const RichTextArea = ({
  className,
  placeholder,
  value,
  readOnly,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  ...other
}) => {
  const [isFocused, setIsFocused] = useState(null);

  // Remove html tags from value
  const valueWithTagsReplaced = useMemo(() => (value ? value.replace(/<[^>]*>?/gm, "") : value), [value]);

  const displayValue = useMemo(() => {
    // Convert links in value string to anchor tags
    return DOMPurify.sanitize(
      isFocused ? value : valueWithTagsReplaced ? replaceLinksWithAnchorTags(value) : placeholder
    );
  }, [isFocused, placeholder]);

  const handleOnChange = e => {
    const value = e.target.innerHTML;
    onChange(value === null || value === undefined ? "" : value);
  };

  const handleOnFocus = e => {
    if (readOnly) {
      e.preventDefault();
      return;
    }
    setIsFocused(true);
    onFocus(e);
  };

  const handleOnBlur = e => {
    setIsFocused(false);
    onBlur(e);
  };

  const handleOnPaste = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const hangleOnKeyDown = e => {
    if (["Escape"].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      e.target.blur();
    }
  };

  const handleOnMouseDown = e => {
    // if target is link prevent focus and is right click
    if (e.target.tagName === "A") {
      e.preventDefault();
    }
  };

  return (
    <InputTextDiv
      contentEditable={isFocused && !readOnly ? true : false}
      isShowingPlaceholder={valueWithTagsReplaced ? false : true}
      className={className}
      tabIndex={0}
      {...other}
      onInput={handleOnChange}
      onKeyDown={hangleOnKeyDown}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onPaste={handleOnPaste}
      onMouseDown={handleOnMouseDown}
      dangerouslySetInnerHTML={{ __html: displayValue }}
    />
  );
};

export default RichTextArea;

import React from "react";
import {
  recapDataSelector,
  getRecapChartOptionForPortfolio,
  getRecapChartTypeForPortfolio,
  getRecapChartTimeRangeForPortfolio,
  recapRawDataSelector,
  chartTimeRange,
  getDateInKuberaFormat,
  recapChartOptions,
  RECAP_CELL_CATEGORY_TYPE_DATE,
  RECAP_CELL_CATEGORY_TYPE_TEXT,
  getColumnarGridColumnHeaderString
} from "@kubera/common";
import { useSelector } from "react-redux";
import { useTheme } from "theme";

import styled from "styled-components";
import ColumnarChartMenuComponent from "./ColumnarChartMenuComponent";
import { getGridDataForRecapLoader, GridColumnData, cellType } from "components/grid/GridDataModel";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import ColumnarChartCellComponent from "./ColumnarChartCellComponent";

const Container = styled.div``;
const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 5px;
  margin-top: 30px;
`;
const Grid = styled(GridComponentWrapper)`
  margin-top: -1px;
`;
const ColumnarChartGridHeaderRowContainer = styled.div`
display : flex
flex:1
margin-bottom : 3px`;

const ColumnarChartGridHeaderRow = styled.div`
  display: inline-flex;
  flex: 1;
  height: 40px;
  position: relative;
`;

const ColumnarGridHeaderSection = styled.div`
  position: sticky;
  top: 0px;
  z-index: 500;
`;

const ColumnarChartGridHeaderCell = styled(ColumnarChartCellComponent)``;

const getPreviousDateForTimeRange = (date, selectedTimeRange) => {
  switch (selectedTimeRange) {
    case chartTimeRange.TODAY:
      return new Date();
    case chartTimeRange.DAILY:
      return new Date(date.setDate(date.getDate() - 1));
    case chartTimeRange.WEEKLY:
      const dayOfWeek = date.getDay();
      return new Date(date.setDate(date.getDate() - dayOfWeek - 1));
    case chartTimeRange.MONTHLY:
      return new Date(date.setDate(0));
    case chartTimeRange.QUARTERLY:
      const currentMonth = date.getMonth();
      const lastQuarterMonth = currentMonth - (currentMonth % 3);
      return new Date(date.setMonth(lastQuarterMonth, 0));
    case chartTimeRange.YEARLY:
      return new Date(date.setFullYear(date.getFullYear() - 1, 11, 31));
  }
};

const getGridHeaderRow = (noOfColumns, selectedTimeRange) => {
  let gridHeaderRow = [];
  let currentDate = new Date();
  for (let i = 0; i < noOfColumns; i++) {
    let date = i === 0 ? currentDate : getPreviousDateForTimeRange(currentDate, selectedTimeRange);
    gridHeaderRow.push({
      value: getColumnarGridColumnHeaderString(getDateInKuberaFormat(date), selectedTimeRange),
      type: RECAP_CELL_CATEGORY_TYPE_DATE
    });
    currentDate = date;
  }
  gridHeaderRow = [
    { value: "", type: cellType.RECAP_CELL, isExpandCollapseIconCell: true },
    { value: "", type: cellType.RECAP_CELL, isChartsCell: true },
    { value: "", type: RECAP_CELL_CATEGORY_TYPE_TEXT },
    ...gridHeaderRow
  ];
  return gridHeaderRow;
};

const RecapLoaderComponent = props => {
  const theme = useTheme();
  const selectedChartOptions = useSelector(getRecapChartOptionForPortfolio);
  const selectedChartType = useSelector(getRecapChartTypeForPortfolio);
  const selectedTimeRange = useSelector(getRecapChartTimeRangeForPortfolio);
  let noOfColumns = selectedTimeRange === chartTimeRange.TODAY ? 1 : props.noOfColumns;
  const gridHeaderRow = getGridHeaderRow(noOfColumns, selectedTimeRange);
  const columns = gridHeaderRow.map(column => {
    return new GridColumnData(column.value, true, false, false);
  });
  const gridData = getGridDataForRecapLoader(
    selectedChartOptions,
    selectedTimeRange,
    selectedChartType,
    columns,
    noOfColumns
  );
  const gridOptions = {
    getRowStyle: (row, rowIndex, sectionIndex, isLastRow, isLastSection, category) => {
      if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
        return {
          borderTop: `${
            category === "categoryHeader" && sectionIndex !== 0 && !row.isArchivedCategory
              ? "6px solid #636363"
              : category === "sheetHeader" || row.isArchivedCategory
              ? "4px solid #636363"
              : category === "sectionHeader"
              ? "2px solid #636363"
              : row.isCollapsed || !isLastSection
              ? `1px solid ${theme.gridSectionBorderColor}`
              : "none"
          }`,
          borderBottom: `${
            isLastSection
              ? category === "sheetHeader" ||
                category === "sectionHeader" ||
                (category === "categoryHeader" && row.isArchivedCategory)
                ? row.isCollapsed
                  ? "2px solid #636363"
                  : category &&
                    (category === "sectionHeader" || category === "categoryHeader" || category === "sheetHeader")
                  ? `1px solid ${theme.gridSectionBorderColor}`
                  : "none"
                : category !== "sheetHeader" && category !== "categoryHeader"
                ? `1px solid ${theme.gridSectionBorderColor}`
                : "none"
              : "none"
          }`,

          minHeight: `${row.isHeaderRow ? "50px" : "40px"} `,
          display: "flex",
          flex: 1,
          // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
          isSeparateStyle: true
          //boxSizing: "border-box"
        };
      } else {
        return {
          borderTop: `${sectionIndex === 0 && rowIndex === 0 ? `1px solid ${theme.gridSectionBorderColor}` : "none"}`,
          borderBottom: `${
            (sectionIndex === 0 && rowIndex === 0) || (isLastRow && !isLastSection && category !== "header")
              ? "4px solid #636363"
              : rowIndex === 0 && sectionIndex !== 0
              ? "2px solid #636363"
              : `1px solid ${theme.gridSectionBorderColor}`
          }`,
          minHeight: `${row.isHeaderRow ? "50px" : "40px"} `,
          display: "flex",
          flex: 1,
          // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
          isSeparateStyle: true
          //boxSizing: "border-box"
        };
      }
    },
    getSectionStyle: (section, isLastSection, totalSections, sectionIndex) => {
      return {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        //the condition to check total sections greater than two is for Investable Assets
        //borderTop: isLastSection && totalSections > 2 ? "6px solid #636363" : "none",
        //borderTop: `${sectionIndex === 0 ? `1px solid ${theme.gridSectionBorderColor}` : "6px solid #636363"}`,
        // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
        isSeparateStyle: true
      };
    }
  };

  return (
    <Container>
      <MenuContainer>
        <ColumnarChartMenuComponent
          selectedChartOptions={selectedChartOptions}
          selectedChartType={selectedChartType}
          selectedTimeRange={selectedTimeRange}
          isLoading={true}
        />
      </MenuContainer>
      <ColumnarGridHeaderSection>
        <ColumnarChartGridHeaderRowContainer>
          <ColumnarChartGridHeaderRow>
            {gridHeaderRow.map((cell, index) => {
              return <ColumnarChartGridHeaderCell cell={cell} isGridHeader={true} key={index} noOfColumns />;
            })}
          </ColumnarChartGridHeaderRow>
        </ColumnarChartGridHeaderRowContainer>
      </ColumnarGridHeaderSection>
      <Grid
        gridData={gridData}
        noOfColumns
        selectedChartOptions={selectedChartOptions}
        selectedChartType={selectedChartType}
        gridOptions={gridOptions}
      />
    </Container>
  );
};
export default RecapLoaderComponent;

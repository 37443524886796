import { hashParams, modalValues } from "routes";

export const CHART_SETTINGS_TABS = {
  SUGGESTED_CHARTS: "SUGGESTED_CHARTS"
};

const staticMethods = {
  show: (history, location, tab, shouldReplace = false, otherLocationParts = {}) => {
    const hash = `${hashParams.MODAL}=${modalValues.CHART_SETTINGS}${tab ? `&${hashParams.TAB}=${tab}` : ""}${
      otherLocationParts.hash ? `&${otherLocationParts.hash}` : ""
    }`;

    if (shouldReplace) {
      history.replace({ ...location, ...otherLocationParts, hash });
    } else {
      history.push({ ...location, ...otherLocationParts, hash });
    }
  },
  dismiss: (history, location, shouldReplace = false, otherLocationParts = {}) => {
    const hash = "";

    if (shouldReplace) {
      history.replace({ ...location, ...otherLocationParts, hash });
    } else {
      history.push({ ...location, ...otherLocationParts, hash });
    }
  }
};

export default staticMethods;

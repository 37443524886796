import React, { useRef, useEffect, useState, cloneElement } from "react";
import styled from "styled-components";
import { Draggable } from "@hello-pangea/dnd";

const ObserverContainer = styled.div`
  position: relative;
  height: ${props => `${props.height}px`};
  margin-bottom: ${props => `${props.marginBottom}px`};
  contain: strict;
  transform: ${props => (!props.isLastFiveRows ? "scale(1, 7)" : "scale(1, 4)")};
  transform-origin: ${props => props.isLastFiveRows && "center bottom"};
  z-index: -1000;
`;

const GridRowsInViewPort = ({ children, rowHeight = 40, index, fromBottomFifthRowIndex, observer, ...props }) => {
  const isDragEnabled = props.isDragEnabled === true;

  const observerRef = useRef();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!observer) {
      return () => null;
    }
    const setItemVisibility = isIntersecting => {
      setIsVisible(isIntersecting);
    };
    const currentElementRef = observerRef.current;
    Object.defineProperty(currentElementRef, "setItemVisibility", {
      value: setItemVisibility,
      writable: false
    });
    observer.observe(currentElementRef);

    return () => {
      observer.unobserve(currentElementRef);
    };
  }, [observer]);

  return (
    <>
      <ObserverContainer
        ref={observerRef}
        isLastFiveRows={props.isLastSection && index > fromBottomFifthRowIndex}
        height={rowHeight}
        marginBottom={isVisible ? -rowHeight : 0}
      >
        &nbsp;
      </ObserverContainer>
      <Draggable
        draggableId={props.entry.viewId}
        index={props.entryIndex}
        isDragDisabled={!isVisible || isDragEnabled === false}
      >
        {(provided, snapshot) => (
          <div {...provided.draggableProps} ref={provided.innerRef}>
            {isVisible && cloneElement(children, { provided, snapshot })}
            <div {...provided.dragHandleProps} />
          </div>
        )}
      </Draggable>
    </>
  );
};

export default React.memo(GridRowsInViewPort);

import React, { useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import {
  createSubscription,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR,
  accountCardDetailsSelector,
  detachSubscriptionCard,
  subscribeBtnSuffixSelector
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { useConfirmCardSetup } from "utilities/CustomHooks";

import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement from "./AccountCardElement";
import AccountSubscriptionOptions from "./AccountSubscriptionOptions";
import useDefaultSelectedFrequency from "./useDefaultSelectedFrequency";
import NFTDiscountMessage from "./NFTDiscountMessage";

const Container = styled.div``;

const InputCardContainer = styled(AccountCardElement)`
  width: 100%;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 125%;

  .StripeElement {
    background: #fff;
  }

  & path {
    fill: ${props => props.theme.mobileTxtColor};
  }
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 251px;
  height: 50px;
  margin-bottom: 27px;
  color: #fff;
  background: ${props => props.theme.mobileColorBtn};
  font-size: 16px;
  line-height: 110%;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: #e3e3e3;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 40px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const CardDetails = styled.div`
  font-size: 16px;
  line-height: 125%;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  color: #342a2a80;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

const CONFIRM_CARD_OPTIONS = {
  usePaymentMethodId: false
};
const AccountCardPayment = ({
  setThanksModalVisible = () => null,
  setIsSubmitted = () => null,
  captureError = () => null,
  invoiceUrlRef
}) => {
  const dispatch = useDispatch();
  const optionsRef = useRef();

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const confirmCardSetup = useConfirmCardSetup(
    accountCardDetails && accountCardDetails.cardLast4 ? undefined : CONFIRM_CARD_OPTIONS
  );
  const subscribeBtnSuffix = useSelector(subscribeBtnSuffixSelector);

  const defaultSelectedFrequency = useDefaultSelectedFrequency();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(defaultSelectedFrequency);

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      const { payload = {} } = await dispatch(createSubscription(paymentMethodId, selectedFrequencyValue, null)).catch(
        err => {
          setIsLoading(false);
          if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
            setIsDeclinedError(true);
          }
        }
      );

      if (payload.paymentAuth && payload.paymentAuth.invoiceUrl) {
        invoiceUrlRef.current = payload.paymentAuth.invoiceUrl;
      }
    },
    [dispatch, selectedFrequencyValue, invoiceUrlRef]
  );

  const handleSubscribeAction = async e => {
    e.preventDefault();
    if (optionsRef.current && !(await optionsRef.current.onSubmitShowModals())) {
      return;
    }

    setIsLoading(true);
    const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
      setIsLoading(false);
    });

    if (paymentIntent) {
      confirmCardSetup({
        paymentIntent,
        onResolve: result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsLoading(false);
            setIsDeclinedError(true);
          } else if (result.setupIntent.status === "succeeded") {
            onSubscriptionChange(result.setupIntent.payment_method);
            setThanksModalVisible(true);
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
            setPaymentErrorMsg(result.setupIntent.last_setup_error);
            setIsDeclinedError(true);
          }
        }
      });
    }

    setIsSubmitted(true);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const handleRemoveCardAction = useCallback(
    e => {
      e.preventDefault();
      setIsRemoveLoading(true);
      dispatch(detachSubscriptionCard(selectedFrequencyValue));
    },
    [dispatch, selectedFrequencyValue]
  );

  const handleRadioButtonSelection = value => {
    setSelectedFrequencyValue(value);
  };

  return (
    <Container>
      <form onSubmit={handleSubscribeAction}>
        <AccountSubscriptionOptions
          ref={optionsRef}
          selectedFrequencyValue={selectedFrequencyValue}
          handleRadioButtonSelection={handleRadioButtonSelection}
        />
        {accountCardDetails && accountCardDetails.cardLast4 ? (
          <CardContainer>
            <CardDetails>
              <CardInfo>
                {accountCardDetails.cardBrand} xxxx {accountCardDetails.cardLast4} • Exp{" "}
                {accountCardDetails.cardExpiryMonth}/{accountCardDetails.cardExpiryYear}
              </CardInfo>
            </CardDetails>
            <ChangeAction
              title="✕"
              data-cy="removeCardBtn"
              onClick={handleRemoveCardAction}
              isLoading={isRemoveLoading}
            />
          </CardContainer>
        ) : (
          <InputCardContainer options={options} />
        )}
        <SubscribeBtn
          title={i18n.t("subscribe") + subscribeBtnSuffix}
          data-cy="subscribeButton"
          onClick={handleSubscribeAction}
          isLoading={isLoading}
        />
        <NFTDiscountMessage accountPlan={selectedFrequencyValue} />
      </form>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </Container>
  );
};

export default AccountCardPayment;

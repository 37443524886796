import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import {
  startTrial,
  setupPaymentIntent,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR
} from "@kubera/common";
import { ANALYTICS } from "analytics";
import { GAWrapperEvent } from "utilities/GAWrapper";
import PrimaryButton from "components/button/PrimaryButton";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement from "./AccountCardElement";
import { useConfirmCardSetup } from "utilities/CustomHooks";

const Container = styled.div``;

const CardContainer = styled(AccountCardElement)`
  width: 100%;
  margin-bottom: 7px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 136px;
  height: 43px;
  margin-bottom: 2px;
`;

const CONFIRM_CARD_OPTIONS = {
  usePaymentMethodId: false
};
const AccountInitialCardSetup = ({ className = "", setIsSubmitted = () => null, captureError = () => null }) => {
  const confirmCardSetup = useConfirmCardSetup(CONFIRM_CARD_OPTIONS);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const selectedFrequencyValue = SUBSCRIPTION_PLANS.YEARLY;
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      const data = await dispatch(startTrial(paymentMethodId)).catch(err => {
        setIsLoading(false);
        if (
          err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED ||
          err.errorCode === SUBSCRIPTION_ERROR_CODES.DUPLICATE ||
          err.errorCode === SUBSCRIPTION_ERROR_CODES.WENT_WRONG
        ) {
          if (err.extra && err.extra.invoiceUrl) {
            setPaymentErrorMsg(
              i18n.t("accountCardDeclined.descriptionWithInvoice").replace("%s%", err.extra.invoiceUrl)
            );
          }

          setIsDeclinedError(true);
        }
      });

      const user = data && data.payload ? data.payload.user : null;

      console.log("initialCC onSubscriptionChange", user);
      if (user && user.tsTrialStart) {
        console.log("initialCC accountTrialStartTs", user.tsTrialStart);
        // commented out temporarily
        // window.lintrk("track", { conversion_id: 8571129 });
        window.ire(
          "trackConversion",
          26214,
          {
            orderId: `${user.id}_trial`,
            customerId: `${user.id}`,
            customerEmail: ""
          },
          {
            verifySiteDefinitionMatch: true
          }
        );

        GAWrapperEvent({
          category: ANALYTICS.CATEGORY.ALL,
          action: ANALYTICS.ACTION.START_TRIAL
        });
      }
    },
    [dispatch]
  );

  const handleSubscribeAction = async e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
      setIsLoading(false);
    });

    if (paymentIntent) {
      confirmCardSetup({
        paymentIntent,
        onResolve: result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsLoading(false);
            setIsDeclinedError(true);
          } else if (result.setupIntent.status === "succeeded") {
            onSubscriptionChange(result.setupIntent.payment_method);
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
            setPaymentErrorMsg(result.setupIntent.last_setup_error);
            setIsDeclinedError(true);
          }
        }
      });
    }

    setIsSubmitted(true);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  return (
    <Container className={className}>
      <form onSubmit={handleSubscribeAction}>
        <CardContainer options={options} />
        <SubscribeBtn
          title={i18n.t("initialSubscription.startTrialBtn")}
          data-cy="subscribeButton"
          onClick={handleSubscribeAction}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </form>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </Container>
  );
};

export default AccountInitialCardSetup;

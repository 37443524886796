import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import GridSheet from "components/grid/GridSheet";
import { cellMode, CELL_MODE_DATA_ATTRIBUTE_KEY } from "components/grid/GridCellExports";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { GridCellData, cellType, arrangeSortKeysForIds } from "components/grid/GridDataModel";
import {
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  getSortKeyBetween,
  getAmountAndTickerFromInput,
  convertCurrency,
  custodiansWithSameParentIdSelector,
  store,
  isCryptoCurrency,
  RECAP_EXPANDED_VIEW_COLUMNS,
  getHashParams
} from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import getPrefixForDataCy from "components/grid/GridHelpers";
import GridTotalHeader from "components/grid/GridTotalHeader";
import GridSheetTitle from "components/grid/GridSheetTitle";
import { hashParams, modalValues } from "routes";
import { ScrollBarHorizontal } from "theme";
import NewAction from "./NewAction";

const Container = styled.div``;

const GridContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SheetContainer = styled.div`
  display: flex;
  position: relative;
`;

const SheetTitles = styled.div`
  display: flex;
  flex-flow: nowrap;
  white-space: nowrap;
  padding-top: 10px;
  margin-top: -10px;
  margin-bottom: 30px;
  overflow: visible;
  height: 45px;
  padding-right: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  ${ScrollBarHorizontal};
  padding-bottom: 7px;
  column-gap: 10px;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    margin-left: -3px;
  }

  :hover {
    ::-webkit-scrollbar {
      height: 13px;
    }
  }

  ::before {
    content: "";
    position: absolute;
    top: -6px;
    right: 36px;
    height: 50px;
    width: 5px; /* Adjust based on preference */
    background: transparent;
    pointer-events: none; /* Ensures it doesn't interfere with scrolling */

    ${({ isSheetsOverflowing }) =>
      isSheetsOverflowing &&
      css`
        background: linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent);
      `};
  }
`;

const SheetHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};
`;

const OptionsButton = styled.button`
  text-align: center;
  min-width: 36px;
  height: 50px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-top: -6px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 13px 13px;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:disabled {
    opacity: 0.5;
    background-color: transparent;
  }
`;

const OverflowDetectElement = styled.div`
  width: 5px;
  height: 1px;
  align-self: center;
  position: relative;
  right: 10px;
`;

const onDragEndEvent = new Event("onDragEndEvent", { bubbles: true });

class GridComponent extends React.Component {
  static getSheetHeaderStyle(isDragging, draggableStyle) {
    return {
      cursor: isDragging ? "grab" : "pointer",
      ...draggableStyle
    };
  }

  static sheetSectionSplitVals(val = "") {
    const splittedSearchVal = val.split("/");
    const sheetName = splittedSearchVal[0].trim();
    const sectionName = splittedSearchVal[1] ? splittedSearchVal[1].trim() : null;

    return { sheetName, sectionName };
  }

  constructor(props) {
    super(props);

    const initialState = {
      currentSheetIndex: props.gridData.currentSheetIndex,
      isShowingSheetContextMenu: false,
      showRemoveSheetDialog: false,
      removeSheetDialogRowCount: 0,
      isSheetsOverflowing: false,
      sectionDragging: void 0,
      isOverValidDestination: false,
      isSectionDragging: false
    };
    this.state = initialState;
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMoveToNextCell = this.handleMoveToNextCell.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleSheetSelection = this.handleSheetSelection.bind(this);
    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleContextMenuDismiss = this.handleContextMenuDismiss.bind(this);
    this.handleSheetTitleUpdate = this.handleSheetTitleUpdate.bind(this);
    this.onBeforeDragStart = this.onBeforeDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleSheetsDragEnd = this.handleSheetsDragEnd.bind(this);
    this.handleRowsDragEnd = this.handleRowsDragEnd.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this);
    this.createSection = this.createSection.bind(this);
    this.handleAddNewSection = this.handleAddNewSection.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.handleRemoveSection = this.handleRemoveSection.bind(this);
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this);
    this.createSheet = this.createSheet.bind(this);
    this.removeSheet = this.removeSheet.bind(this);
    this.handleRemoveSheet = this.handleRemoveSheet.bind(this);
    this.handleOptionsButtonClick = this.handleOptionsButtonClick.bind(this);
    this.handleRemoveSheetDialogNegativeButtonClick = this.handleRemoveSheetDialogNegativeButtonClick.bind(this);
    this.handleRemoveSheetDialogPositiveButtonClick = this.handleRemoveSheetDialogPositiveButtonClick.bind(this);
    this.setSheetTitlesRef = this.setSheetTitlesRef.bind(this);
    this.setSheetHeaderRef = this.setSheetHeaderRef.bind(this);
    this.handleSheetsObserver = this.handleSheetsObserver.bind(this);
    this.intersectionCallback = this.intersectionCallback.bind(this);
    this.handleTotalChangeClick = this.handleTotalChangeClick.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);

    this.contextMenuRef = React.createRef();
    this.newActionContextMenuRef = React.createRef();
    this.overflowElemRef = React.createRef();
    this.sheetsOverflowTooltipRef = React.createRef();
    this.absOptionsBtnRef = React.createRef();
    this.sheetTitleRefs = [];
    this.sheetTitlesRef = null;
    this.sheetHeaderRef = [];
    this.preventSheetCollapse = false;
    this.isSheetsChanged = false;
    this.intersectionCbIdleId = null;
    this.modalMatchFound = false;
    this.sheetMatchedIndex = props.gridData.sheets.length;
    this.dashboardScrollTopVal = 0;
    this.searchNavigateTimeoutId = React.createRef();

    addKeyboardEventListener(this.handleKeyDown);
    if (this.props.gridData.autoFocusFirstCell === true) {
      setTimeout(() => {
        const firstSection = this.props.gridData.sheets[0].sections[0];
        this.selectCell(0, 0, 0, firstSection.getFirstSelectableColumnIndex(true));
      }, 300);
    }
  }

  componentDidMount() {
    this.handleSheetsObserver();
  }

  componentWillUnmount() {
    localStorage.removeItem("latestCellIndexes");
    removeKeyboardEventListener(this.handleKeyDown);

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  init() {
    const urlHashSheetId = getHashParams(window.location)[hashParams.SHEET_ID];

    setTimeout(() => {
      if (this.props.gridData.currentSheetIndex !== 0) {
        return;
      }
      if (!urlHashSheetId === true) {
        this.handleSheetSelection(0);
      } else {
        this.navigateToItemIfNeeded();
      }
    }, 0);
  }

  componentDidUpdate(oldProps) {
    this.navigateToItemIfNeeded();
  }

  get selectedSheet() {
    return this.props.gridData.sheets[this.state.currentSheetIndex];
  }

  get newActionContextMenuItems() {
    if (!this.selectedSheet || !this.selectedSheet.getNewActionContextMenuItems) {
      return null;
    }
    return this.selectedSheet.getNewActionContextMenuItems();
  }

  navigateToItemIfNeeded() {
    const urlHashParams = getHashParams(window.location);
    const sheetId = urlHashParams[hashParams.SHEET_ID];
    const sectionId = urlHashParams[hashParams.SECTION_ID];
    const custodianId = urlHashParams[hashParams.ID];
    const modal = urlHashParams[hashParams.MODAL];

    if (sheetId && !modal) {
      const sheetIndex = this.props.gridData.sheets.findIndex(sheet => sheet.id === sheetId);
      if (sheetIndex !== -1 && sheetIndex !== this.state.currentSheetIndex) {
        this.setState({ currentSheetIndex: sheetIndex });
      } else if (sheetIndex === -1) {
        this.setSelectedSheetHash(0);
      }
    }
    if (custodianId && sectionId) {
      setTimeout(() => {
        document.scrollToElement(custodianId, { behavior: "smooth", block: "center" });
      }, 0);
    } else if (sectionId) {
      setTimeout(() => {
        document.scrollToElement(sectionId);
      }, 0);
    }
    if (sheetId && custodianId && !modal) {
      clearTimeout(this.searchNavigateTimeoutId.current);
      this.searchNavigateTimeoutId.current = setTimeout(() => {
        document.flashElement(custodianId);
        this.removeLocationHash();
      }, 100);
    }
  }

  removeLocationHash() {
    if (this.props.history && this.props.location) {
      this.props.history.replace({ ...this.props.location, hash: "" });
    } else {
      window.location.hash = "";
    }
  }

  handleSheetsObserver() {
    if (!this.sheetTitlesRef) {
      return;
    }

    const observerOptions = {
      root: this.sheetTitlesRef,
      rootMargin: "0px",
      threshold: [1]
    };
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      this.observer = new IntersectionObserver(this.intersectionCallback, observerOptions);
      this.observer.observe(this.overflowElemRef.current);
    }
  }

  scrollSheetsToRightEnd() {
    if (!this.sheetTitlesRef) {
      return;
    }

    this.sheetTitlesRef.scrollLeft = this.sheetTitlesRef.scrollWidth;
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, isFirstEdit) {
    const newGridData = this.props.gridData;
    const rows = newGridData.sheets[sheetIndex].sections[sectionIndex].rows;

    newRow = newRow.clone();

    if (newRow.isComplete() === true) {
      newRow.wasRowEverComplete = true;
    } else if (newRow.isEmpty() === true) {
      newRow.wasRowEverComplete = false;
    }

    if (!newRow.tsModified === false) {
      newRow.tsModified = Math.floor(new Date().getTime() / 1000);
    }

    rows[rowIndex] = newRow;
    this.props.onChange(newGridData);
    if (this.props.onRowUpdate) {
      if (!newRow.onUpdateDelay === true) {
        this.props.onRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, isFirstEdit);
      } else {
        this.pendingRowUpdate = updateRowIndex => {
          if (updateRowIndex === null || updateRowIndex === undefined) {
            updateRowIndex = rowIndex;
            this.pendingRowUpdate = null;
          }
          this.pendingRowUpdateIndex = null;

          const row = rows[updateRowIndex];
          for (const cell of row.cells) {
            if (!cell.invalidInputText === false) {
              return;
            }
          }

          this.props.onRowUpdate(sheetIndex, sectionIndex, updateRowIndex, row, isFirstEdit);
        };

        const scheduleRowUpdate = () => {
          this.pendingRowUpdateIndex = rowIndex;
          this.rowUpdateTimeout = setTimeout(() => {
            if (this.pendingRowUpdate) {
              this.pendingRowUpdate();
            }
          }, newRow.onUpdateDelay);
        };

        if (!this.rowUpdateTimeout === true) {
          scheduleRowUpdate();
        } else {
          if (
            this.pendingRowUpdateIndex !== null &&
            this.pendingRowUpdateIndex !== undefined &&
            this.pendingRowUpdateIndex !== rowIndex
          ) {
            this.pendingRowUpdate(this.pendingRowUpdateIndex);
          }
          clearTimeout(this.rowUpdateTimeout);
          this.rowUpdateTimeout = null;
          scheduleRowUpdate();
        }
      }
    }
  }

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (this.props.onCellBlur) {
      this.props.onCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex);
    }

    if (!this.rowUpdateTimeout === false && !this.pendingRowUpdate === false) {
      clearTimeout(this.rowUpdateTimeout);
      this.rowUpdateTimeout = null;
      this.pendingRowUpdate();
    }
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, newRow, removeIfEmpty = true, selectLatest = true) {
    if (!this.props.onAddNewRow === true) {
      return;
    }

    const newGridData = this.props.gridData;
    const rows = newGridData.sheets[sheetIndex].sections[sectionIndex].rows;
    const sortKeyBefore = rowIndex === 0 ? null : rows[rowIndex - 1].sortKey;
    const sortKeyAfter = rowIndex === rows.length ? null : rows[rowIndex].sortKey;
    const sortKey = getSortKeyBetween(sortKeyBefore, sortKeyAfter);

    if (!newRow) {
      newRow = this.props.getEmptyRow(sortKey);
    } else {
      newRow.sortKey = sortKey;
    }
    newRow.removeIfEmpty = removeIfEmpty;

    rows.splice(rowIndex, 0, newRow);

    this.props.onChange(newGridData);
    this.props.onAddNewRow(sheetIndex, sectionIndex, rowIndex, newRow);

    if (selectLatest) {
      this.selectCell(sheetIndex, sectionIndex, rowIndex, newRow.defaultCellIndexToSelect);
    }
  }

  handleRemoveRow(sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.props.gridData;
    const sheet = newGridData.sheets[sheetIndex];
    const section = sheet.sections[sectionIndex];
    const row = section.rows[rowIndex];

    section.rows.splice(rowIndex, 1);

    if (section.rows.length === 0) {
      if (sheet.sections.length === 1) {
        this.handleRemoveSheet(sheetIndex);
        this.props.onChange(newGridData);
        return;
      }
      this.handleRemoveSection(sheetIndex, sectionIndex);
      const sectionBeforeIndex = sectionIndex - 1;
      if (sectionBeforeIndex >= 0) {
        const section = sheet.sections[sectionBeforeIndex];
        this.selectCell(
          sheetIndex,
          sectionIndex - 1,
          section.rows.length - 1,
          section.getFirstSelectableColumnIndex(true)
        );
      }
      return;
    } else if (rowIndex > section.rows.length - 1) {
      this.selectCell(sheetIndex, sectionIndex, section.rows.length - 1, section.getFirstSelectableColumnIndex(true));
    } else {
      this.selectCell(sheetIndex, sectionIndex, rowIndex, section.getFirstSelectableColumnIndex(true));
    }

    this.props.onChange(newGridData);
    if (this.props.onRemoveRow) {
      this.props.onRemoveRow(sheetIndex, sectionIndex, rowIndex, row);
    }
  }

  deleteEmptySection(sheetIndex, sectionIndex) {
    this.removeSection(sheetIndex, sectionIndex);
  }

  handleSectionUpdate(sheetIndex, sectionIndex, newSection) {
    if (!this.props.onSectionUpdate) {
      return;
    }

    const newGridData = this.props.gridData;
    const sheet = newGridData.sheets[sheetIndex];

    sheet.sections[sectionIndex] = newSection;
    this.props.onChange(newGridData);
    this.props.onSectionUpdate(sheetIndex, sectionIndex, newSection);
  }

  createSection(sheetIndex, sectionIndex, sectionData = {}, newGridData = this.props.gridData) {
    const sections = newGridData.sheets[sheetIndex].sections;
    const sortKeyAfter = sectionIndex === sections.length ? null : sections[sectionIndex].sortKey;
    const sortKeyBefore = sectionIndex === 0 ? null : sections[sectionIndex - 1].sortKey;
    const sortKey = getSortKeyBetween(sortKeyBefore, sortKeyAfter);

    const newSection = this.props.getEmptySection(newGridData.sheets[sheetIndex].sections.length, sortKey);
    newSection.name = sectionData.name || newSection.name;

    sections.splice(sectionIndex, 0, newSection);
    this.props.onChange(newGridData);

    return { section: newSection };
  }

  handleAddNewSection(sheetIndex, sectionIndex, sectionData = {}) {
    const { section } = this.createSection(sheetIndex, sectionIndex, sectionData);
    this.props.onAddNewSection(sheetIndex, sectionIndex, section);
    this.selectCell(sheetIndex, sectionIndex, 0, 0);
  }

  removeSection(sheetIndex, sectionIndex) {
    const newGridData = this.props.gridData;
    const sheet = newGridData.sheets[sheetIndex];
    const section = sheet.sections[sectionIndex];

    sheet.sections.splice(sectionIndex, 1);
    this.props.onChange(newGridData);

    return section;
  }

  handleRemoveSection(sheetIndex, sectionIndex) {
    const section = this.removeSection(sheetIndex, sectionIndex);
    this.props.onRemoveSection(sheetIndex, sectionIndex, section);
  }

  handleSheetUpdate(sheetIndex, newSheet) {
    const newGridData = this.props.gridData;

    newGridData.sheets[sheetIndex] = newSheet;
    this.props.onChange(newGridData);
    this.props.onSheetUpdate(sheetIndex, newSheet);
  }

  createSheet(sheetData = {}, newGridData = this.props.gridData, preventEmptySection = false) {
    const sheetIndex = newGridData.sheets.length;
    const sheets = newGridData.sheets;
    const sortKeyBefore = sheets.length > 0 ? sheets[sheetIndex - 1].sortKey : "0";
    const sortKey = getSortKeyBetween(sortKeyBefore, null);

    const newSheet = this.props.getEmptySheet(sortKey);
    newSheet.name = sheetData.name || "Sheet " + (sheets.length + 1);
    if (!preventEmptySection) {
      newSheet.sections = sheetData.sections || [this.props.getEmptySection(0, "1")];
    }

    sheets.splice(sheetIndex, 0, newSheet);
    this.props.onChange(newGridData);

    return { index: sheetIndex, sheet: newSheet };
  }

  handleAddNewSheet = async (sheetData = {}) => {
    const { index, sheet } = this.createSheet(sheetData);
    this.props.onAddNewSheet(index, sheet);

    await this.handleSheetSelection(index);
    this.selectCell(index, 0, 0, 0);
    this.isSheetsChanged = true;
    this.scrollSheetsToRightEnd();
  };

  removeSheet(sheetIndex) {
    const newGridData = this.props.gridData;
    const sheets = newGridData.sheets;
    const sheet = sheets[sheetIndex];

    const newSheetIndex =
      this.state.currentSheetIndex === 0
        ? 0
        : this.state.currentSheetIndex === sheets.length - 1
        ? this.state.currentSheetIndex - 1
        : this.state.currentSheetIndex;

    sheets.splice(sheetIndex, 1);
    this.setState({
      showRemoveSheetDialog: false,
      currentSheetIndex: newSheetIndex
    });
    this.props.gridData.currentSheetIndex = newSheetIndex;

    this.props.onChange(newGridData);

    return { sheet };
  }

  handleRemoveSheet(sheetIndex) {
    if (!this.props.onRemoveSheet) {
      return;
    }

    const { sheet } = this.removeSheet(sheetIndex);
    this.props.onRemoveSheet(sheetIndex, sheet);
    this.isSheetsChanged = true;
  }

  handleSheetTitleUpdate(sheetIndex, value) {
    const newGridData = this.props.gridData;
    const sheet = newGridData.sheets[sheetIndex];

    sheet.name = value;
    this.handleSheetUpdate(sheetIndex, sheet);
  }

  handleKeyDown(e) {
    const selectedCellIndexes = GridCellData.getSelectedCellIndexes(this.getCurrentlyActiveItem());
    const isAnyCellSelected =
      selectedCellIndexes !== null && this.getCurrentlyActiveItem().id.includes(this.props.gridData.id);
    // Arrow keys for cell navigation
    if (e.key === "ArrowRight" || e.key === "ArrowLeft" || e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (!isAnyCellSelected && e.altKey === true) {
        const currentSheetIndex = this.state.currentSheetIndex;
        if (e.key === "ArrowRight") {
          if (currentSheetIndex + 1 < this.props.gridData.sheets.length) {
            this.handleSheetSelection(currentSheetIndex + 1);
          }
          return true;
        } else if (e.key === "ArrowLeft") {
          if (currentSheetIndex - 1 >= 0) {
            this.handleSheetSelection(currentSheetIndex - 1);
          }
          return true;
        }
      }

      // Ignore arrow keys if anything other than the cells is already focused
      if (this.getCurrentlyActiveItem() !== null && isAnyCellSelected === false) {
        return true;
      }

      if (this.getCurrentlyActiveItem() === null && isAnyCellSelected === false) {
        const firstSelectableCell = this.props.gridData.sheets[0].sections[0].getFirstSelectableColumnIndex(false);
        this.selectCell(this.state.currentSheetIndex, 0, 0, firstSelectableCell);
        e.preventDefault();
        return true;
      }

      // If cell is in edit mode ignore all these events
      const currentCellMode = e.target.getAttribute(CELL_MODE_DATA_ATTRIBUTE_KEY);
      if (currentCellMode && currentCellMode === cellMode.EDIT) {
        return false;
      }

      const gridData = this.props.gridData;
      const sheetIndex = selectedCellIndexes[0];
      const sectionIndex = selectedCellIndexes[1];
      const rowIndex = selectedCellIndexes[2];
      const cellIndex = selectedCellIndexes[3];
      const section = gridData.sheets[sheetIndex].sections[sectionIndex];

      switch (e.key) {
        case "ArrowLeft":
          if (cellIndex === section.getFirstSelectableColumnIndex(false)) {
            return true;
          } else {
            this.selectCell(
              sheetIndex,
              sectionIndex,
              rowIndex,
              section.getPreviousSelectableColumnIndex(false, cellIndex),
              false
            );
          }
          break;

        case "ArrowRight":
          if (cellIndex === section.getLastSelectableColumnIndex(false)) {
            return true;
          } else {
            this.selectCell(sheetIndex, sectionIndex, rowIndex, section.getNextSelectableColumnIndex(false, cellIndex));
          }
          break;

        case "ArrowUp":
          if (rowIndex === 0 && sectionIndex === 0) {
            return;
          }
          if (rowIndex === 0 && sectionIndex > 0) {
            const sectionToSelect = gridData.sheets[sheetIndex].sections[sectionIndex - 1];
            const isRecapSection = sectionToSelect.isRecapSection;
            const isSectionCollapsed = sectionToSelect.isCollapsed;
            if (isRecapSection && isSectionCollapsed) {
              this.selectCell(sheetIndex, sectionIndex - 1, 0, cellIndex);
            } else {
              const rowIndexToSelect = gridData.sheets[sheetIndex].sections[sectionIndex - 1].rows.length - 1;
              this.selectCell(sheetIndex, sectionIndex - 1, rowIndexToSelect, cellIndex);
            }
          } else {
            this.selectCell(sheetIndex, sectionIndex, rowIndex - 1, cellIndex);
          }
          break;

        case "ArrowDown":
          const currentSheetSectionCount = gridData.sheets[sheetIndex].sections.length;
          const currentSectionEntryCount = gridData.sheets[sheetIndex].sections[sectionIndex].rows.length;
          const isRecapSection = section.isRecapSection;
          const isSectionCollapsed = section.isCollapsed;
          if (rowIndex + 1 === currentSectionEntryCount && sectionIndex + 1 === currentSheetSectionCount) {
            return;
          }
          if (
            (rowIndex === currentSectionEntryCount - 1 && currentSheetSectionCount > 0) ||
            (isRecapSection && isSectionCollapsed)
          ) {
            this.selectCell(sheetIndex, sectionIndex + 1, 0, cellIndex);
          } else {
            this.selectCell(sheetIndex, sectionIndex, rowIndex + 1, cellIndex);
          }
          break;
        default:
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  getCurrentlyActiveItem() {
    const activeElement = document.activeElement;
    if (activeElement.tagName === "BODY") {
      return null;
    }
    return activeElement;
  }

  selectCell(sheetIndex, sectionIndex, rowIndex, cellIndex, isMovingToRight = true) {
    setTimeout(() => {
      const cellId = GridCellData.getCellId(this.props.gridData.id, sheetIndex, sectionIndex, rowIndex, cellIndex);
      const cell = document.getElementById(cellId);
      if (cell && window.getComputedStyle(cell).visibility === "hidden") {
        const section = this.props.gridData.sheets[sheetIndex].sections[sectionIndex];
        const nextCellIndex = isMovingToRight
          ? section.getNextSelectableColumnIndex(false, cellIndex)
          : section.getPreviousSelectableColumnIndex(false, cellIndex);
        this.selectCell(sheetIndex, sectionIndex, rowIndex, nextCellIndex);
        return;
      }

      const currentlyActiveItem = this.getCurrentlyActiveItem();
      if (currentlyActiveItem !== null) {
        currentlyActiveItem.blur();
      }
      if (cell !== null) {
        cell.focus();
        // in expanded view, control the scroll behaviour horizontally as well
        if (this.props.noOfColumns >= RECAP_EXPANDED_VIEW_COLUMNS) {
          const boundingClientRect = cell.getBoundingClientRect();
          if (boundingClientRect.x < 500) {
            cell.scrollIntoView({
              block: "nearest",
              behavior: "instant",
              inline: "end"
            });
          } else {
            cell.scrollIntoView({
              block: "nearest",
              behavior: "instant",
              inline: "nearest"
            });
          }
        }
        const latestCellIndexes = `${sheetIndex},${sectionIndex},${rowIndex},${cellIndex}`;
        localStorage.setItem("latestCellIndexes", latestCellIndexes);
      }
    }, 0);
  }

  getCellId(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    return sheetIndex + "," + sectionIndex + "," + rowIndex + "," + cellIndex;
  }

  onMouseUpAfterSectionDrag = () => {
    setTimeout(() => {
      this.setState({
        sectionDragging: void 0
      });
    }, 600);

    window.removeEventListener("mouseup", this.onMouseUpAfterSectionDrag);
  };

  onBeforeCapture = result => {
    const sections = this.props.gridData.sheets[this.state.currentSheetIndex].sections;
    const sourceSectionIndex = sections.findIndex(section => {
      return section.id === result.draggableId;
    });

    if (sourceSectionIndex >= 0) {
      this.setState({
        sectionDragging: result.draggableId,
        isSectionDragging: true
      });
      window.addEventListener("mouseup", this.onMouseUpAfterSectionDrag);
    }

    window.dispatchEvent(
      new CustomEvent("onBeforeCaptureEvent", {
        detail: result
      })
    );
  };

  onBeforeDragStart(result) {
    this.preventSheetCollapse = true;

    // Once drag starts we don't want empty rows to be
    // remove automatically on losing focus
    if (result.type === "SECTIONROWS") {
      const sheet = this.props.gridData.sheets[this.state.currentSheetIndex];
      const sourceSectionIndex = sheet.sections.findIndex(section => {
        return section.id === result.source.droppableId;
      });
      const sourceSection = sheet.sections[sourceSectionIndex];
      for (const row of sourceSection.rows) {
        row.removeIfEmpty = false;
      }
    }
  }

  handleDragEnd(result = {}) {
    this.preventSheetCollapse = false;

    const { destination, type } = result;
    if (!destination) {
      return;
    }

    if (type === "SHEETS") {
      this.handleSheetsDragEnd(result);
      this.isSheetsChanged = true;
    } else if (type === "SECTIONROWS") {
      this.handleRowsDragEnd(result);
    } else if (type === "SECTIONS") {
      this.handleSectionsDragEnd(result);
    }

    this.handleSortPreference(destination);
    window.dispatchEvent(onDragEndEvent);
  }

  handleDragUpdate = result => {
    const { destination, type } = result;
    if (type === "SECTIONS" && destination) {
      this.setState({
        isOverValidDestination: true
      });
    } else if (type === "SECTIONS") {
      this.setState({
        isOverValidDestination: false
      });
    }
  };

  handleSortPreference = destination => {
    this.props.removeSectionSort(destination.droppableId);
  };

  handleSheetsDragEnd(result) {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const newGridData = this.props.gridData;
    const sheets = newGridData.sheets;
    const source = newGridData.sheets[sourceIndex];
    const destination = newGridData.sheets[destinationIndex];
    let destinationPrevKey = null;
    let destinationNextKey = null;
    let isDestinationUpdated = false;

    if (sourceIndex === destinationIndex) {
      return;
    }
    if (destinationIndex > sourceIndex) {
      destinationPrevKey = sheets[destinationIndex].sortKey;
      destinationNextKey = destinationIndex === sheets.length - 1 ? null : sheets[destinationIndex + 1].sortKey;
    } else {
      destinationPrevKey = destinationIndex === 0 ? null : sheets[destinationIndex - 1].sortKey;
      destinationNextKey = sheets[destinationIndex].sortKey;
    }

    if (destinationPrevKey === null && destinationNextKey === "0") {
      let prevKey = "0";
      let nextKey = sheets[1].sortKey;
      destination.sortKey = getSortKeyBetween(prevKey, nextKey);
      isDestinationUpdated = true;
    }

    const sortKey = getSortKeyBetween(destinationPrevKey, destinationNextKey);
    source.sortKey = sortKey;

    newGridData.sheets.sort((a, b) => {
      return a.sortKey.localeCompare(b.sortKey);
    });

    this.props.onChange(newGridData);
    this.props.onSheetUpdate(sourceIndex, source);

    if (isDestinationUpdated) {
      this.props.onSheetUpdate(destinationIndex, destination);
    }
  }

  handleRowsDragEnd(result) {
    const gridData = this.props.gridData;
    const sheet = gridData.sheets[this.state.currentSheetIndex];
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceSectionIndex = sheet.sections.findIndex(section => {
      return section.id === source.droppableId;
    });
    const sourceSection = sheet.sections[sourceSectionIndex];

    const destinationSectionIndex = sheet.sections.findIndex(section => {
      return section.id === destination.droppableId;
    });
    const destinationSection = sheet.sections[destinationSectionIndex];

    const draggedRow = sheet.sections[sourceSectionIndex].rows.find(entry => {
      return entry.viewId === draggableId;
    });

    if (!draggedRow) {
      return;
    }

    const newSourceEntries = Array.from(sheet.sections[sourceSectionIndex].rows);
    newSourceEntries.splice(source.index, 1);

    const newDestinationEntries =
      source.droppableId === destination.droppableId
        ? newSourceEntries
        : Array.from(sheet.sections[destinationSectionIndex].rows);
    // This can happen if the section looses rows after the drag ends
    if (destination.index >= newDestinationEntries.length) {
      destination.index = newDestinationEntries.length;
    }
    newDestinationEntries.splice(destination.index, 0, draggedRow);

    const newGridData = this.props.gridData;
    newGridData.sheets[this.state.currentSheetIndex].sections[sourceSectionIndex].rows = newSourceEntries;
    newGridData.sheets[this.state.currentSheetIndex].sections[destinationSectionIndex].rows = newDestinationEntries;

    if (newSourceEntries.length === 0) {
      this.deleteEmptySection(this.state.currentSheetIndex, sourceSectionIndex);
    }

    const sourceSectionArranged = arrangeSortKeysForIds(newSourceEntries);
    const destinationSectionArranged =
      sourceSectionIndex !== destinationSectionIndex
        ? arrangeSortKeysForIds(newDestinationEntries)
        : { list: [], hashMap: {} };

    this.props.onReorderRows(this.state.currentSheetIndex, sourceSection, destinationSection, draggedRow);
    this.props.updateCustodianBulk({
      list: [...sourceSectionArranged.list, ...destinationSectionArranged.list],
      hashMap: { ...sourceSectionArranged.hashMap, ...destinationSectionArranged.hashMap }
    });
  }

  handleSectionsDragEnd = result => {
    if (!result.destination || result.destination.index === result.source.index) return;

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex }
    } = result;

    const newGridData = this.props.gridData;
    const currentSheet = newGridData.sheets[this.state.currentSheetIndex];

    const sourceSection = currentSheet.sections[sourceIndex];
    const currentSheetSections = [...currentSheet.sections];

    currentSheetSections.splice(destinationIndex, 0, sourceSection);
    currentSheetSections.splice(sourceIndex > destinationIndex ? sourceIndex + 1 : sourceIndex, 1);

    this.setState(
      {
        isSectionDragging: false
      },
      () => {
        this.props.updateSectionBulk(arrangeSortKeysForIds(currentSheetSections));
      }
    );
  };

  handleMoveToNextCell() {
    const selectedCellIndexes = GridCellData.getSelectedCellIndexes(this.getCurrentlyActiveItem());
    if (selectedCellIndexes === null) {
      return;
    }
    var sheetIndex = selectedCellIndexes[0];
    var sectionIndex = selectedCellIndexes[1];
    var rowIndex = selectedCellIndexes[2];
    var cellIndex = selectedCellIndexes[3];
    const gridData = this.props.gridData;
    const sections = gridData.sheets[sheetIndex].sections;
    const rows = sections[sectionIndex].rows;
    const currentSection = sections[sectionIndex];
    const lastEditableColumnIndex = sections[sectionIndex].getLastSelectableColumnIndex(true);

    // Move from cells in left to right and top to down order.
    // If there is no cell to move to remove focus from the
    // currently selected cell.
    if (cellIndex === lastEditableColumnIndex) {
      rowIndex += 1;
      cellIndex = sections[sectionIndex].getFirstSelectableColumnIndex(true);

      if (rowIndex === rows.length) {
        if (sectionIndex < sections.length - 1) {
          sectionIndex += 1;
          rowIndex = 0;
        } else {
          this.getCurrentlyActiveItem().blur();
          return;
        }
      }
    } else {
      cellIndex = currentSection.getNextSelectableColumnIndex(true, cellIndex);
    }
    this.selectCell(sheetIndex, sectionIndex, rowIndex, cellIndex);
  }

  handlePaste(content) {
    const selectedCellIndexes = GridCellData.getSelectedCellIndexes(this.getCurrentlyActiveItem());
    if (!selectedCellIndexes === true) {
      return;
    }
    const contentRows = content.split(/\r\n|\n|\r/).filter(item => !item === false && item.trim().length !== 0);
    console.log(">>" + content + "<<");
    console.log(contentRows);
    const newGridData = this.props.gridData;
    const section = newGridData.sheets[selectedCellIndexes[0]].sections[selectedCellIndexes[1]];
    const gridRows = section.rows;
    var contentRowOffset = 0;

    for (var contentRow of contentRows) {
      var columns = contentRow.split("\t");
      const currentRowIndex = selectedCellIndexes[2] + contentRowOffset;

      // Don't process single cell copy paste
      if (contentRows.length === 1 && columns.length === 1) {
        return false;
      }
      // For single row copy paste remove empty columns
      // This is needed as when copying data from websites there is usually
      // a trailing \t appeneded to the content which results in the next column
      // getting removed
      if (contentRows.length === 1) {
        columns = columns.filter((item, index) => index !== columns.length - 1 || !item === false);
      }

      var row = null;
      if (currentRowIndex < gridRows.length) {
        row = gridRows[currentRowIndex];
      } else {
        row = this.props.getEmptyRow(null);
      }

      const isEmptyRow = row.isEmpty();
      row = this.fillRowFromPastedContentColumns(
        columns,
        row,
        section,
        selectedCellIndexes[0],
        selectedCellIndexes[1],
        currentRowIndex,
        selectedCellIndexes[3]
      );

      if (!row === true) {
        continue;
      }
      contentRowOffset++;

      if (currentRowIndex < gridRows.length) {
        this.handleRowUpdate(selectedCellIndexes[0], selectedCellIndexes[1], currentRowIndex, row, isEmptyRow);
      } else {
        this.handleAddNewRow(selectedCellIndexes[0], selectedCellIndexes[1], currentRowIndex, row, false, false);
        this.handleRowUpdate(selectedCellIndexes[0], selectedCellIndexes[1], currentRowIndex, row, true);
      }

      if (!this.props.onPaste === false) {
        this.props.onPaste(selectedCellIndexes[0], selectedCellIndexes[1], currentRowIndex, selectedCellIndexes[3]);
      }
    }
    this.selectCell(selectedCellIndexes[0], selectedCellIndexes[1], selectedCellIndexes[2], selectedCellIndexes[3]);
    return true;
  }

  fillRowFromPastedContentColumns(columns, row, section, sheetIndex, sectionIndex, rowIndex, cellIndex) {
    var modifiedCells = 0;
    var applicableCellIndex = cellIndex;

    for (const column of columns) {
      if (section.columns[applicableCellIndex].isEditable === false) {
        while (
          applicableCellIndex < section.columns.length &&
          section.columns[applicableCellIndex].isEditable === false
        ) {
          applicableCellIndex++;
        }
      }

      if (applicableCellIndex >= section.columns.length) {
        return modifiedCells === 0 ? null : row;
      }

      const applicableCellType = row.cells[applicableCellIndex].type;
      switch (applicableCellType) {
        case cellType.TEXT:
          modifiedCells++;
          row.cells[applicableCellIndex].value = column.trim();
          break;
        case cellType.NUMBER:
          if (isNaN(column) === false) {
            modifiedCells++;
            row.cells[applicableCellIndex].value = !column ? 0 : parseInt(column);
          }
          break;
        case cellType.CURRENCY:
          const currencyString = column.trim();
          const data = getAmountAndTickerFromInput(currencyString, this.props.gridData.currency);

          if (isNaN(data.value) === false) {
            modifiedCells++;

            if (
              applicableCellIndex === section.columnIndexForCost ||
              applicableCellIndex === section.columnIndexForValue
            ) {
              row.isUpdated = true;
            }

            const cell = row.cells[applicableCellIndex];
            const supportedTickerTypes = row.cells[applicableCellIndex].supportedTickerTypes;
            var value = data.value;

            if (!data.value === false) {
              value = parseFloat(value);
            } else {
              if (!currencyString === false && (value === undefined || value === null)) {
                value = 1;
              }
            }

            if (data.isTickerInputValid === false) {
              cell.invalidInputText = currencyString;
              cell.value = value;

              const cellIndex = applicableCellIndex;
              setTimeout(() => {
                if (this.props.onCellInvalidTickerAdded) {
                  this.props.onCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex);
                }
              }, 100);
            } else if (supportedTickerTypes.includes(data.ticker.type)) {
              cell.value = value;
              cell.currency = data.ticker.shortName;
              cell.setExchangeRateDetails(this.props.gridData.currency);

              if (applicableCellIndex === section.columnIndexForValue) {
                row.valueTickerId = data.ticker.id;
              }
            } else {
              cell.value = convertCurrency(value, data.ticker.shortName, this.props.gridData.currency);
              cell.currency = this.props.gridData.currency;
              cell.setExchangeRateDetails(this.props.gridData.currency);

              if (this.props.onCellInvalidTickerAdded) {
                this.props.onCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, applicableCellIndex);
              }
            }
          }
          break;
        default:
          row.cells[applicableCellIndex].value = "";
          break;
      }
      applicableCellIndex++;
    }
    return modifiedCells === 0 ? null : row;
  }

  setSelectedSheetHash = selectedIndex => {
    const selectedSheet = this.props.gridData.sheets[selectedIndex];

    if (!selectedSheet) {
      return;
    }

    const hash = `${hashParams.SHEET_ID}=${selectedSheet.id}`;
    this.props.history.replace({ ...this.props.location, hash: hash });
  };

  handleSheetSelection(selectedIndex) {
    if (selectedIndex === this.state.currentSheetIndex) {
      // @TODO: Adding a temporary check for modalValue. If no modal open then only set sheetId to URL.
      const urlHashSheetId = getHashParams(window.location)[hashParams.SHEET_ID];
      const modalValue = getHashParams(window.location)[hashParams.MODAL];
      if (
        this.props.gridData.appendDefaultSheetToUrl === true &&
        !urlHashSheetId === true &&
        modalValue === undefined
      ) {
        this.setSelectedSheetHash(selectedIndex);
      }
      return;
    }

    this.setSelectedSheetHash(selectedIndex);

    this.props.gridData.currentSheetIndex = selectedIndex;
    if (this.props.onSheetSelection) {
      this.props.onSheetSelection(selectedIndex);
    }
    this.setState({ ...this.state, currentSheetIndex: selectedIndex });
  }

  handleMenuButtonClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    const removeSheetItem = contextMenuItemType.REMOVE_SHEET;
    removeSheetItem.disabled = this.props.gridData.sheets.length === 1;
    const moveSheetItem = contextMenuItemType.MOVE_SHEET;
    moveSheetItem.disabled =
      this.props.gridData.sheets.length === 1 ||
      (this.props.gridData.portfoliosCount !== null && this.props.gridData.portfoliosCount === 1);
    const selectedSheet = this.props.gridData.sheets[this.state.currentSheetIndex];
    const selectedUpdateFrequency = selectedSheet.updateFrequency;
    const autoUnUpdateItem = contextMenuItemType.AUTO_UNUPDATE;
    autoUnUpdateItem.description = `${selectedUpdateFrequency.label} ${
      !selectedUpdateFrequency.description ? "" : `(${selectedUpdateFrequency.description})`
    }`;

    const menuItems = [
      [autoUnUpdateItem],
      [contextMenuItemType.DOWNLOADEXCEL],
      [contextMenuItemType.RENAME, moveSheetItem, removeSheetItem]
    ];

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left - 200,
      targetPosition.top + targetPosition.height + 2,
      true,
      e.target
    );
    this.setState({ ...this.state, isShowingSheetContextMenu: true });
  }

  handleOptionsButtonClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();

    var menuItems = [[contextMenuItemType.SHEET_ADD], [contextMenuItemType.ARCHIVED]];
    if (this.props.gridData.isEditable === false && this.props.gridData.forceShowArchivedItems === true) {
      menuItems = [[contextMenuItemType.ARCHIVED]];
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left - 200,
      targetPosition.top + targetPosition.height - 20,
      true,
      e.target
    );
  }

  handleContextMenuSelection(item) {
    const currentSheetIndex = this.state.currentSheetIndex;
    const selectedSheet = this.props.gridData.sheets[currentSheetIndex];

    if (item.id === contextMenuItemType.RENAME.id) {
      this.sheetTitleRefs[this.state.currentSheetIndex].edit();
    } else if (item.id === contextMenuItemType.REMOVE_SHEET.id) {
      const rowsAffected = this.getTotalRowsToBeDeletedWithSheet(selectedSheet);
      if (rowsAffected.isRowLinked === true || rowsAffected.count > 1) {
        this.setState({ ...this.state, showRemoveSheetDialog: true, removeSheetDialogRowCount: rowsAffected.count });
      } else {
        this.handleRemoveSheet(this.state.currentSheetIndex);
      }
    } else if (item.id === contextMenuItemType.ARCHIVED.id) {
      this.props.onGridContextMenuSelection(item);
    } else if (item.id === contextMenuItemType.SHEET_ADD.id) {
      this.handleAddNewSheet();
    } else if (item.category === contextMenuItemType.SHEET_SELECT.category) {
      this.handleSheetSelection(item.index);
    } else {
      this.props.onSheetContextMenuSelection(this.state.currentSheetIndex, item);
    }
  }

  handleNewContextMenuSelection = item => {
    const currentSheetIndex = this.state.currentSheetIndex;

    if (item.id === contextMenuItemType.NEW_ACTION_SECTION.id) {
      this.handleAddNewSection(currentSheetIndex, this.selectedSheet.sections.length);
    } else if (item.id === contextMenuItemType.NEW_ACTION_SECTION_ROW.id) {
      const sectionIndex = this.selectedSheet.sections.length;
      this.props.onAddNewFooterButtonClick(currentSheetIndex, sectionIndex, linkingStarted => {
        if (
          linkingStarted !== true &&
          sectionIndex <= this.props.gridData.sheets[currentSheetIndex].sections.length - 1
        ) {
          this.handleRemoveSection(currentSheetIndex, sectionIndex);
        }
      });
    }
  };

  getTotalRowsToBeDeletedWithSheet = sheet => {
    const rows = sheet.getAllRows();

    const rowsWithoutParent = rows.filter(row => row.isEmpty() === false && !row.parentId === true);
    var uniqueParentIds = new Set();
    for (const row of rows) {
      if (!row.parentId === false) {
        uniqueParentIds.add(row.parentId);
      }
    }

    var count = rowsWithoutParent.length;
    var isRowLinked = false;
    uniqueParentIds.forEach(parentId => {
      count += custodiansWithSameParentIdSelector(store.getState(), this.props.portfolio.id, parentId).length;
    });
    if (count === 1 && !rowsWithoutParent[0].linkType === false) {
      isRowLinked = true;
    }
    return { count, isRowLinked };
  };

  handleContextMenuDismiss() {
    this.setState({ ...this.state, isShowingSheetContextMenu: false });
  }

  handleNewContextMenuDismiss = () => {};

  getTotalValueForSheet(sheetIndex) {
    const sheet = this.props.gridData.sheets[sheetIndex];
    const total = sheet.getTotalValue(this.props.gridData.currency);
    const value = isCryptoCurrency(this.props.gridData.currency)
      ? total
      : total > 0 && total < 1
      ? total
      : Math.kuberaFloor(total);
    const symbolForCurrency = getSymbolForTickerUsingShortName(this.props.gridData.currency);
    return value === 0 ? (symbolForCurrency.length > 1 ? symbolForCurrency : `${symbolForCurrency}`.repeat(4)) : value;
  }

  getTotalValue() {
    return this.props.gridData.getTotalValue(this.props.gridData.currency);
  }

  getTitle(totalValue, currency) {
    if (totalValue === 0) {
      return this.props.title;
    }

    const formattedText = `${shortFormatNumberWithCurrency(totalValue, currency, false)}`;
    return formattedText.replace("-", "");
  }

  handleRemoveSheetDialogNegativeButtonClick(e) {
    this.setState({ ...this.state, showRemoveSheetDialog: false });
  }

  handleRemoveSheetDialogPositiveButtonClick(e) {
    this.handleRemoveSheet(this.state.currentSheetIndex);
  }

  setSheetTitlesRef(ref) {
    this.sheetTitlesRef = ref;
  }

  setSheetHeaderRef(ref, index) {
    this.sheetHeaderRef[index] = ref;
  }

  intersectionCallback(entries) {
    if (this.preventSheetCollapse) {
      return;
    }

    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        this.setState({
          isSheetsOverflowing: true
        });
      } else {
        if (this.state.isSheetsOverflowing === true) {
          this.setState({
            isSheetsOverflowing: false
          });
        }
      }
    });
  }

  handleTotalChangeClick(e, forInterval) {
    window.location.hash = `${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${forInterval}`;
  }

  handleNewActionClick = e => {
    const targetPosition = e.target.getBoundingClientRect();

    this.newActionContextMenuRef.current.show(
      this.newActionContextMenuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height + 2,
      true,
      e.target
    );
  };

  render() {
    const currentSheetIndex = this.state.currentSheetIndex;
    const sheets = this.props.gridData.sheets;
    const selectedSheet = this.selectedSheet;
    const currency = this.props.gridData.currency;
    const totalValue = this.getTotalValue();
    const showGridTitle = this.props.gridData.sheets.length > 1 || this.props.gridData.forceShowSheetsTitles === true;
    const showRemoveSheetDialog = this.state.showRemoveSheetDialog;
    const disableTotalChangeColor = this.props.gridData.category === category.DEBT;
    const userPreferences = this.props.userPreferences;
    const showChangeBadge =
      this.props.gridData.category === category.ASSET &&
      !userPreferences === false &&
      userPreferences.isContributingCustodiansTipShown === false &&
      this.props.gridData.isEditable === true;

    window.renderlog("Render: GridComponent");
    return (
      <DragDropContext
        onBeforeCapture={this.onBeforeCapture}
        onBeforeDragStart={this.onBeforeDragStart}
        onDragEnd={this.handleDragEnd}
        onDragUpdate={this.handleDragUpdate}
      >
        <Container className={this.props.className}>
          {showGridTitle === true && (
            <GridContainer>
              <GridTotalHeader
                category={this.props.gridData.category}
                currency={currency}
                totalValue={totalValue}
                changeData={this.props.gridData.changeData}
                onTotalChange={this.props.gridData.onTotalChange}
                title={this.props.title}
                disableTotalChangeColor={disableTotalChangeColor}
                showChangeBadge={showChangeBadge}
                onTotalChangeClick={this.handleTotalChangeClick}
                userPreferences={userPreferences}
              />
              <Droppable droppableId="sheets_droppable" direction="horizontal" type="SHEETS">
                {provided => (
                  <SheetContainer>
                    <SheetTitles
                      ref={ref => {
                        this.setSheetTitlesRef(ref);
                        provided.innerRef(ref);
                      }}
                      isSheetsOverflowing={this.state.isSheetsOverflowing}
                      {...provided.droppableProps}
                    >
                      {sheets.map((sheet, index) => {
                        const datacyPrefix = getPrefixForDataCy();
                        let displaySheet = sheet,
                          displayIndex = index,
                          isHidden = false;

                        const isSelected = displayIndex === currentSheetIndex;

                        if (!displaySheet) {
                          return null;
                        }

                        return (
                          <Draggable
                            draggableId={displaySheet.id}
                            index={index}
                            key={displaySheet.id}
                            isDragDisabled={!this.props.gridData.isEditable === true}
                          >
                            {(provided, snapshot) => (
                              <SheetHeader
                                ref={provided.innerRef}
                                onClick={() => this.handleSheetSelection(displayIndex)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={GridComponent.getSheetHeaderStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                data-sheetindex={displayIndex}
                                isHidden={isHidden}
                              >
                                <GridSheetTitle
                                  isSelected={isSelected}
                                  displaySheet={displaySheet}
                                  datacyPrefix={datacyPrefix}
                                  displayIndex={displayIndex}
                                  sheetTitleRefs={this.sheetTitleRefs}
                                  isDragging={snapshot.isDragging}
                                  isShowingSheetContextMenu={this.state.isShowingSheetContextMenu}
                                  isEditable={this.props.gridData.isEditable}
                                  value={this.getTotalValueForSheet(displayIndex, currency)}
                                  currency={currency}
                                  onSheetTitleChange={this.handleSheetTitleUpdate}
                                  onMenuButtonClick={this.handleMenuButtonClick}
                                />
                              </SheetHeader>
                            )}
                          </Draggable>
                        );
                      })}
                      <OverflowDetectElement ref={this.overflowElemRef} />
                      {provided.placeholder}
                    </SheetTitles>
                    <OptionsButton
                      data-cy="optionsButton"
                      onClick={this.handleOptionsButtonClick}
                      isHidden={
                        this.props.gridData.isEditable === false && this.props.gridData.forceShowArchivedItems === false
                      }
                    />
                  </SheetContainer>
                )}
              </Droppable>
            </GridContainer>
          )}
          <GridSheet
            gridId={this.props.gridData.id}
            isEditable={this.props.gridData.isEditable === true}
            sheet={selectedSheet}
            currency={currency}
            sheetIndex={currentSheetIndex}
            onMoveToNextCell={this.handleMoveToNextCell}
            onPaste={this.handlePaste}
            onRowUpdate={this.handleRowUpdate}
            onAddNewRow={this.handleAddNewRow}
            onRemoveRow={this.handleRemoveRow}
            onSectionUpdate={this.handleSectionUpdate}
            onAddNewSection={this.handleAddNewSection}
            onRemoveSection={this.handleRemoveSection}
            onSheetUpdate={this.handleSectionUpdate}
            onRemoveSheet={this.handleRemoveSheet}
            onDetailsClick={this.props.onDetailsClick}
            onOptionsClick={this.props.onOptionsClick}
            onLinkErrorClick={this.props.onLinkErrorClick}
            onStarSection={this.props.onStarSection}
            onChangeSectionUpdatedStatus={this.props.onChangeSectionUpdatedStatus}
            onUserLeavingCellEmpty={this.props.onUserLeavingCellEmpty}
            onRowContextMenuSelection={this.props.onRowContextMenuSelection}
            onSectionContextMenuSelection={this.props.onSectionContextMenuSelection}
            onCellBlur={this.handleCellBlur}
            onCellInvalidTickerAdded={this.props.onCellInvalidTickerAdded}
            onAddNewFooterButtonClick={this.props.onAddNewFooterButtonClick}
            onRowClick={this.props.onRowClick}
            portfolio={this.props.portfolio}
            userPreferences={userPreferences}
            addRowBtnTxt={this.props.addRowBtnTxt}
            sectionDragging={this.state.sectionDragging}
            isSectionDragging={this.state.isSectionDragging}
            isOverValidDestination={this.state.isOverValidDestination}
            gridOptions={this.props.gridOptions}
            sheetHeaderCells={this.props.sheetHeaderCells}
            onOpenChartsModalClick={this.props.onOpenChartsModalClick}
            category={this.props.category}
          />
          <ContextMenu
            ref={this.contextMenuRef}
            onSelection={this.handleContextMenuSelection}
            onDismiss={this.handleContextMenuDismiss}
          />
          {this.newActionContextMenuItems && (
            <>
              <NewAction onClick={this.handleNewActionClick} isReadOnly={this.props.gridData.isEditable === false} />
              <ContextMenu
                ref={this.newActionContextMenuRef}
                onSelection={this.handleNewContextMenuSelection}
                onDismiss={this.handleNewContextMenuDismiss}
              />
            </>
          )}
        </Container>
        {showRemoveSheetDialog === true && (
          <ConfirmationDialog
            title={
              i18n
                .t("removeRows")
                .replace("%s1%", `${this.state.removeSheetDialogRowCount}`)
                .replace("rows", this.state.removeSheetDialogRowCount === 1 ? "row" : "rows") + "?"
            }
            description={i18n.t("removeSheetDialog.description")}
            positiveButtonTitle={i18n
              .t("removeRows")
              .replace("%s1%", `${this.state.removeSheetDialogRowCount}`)
              .replace("rows", this.state.removeSheetDialogRowCount === 1 ? "row" : "rows")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleRemoveSheetDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleRemoveSheetDialogPositiveButtonClick}
          />
        )}
      </DragDropContext>
    );
  }
}

GridComponent.defaultProps = {
  showToastTip: () => void 0
};

export default GridComponent;

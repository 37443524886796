import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { ApiClient, statusConfigSelector, setStatusConfigAction } from "@kubera/common";

import BannerComponent from "components/Banner";

const Message = styled.div`
  margin-right: 60px;
  padding: 20px 0 20px 20px;
  font-size: 14px;
  line-height: 130%;
  font-weight: normal;
`;

class OutageBannerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.bannerTimeoutId = null;

    this.fetchAndSetTimer = this.fetchAndSetTimer.bind(this);
    this.handleUpdateBanner = this.handleUpdateBanner.bind(this);
    this.handleBannerClose = this.handleBannerClose.bind(this);
    this.handleBannerOnCloseClick = this.handleBannerClose.bind(this, true);
  }

  componentDidMount() {
    this.fetchAndSetTimer();
  }

  componentWillUnmount() {
    if (!this.outageCheckTimer === false) {
      clearInterval(this.outageCheckTimer);
    }

    clearTimeout(this.bannerTimeoutId);
  }

  async fetchAppConfig() {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const response = await ApiClient.getAppStatusConfig(baseUrl).catch(apiError => {
      this.handleBannerClose();
    });

    if (response && response.data) {
      this.props.setStatusConfig(response.data);
    }

    return response;
  }

  async fetchAndSetTimer() {
    this.outageCheckTimer = setInterval(() => {
      this.fetchAppConfig();
    }, 5 * 60 * 1000);

    const response = await this.fetchAppConfig();

    return response;
  }

  handleUpdateBanner(expiryTime) {
    this.forceUpdate();

    this.bannerTimeoutId = setTimeout(async () => {
      clearInterval(this.outageCheckTimer);
      await this.fetchAndSetTimer();
      this.handleUpdateBanner(expiryTime);
    }, expiryTime);
  }

  handleBannerClose(setCookie = false) {
    const expiryTime = 12 * 60 * 60 * 1000;
    if (setCookie) {
      const today = new Date(new Date().getTime() + expiryTime);

      Cookies.set("kuberaOutageBanner", window.location.host, { expires: today, sameSite: "Strict", secure: true });
    }
    this.handleUpdateBanner(expiryTime);
    clearInterval(this.outageCheckTimer);
  }

  render() {
    if (!this.props.statusConfig === true) {
      return null;
    }

    const outageMessage = this.props.statusConfig.outageMessage;
    if (Cookies.get("kuberaOutageBanner") || !outageMessage === true || outageMessage.trim().length === 0) {
      return null;
    }

    return (
      <BannerComponent onClose={this.handleBannerOnCloseClick}>
        <Message>{outageMessage}</Message>
      </BannerComponent>
    );
  }
}

const mapStateToProps = state => ({
  statusConfig: statusConfigSelector(state)
});

const mapDispatchToProps = {
  setStatusConfig: setStatusConfigAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutageBannerComponent);

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  cleanUpSeparatorFromNumberString,
  parseNumberStringToFloat,
  getCharacterForDecimal,
  getCharacterForNumberSeparator,
  formatNumberWithKuberaNumberFormatSettings,
  userMaskAllValuesSelector
} from "@kubera/common";
import { cellMode } from "components/grid/GridCellExports";

const Input = styled.input`
  font-feature-settings: "ss01", "calt" 0;
  font-size: 14px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const NumberValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    !props.textAlignment === true || props.textAlignment === "right" ? "flex-end" : "flex-start"};
`;

const NumberValue = styled.div`
  position: absolute;
  display: flex;
  margin-top: 2px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 17px;
  width: ${props => (props.width ? `${props.width}` : "90px")};
  justify-content: ${props =>
    !props.textAlignment === true || props.textAlignment === "right" ? "flex-end" : "flex-start"};
  align-items: ${props => (props.textVerticalAlignment === "top" ? "flex-start" : "center")};
  visibility: ${props => (props.mode === cellMode.SELECT ? "visible" : "hidden")};
`;

const NumberCellPreviousValue = styled.div`
  display: flex;
  text-decoration: line-through;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.textCellDescriptionCLR};
  visibility: ${props => (props.mode === cellMode.SELECT ? "visible" : "hidden")};
`;

const NumberCell = (
  {
    className,
    type,
    value: defaultValue,
    previousValue,
    emptyValueIndicator,
    width,
    mode,
    textAlignment,
    textVerticalAlignment,
    onChange = () => null,
    onBlur = () => null,
    ...other
  },
  ref
) => {
  const useMaskedValues = useSelector(userMaskAllValuesSelector);

  const [value, setValue] = useState();

  useEffect(() => {
    const value =
      !defaultValue === false
        ? formatNumberWithKuberaNumberFormatSettings(parseFloat(defaultValue), {
            maximumFractionDigits: 8
          })
        : defaultValue;

    setValue(value);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e, isBlur = false) => {
    if (!e.target.value === false && e.target.value.length >= 1) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1);
      if (
        isNaN(parseFloat(lastChar)) &&
        lastChar !== getCharacterForDecimal() &&
        lastChar !== getCharacterForNumberSeparator() &&
        lastChar !== "-"
      ) {
        return;
      }
    }

    setValue(e.target.value);

    const sanitizedNumber = cleanUpSeparatorFromNumberString(e.target.value, false);
    const parsedNumber = parseNumberStringToFloat(sanitizedNumber);

    if (sanitizedNumber !== "" && parsedNumber === null) {
      return;
    }
    if (sanitizedNumber === value) {
      return;
    }

    e.target.value = parsedNumber;

    if (!isBlur) {
      onChange(e, parsedNumber);
    } else {
      onBlur(e, parsedNumber);
    }
  };

  const handleInputBlur = e => {
    handleInputChange.call(this, e, true);
  };

  const getValueDisplayString = () => {
    if (useMaskedValues) {
      return "XXXX";
    }

    if (value === null || value === undefined || value === "") {
      return "";
    }
    return value;
  };

  const inputValue = getValueDisplayString();

  return (
    <>
      <Input
        {...other}
        ref={ref}
        className={className}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        readOnly={useMaskedValues}
        autoComplete="off"
        data-protonpass-ignore
        data-1p-ignore
      />
      <NumberValue
        width={width}
        textAlignment={textAlignment}
        textVerticalAlignment={textVerticalAlignment}
        mode={mode}
      >
        <NumberValueContainer>
          <div>{inputValue || emptyValueIndicator}</div>
          {previousValue && value !== previousValue && (
            <NumberCellPreviousValue
              width={width}
              textAlignment={textAlignment}
              textVerticalAlignment={textVerticalAlignment}
              mode={mode}
            >
              {formatNumberWithKuberaNumberFormatSettings(previousValue)}
            </NumberCellPreviousValue>
          )}
        </NumberValueContainer>
      </NumberValue>
    </>
  );
};

export default React.forwardRef(NumberCell);

import createWhitelistFilter from "redux-persist-transform-filter";
import { store } from "../../store";
import {
  sortPortfolio,
  categoryType,
  isAssetCustodian,
  FETCH_PORTFOLIOS_PENDING,
  FETCH_PORTFOLIOS_SUCCESS,
  FETCH_PORTFOLIOS_ERROR,
  SET_PORTFOLIOS,
  INSERT_PORTFOLIO,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
  UPDATE_CURRENT_PORTFOLIO,
  INSERT_CUSTODIAN,
  UPDATE_CUSTODIAN_BULK,
  DELETE_CUSTODIAN,
  DELETE_CUSTODIAN_BULK,
  INSERT_SECTION,
  UPDATE_SECTION,
  MOVE_SECTION,
  UPDATE_SHEET,
  MOVE_SHEET,
  UPDATE_DASHBOARD,
  BULK_CHANGE_CUSTODIAN_STAR_STATUS,
  BULK_CHANGE_CUSTODIAN_UPDATED_STATUS,
  DELETE_SECTION,
  DELETE_SHEET,
  INSERT_SHEET,
  INSERT_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  SET_PORTFOLIO_LAST_FORCE_REFRESH_TS,
  SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS,
  FETCH_NET_WORTH_DATA_PENDING,
  FETCH_NET_WORTH_DATA_SUCCESS,
  SET_FF_PAYLOAD,
  FETCH_NET_WORTH_DATA_ERROR,
  FETCH_RECAP_DATA_ERROR,
  FETCH_RECAP_DATA_PENDING,
  FETCH_RECAP_DATA_SUCCESS,
  FETCH_INITIAL_RECAP_DATA_PENDING,
  FETCH_INITIAL_RECAP_DATA_ERROR,
  SET_LINK_TYPE,
  RESET_PORTFOLIO_STATE,
  SET_SLIDE_DIRECTION,
  SET_SHOW_REFRESHING,
  PAGE_RELOADING,
  REFRESH_CUSTODIAN_DONE,
  SET_SECTION_UPDATED,
  REMOVE_SECTION_UPDATED,
  FETCH_PORTFOLIO_CHANGE_DATA_PENDING,
  FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS,
  FETCH_PORTFOLIO_CHANGE_DATA_ERROR,
  recapChartTypes,
  THEME_SUFFIX_STR,
  INITIAL_FETCH_PORTFOLIOS_SUCCESS,
  REMOVE_CONNECTION_ERRORS,
  getChartDataFromResponse,
  chartTimeRangeGroups,
  getYTDStartDate,
  chartStyle,
  RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
  RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS_WITHOUT_CASH,
  getRecommendationCountForAChart,
  chartKeyParams,
  reportPaths,
  getLineChartTitle,
  irrTypes,
  GET_CONNECTIVITY_CENTER_DATA_ERROR,
  GET_CONNECTIVITY_CENTER_DATA_PENDING,
  GET_CONNECTIVITY_CENTER_DATA_SUCCESS,
  UPDATE_CONNECTIVITY_CENTER_DATA,
  custodianSubTypes,
  custodianTaxTypes,
  ADD_SCENARIO,
  DELETE_SCENARIO,
  ADD_RULE,
  DELETE_RULE,
  UPDATE_RULE,
  UPDATE_RULE_BULK,
  UPDATE_SCENARIO,
  ADD_REPORT_PREFERENCE,
  ADD_DIY_CHART,
  DELETE_DIY_CHART,
  UPDATE_CASH_FORECAST_DURATION,
  custodiansLinkedToSameAccount,
  REHYDRATE_RECAP,
  tickerTypes,
  getSymbolForTickerUsingShortName,
  isCryptoCurrency,
  shortFormatNumberWithCurrency,
  getPVSTRate,
  accountLinkingService,
  calcCustodianOwnershipValue,
  getYTDCustodianChartData,
  filterChartDataBasedOnPortfolioStartDate,
  GET_FUND_SCHEDULE_DATA_SUCCESS,
  fundScheduleDurations,
  fundScheduleTypes,
  UPDATE_SHOW_CHARTS_MODAL_LOADER,
  FETCH_PERCENTAGE_ALLOCATION_DATA_PENDING,
  FETCH_PERCENTAGE_ALLOCATION_DATA_SUCCESS,
  PVST_VALUE_TICKER_ID
} from "../actions/Common";
import i18n from "locale/i18n";
import {
  getPercentageValue,
  getUuid,
  parseNetWorthDateString,
  getKuberaDateString,
  getActualValueFromPercentage,
  getDateInKuberaFormat,
  parseKuberaDateString,
  todayMidnight,
  addDaysToDate
} from "../../utilities/Number";
import {
  userPreferencesSelector,
  currentPortfolioSelector,
  getCustodianCost,
  portfoliosSelector,
  portfoliosStateSelector,
  portfoliosReducerName,
  currentPortfolioCurrencySelector,
  findCustodianInPortfolios,
  custodianSelector,
  recapDataSelector,
  chartTimeRange,
  checkIfAChartOptionHasNoDataToShow,
  recapChartOptions,
  recapDataTotalInvestableAssetsSelector,
  recapDataCurrencySelector,
  recapReportNodeSelector,
  recapReportNameSelector,
  recapReportContentsDataSelector,
  reportPreferencesSelector,
  recapDataTotalAssetsSelector,
  sheetAndSectionReportNodeSelector,
  RECAP_CATEGORY_TYPE_ASSET,
  RECAP_CATEGORY_TYPE_NETWORTH,
  RECAP_CATEGORY_TYPE_DEBT,
  RECAP_CATEGORY_TYPE_FIAT_ASSET,
  convertCurrency,
  chartContent,
  getContentsTabTitle,
  getExchangeRate,
  getTickerUsingId,
  getTickerUsingShortName,
  getComparisonReportsTabTitle,
  recapReportComparisonDataSelector
} from "./Common";
import { isMobile, parseParams } from "../../utilities/Location";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";
import { isAppInViewMode } from "../../utilities/Common";
import { getState } from "../../utilities/getState";

export const initialState = {
  initialFetchSuccess: false,
  fetchPortfolioPending: false,
  fetchPortfolioError: null,
  fetchPortfolioChangeDataSuccess: false,
  lastPortfoliosFetchTs: new Date().getTime(),
  portfolios: null,
  currentPortfolioId: null,
  currentPortfolioCustodiansUpdatedTimestamp: undefined,
  lastDashboardUpdateTs: new Date().getTime(),
  dashboardEntitesToUpdate: [],
  portfolioLastForceRefreshTsMap: {},
  sortPreference: null,
  isPageReloading: false,
  isColumnarChartDataFetchError: false,
  sectionsUpdated: {},
  connectivityCenterData: null,
  recapDataPortfolioMap: {},
  recapDataPortfolioMapUpdateTimestamp: undefined,
  portfolioChangeDataLastForceRefreshTsMap: {},
  portfolioCashForecastDurationMap: {},
  portfolioFundScheduleMap: {},
  recapRawData: {},
  showLoaderOnChartsModal: false,
  fetchingPercentageAllocationData: false
};

export const portfolioPersistTransform = portfolioUserId => {
  if (isAppInViewMode()) {
    return createWhitelistFilter(portfoliosReducerName(portfolioUserId), [
      "portfolios",
      "currentPortfolioId",
      "portfolioLastForceRefreshTsMap",
      "portfolioChangeDataLastForceRefreshTsMap",
      "lastPortfoliosFetchTs",
      "connectivityCenterData",
      "portfolioCashForecastDurationMap",
      "portfolioFundScheduleMap"
    ]);
  }

  return createWhitelistFilter(portfoliosReducerName(portfolioUserId), [
    "currentPortfolioId",
    "portfolioLastForceRefreshTsMap",
    "portfolioChangeDataLastForceRefreshTsMap",
    "lastPortfoliosFetchTs",
    "connectivityCenterData",
    "portfolioCashForecastDurationMap",
    "portfolioFundScheduleMap"
  ]);
};

export const pastValueInterval = {
  DAY: "day1",
  WEEK: "week1",
  MONTH: "month1",
  YEAR: "year1",
  YTD: "ytd"
};

export const undoQueue = [];
export const redoQueue = [];

export const portfolioActions = [
  INITIAL_FETCH_PORTFOLIOS_SUCCESS,
  RESET_PORTFOLIO_STATE,
  FETCH_PORTFOLIOS_PENDING,
  FETCH_PORTFOLIOS_SUCCESS,
  SET_PORTFOLIOS,
  FETCH_PORTFOLIOS_ERROR,
  INSERT_PORTFOLIO,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
  UPDATE_CURRENT_PORTFOLIO,
  INSERT_CUSTODIAN,
  UPDATE_CUSTODIAN_BULK,
  BULK_CHANGE_CUSTODIAN_STAR_STATUS,
  BULK_CHANGE_CUSTODIAN_UPDATED_STATUS,
  DELETE_CUSTODIAN,
  DELETE_CUSTODIAN_BULK,
  INSERT_SECTION,
  UPDATE_SECTION,
  MOVE_SECTION,
  DELETE_SECTION,
  UPDATE_SHEET,
  MOVE_SHEET,
  DELETE_SHEET,
  INSERT_SHEET,
  INSERT_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DASHBOARD,
  SET_PORTFOLIO_LAST_FORCE_REFRESH_TS,
  SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS,
  FETCH_NET_WORTH_DATA_PENDING,
  FETCH_NET_WORTH_DATA_SUCCESS,
  FETCH_NET_WORTH_DATA_ERROR,
  REMOVE_CONNECTION_ERRORS,
  FETCH_INITIAL_RECAP_DATA_PENDING,
  FETCH_RECAP_DATA_PENDING,
  FETCH_RECAP_DATA_SUCCESS,
  GET_CONNECTIVITY_CENTER_DATA_ERROR,
  GET_CONNECTIVITY_CENTER_DATA_PENDING,
  GET_CONNECTIVITY_CENTER_DATA_SUCCESS,
  FETCH_INITIAL_RECAP_DATA_ERROR,
  FETCH_RECAP_DATA_ERROR,
  FETCH_PORTFOLIO_CHANGE_DATA_PENDING,
  FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS,
  FETCH_PORTFOLIO_CHANGE_DATA_ERROR,
  SET_LINK_TYPE,
  SET_SLIDE_DIRECTION,
  SET_SHOW_REFRESHING,
  PAGE_RELOADING,
  REFRESH_CUSTODIAN_DONE,
  SET_SECTION_UPDATED,
  REMOVE_SECTION_UPDATED,
  UPDATE_CONNECTIVITY_CENTER_DATA,
  ADD_SCENARIO,
  DELETE_SCENARIO,
  UPDATE_SCENARIO,
  ADD_RULE,
  UPDATE_RULE,
  UPDATE_RULE_BULK,
  DELETE_RULE,
  UPDATE_CASH_FORECAST_DURATION,
  ADD_REPORT_PREFERENCE,
  ADD_DIY_CHART,
  DELETE_DIY_CHART,
  REHYDRATE_RECAP,
  GET_FUND_SCHEDULE_DATA_SUCCESS,
  SET_FF_PAYLOAD
];

const getNewPortfoliosStateWithCurrentValues = (oldPortfolios, newPortfolios, currentPortfolioId) => {
  for (let portfolioIndex = 0; portfolioIndex < newPortfolios.length; portfolioIndex++) {
    if (newPortfolios[portfolioIndex].id === currentPortfolioId) {
      newPortfolios[portfolioIndex] = {
        ...(oldPortfolios?.length ? oldPortfolios.find(portfolio => portfolio.id === currentPortfolioId) || {} : {}),
        ...newPortfolios[portfolioIndex]
      };
      break;
    }
  }

  return newPortfolios;
};

export function portfolioReducer(state = initialState, action) {
  const suffix = !action.portfolioUserId === false ? `-${action.portfolioUserId}` : "";
  // make sure to add newPortfolioActions to portfolioActions array in Common.js to make sure subuser suffix logic works correctly
  switch (action.type) {
    case INITIAL_FETCH_PORTFOLIOS_SUCCESS + suffix:
      return {
        ...state,
        initialFetchSuccess: true
      };
    case RESET_PORTFOLIO_STATE + suffix:
      return {
        ...initialState
      };
    case FETCH_PORTFOLIOS_PENDING + suffix:
      return {
        ...state,
        fetchPortfolioPending: action.isBackgroundRefresh === false,
        fetchPortfolioError: null
      };
    case FETCH_PORTFOLIOS_SUCCESS + suffix: {
      const newState = { ...state };
      newState.fetchPortfolioPending = false;
      newState.portfolios = getNewPortfoliosStateWithCurrentValues(
        state.portfolios,
        action.portfolios,
        state.currentPortfolioId
      );
      newState.fetchPortfolioError = null;
      newState.lastPortfoliosFetchTs = new Date().getTime();

      const isCurrentPortfolioValid =
        action.portfolios.findIndex(portfolio => portfolio.id === newState.currentPortfolioId) !== -1;
      if (isCurrentPortfolioValid === false && action.portfolios.length > 0) {
        newState.currentPortfolioId = action.portfolios[0].id;
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      return newState;
    }
    case SET_PORTFOLIOS + suffix: {
      const newState = { ...state };
      newState.portfolios = getNewPortfoliosStateWithCurrentValues(
        state.portfolios,
        action.portfolios,
        state.currentPortfolioId
      );

      const currentPortfolio = action.portfolios.find(portfolio => portfolio.id === newState.currentPortfolioId);
      var newCurrentPortfolioId =
        !currentPortfolio === false
          ? currentPortfolio.id
          : action.portfolios.length > 0
          ? action.portfolios[0].id
          : null;

      if (
        action.showNonEmptyAsCurrent === true &&
        (!currentPortfolio === true || isPortfolioEmpty(currentPortfolio) === true)
      ) {
        for (const portfolio of newState.portfolios) {
          if (isPortfolioEmpty(portfolio) === false) {
            newCurrentPortfolioId = portfolio.id;
            break;
          }
        }
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.currentPortfolioId = newCurrentPortfolioId;
      return newState;
    }
    case FETCH_PORTFOLIOS_ERROR + suffix:
      return {
        ...state,
        fetchPortfolioPending: false,
        isRefreshing: false,
        fetchPortfolioError: action.error
      };
    case INSERT_PORTFOLIO + suffix:
      return {
        ...state,
        createPortfolioPending: false,
        portfolios: [...state.portfolios, action.portfolio]
      };
    case UPDATE_PORTFOLIO + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolio.id);
      if (portfolioIndex !== -1) {
        sortPortfolio(action.portfolio);
        portfolios[portfolioIndex] = action.portfolio;
      }
      newState.portfolios = portfolios;
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      return newState;
    }
    case DELETE_PORTFOLIO + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolio.id);
      if (portfolioIndex !== -1) {
        portfolios.splice(portfolioIndex, 1);
        newState.portfolios = portfolios;
      }
      if (action.portfolio.id === newState.currentPortfolioId) {
        newState.currentPortfolioId = portfolios[0].id;
      }
      return newState;
    }
    case UPDATE_CURRENT_PORTFOLIO + suffix: {
      const newState = state;
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();

      return {
        ...newState,
        currentPortfolioId:
          portfolioIndex === -1 && !portfolios === false && portfolios.length > 0
            ? portfolios[0].id
            : action.portfolioId
      };
    }
    case INSERT_CUSTODIAN + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const custodianIndex = currentPortfolio.details.custodian.findIndex(
        custodian => custodian.id === action.custodian.id
      );

      if (!action.custodian.parentId === false) {
        copyLinkingInfoFromParentCustodian(state.portfolios, action.custodian.parentId, action.custodian);
      }

      if (custodianIndex === -1) {
        currentPortfolio.details.custodian.unshift(action.custodian);
      } else {
        currentPortfolio.details.custodian[custodianIndex] = {
          ...currentPortfolio.details.custodian[custodianIndex],
          ...action.custodian
        };
      }
      sortPortfolio(currentPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case UPDATE_CUSTODIAN_BULK + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }

      for (const custodian of action.custodians) {
        const custodianIndex = currentPortfolio.details.custodian.findIndex(item => item.id === custodian.id);

        if (custodianIndex !== -1) {
          if (!custodian.parentId === false) {
            copyLinkingInfoFromParentCustodian(state.portfolios, custodian.parentId, custodian);
          }
          currentPortfolio.details.custodian[custodianIndex] = {
            ...currentPortfolio.details.custodian[custodianIndex],
            ...custodian
          };
        } else if (action.includeNotFoundCustodians) {
          currentPortfolio.details.custodian.unshift(custodian);
        }
      }
      sortPortfolio(currentPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case BULK_CHANGE_CUSTODIAN_STAR_STATUS + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      for (const custodian of currentPortfolio.details.custodian) {
        if (action.custodianIdArray.includes(custodian.id)) {
          custodian.star = action.isStarred === true ? 1 : 0;
        }
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case BULK_CHANGE_CUSTODIAN_UPDATED_STATUS + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      for (const custodian of currentPortfolio.details.custodian) {
        if (action.custodianIdArray.includes(custodian.id)) {
          custodian.past = action.isUpdated === true ? 0 : 1;
        }
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case DELETE_CUSTODIAN + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const custodians = currentPortfolio.details.custodian;
      const custodianIndex = custodians.findIndex(custodian => custodian.id === action.custodianId);
      if (custodianIndex !== -1) {
        custodians.splice(custodianIndex, 1);
        currentPortfolio.details.custodian = custodians;
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case DELETE_CUSTODIAN_BULK + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const custodians = currentPortfolio.details.custodian;
      currentPortfolio.details.custodian = currentPortfolio.details.custodian.filter(
        item => action.custodianIds.includes(item.id) === false
      );
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case INSERT_SECTION + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sectionIndex = currentPortfolio.details.section.findIndex(section => section.id === action.section.id);
      if (sectionIndex === -1) {
        currentPortfolio.details.section.unshift(action.section);
      }
      sortPortfolio(currentPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case UPDATE_SECTION + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sectionIndex = currentPortfolio.details.section.findIndex(section => section.id === action.section.id);
      if (sectionIndex !== -1) {
        currentPortfolio.details.section[sectionIndex] = {
          ...currentPortfolio.details.section[sectionIndex],
          ...action.section
        };
      } else {
        currentPortfolio.details.section.push(action.section);
      }
      sortPortfolio(currentPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case MOVE_SECTION + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      const targetPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.targetPortfolioId);

      if (!currentPortfolio === true) {
        return state;
      }

      if (targetPortfolio.id === currentPortfolio.id) {
        const sectionIndex = currentPortfolio.details.section.findIndex(section => section.id === action.section.id);
        if (sectionIndex !== -1) {
          currentPortfolio.details.section[sectionIndex] = {
            ...currentPortfolio.details.section[sectionIndex],
            ...action.section
          };
        }
      } else {
        currentPortfolio.details.section = currentPortfolio.details.section.filter(
          item => item.id !== action.section.id
        );
        targetPortfolio.details.section.push(action.section);

        const sectionCustodians = currentPortfolio.details.custodian.filter(
          item => item.sectionId === action.section.id
        );
        targetPortfolio.details.custodian.push(...sectionCustodians);
      }
      sortPortfolio(targetPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case DELETE_SECTION + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sections = currentPortfolio.details.section;
      const sectionIndex = sections.findIndex(section => section.id === action.sectionId);
      if (sectionIndex !== -1) {
        sections.splice(sectionIndex, 1);
        currentPortfolio.details.section = sections;

        const custodians = currentPortfolio.details.custodian;
        if (custodians) {
          const newCustodians = custodians.filter(
            custodian => custodian.sectionId !== action.sectionId || custodian.hidden === 1
          );
          currentPortfolio.details.custodian = newCustodians;
        }
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case UPDATE_SHEET + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sheetIndex = currentPortfolio.details.sheet.findIndex(sheet => sheet.id === action.sheet.id);
      if (sheetIndex !== -1) {
        currentPortfolio.details.sheet[sheetIndex] = action.sheet;
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case MOVE_SHEET + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      const targetPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.targetPortfolioId);

      if (!currentPortfolio === true) {
        return state;
      }

      const sheetSections = currentPortfolio.details.section.filter(item => item.sheetId === action.sheet.id);
      const sheetSectionsIds = sheetSections.map(item => item.id);
      const sheetCustodians = currentPortfolio.details.custodian.filter(item =>
        sheetSectionsIds.includes(item.sectionId)
      );

      currentPortfolio.details.sheet = currentPortfolio.details.sheet.filter(item => item.id !== action.sheet.id);
      currentPortfolio.details.section = currentPortfolio.details.section.filter(
        item => sheetSectionsIds.includes(item.id) === false
      );
      currentPortfolio.details.custodian = currentPortfolio.details.custodian.filter(
        item => sheetSectionsIds.includes(item.sectionId) === false
      );

      targetPortfolio.details.sheet.push(action.sheet);
      targetPortfolio.details.section.push(...sheetSections);
      targetPortfolio.details.custodian.push(...sheetCustodians);

      sortPortfolio(targetPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case DELETE_SHEET + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sheets = currentPortfolio.details.sheet;
      const sheetIndex = sheets.findIndex(sheet => sheet.id === action.sheetId);
      if (sheetIndex !== -1) {
        sheets.splice(sheetIndex, 1);
        currentPortfolio.details.sheet = sheets;

        const sections = currentPortfolio.details.section;
        if (sections) {
          const newSections = sections.filter(section => section.sheetId !== action.sheetId);
          currentPortfolio.details.section = newSections;

          const newSectionIds = newSections.map(section => section.id);
          const custodians = currentPortfolio.details.custodian;
          if (custodians) {
            const newCustodians = custodians.filter(
              custodian => newSectionIds.includes(custodian.sectionId) === true || custodian.hidden === 1
            );
            currentPortfolio.details.custodian = newCustodians;
          }
        }
      }
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case INSERT_SHEET + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const sheetIndex = currentPortfolio.details.sheet.findIndex(sheet => sheet.id === action.sheet.id);
      if (sheetIndex === -1) {
        currentPortfolio.details.sheet.push(action.sheet);
      }
      sortPortfolio(currentPortfolio);
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case INSERT_DOCUMENT + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const newDocuments = [...currentPortfolio.details.document, action.document];
      currentPortfolio.details.document = newDocuments;
      newState.portfolios = portfolios;
      return newState;
    }
    case UPDATE_DOCUMENT + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      var newDocuments = currentPortfolio.details.document.filter(doc => doc.id !== action.document.id);
      newDocuments.push(action.document);
      currentPortfolio.details.document = newDocuments;
      newState.portfolios = portfolios;
      return newState;
    }
    case DELETE_DOCUMENT + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === true) {
        return state;
      }
      const newDocuments = currentPortfolio.details.document.filter(
        doc => doc.id !== action.document.id && doc.custodianId !== action.document.id
      );
      currentPortfolio.details.document = newDocuments;
      newState.portfolios = portfolios;
      return newState;
    }
    case UPDATE_DASHBOARD + suffix: {
      const newState = { ...state };
      newState.lastDashboardUpdateTs = new Date().getTime();
      newState.dashboardEntitesToUpdate = action.updatedEntitiesArray;

      const portfolios = Array.isArray(newState.portfolios) ? [...newState.portfolios] : [];
      for (const portfolio of portfolios) {
        for (const section of portfolio.details.section) {
          const custodians = portfolio.details.custodian
            .filter(custodian => custodian.sectionId === section.id)
            .filter(custodian => custodian.hidden !== 1);

          // Insert 3 empty custodians if none present for a section
          if (custodians.length === 0) {
            var defaultCustodians = [
              { id: getUuid(), sectionId: section.id, sortKey: "1", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: section.id, sortKey: "2", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: section.id, sortKey: "3", autoAddedForEmptySections: true }
            ];
            portfolio.details.custodian.push(...defaultCustodians);
          }
        }

        // Remove duplicate custodians if any
        const custodianIdMap = {};
        var uniqueCustodians = [];
        for (const custodian of portfolio.details.custodian) {
          if (!custodianIdMap[custodian.id] === true) {
            custodianIdMap[custodian.id] = custodian;
          }
        }
        if (Object.keys(custodianIdMap).length !== portfolio.details.custodian.length) {
          for (const key in custodianIdMap) {
            uniqueCustodians.push(custodianIdMap[key]);
          }
          portfolio.details.custodian = uniqueCustodians;
        }
      }

      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case SET_PORTFOLIO_LAST_FORCE_REFRESH_TS + suffix: {
      const newState = { ...state };
      const portfolioLastForceRefreshTsMap = newState.portfolioLastForceRefreshTsMap;
      portfolioLastForceRefreshTsMap[action.portfolioId] = action.timestamp;
      return newState;
    }
    case SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS + suffix: {
      const newState = { ...state };
      const portfolioChangeDataLastForceRefreshTsMap = newState.portfolioChangeDataLastForceRefreshTsMap;
      portfolioChangeDataLastForceRefreshTsMap[action.portfolioId] = action.timestamp;
      return newState;
    }
    case FETCH_NET_WORTH_DATA_PENDING + suffix: {
      const newState = { ...state };
      const portfolios = Array.isArray(newState.portfolios) ? [...newState.portfolios] : [];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.networth === true) {
          currentPortfolio.details.networth = {};
        }
        currentPortfolio.details.networth = {
          ...currentPortfolio.details.networth,
          ...{ isFetching: true, error: null }
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case SET_FF_PAYLOAD + suffix: {
      const newState = { ...state };
      const portfolios = Array.isArray(newState.portfolios) ? [...newState.portfolios] : [];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        currentPortfolio.ffPayload = action.data;
      }

      newState.portfolios = portfolios;
      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now(); // to persist the data
      return newState;
    }

    case FETCH_NET_WORTH_DATA_SUCCESS + suffix: {
      const newState = { ...state };
      const portfolios = [...newState?.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      const recapRawData = action.data.recapRawData;
      delete action.data.recapRawData;
      if (!currentPortfolio === false) {
        currentPortfolio.details.networth = {
          data: action.data,
          isFetching: false,
          error: null
        };
      }
      newState.portfolios = portfolios;
      newState.recapRawData = recapRawData;
      return newState;
    }
    case FETCH_NET_WORTH_DATA_ERROR + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.networth === true) {
          currentPortfolio.details.networth = {};
        }
        currentPortfolio.details.networth = {
          ...currentPortfolio.details.networth,
          ...{ isFetching: false, error: action.error }
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }
    case REMOVE_CONNECTION_ERRORS + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      if (
        !currentPortfolio === false &&
        currentPortfolio.details.networth &&
        currentPortfolio.details.networth.data.connErrors
      ) {
        const connectionErrors = currentPortfolio.details.networth.data.connErrors;
        currentPortfolio.details.networth.data.connErrors = connectionErrors.filter(
          item => action.custodianIds.includes(item.id) === false
        );
      }
      currentPortfolio.details.networth = { ...currentPortfolio.details.networth };
      newState.portfolios = portfolios;
      return newState;
    }

    case FETCH_INITIAL_RECAP_DATA_PENDING + suffix:
    case FETCH_RECAP_DATA_PENDING + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      if (!currentPortfolio === false) {
        currentPortfolio.details.recapDataStatus = {
          isFetching: true
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case FETCH_RECAP_DATA_SUCCESS + suffix: {
      const newState = { ...state };
      const portfolios = Array.isArray(newState.portfolios) ? [...newState.portfolios] : [];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);
      const { recapData, currency, isRecapPage } = action.data;
      if (!currentPortfolio === false && isRecapPage) {
        currentPortfolio.details.recapDataStatus = {
          isFetching: false
        };
      }

      if (!newState.recapDataPortfolioMap === true) {
        newState.recapDataPortfolioMap = {};
      }
      newState.recapDataPortfolioMap[currentPortfolio && currentPortfolio.id] = { data: recapData, currency: currency };
      newState.recapDataPortfolioMapUpdateTimestamp = Date.now();
      newState.portfolios = portfolios;
      newState.fetchingPercentageAllocationData = false;
      newState.showLoaderOnChartsModal = false;
      return newState;
    }

    case UPDATE_SHOW_CHARTS_MODAL_LOADER: {
      return {
        ...state,
        showLoaderOnChartsModal: action.showLoader
      };
    }
    case GET_CONNECTIVITY_CENTER_DATA_ERROR + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.connectivityCenterData === true) {
          currentPortfolio.details.connectivityCenterData = {};
        }
        currentPortfolio.details.connectivityCenterData = {
          ...currentPortfolio.details.connectivityCenterData,
          ...{ isFetching: false, error: action.error }
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }
    case GET_CONNECTIVITY_CENTER_DATA_PENDING + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.connectivityCenterData === true) {
          currentPortfolio.details.connectivityCenterData = {};
        }
        currentPortfolio.details.connectivityCenterData = {
          ...currentPortfolio.details.connectivityCenterData,
          ...{ isFetching: true, error: null }
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }
    case GET_CONNECTIVITY_CENTER_DATA_SUCCESS + suffix: {
      const newState = { ...state };
      newState.connectivityCenterData = action.data;
      return newState;
    }
    case FETCH_INITIAL_RECAP_DATA_ERROR + suffix: {
      const newState = { ...state };
      newState.isColumnarChartDataFetchError = true;
      return newState;
    }
    case FETCH_RECAP_DATA_ERROR + suffix: {
      const newState = { ...state };
      newState.isColumnarChartDataFetchError = true;
      return newState;
    }
    case FETCH_PORTFOLIO_CHANGE_DATA_PENDING + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.changeData === true) {
          currentPortfolio.details.changeData = {};
        }
        currentPortfolio.details.changeData = {
          ...currentPortfolio.details.changeData,
          ...{ isDayChangeFetching: true, isAllChangeFetching: true, error: null }
        };
      }
      newState.portfolios = portfolios;
      return newState;
    }
    case FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS + suffix: {
      const newState = { ...state };
      newState.fetchPortfolioChangeDataSuccess = true;

      const portfolios = [...newState.portfolios];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false && !currentPortfolio.details.changeData === false) {
        var changeData = currentPortfolio.details.changeData;

        if (action.isDayChangeData) {
          for (const key in action.data.change) {
            if (!action.data.change[key] === true) {
              delete action.data.change[key];
            }
          }
        }

        if (changeData.data && changeData.data.change) {
          action.data.change = { ...changeData.data.change, ...action.data.change };
        }

        currentPortfolio.details.changeData = {
          data: action.data,
          isDayChangeFetching: false,
          isAllChangeFetching: action.isDayChangeData ? changeData.isAllChangeFetching : false,
          error: null
        };
      } else if (!currentPortfolio === false && currentPortfolio.details) {
        currentPortfolio.details.changeData = {
          ...currentPortfolio.details.changeData,
          isDayChangeFetching: false,
          isAllChangeFetching: action.isDayChangeData ? changeData.isAllChangeFetching : false
        };
      }

      newState.currentPortfolioCustodiansUpdatedTimestamp = Date.now();
      newState.portfolios = portfolios;
      return newState;
    }
    case FETCH_PORTFOLIO_CHANGE_DATA_ERROR + suffix: {
      const newState = { ...state };
      const portfolios = Array.isArray(newState.portfolios) ? [...newState.portfolios] : [];
      const currentPortfolio = (portfolios || []).find(portfolio => portfolio.id === action.portfolioId);

      if (!currentPortfolio === false) {
        if (!currentPortfolio.details.changeData === true) {
          currentPortfolio.details.changeData = {};
        }
        const changeData = currentPortfolio.details.changeData;
        currentPortfolio.details.changeData = {
          ...currentPortfolio.details.changeData,
          ...{
            isDayChangeFetching: false,
            isAllChangeFetching: action.isDayChangeData ? changeData.isAllChangeFetching : false,
            error: action.error
          }
        };
      } else if (!currentPortfolio === false && currentPortfolio.details) {
        currentPortfolio.details.changeData = {
          ...currentPortfolio.details.changeData,
          isDayChangeFetching: false,
          isAllChangeFetching: action.isDayChangeData ? changeData.isAllChangeFetching : false
        };
      }

      newState.portfolios = portfolios;
      return newState;
    }
    case SET_LINK_TYPE + suffix:
      return {
        ...state,
        connectedLinkType: action.connectedLinkType
      };
    case SET_SLIDE_DIRECTION + suffix:
      return {
        ...state,
        slideDirection: action.slideDirection
      };
    case SET_SHOW_REFRESHING + suffix:
      return {
        ...state,
        isRefreshing: action.isRefreshing
      };
    case PAGE_RELOADING + suffix:
      return {
        ...state,
        isPageReloading: action.isLoading
      };
    case REFRESH_CUSTODIAN_DONE + suffix:
      return {
        ...state,
        refreshCustodoanTime: new Date().getTime()
      };
    case SET_SECTION_UPDATED + suffix:
      return {
        ...state,
        sectionsUpdated: {
          ...state.sectionsUpdated,
          [action.sectionId]: true
        }
      };
    case REMOVE_SECTION_UPDATED + suffix: {
      const newState = { ...state };

      delete newState.sectionsUpdated[action.sectionId];
      return newState;
    }
    case UPDATE_CONNECTIVITY_CENTER_DATA + suffix: {
      const newState = { ...state };
      const portfolioIndex = newState.connectivityCenterData.findIndex(data => data.portfolioId === action.portfolioId);
      const connectivityCenterData = [...newState.connectivityCenterData];
      connectivityCenterData[portfolioIndex] = {
        ...newState.connectivityCenterData[portfolioIndex],
        connectivityCenter: action.connectivityCenterDataForAPortfolio
      };
      newState.connectivityCenterData = connectivityCenterData;
      return { ...newState };
    }

    case ADD_SCENARIO + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        if (action.scenarioIndex !== undefined) {
          planningDataForAPortfolio.scenario.splice(action.scenarioIndex, 0, action.scenario);
        } else {
          planningDataForAPortfolio.scenario = [...planningDataForAPortfolio.scenario, action.scenario];
        }
        planningDataForAPortfolio.rule = [...planningDataForAPortfolio.rule, ...action.scenario.rule];
        portfolios[portfolioIndex].planning = planningDataForAPortfolio;
      }
      newState.portfolios = portfolios;
      return { ...newState };
    }

    case DELETE_SCENARIO + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        const planningScenarios = [...planningDataForAPortfolio.scenario];
        const planningRules = [...planningDataForAPortfolio.rule];
        const scenarioIndex = planningScenarios.findIndex(scenario => scenario.id === action.scenarioId);
        if (scenarioIndex !== -1) {
          planningScenarios.splice(scenarioIndex, 1);
          const filteredRules = planningRules.filter(rule => rule.scenarioId !== action.scenarioId);
          planningDataForAPortfolio.scenario = planningScenarios;
          planningDataForAPortfolio.rule = filteredRules;
          portfolios[portfolioIndex].planning = planningDataForAPortfolio;
        }
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case UPDATE_SCENARIO + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = portfolioDetails.planning;
        const scenarioIndex = planningDataForAPortfolio.scenario.findIndex(
          scenario => scenario.id === action.scenario.id
        );
        if (scenarioIndex !== -1) {
          planningDataForAPortfolio.scenario[scenarioIndex] = action.scenario;
          const updatedPortfolioDetails = { ...portfolioDetails, planning: planningDataForAPortfolio };
          portfolios[portfolioIndex] = updatedPortfolioDetails;
        }
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case ADD_RULE + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        planningDataForAPortfolio.rule.push(action.rule);
        portfolios[portfolioIndex].planning = planningDataForAPortfolio;
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case UPDATE_RULE + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        const ruleIndex = planningDataForAPortfolio.rule.findIndex(rule => rule.id === action.ruleId);
        if (ruleIndex !== -1) {
          const ruleToBeUpdated = planningDataForAPortfolio.rule[ruleIndex];
          planningDataForAPortfolio.rule[ruleIndex] = { ...ruleToBeUpdated, ...action.dataToBeUpdated };
          portfolios[portfolioIndex].planning = planningDataForAPortfolio;
        }
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case UPDATE_RULE_BULK + suffix: {
      const newState = { ...state };
      const portfolios = [...newState.portfolios];
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        for (const rule of action.rules) {
          const ruleIndex = planningDataForAPortfolio.rule.findIndex(item => item.id === rule.id);

          if (ruleIndex !== -1) {
            planningDataForAPortfolio.rule[ruleIndex] = {
              ...planningDataForAPortfolio.rule[ruleIndex],
              ...rule
            };
            portfolios[portfolioIndex].planning = planningDataForAPortfolio;
          }
        }
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case DELETE_RULE + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const planningDataForAPortfolio = { ...portfolioDetails.planning };
        const planningRules = [...planningDataForAPortfolio.rule];
        const ruleIndex = planningRules.findIndex(rule => rule.id === action.ruleId);
        if (ruleIndex !== -1) {
          planningRules.splice(ruleIndex, 1);
          planningDataForAPortfolio.rule = planningRules;
          portfolios[portfolioIndex].planning = planningDataForAPortfolio;
        }
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case UPDATE_CASH_FORECAST_DURATION + suffix: {
      const newState = { ...state };
      const portfolioCashForecastDurationMap = newState.portfolioCashForecastDurationMap;
      portfolioCashForecastDurationMap[action.portfolioId] = action.duration;
      return newState;
    }

    case ADD_REPORT_PREFERENCE + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const reportPreferences = portfolioDetails.reportPreference;
        const indexForSelectedReportPreference = reportPreferences.findIndex(
          preference => preference.reportType === action.reportType
        );
        if (indexForSelectedReportPreference !== -1) {
          reportPreferences[indexForSelectedReportPreference] = action.reportPreference;
        } else {
          reportPreferences.push(action.reportPreference);
        }
        portfolios[portfolioIndex].reportPreference = [...reportPreferences];
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case ADD_DIY_CHART + suffix: {
      const newState = { ...state };
      const portfolios = newState.portfolios;
      const portfolioIndex = (portfolios || []).findIndex(portfolio => portfolio.id === action.portfolioId);
      if (portfolioIndex !== -1) {
        const portfolioDetails = portfolios[portfolioIndex];
        const updateDiyChartsData = { portfolioId: action.portfolioId, data: action.updatedDiyChartForPortfolio };
        portfolioDetails.diyChart = updateDiyChartsData;
      }
      newState.portfolios = portfolios;
      return newState;
    }

    case DELETE_DIY_CHART + suffix: {
      const newState = { ...state };
      return newState;
    }
    case GET_FUND_SCHEDULE_DATA_SUCCESS + suffix: {
      const newState = { ...state };
      newState.portfolioFundScheduleMap[action.portfolioId] = action.fundSchedule;
      return newState;
    }
    case REHYDRATE_RECAP + suffix:
      return {
        ...state,
        recapDataPortfolioMap: action.payload
      };
    case FETCH_PERCENTAGE_ALLOCATION_DATA_PENDING:
      return {
        ...state,
        fetchingPercentageAllocationData: action.showLoader
      };
    case FETCH_PERCENTAGE_ALLOCATION_DATA_SUCCESS:
      return {
        ...state
      };
    default:
      return state;

    // make sure to add newPortfolioActions to portfolioActions array in Common.js to make sure subuser suffix logic works correctly
  }
}

export const copyParentInfoToChild = (parent, child) => {
  if (!parent === true || !child === true) {
    return;
  }

  child.linkType = parent.linkType;
  child.tsLastTransaction = parent.tsLastTransaction;
  child.tsLastUpdateCheck = parent.tsLastUpdateCheck;
  child.status = parent.status;
  child.statusInfo = parent.statusInfo;
  child.linkProviderAccountId = parent.linkProviderAccountId;
  child.linkProviderId = parent.linkProviderId;
  child.linkAccountId = parent.linkAccountId;
  child.linkProviderName = parent.linkProviderName;
  child.linkContainer = parent.linkContainer;
  child.linkAccountName = parent.linkAccountName;
  child.linkAccountMask = parent.linkAccountMask;
  child.linkAccountTsCreated = parent.linkAccountTsCreated;
  child.ownership = parent.ownership;
  child.holdingSubType = child.holdingSubType ? child.holdingSubType : child.subType;
  child.subType = child.subType === custodianSubTypes.CASH ? child.subType : parent.subType;
};

export const copyLinkingInfoFromParentCustodian = (portfolios, parentId, child) => {
  if (!parentId === true || !child === true) {
    return;
  }

  const parent = findCustodianInPortfolios(portfolios, parentId);
  if (!parent === true) {
    return;
  }

  copyParentInfoToChild(parent, child);
};

export const initialFetchSuccessSelector = state => portfoliosStateSelector(state).initialFetchSuccess;

export const sectionsUpdatedSelector = state => portfoliosStateSelector(state).sectionsUpdated;

export const refreshCustodianTimeSelector = state => portfoliosStateSelector(state).refreshCustodoanTime;

export const connectedLinkTypeSelector = state => portfoliosStateSelector(state).connectedLinkType;

export const fetchPortfolioPendingSelector = state => portfoliosStateSelector(state).fetchPortfolioPending;

export const fetchPortfolioChangeDataSuccessSelector = state =>
  portfoliosStateSelector(state).fetchPortfolioChangeDataSuccess;

export const fetchPortfolioErrorSelector = state => portfoliosStateSelector(state).fetchPortfolioError;

export const lastDashboardUpdateTsSelector = state => portfoliosStateSelector(state).lastDashboardUpdateTs;

export const dashboardEntitesToUpdateSelector = state => portfoliosStateSelector(state).dashboardEntitesToUpdate;

export const portfoliosNameSelector = state =>
  portfoliosStateSelector(state).portfolios.map(portfolio => portfolio.name);

export const sortPreferenceSelector = state => portfoliosStateSelector(state).sortPreference;

export const currentPortfolioCustodiansUpdatedTimestampSelector = state =>
  portfoliosStateSelector(state).currentPortfolioCustodiansUpdatedTimestamp;

export const recapDataPortfolioMapUpdateTimestampSelector = state =>
  portfoliosStateSelector(state).recapDataPortfolioMapUpdateTimestamp;

export const showRecapPage = state => {
  const recapData = recapDataSelector(state);
  const networthReportTotalsData =
    recapData &&
    recapData.data &&
    recapData.data[chartTimeRange.TODAY] &&
    recapData.data[chartTimeRange.TODAY][recapChartOptions.NETWORTH.id] &&
    recapData.data[chartTimeRange.TODAY][recapChartOptions.NETWORTH.id][recapChartTypes.TOTALS];
  let recapCustodians = [];
  if (networthReportTotalsData && networthReportTotalsData.Assets) {
    recapCustodians.push(networthReportTotalsData.Assets);
  }
  if (networthReportTotalsData && networthReportTotalsData.Debts) {
    recapCustodians.push(networthReportTotalsData.Debts);
  }
  recapCustodians = recapCustodians
    .flat()
    .filter(custodian => custodian.type !== "header" && custodian.values[0].value && !custodian.isArchived);
  return recapCustodians.length >= 5;
};

export const shouldShowLoaderOnPortfolioAndNeworthCurrencyMismatch = state => {
  const currentPortfolioCurrency = currentPortfolioCurrencySelector(state);
  const netWorthData = currentPortfolioNetWorthDataSelector(state);
  const netWorthDataCurrency = netWorthData && netWorthData.data && netWorthData.data.netWorthDataCurrency;
  return currentPortfolioCurrency !== netWorthDataCurrency;
};

export const shouldShowLoaderOnPortfolioAndRecapCurrencyMismatch = state => {
  const currentPortfolioCurrency = currentPortfolioCurrencySelector(state);
  const recapCurrency = recapDataCurrencySelector(state);
  return currentPortfolioCurrency !== recapCurrency;
};

export const recapReportDescriptionSelector = (
  state,
  reportId,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart
) => {
  if (!reportId === true) {
    return null;
  }

  const recapNode = recapReportNodeSelector(
    state,
    reportId,
    true,
    null,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart
  );
  const recapCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  return recapNode && recapNode.isUsFund
    ? `${recapNode.percentage}% of ${shortFormatNumberWithCurrency(
        convertCurrency(recapNode.totalValue, recapCurrency, portfolioCurrency),
        portfolioCurrency,
        true,
        true
      )}`
    : null;
};

export const recapDataCashOnHandTotalSelector = state => {
  const recapData = recapDataSelector(state);
  const recapDataCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  const cashOnHandValue =
    recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.CASH_ON_HAND.id]?.totals?.["Cash on hand"]?.[0]
      ?.values[0]?.value;
  return convertCurrency(cashOnHandValue, recapDataCurrency, portfolioCurrency);
};

export const recapDataCashOnHandCustodiansSelector = createSelector(
  [
    state => recapDataSelector(state),
    state => {
      const recapData = recapDataSelector(state);
      const cashOnHandReportData =
        recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.CASH_ON_HAND.id]?.totals?.["Cash on hand"];
      return cashOnHandReportData;
    }
  ],
  (_, cashOnHandReportData) => {
    return (
      cashOnHandReportData &&
      cashOnHandReportData
        .filter(data => (data.type === "header" ? false : true))
        .map(data => {
          return {
            name: data.name,
            id: data.id,
            value: data.values[0].value
          };
        })
    );
  }
);

export const recapDataTaxOnUnrealisedGainsSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData && recapData.data && recapData.data.taxOnUnrealisedGainsList;
};

export const recapDataCustodiansWithCommitedCapitalSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData && recapData.data && recapData.data.unfundedCustodiansList;
};

export const recapReportValueSelector = (
  state,
  reportId,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart
) => {
  if (!reportId === true) {
    return null;
  }

  const recapNode = recapReportNodeSelector(
    state,
    reportId,
    true,
    chartTimeRange.TODAY,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart
  );
  return recapNode && recapNode.values[0] && recapNode.values[0].value;
};

const recapReportLineChartDataSelectorMemoized = memoize(reportId => {
  const getMemoizedState = memoize(state => state, state => recapDataSelector(state));
  return createSelector(
    [getMemoizedState, getNetWorthChartStartDateForPortfolio, recapDataCurrencySelector],
    (state, portfolioStartDate, recapCurrency) => {
      if (!reportId === true) {
        return null;
      }

      try {
        const reportParams = parseParams(decodeURIComponent(reportId));
        const dailyData = { ...recapReportNodeSelector(state, reportId, true, chartTimeRange.DAILY) };
        const weeklyData = { ...recapReportNodeSelector(state, reportId, true, chartTimeRange.WEEKLY) };
        const monthlyData = { ...recapReportNodeSelector(state, reportId, true, chartTimeRange.MONTHLY) };
        const yearlyData = { ...recapReportNodeSelector(state, reportId, true, chartTimeRange.YEARLY) };
        dailyData.currency = recapCurrency;
        weeklyData.currency = recapCurrency;
        monthlyData.currency = recapCurrency;
        yearlyData.currency = recapCurrency;

        dailyData.data = [...dailyData.values].reverse();
        delete dailyData.values;

        weeklyData.data = [...weeklyData.values].reverse();
        delete weeklyData.values;

        monthlyData.data = [...monthlyData.values].reverse();
        delete monthlyData.values;

        yearlyData.data = [...yearlyData.values].reverse();
        delete yearlyData.values;

        var chartRawData = { chart: {} };
        chartRawData.chart[chartTimeRangeGroups.GROUP_BY_DAY] = dailyData;
        chartRawData.chart[chartTimeRangeGroups.GROUP_BY_WEEK] = weeklyData;
        chartRawData.chart[chartTimeRangeGroups.GROUP_BY_MONTH] = monthlyData;
        chartRawData.chart[chartTimeRangeGroups.GROUP_BY_YEAR] = yearlyData;

        let chartData = chartRawData;
        chartData.chart = getChartDataFromResponse(chartData.chart, "data", false, false, portfolioStartDate);

        if (!chartData === false) {
          for (const chartDuration in chartData.chart) {
            if (chartDuration === chartTimeRange.LAST_YEAR_END || !chartData.chart[chartDuration] === true) {
              continue;
            }

            const chartDataArray =
              chartData.chart[chartDuration].data &&
              chartData.chart[chartDuration].data
                .map(dataPoint => {
                  //dataPoint.value = Math.kuberaFloor(dataPoint.value);
                  return dataPoint;
                })
                .filter(dataPoint => {
                  return getDateInKuberaFormat(dataPoint.date).getTime() >= portfolioStartDate.getTime();
                });
            chartData.chart[chartDuration].data = chartDataArray.slice(
              chartDataArray.findIndex(data => data.value !== 0)
            );
          }
        }
        chartData = filterChartDataBasedOnPortfolioStartDate(chartData);

        const ytdDataPoint = { value: null, date: getKuberaDateString(getYTDStartDate().getTime()) };
        const ytdCustodianChartData = getYTDCustodianChartData(chartData, ytdDataPoint, portfolioStartDate);
        chartData.chart[chartTimeRange.YTD] = ytdCustodianChartData;
        const reportName = recapReportNameSelector(state, reportId);
        chartData.title = getLineChartTitle(reportParams.chart_option, reportParams.report_path, reportName, reportId);
        return chartData;
      } catch (e) {
        return null;
      }
    }
  );
});
export const recapReportLineChartDataSelector = (state, reportId) =>
  recapReportLineChartDataSelectorMemoized(reportId)(state);

export const recapReportContentsDoughnutDataSelectorMemoized = memoize(
  (
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) =>
    createSelector(
      [
        (state, reportContents) => {
          if (reportContents) return reportContents;
          return recapReportContentsDataSelector(
            state,
            reportId,
            isInvestableAssetsBySheetsAndSectionsChart,
            isInvestableAssetsBySectionsChart,
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
            isInvestableAssetsWithoutCashBySectionsChart,
            isAssetsBySectionsChart
          );
        },
        reportPreferencesSelector
      ],
      (reportContents, _) => {
        if (!reportId === true) {
          return null;
        }
        if (!reportContents === true) {
          return null;
        }
        return getDoughnutData(
          reportContents.name,
          reportContents.total,
          reportContents.contents.filter(item => item && item.value > 0).map(item => item.name),
          reportContents.contents.filter(item => item && item.value > 0).map(item => item.value),
          reportContents.currency,
          undefined,
          2
        );
      }
    ),
  (
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) =>
    "" +
    reportId +
    isInvestableAssetsBySheetsAndSectionsChart +
    isInvestableAssetsBySectionsChart +
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart +
    isInvestableAssetsWithoutCashBySectionsChart +
    isAssetsBySectionsChart
);

export const recapReportContentsDoughnutDataSelector = (
  state,
  reportId,
  reportContents = null,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) =>
  recapReportContentsDoughnutDataSelectorMemoized(
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  )(state, reportContents);

export const recapReportComparisonDoughnutDataSelectorMemoized = memoize(
  (
    reportId,
    reportContents,
    shouldCompareWithInvestableAsset,
    shouldCompareWithTotalAssetsOrDebts,
    shouldCompareWithSheet,
    shouldCompareAgainstInvestableAssetsWithOutCash
  ) => {
    return createSelector(
      [
        state => {
          if (reportContents) return reportContents;
          return recapReportComparisonDataSelector(
            state,
            reportId,
            shouldCompareWithInvestableAsset,
            shouldCompareWithTotalAssetsOrDebts,
            shouldCompareWithSheet,
            shouldCompareAgainstInvestableAssetsWithOutCash
          );
        },
        reportPreferencesSelector
      ],
      reportContents => {
        if (!reportId === true) {
          return null;
        }
        if (!reportContents === true) {
          return null;
        }
        const doughnutData = getDoughnutData(
          reportContents.name,
          reportContents.total,
          reportContents.contents.filter(item => item.value > 0).map(item => item.name),
          reportContents.contents.filter(item => item.value > 0).map(item => item.value),
          reportContents.currency,
          undefined,
          2
        );
        doughnutData.value = reportContents.value;
        return doughnutData;
      }
    );
  },
  (
    reportId,
    reportContents,
    shouldCompareAgainstInvestableAssets,
    shouldCompareAgainstTotalAssetsOrDebts,
    shouldCompareAgainstSheet,
    shouldCompareAgainstInvestableAssetsWithOutCash
  ) =>
    "" +
    reportId +
    reportContents +
    shouldCompareAgainstInvestableAssets +
    shouldCompareAgainstTotalAssetsOrDebts +
    shouldCompareAgainstSheet +
    shouldCompareAgainstInvestableAssetsWithOutCash
);

export const recapReportComparisonDoughnutDataSelector = (
  state,
  reportId,
  reportContents,
  shouldCompareWithInvestableAsset,
  shouldCompareWithTotalAssetsOrDebts,
  shouldCompareWithSheet,
  shouldCompareWithInvestableAssetsWithOutCash
) =>
  recapReportComparisonDoughnutDataSelectorMemoized(
    reportId,
    reportContents,
    shouldCompareWithInvestableAsset,
    shouldCompareWithTotalAssetsOrDebts,
    shouldCompareWithSheet,
    shouldCompareWithInvestableAssetsWithOutCash
  )(state);

export const showRefreshingSelector = state => {
  const portfoliosState = portfoliosStateSelector(state);
  const showRefreshing = portfoliosState.isRefreshing || portfoliosState.fetchPortfolioPending;

  if (isMobile()) {
    return showRefreshing;
  } else {
    const lastPortfoliosFetchAge = new Date().getTime() - portfoliosState.lastPortfoliosFetchTs;
    return showRefreshing && lastPortfoliosFetchAge > 7 * 24 * 60 * 60 * 1000;
  }
};

export const pageReloadingSelector = state => portfoliosStateSelector(state).isPageReloading;

export const currentThemeMode = state => {
  try {
    const userPreferences = userPreferencesSelector(state);

    return (
      userPreferences[`currentThemeMode${THEME_SUFFIX_STR}`] ||
      localStorage.getItem(`theme${THEME_SUFFIX_STR}`) ||
      "default"
    );
  } catch (e) {
    return "default";
  }
};

export const currentSlideDirectionSelector = state => portfoliosStateSelector(state).slideDirection;

export const portfolioLastForceUpdateTsSelector = (state, portfolioId) => {
  const lastRefreshTs = portfoliosStateSelector(state).portfolioLastForceRefreshTsMap[portfolioId];
  if (!lastRefreshTs === true) {
    return 0;
  }
  return lastRefreshTs;
};

export const portfolioChangeDataLastForceUpdateTsSelector = (state, portfolioId) => {
  const lastRefreshTs = portfoliosStateSelector(state).portfolioChangeDataLastForceRefreshTsMap[portfolioId];
  if (!lastRefreshTs === true) {
    return 0;
  }
  return lastRefreshTs;
};

export const portfolioSelector = createSelector(
  [portfoliosSelector, (_, portfolioId) => portfolioId],
  (portfolios, portfolioId) => {
    if (portfolios === null) {
      return null;
    }
    const currentPortfolioIndex = (portfolios || []).findIndex(portfolio => {
      return portfolio.id === portfolioId;
    });
    return currentPortfolioIndex === -1 ? null : portfolios[currentPortfolioIndex];
  }
);

export const hasValidPortfolio = portfolio => {
  const { details } = portfolio || { details: null };

  if (!details || !details.custodian) {
    return false;
  }

  let foundData = false;

  for (let i = 0; i < details.custodian.length; i++) {
    if (details.custodian[i].value) {
      foundData = true;
      break;
    }
  }

  return foundData;
};

export const hasValidPortfolioSelector = state => {
  return hasValidPortfolio(currentPortfolioSelector(state));
};

export const custodianUsingIdSelector = custodianId => {
  return state => {
    const portfolios = portfoliosStateSelector(state).portfolios;
    return findCustodianInPortfolios(portfolios, custodianId);
  };
};

export const custodianSelectorWrapper = custodianId => {
  return state => {
    return custodianSelector(state, custodianId);
  };
};

const custodiansWithSameParentIdSelectorMemoized = memoize(
  (portfolioId, parentId) => {
    return createSelector(
      [
        state => state,
        state => currentPortfolioCustodiansUpdatedTimestampSelector(state),
        state => (!parentId === true ? null : portfolioSelector(state, portfolioId))
      ],
      (_, _1, portfolio) => {
        if (!parentId === true) {
          return [];
        }
        return portfolio.details.custodian.filter(custodian => custodian.parentId === parentId);
      }
    );
  },
  (portfolioId, parentId) => `${portfolioId}-${parentId}`
);

export const custodiansWithSameParentIdSelector = (state, portfolioId, parentId) =>
  custodiansWithSameParentIdSelectorMemoized(portfolioId, parentId)(state);

export const sectionSelector = (state, sectionId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  for (const portfolio of portfolios) {
    const section = portfolio.details.section.find(section => section.id === sectionId);
    if (section) {
      return section;
    }
  }
  return null;
};

export const sectionPortfolioSelector = (state, sectionId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }
  return portfolios.find(portfolio => !portfolio.details.section.find(item => item.id === sectionId) === false);
};

export const sheetPortfolioSelector = (state, sheetId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }
  return portfolios.find(portfolio => !portfolio.details.sheet.find(item => item.id === sheetId) === false);
};

export const sectionIndexSelector = (state, sectionId, sheetId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  for (const portfolio of portfolios) {
    const filteredSection = portfolio.details.section
      .filter(section => section.sheetId === sheetId)
      .filter(section => {
        const rows = portfolio.details.custodian.filter(row => row.sectionId === section.id && row.tsModified);
        if (rows.length) {
          return true;
        }
        return false;
      });

    const index = filteredSection.findIndex(section => section.id === sectionId);
    if (index !== -1) {
      return index;
    }
  }
  return null;
};

export const sectionCustodiansSelector = (
  state,
  portfolioId,
  sectionId,
  includeAssociatedItems = false,
  onlyVisible = false
) => {
  const portfolio = portfolioSelector(state, portfolioId);
  if (!portfolio) return [];
  var custodians = portfolio.details.custodian.filter(custodian => custodian.sectionId === sectionId);

  if (includeAssociatedItems === true) {
    const associatedCustodians = sectionAssociatedCustodiansSelector(state, portfolioId, sectionId);
    custodians.push(...associatedCustodians);
  }
  custodians = [...new Set(custodians)];
  if (onlyVisible === true) {
    custodians = custodians.filter(item => item.hidden !== 1);
  }
  return custodians;
};

export const sectionAssociatedCustodiansSelector = (state, portfolioId, sectionId) => {
  var associatedCustodians = [];

  const sectionCustodians = sectionCustodiansSelector(state, portfolioId, sectionId);
  for (const custodian of sectionCustodians) {
    if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      associatedCustodians = associatedCustodians.concat(
        custodiansLinkedToSameAccount(custodian.id).portfolioCustodiansMap[portfolioId].filter(
          item => item.id !== custodian.id
        )
      );
    } else if (!custodian.parentId === false && custodian.hidden === 0) {
      const siblings = custodiansWithSameParentIdSelector(state, portfolioId, custodian.parentId);

      for (const sibling of siblings) {
        if (associatedCustodians.findIndex(item => item.id === sibling.id) === -1) {
          associatedCustodians.push(sibling);
        }
      }

      const parent = custodianSelector(state, custodian.parentId);
      if (parent && associatedCustodians.findIndex(item => item.id === parent.id) === -1) {
        associatedCustodians.push(parent);
      }
    }
  }
  return associatedCustodians;
};

export const sheetSectionsSelector = (state, portfolioId, sheetId) => {
  const portfolio = portfolioSelector(state, portfolioId);
  return portfolio.details.section.filter(section => section.sheetId === sheetId);
};

export const isSheetEmpty = (state, portfolioId, sheetId) => {
  const custodians = sheetCustodiansSelector(state, portfolioId, sheetId, false, true);
  return custodians.filter(item => isCustodianEmpty(item) === false).length === 0;
};

export const sheetCustodiansSelector = (
  state,
  portfolioId,
  sheetId,
  includeAssociatedItems = false,
  onlyVisible = false
) => {
  var custodians = [];

  const sections = sheetSectionsSelector(state, portfolioId, sheetId);
  for (const section of sections) {
    custodians.push(...sectionCustodiansSelector(state, portfolioId, section.id, includeAssociatedItems, onlyVisible));
  }
  custodians = [...new Set(custodians)];
  return custodians;
};

export const sheetAssociatedCustodiansSelector = (state, portfolioId, sheetId) => {
  var associatedCustodians = [];

  const sections = sheetSectionsSelector(state, portfolioId, sheetId);
  for (const section of sections) {
    const sectionAssociatedCustodians = sectionAssociatedCustodiansSelector(state, portfolioId, section.id);
    for (const custodian of sectionAssociatedCustodians) {
      if (associatedCustodians.findIndex(item => item.id === custodian.id) === -1) {
        associatedCustodians.push(custodian);
      }
    }
  }
  return associatedCustodians;
};

export const custodianPortfolioSelector = (state, custodianId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  for (const portfolio of portfolios) {
    if (portfolio.details.custodian.find(custodian => custodian.id === custodianId)) {
      return portfolio;
    }
  }
  return null;
};

export const custodianSheetSelector = (state, custodianId) => {
  if (!custodianId === true) {
    return null;
  }
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  var currentPortfolio = null;
  var currentCustodian = null;
  for (const portfolio of portfolios) {
    const custodian = portfolio.details.custodian.find(custodian => custodian.id === custodianId);
    if (custodian) {
      currentPortfolio = portfolio;
      currentCustodian = custodian;
      break;
    }
  }
  if (!currentPortfolio === true || !currentCustodian === true) {
    return null;
  }
  const currentSection = currentPortfolio.details.section.find(section => section.id === currentCustodian.sectionId);
  if (!currentSection === true) {
    return null;
  }
  return currentPortfolio.details.sheet.find(sheet => sheet.id === currentSection.sheetId);
};

export const custodianSectionSelector = (state, custodianId) => {
  const custodian = custodianSelector(state, doc.custodianId);
  if (!custodian === true) {
    return null;
  }
  return sectionSelector(state, custodian.sectionId);
};

export const sheetSelector = (state, sheetId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null || !sheetId === true) {
    return null;
  }

  for (const portfolio of portfolios) {
    const sheet = portfolio.details.sheet.find(sheet => sheet.id === sheetId);
    if (sheet) {
      return sheet;
    }
  }
  return null;
};

export const sheetIndexSelector = (state, sheetId, category) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  for (const portfolio of portfolios) {
    const filteredSheets = portfolio.details.sheet
      .filter(sheet => sheet.category === category)
      .filter(sheet => {
        const sections = portfolio.details.section
          .filter(section => section.sheetId === sheet.id)
          .filter(section => {
            const rows = portfolio.details.custodian.filter(row => row.sectionId === section.id && row.tsModified);
            if (rows.length) {
              const isRowValuesZero = rows.every(row => row.value === 0);
              return !isRowValuesZero;
            }
            return false;
          });
        if (sections.length) {
          return true;
        }
        return false;
      });
    const index = filteredSheets.findIndex(sheet => sheet.id === sheetId);
    if (index !== -1) {
      return index;
    }
  }
  return null;
};

export const rowIndexSelector = (state, sectionId, rowId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }

  for (const portfolio of portfolios) {
    const filteredRows = portfolio.details.custodian.filter(row => row.sectionId === sectionId && row.tsModified);
    const index = filteredRows.findIndex(row => row.id === rowId);
    if (index !== -1) {
      return index;
    }
  }
  return null;
};

export const sheetNameSelector = (state, portfolioId, sheetName) => {
  const portfolio = portfolioSelector(state, portfolioId);

  const sheet = portfolio.details.sheet.find(sheet => sheet.name === sheetName);
  if (sheet) {
    return sheet;
  }
  return null;
};

const ifNewDoc = (doc, list) => {
  if (list.includes(doc.id)) {
    return false;
  }

  list.push(doc.id);
  return true;
};

export const currentPortfolioDocumentsSelector = state => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }
  const currentPortfolioId = portfoliosStateSelector(state).currentPortfolioId;
  const currentPortfolio = (portfolios || []).find(portfolio => {
    return portfolio.id === currentPortfolioId;
  });
  if (!currentPortfolio) {
    return null;
  }
  const docIdsAdded = [];
  const portfolioDocuments = currentPortfolio.details.document || [];

  const folderOrCustodianIdsCount = portfolioDocuments.reduce((result, document) => {
    const id = document.custodianId || document.folderId;

    if (!id) {
      return result;
    }

    result[id] = id in result ? result[id] + 1 : 1;

    return result;
  }, {});

  const mappedCustodiansToFolderIds = [];
  const mappedFolders = portfolioDocuments.map(doc => {
    if (doc.fileType === "folder") {
      return {
        ...doc,
        filesCount: folderOrCustodianIdsCount[doc.id]
      };
    }

    if (doc.custodianId !== null && !mappedCustodiansToFolderIds.includes(doc.custodianId)) {
      mappedCustodiansToFolderIds.push(doc.custodianId);
      const custodian = custodianSelector(state, doc.custodianId);

      if (!custodian) {
        return doc;
      }

      const targetSheet = custodianSheetSelector(state, custodian.id);

      return {
        ...doc,
        custodianId: null,
        fileType: "custodian",
        folderId: null,
        id: doc.custodianId,
        size: 0,
        thumbnailToken: null,
        name: custodian.name,
        custodian,
        category: targetSheet && targetSheet.category,
        filesCount: folderOrCustodianIdsCount[doc.custodianId]
      };
    }

    return doc;
  });
  const uploadedDocuments =
    !mappedFolders === true
      ? []
      : mappedFolders.filter(doc => doc.custodianId === null && doc.folderId === null && ifNewDoc(doc, docIdsAdded));
  const pendingDocuments = (state.sync.pendingDocumentUploads || []).filter(
    doc =>
      doc.portfolioId === currentPortfolioId &&
      doc.custodianId === null &&
      doc.folderId === null &&
      ifNewDoc(doc, docIdsAdded)
  );
  return [...uploadedDocuments, ...pendingDocuments];
};

export const folderPortfolioDocumentsSelector = folderId => state => {
  if (!folderId) {
    return null;
  }
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }
  const currentPortfolioId = portfoliosStateSelector(state).currentPortfolioId;
  const currentPortfolio = (portfolios || []).find(portfolio => {
    return portfolio.id === currentPortfolioId;
  });
  if (!currentPortfolio) {
    return null;
  }
  const docIdsAdded = [];
  const uploadedDocuments =
    !currentPortfolio.details.document === true
      ? []
      : currentPortfolio.details.document.filter(doc => doc.folderId === folderId && ifNewDoc(doc, docIdsAdded));
  const pendingDocuments = (state.sync.pendingDocumentUploads || []).filter(
    doc => doc.portfolioId === currentPortfolioId && doc.folderId === folderId && ifNewDoc(doc, docIdsAdded)
  );
  return [...uploadedDocuments, ...pendingDocuments];
};

export const currentPortfolioDocumentsCountSelector = state => {
  const documents = currentPortfolioDocumentsSelector(state);
  return !documents === true ? 0 : documents.length;
};

export const custodianDocumentsSelector = (state, custodianId) => {
  const portfolios = portfoliosStateSelector(state).portfolios;
  if (portfolios === null) {
    return null;
  }
  const currentPortfolioId = portfoliosStateSelector(state).currentPortfolioId;
  const currentPortfolio = (portfolios || []).find(portfolio => {
    return portfolio.id === currentPortfolioId;
  });
  if (!currentPortfolio) {
    return null;
  }

  const docIdsAdded = [];
  const uploadedDocuments =
    !currentPortfolio.details.document === false
      ? currentPortfolio.details.document.filter(doc => doc.custodianId === custodianId && ifNewDoc(doc, docIdsAdded))
      : [];
  const pendingDocuments = state.sync.pendingDocumentUploads
    ? state.sync.pendingDocumentUploads.filter(doc => doc.custodianId === custodianId && ifNewDoc(doc, docIdsAdded))
    : [];

  const custodian = custodianSelector(state, custodianId);
  var parentCustodianDocuments = [];
  if (custodian && !custodian.parentId === false) {
    parentCustodianDocuments =
      !currentPortfolio.details.document === false
        ? currentPortfolio.details.document.filter(doc => doc.custodianId === custodian.parentId)
        : [];
  }

  return [...uploadedDocuments, ...parentCustodianDocuments, ...pendingDocuments];
};

export const hasUserLinkedAnyAccounts = createSelector(
  [portfoliosSelector],
  portfolios => {
    if (!portfolios === false) {
      for (const portfolio of portfolios) {
        for (const custodian of portfolio.details.custodian) {
          if (!custodian.linkType === false || custodian.isLinking === true) {
            return true;
          }
        }
      }
    }
    return false;
  }
);

export const hasUserMadeAnyManualEntries = createSelector(
  [portfoliosSelector],
  portfolios => {
    if (!portfolios === false) {
      for (const portfolio of portfolios) {
        for (const custodian of portfolio.details.custodian) {
          if (
            !custodian.linkType === true &&
            (!custodian.tsModified === false || !custodian.isLocallyEdited === false)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }
);

export const uniqueProviderIdsLinkedSelector = createSelector(
  [portfoliosSelector],
  portfolios => {
    var uniqueProviderIds = [];
    if (!portfolios === false) {
      for (const portfolio of portfolios) {
        for (const custodian of portfolio.details.custodian) {
          if (!custodian.linkProviderId === false && uniqueProviderIds.includes(custodian.linkProviderId) === false) {
            uniqueProviderIds.push(custodian.linkProviderId);
          }
        }
      }
    }
    return uniqueProviderIds;
  }
);

export const getCustodiansLinkedWithProviderAccountId = (state, providerAccountId, includeChildren = true) => {
  const custodians = allPortfoliosCustodiansSelector(state);
  var matchingCustodians = [];

  if (!custodians) {
    return matchingCustodians;
  }
  for (const custodian of custodians) {
    if (custodian.linkProviderAccountId === providerAccountId) {
      if (includeChildren === true || !custodian.parentId === true) {
        matchingCustodians.push(custodian);
      }
    }
  }
  return matchingCustodians;
};

export const isPortfolioEmpty = portfolio => {
  const assets = portfolioCustodiansSelector(undefined, portfolio, categoryType.ASSET, true);
  const debts = portfolioCustodiansSelector(undefined, portfolio, categoryType.DEBT, true);
  const insurance = portfolioCustodiansSelector(undefined, portfolio, categoryType.INSURANCE, true);

  return assets.length === 0 && debts.length === 0 && insurance.length === 0;
};

export const isCurrentPortfolioEmpty = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  return isPortfolioEmpty(currentPortfolio);
};

export const currentPortfolioCustodiansCountSelector = (state, category, excludeEmpty = false) => {
  return currentPortfolioCustodiansSelector(state, category, excludeEmpty).length;
};

export const currentPortfolioCustodiansSelector = (state, category, excludeEmpty = false) => {
  const currentPortfolio = currentPortfolioSelector(state);
  return portfolioCustodiansSelector(state, currentPortfolio, category, excludeEmpty);
};

export const allPortfoliosCustodiansSelector = state => {
  const portfolios = portfoliosSelector(state);
  var custodians = [];

  if (!portfolios === true) {
    return custodians;
  }
  for (const portfolio of portfolios) {
    custodians.push(...portfolio.details.custodian);
  }
  return custodians;
};

const portfolioCustodiansSelectorMemoized = memoize(
  (category, excludeEmpty, onlyInvestable) =>
    createSelector(
      [
        (state, portfolio) => {
          if (!portfolio && state) {
            return currentPortfolioSelector(state);
          }
          return portfolio;
        },
        state => {
          if (state) {
            return currentPortfolioCustodiansUpdatedTimestampSelector(state);
          }
          return undefined;
        }
      ],
      (portfolio, _) => {
        const categorySheets = portfolio.details.sheet.filter(sheet => sheet.category === category);
        const categorySheetIds = categorySheets.map(sheet => sheet.id);
        const categorySections = portfolio.details.section.filter(section =>
          categorySheetIds.includes(section.sheetId)
        );
        const categorySectionIds = categorySections.map(section => section.id);
        var categoryCustodians = portfolio.details.custodian.filter(custodian =>
          categorySectionIds.includes(custodian.sectionId)
        );
        if (excludeEmpty === true) {
          categoryCustodians = categoryCustodians.filter(custodian => isCustodianEmpty(custodian) === false);
        }
        if (onlyInvestable === true) {
          categoryCustodians = categoryCustodians.filter(custodian => custodian.type === 0 || custodian.type === 2);
        }
        return categoryCustodians;
      }
    ),
  (category, excludeEmpty, onlyInvestable) => "" + category + excludeEmpty + onlyInvestable
);

export const portfolioCustodiansSelector = (state = null, portfolio, category, excludeEmpty, onlyInvestable) =>
  portfolioCustodiansSelectorMemoized(category, excludeEmpty, onlyInvestable)(state, portfolio);

export const portfolioNetWorth = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  const portfolioTotalAssets = portfolioTotalForCategory(state, portfolio, categoryType.ASSET);
  const portfolioTotalDebts = portfolioTotalForCategory(state, portfolio, categoryType.DEBT);
  return portfolioTotalAssets - portfolioTotalDebts;
};

export const custodiansWithLiquidationExpenseSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  return portfolio.details.custodian.filter(item => !item.liqExpValue === false && item.hidden !== 1);
};

export const portfolioLiquidationExpenses = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }

  const custodiansWithExpense = custodiansWithLiquidationExpenseSelector(state, portfolio);
  return custodiansWithExpense.reduce((total, temp) => {
    const expense = convertCurrency(
      temp.liqExpValue,
      getTickerUsingId(temp.liqExpValueTickerId).shortName,
      portfolio.currency
    );
    return total + expense;
  }, 0);
};

const getMemoizedStateForcustodiansWithCashOnHandSelector = memoize(
  state => state,
  state => currentPortfolioCustodiansUpdatedTimestampSelector(state)
);
export const custodiansWithCashOnHandSelector = createSelector(
  [
    getMemoizedStateForcustodiansWithCashOnHandSelector,
    state => {
      const portfolio = currentPortfolioSelector(state);
      return portfolio.details.custodian;
    }
  ],
  (state, portfolioCustodians, _) => {
    return portfolioCustodians.filter(item => {
      const sheet = custodianSheetSelector(state, item.id);
      const parent = custodianSelector(state, item.parentId);

      // In hidden state cash on hand is true when parent is of type investable/cash on hand
      // and child is of type cash on hand
      // In visible state cash on hand is true only based on the item's type flag
      return (
        sheet &&
        sheet.category &&
        sheet.category === categoryType.ASSET &&
        (!item.parentId === true || !parent === false) &&
        ((item.hidden === 1 && !parent === false && parent.type !== 1 && item.type === 2) ||
          (item.hidden !== 1 && item.type === 2))
      );
    });
  }
);

const getMemoizedStateForCustodiansWithTaxOnUnrealizedGainsSelector = memoize(
  state => state,
  state => currentPortfolioCustodiansUpdatedTimestampSelector(state)
);
export const custodiansWithTaxOnUnrealizedGainsSelector = createSelector(
  [
    getMemoizedStateForCustodiansWithTaxOnUnrealizedGainsSelector,
    state => {
      const portfolio = currentPortfolioSelector(state);
      return portfolio.details.custodian;
    }
  ],
  (state, portfolioCustodians, _) => {
    return portfolioCustodians.filter(item => {
      const sheet = custodianSheetSelector(state, item.id);
      const taxDetails = item.taxDetails && JSON.parse(item.taxDetails);
      const isCustodianMarkedAsTaxFree =
        taxDetails && taxDetails.taxableAssetType ? taxDetails.taxableAssetType === custodianTaxTypes.TAX_FREE : false;
      return (
        sheet &&
        sheet.category &&
        sheet.category === categoryType.ASSET &&
        item.hidden === 0 &&
        !isCustodianMarkedAsTaxFree &&
        (!taxDetails ||
          (taxDetails && (taxDetails.costBasis === 0 || !taxDetails.costBasis === false)) ||
          (item.irrType === irrTypes.COSTBASIS && (item.cost === 0 || !item.cost === false))) &&
        item.type !== 2
      );
    });
  }
);

export const hasLinkedPortfolioAsCustodianSelector = createSelector(
  [portfoliosSelector],
  portfolios => {
    if (!portfolios) return false;

    for (const portfolio of portfolios) {
      for (const custodian of portfolio.details.custodian) {
        if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
          return true;
        }
      }
    }

    return false;
  }
);

export function getUnfundedCommitmentForCustodian(custodian) {
  const state = getState(this);
  if (!custodian.cmtdCap === true) {
    return null;
  }
  const currentPortfolio = currentPortfolioSelector(state);
  let irrDetails = null;
  try {
    if (!custodian.irr === false) {
      irrDetails = JSON.parse(custodian.irr);
    }
  } catch (e) {
    return null;
  }
  if (irrDetails && irrDetails.totalCashInTickerId && irrDetails.totalCashInTickerId === custodian.cmtdCapTickerId) {
    const unfundedCommitment = custodian.cmtdCap - irrDetails.totalCashIn;

    if (unfundedCommitment <= 0) {
      return null;
    }

    return convertCurrency(
      unfundedCommitment,
      getTickerUsingId(custodian.cmtdCapTickerId).shortName,
      currentPortfolio.currency
    );
  }
  const committedCapital = convertCurrency(
    custodian.cmtdCap,
    getTickerUsingId(custodian.cmtdCapTickerId).shortName,
    currentPortfolio.currency
  );
  var cashIns = 0;

  if (!custodian.irr === false) {
    try {
      cashIns = convertCurrency(
        irrDetails.all.cashIn,
        getTickerUsingId(irrDetails.all.cashTickerId).shortName,
        currentPortfolio.currency
      );
    } catch (e) {
      return null;
    }
  }
  const unfundedCommitment = committedCapital - cashIns;
  if (unfundedCommitment > 0) {
    return custodian.linkedOwnership
      ? calcCustodianOwnershipValue(unfundedCommitment, custodian.linkedOwnership)
      : unfundedCommitment;
  }
  return null;
}

export const getUnfundedCommitmentForCustodianReselectFn = createSelector(
  [state => state],
  state => getUnfundedCommitmentForCustodian.bind(state)
);

export const portfolioUnfundedCapital = state => {
  const custodiansWithCommittedCapital = recapDataCustodiansWithCommitedCapitalSelector(state);
  const committedCapitalValue =
    custodiansWithCommittedCapital &&
    custodiansWithCommittedCapital.reduce((total, temp) => {
      const value = getUnfundedCommitmentForCustodian.bind(state)(temp);
      return total + value;
    }, 0);
  const recapDataCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  return convertCurrency(committedCapitalValue, recapDataCurrency, portfolioCurrency);
};

export const getDefaultTaxRateForPortfolio = () => {
  const state = store.getState();
  const currentPortfolio = currentPortfolioSelector(state);
  return currentPortfolio && (currentPortfolio.taxRate || currentPortfolio.taxRate === 0)
    ? currentPortfolio.taxRate
    : 30;
};

export const getCostTickerIdForTaxOnUnrealizedGainsForCustodian = custodian => {
  const taxDetails = custodian.taxDetails ? JSON.parse(custodian.taxDetails) : null;
  if (!taxDetails === true || !taxDetails.costBasisTickerId === true) {
    if (custodian.irrType === irrTypes.COSTBASIS && custodian.cost) {
      return custodian.costTickerId;
    }
    return null;
  }
  return taxDetails.costBasisTickerId;
};

export const getCostExchangeRateForTaxOnUnrealizedGainsForCustodian = custodian => {
  const taxDetails = custodian.taxDetails ? JSON.parse(custodian.taxDetails) : null;
  if (!taxDetails === true || !taxDetails.costBasisExchangeRate === true) {
    if (custodian.irrType === irrTypes.COSTBASIS && custodian.cost) {
      return custodian.costExchangeRate;
    }
    return null;
  }
  return taxDetails.costBasisExchangeRate;
};

export const getEstimatedTaxOnUnrealizedGainsForCustodian = (custodian, unrealizedGain) => {
  if (!unrealizedGain) {
    return unrealizedGain === null ? null : 0;
  }
  const taxDetails = custodian.taxDetails ? JSON.parse(custodian.taxDetails) : null;
  let taxPercentage;
  if (taxDetails && taxDetails.taxPercentage !== undefined && taxDetails.taxPercentage !== null) {
    taxPercentage = taxDetails.taxPercentage;
  } else {
    taxPercentage = getDefaultTaxRateForPortfolio();
  }

  const estimatedTax = unrealizedGain <= 0 ? 0 : getActualValueFromPercentage(taxPercentage, unrealizedGain);

  return estimatedTax;
};

const getIrrDetails = irr => {
  try {
    const details = JSON.parse(irr);
    if (!details.all.error === false) {
      return null;
    }
    return details;
  } catch (e) {
    return null;
  }
};

export const getCostBasisForTaxOnUnrealizedGains = (custodian, holdings) => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  const cashHoldingsCost = getCashHoldingsCost(holdings, custodian.costTickerId);
  const taxDetails = custodian.taxDetails ? JSON.parse(custodian.taxDetails) : null;
  if (!taxDetails === true || !taxDetails.costBasis === true) {
    if (taxDetails && taxDetails.costBasis === 0) {
      return taxDetails.costBasis;
    }
    if (custodian.irrType === irrTypes.HOLDING && (custodian.cost === 0 || !custodian.cost === false)) {
      const irrDetails = getIrrDetails(custodian.irr);
      const cashFlowHoldings = holdings.filter(holding => holding.irrType === irrTypes.CASHFLOW);
      if (!irrDetails || cashFlowHoldings.length > 0) return null;
      const cashTicker = getTickerUsingId(irrDetails.all.cashTickerId);
      const cashExchangeRate = getExchangeRate(cashTicker.shortName, currentPortfolio.currency);
      const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
      return totalCashIn - cashHoldingsCost <= 0 ? 0 : totalCashIn - cashHoldingsCost;
    }
    if (custodian.irrType === irrTypes.COSTBASIS && (custodian.cost === 0 || !custodian.cost === false)) {
      const actualCustodianCost = custodian.cost;
      return actualCustodianCost - cashHoldingsCost <= 0 ? 0 : actualCustodianCost - cashHoldingsCost;
    }
    return null;
  }
  return taxDetails.costBasis;
};

const getActualCostBasisForTaxOnUnrealizedGainsForCustodian = (custodian, cashHoldingsCost = 0, holdings) => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  const taxDetails = custodian.taxDetails ? JSON.parse(custodian.taxDetails) : null;
  if (!taxDetails === true || !taxDetails.costBasis === true) {
    if (taxDetails && taxDetails.costBasis === 0) {
      return taxDetails.costBasis;
    }
    if (custodian.irrType === irrTypes.HOLDING && (custodian.cost === 0 || !custodian.cost === false)) {
      return getCostBasisForTaxOnUnrealizedGains(custodian, holdings);
    }
    if (custodian.irrType === irrTypes.COSTBASIS && (custodian.cost === 0 || !custodian.cost === false)) {
      return (
        getCustodianCost(
          custodian.cost,
          custodian.costExchangeRate,
          getTickerUsingId(custodian.costTickerId),
          currentPortfolio.currency
        ) - cashHoldingsCost
      );
    }
    return null;
  }
  const costBasisForTaxOnUnrealizedGains = convertCurrency(
    taxDetails.costBasis,
    getTickerUsingId(taxDetails.costBasisTickerId).shortName,
    currentPortfolio.currency
  );
  return costBasisForTaxOnUnrealizedGains;
};

export function getUnrealizedGainForACustodian(custodian, category, holdings) {
  const state = getState(this);
  const cashHoldingsCost = getCashHoldingsCost(holdings);
  const cashHoldingsValue = getCashHoldingsValueForCustodian(holdings);
  const actualCostBasisOnTaxOnUnrealizedGains = getActualCostBasisForTaxOnUnrealizedGainsForCustodian(
    custodian,
    cashHoldingsCost,
    holdings
  );
  if (
    (!actualCostBasisOnTaxOnUnrealizedGains && actualCostBasisOnTaxOnUnrealizedGains !== 0) ||
    actualCostBasisOnTaxOnUnrealizedGains < 0
  ) {
    return actualCostBasisOnTaxOnUnrealizedGains === null ? null : 0;
  }

  const currentPortfolio = currentPortfolioSelector(state);
  const custodianValue = getCustodianValue(
    custodian,
    category,
    getTickerUsingShortName.bind(state)(currentPortfolio.currency)
  );
  return custodianValue - cashHoldingsValue - actualCostBasisOnTaxOnUnrealizedGains;
}

export const getUnrealizedGainForACustodianReselectFn = createSelector(
  state => state,
  state => getUnrealizedGainForACustodian.bind(state)
);

export const portfolioTaxOnUnrealizedGains = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  const custodiansWithTaxOnUnrealizedGains = recapDataTaxOnUnrealisedGainsSelector(state);
  const taxOnUnrealizedGainsValue =
    custodiansWithTaxOnUnrealizedGains &&
    custodiansWithTaxOnUnrealizedGains.reduce((total, temp) => {
      const holdingsForACustodian = portfolio.details.custodian.filter(custodian => custodian.parentId === temp.id);
      const unrealizedGain = getUnrealizedGainForACustodian.bind(state)(
        temp,
        categoryType.ASSET,
        holdingsForACustodian
      );
      const value = temp.linkedOwnership
        ? calcCustodianOwnershipValue(
            getEstimatedTaxOnUnrealizedGainsForCustodian(temp, unrealizedGain),
            temp.linkedOwnership
          )
        : getEstimatedTaxOnUnrealizedGainsForCustodian(temp, unrealizedGain);
      return total + value;
    }, 0);
  const recapDataCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  return convertCurrency(taxOnUnrealizedGainsValue, recapDataCurrency, portfolioCurrency);
};

export const currentPortfolioTotalForCategory = (state, category, onlyInvestable = false) => {
  const currentPortfolio = currentPortfolioSelector(state);
  return portfolioTotalForCategory(state, currentPortfolio, category, onlyInvestable);
};

export const portfolioTotalForAssets = state => {
  const portfolio = currentPortfolioSelector(state);
  const portfolioTotalAssets = portfolioTotalForCategory(state, portfolio, categoryType.ASSET);

  return portfolioTotalAssets;
};

export const portfolioTotalForDebts = state => {
  const portfolio = currentPortfolioSelector(state);
  const portfolioTotalDebts = portfolioTotalForCategory(state, portfolio, categoryType.DEBT);

  return portfolioTotalDebts;
};

export const portfolioTotalForCategoryMemoized = memoize(
  (category, onlyInvestable) => {
    const getMemoizedState = memoize(
      state => state,
      state => currentPortfolioCustodiansUpdatedTimestampSelector(state)
    );
    return createSelector(
      [
        getMemoizedState,
        (state, portfolio) => {
          if (!portfolio) {
            portfolio = currentPortfolioSelector(state);
          }

          return portfolio;
        }
      ],
      (state, portfolio) => {
        if (!portfolio) {
          return 0;
        }
        const categoryCustodians = portfolioCustodiansSelector(state, portfolio, category);
        const portfolioTicker = getTickerUsingShortName.bind(state)(portfolio.currency);

        var total = 0;
        for (const custodian of categoryCustodians) {
          if (custodian.hidden === 1) {
            continue;
          }
          const custodianValue = getCustodianValue.bind(state)(custodian, category, portfolioTicker);
          if (onlyInvestable === true) {
            if (custodian.type === 0 || custodian.type === 2) {
              total = total + custodianValue;
            }
          } else {
            total = total + custodianValue;
          }
        }
        return total;
      }
    );
  },
  (category, onlyInvestable) => "" + category + onlyInvestable
);

export const portfolioTotalForCategory = (state, portfolio, category = "Asset", onlyInvestable = false) =>
  portfolioTotalForCategoryMemoized(category, onlyInvestable)(state, portfolio);

export const getCashHoldingsValueForCustodian = holdings => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  return holdings
    .filter(holding => holding.type === 2)
    .reduce((total, temp) => {
      const holdingValue = getCustodianValue(
        temp,
        categoryType.ASSET,
        getTickerUsingShortName(currentPortfolio.currency)
      );
      return total + holdingValue;
    }, 0);
};

export const getCashHoldingsCost = (holdings, custodianCostTickerId) => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  return holdings
    .filter(holding => holding.type === 2)
    .reduce((total, temp) => {
      const holdingCost = getCustodianCost(
        temp.cost,
        temp.costExchangeRate,
        getTickerUsingId(temp.costTickerId),
        custodianCostTickerId ? getTickerUsingId(custodianCostTickerId).shortName : currentPortfolio.currency
      );
      return total + holdingCost;
    }, 0);
};

export function getCustodianValue(custodian, category, portfolioTicker, convertToPortfolioCurrency = true) {
  const state = getState(this);
  if (!custodian.name === false && !custodian.value === false) {
    let value;
    if (custodian.valueTickerId === PVST_VALUE_TICKER_ID) {
      if (custodian.rate) {
        const price = getPVSTRate(custodian.rate, portfolioTicker.shortName);
        value =
          category === categoryType.DEBT && isAssetCustodian(custodian.linkContainer, custodian.linkType)
            ? -custodian.value * price
            : custodian.value * price;
      } else {
        value = 0;
      }
    } else {
      value =
        category === categoryType.DEBT && isAssetCustodian(custodian.linkContainer, custodian.linkType)
          ? -custodian.value
          : custodian.value;
    }
    if (
      !custodian.valueTickerId === true ||
      custodian.valueTickerId === portfolioTicker.id ||
      custodian.valueTickerId === PVST_VALUE_TICKER_ID ||
      convertToPortfolioCurrency === false
    ) {
      return calcCustodianOwnershipValue(value, custodian.ownership);
    } else {
      const custodianTicker = getTickerUsingId.bind(state)(custodian.valueTickerId);
      const rate = getExchangeRate.bind(state)(custodianTicker.shortName, portfolioTicker.shortName);
      return calcCustodianOwnershipValue(value, custodian.ownership) * rate;
    }
  }
  return 0;
}

export const currentPortfolioNameSelector = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  return currentPortfolio && currentPortfolio.name;
};

export const currentPortfolioNetWorthDataSelector = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  if (currentPortfolio) {
    return currentPortfolio.details.networth;
  }
  return 0;
};

export const currentPortfolioConnectionErrorsSelector = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  if (currentPortfolio && currentPortfolio.details.networth && currentPortfolio.details.networth.data) {
    return currentPortfolio.details.networth.data.connErrors;
  }
  return null;
};

export const currentPortfolioChangeDataSelector = state => {
  if (currentPortfolioSelector(state)) {
    return currentPortfolioSelector(state).details.changeData;
  }
  return null;
};

export const getChangeTotalsWithContributorsForCurrentPortfolio = createSelector(
  [
    currentPortfolioCurrencySelector,
    currentPortfolioSelector,
    currentPortfolioChangeDataSelector,
    state =>
      currentPortfolioChangeDataSelector(state)?.data ? currentPortfolioChangeDataSelector(state).data.change : null,
    (_, category) => category
  ],
  (_, currentPortfolio, changeData, changeDataChange, category) => {
    const getCurrentTotalValue = () => {
      const total = 0;
      const changeDataKey = "day";
      if (!changeData === false && !changeData.data === false) {
        if (!changeDataChange[changeDataKey] === false) {
          const yesterdaysTotal =
            category === categoryType.DEBT
              ? changeDataChange[changeDataKey].debtTotal
              : changeDataChange[changeDataKey].assetTotal;
          const change =
            category === categoryType.DEBT
              ? changeDataChange[changeDataKey].debtChangeTotal
              : changeDataChange[changeDataKey].assetChangeTotal;
          const value = yesterdaysTotal + change;
          return convertCurrency(value, changeData.data.currency, currentPortfolio.currency);
        }
      }
      return total;
    };

    const getChangeDataKey = forPastInterval => {
      switch (forPastInterval) {
        case pastValueInterval.DAY:
          return "day";
        case pastValueInterval.WEEK:
          return "week";
        case pastValueInterval.MONTH:
          return "month";
        case pastValueInterval.YEAR:
          return "year";
        case pastValueInterval.YTD:
          return "lastYearEnd";
        default:
          return null;
      }
    };

    const getTotalValueAndContributors = forInterval => {
      const changeDataKey = getChangeDataKey(forInterval);
      if (!changeData === false && !changeData.data === false && !changeDataKey === false) {
        const changeDataForInterval = changeData.data.change[changeDataKey];

        if (!changeDataForInterval === false) {
          const value =
            category === categoryType.DEBT ? changeDataForInterval.debtTotal : changeDataForInterval.assetTotal;
          const total = convertCurrency(value, changeData.data.currency, currentPortfolio.currency);

          const contributors =
            category === categoryType.ASSET ? changeDataForInterval.asset : changeDataForInterval.debt;

          const nonArchivedContributors = contributors.filter(item => item.archived === 0);
          const archivedContributors = contributors.filter(item => item.archived === 1);
          const sortedContributors = [...nonArchivedContributors, ...archivedContributors];

          const changeContributors = sortedContributors.map(item => {
            const value = convertCurrency(item.value, changeData.data.currency, currentPortfolio.currency);

            return {
              name: item.name,
              startValue: item.archived === 0 && value > 0 ? 0 : Math.abs(value),
              currentValue: item.archived === 0 && value > 0 ? Math.abs(value) : 0,
              isArchived: item.archived === 1
            };
          });

          const changeTotal = parseFloat((getCurrentTotalValue() - total).toFixed(2));
          const contributorsTotal = parseFloat(
            changeContributors.reduce((total, temp) => total + (temp.currentValue - temp.startValue), 0).toFixed(2)
          );

          if (changeTotal !== contributorsTotal) {
            changeContributors.push({
              name: `Others (${
                category === categoryType.DEBT ? "Debts" : "Assets"
              } with less than ${getSymbolForTickerUsingShortName(currentPortfolio.currency)}${
                isCryptoCurrency(currentPortfolio.currency) ? 0.0001 : 1
              } change)`,
              startValue: contributorsTotal,
              currentValue: changeTotal
            });
          }

          return {
            total: total,
            contributors: changeContributors,
            isInsidePortfolioStartDate:
              checkIfDateIsGreaterThanPortfolioStartDate(currentPortfolio.id, forInterval) ||
              forInterval === pastValueInterval.YTD
          };
        }
      }
      return { total: 0, contributors: [] };
    };

    var totalsWithContributors = {};
    totalsWithContributors.currentTotal = getCurrentTotalValue();
    totalsWithContributors.changes = {};
    totalsWithContributors.changes.day = getTotalValueAndContributors(pastValueInterval.DAY);
    totalsWithContributors.changes.week = getTotalValueAndContributors(pastValueInterval.WEEK);
    totalsWithContributors.changes.month = getTotalValueAndContributors(pastValueInterval.MONTH);
    totalsWithContributors.changes.year = getTotalValueAndContributors(pastValueInterval.YEAR);
    totalsWithContributors.changes.ytd = getTotalValueAndContributors(pastValueInterval.YTD);
    return totalsWithContributors;
  }
);

export const isPortfolioReadySelector = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  const netWorthTotal = portfolioNetWorth(state);
  if (!currentPortfolio === true) {
    return true;
  }
  const validCustodianCount = currentPortfolio.details.custodian.filter(custodian => {
    const sheetData = custodianSheetSelector(state, custodian.id);
    return (
      sheetData && sheetData.category === categoryType.ASSET && !custodian.name === false && !custodian.value === false
    );
  }).length;
  return validCustodianCount >= 1 && netWorthTotal !== 0;
};

export const shouldDefaultChartBeEnabled = chartId => {
  const chartParams = parseParams(chartId);
  const reportId = chartParams.report_id;
  let data = null;
  if (chartParams.chart_style === chartStyle.DOUGHNUT) {
    if (chartParams.chart_content === chartContent.CONTENTS) {
      data = recapReportContentsDoughnutDataSelector(store.getState(), reportId);
    }
  }
  return data && data.labels.length >= 2;
};

export const dashboardChartsDataSelectorMemoized = memoize(chartIds => {
  const getMemoizedState = memoize(state => state, state => recapDataSelector(state));
  return createSelector(
    [getMemoizedState],
    state => {
      var chartsData = [];
      for (const chartId of chartIds) {
        try {
          const chartParams = parseParams(chartId);
          const reportId = chartParams.report_id;
          const isChecked = chartParams.is_checked;
          const content = chartParams.chart_content;
          const chartName = chartParams.chart_name;
          const isDefaultChart = chartParams.is_default_chart;
          const shouldCompareWithTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts === "true";
          const shouldCompareWithInvestableAsset = chartParams.should_compare_with_investable_assets === "true";
          const shouldCompareWithSheet = chartParams.should_compare_with_sheet === "true";
          const shouldCompareWithInvestableAssetsWithOutCash =
            chartParams.should_compare_with_investable_assets_without_cash === "true";
          const isDisabled = chartParams.is_disabled === "true";
          var data = null;
          let recommendationCount = 0;
          if (chartParams.chart_style === chartStyle.LINE) {
            data = recapReportLineChartDataSelector(state, reportId);
          } else if (chartParams.chart_style === chartStyle.DOUGHNUT) {
            if (chartParams.chart_content === chartContent.CONTENTS) {
              data = recapReportContentsDoughnutDataSelector(state, reportId);
              recommendationCount = getRecommendationCountForAChart(
                reportId,
                content,
                (data && data.percentages) || []
              );
            } else if (chartParams.chart_content === chartContent.REPORTS) {
              data = recapReportComparisonDoughnutDataSelector(
                state,
                reportId,
                null,
                shouldCompareWithInvestableAsset,
                shouldCompareWithTotalAssetsOrDebts,
                shouldCompareWithSheet,
                shouldCompareWithInvestableAssetsWithOutCash
              );
              if (data) {
                data.content = content;
              }
              recommendationCount = getRecommendationCountForAChart(
                reportId,
                content,
                (data && data.percentages) || []
              );
            }
            if (chartParams.chart_content === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION) {
              data = recapReportContentsDoughnutDataSelector(state, reportId, null, true);
              recommendationCount = getRecommendationCountForAChart(
                reportId,
                content,
                (data && data.percentages) || []
              );
            }
            if (chartParams.chart_content === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION) {
              const node = recapReportNodeSelector(state, reportId, true, chartTimeRange.TODAY, false, true);
              const isAllSheetsHaveOneSection =
                node && node.sheets.filter(sheet => sheet).every(sheet => sheet?.sections?.length === 1);
              if (!isAllSheetsHaveOneSection) {
                data = recapReportContentsDoughnutDataSelector(state, reportId, null, false, true);
                recommendationCount = getRecommendationCountForAChart(
                  reportId,
                  content,
                  (data && data.percentages) || []
                );
              }
            }
            if (
              chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION
            ) {
              data = recapReportContentsDoughnutDataSelector(state, reportId, null, false, false, true);
              recommendationCount = getRecommendationCountForAChart(
                reportId,
                content,
                (data && data.percentages) || []
              );
            }
            if (chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION) {
              const node = recapReportNodeSelector(
                state,
                reportId,
                true,
                chartTimeRange.TODAY,
                false,
                false,
                false,
                true
              );
              const isAllSheetsHaveOneSection =
                node && node.sheets.filter(sheet => sheet).every(sheet => sheet?.sections?.length === 1);
              if (!isAllSheetsHaveOneSection) {
                data = recapReportContentsDoughnutDataSelector(state, reportId, null, false, false, false, true);
                recommendationCount = getRecommendationCountForAChart(
                  reportId,
                  content,
                  (data && data.percentages) || []
                );
              }
            }
            if (chartParams.chart_content === chartContent.ASSETS_GROUPED_BY_SECTIONS) {
              const node = recapReportNodeSelector(state, reportId, null, false, false, false, false, false, true);
              const isAllSheetsHaveOneSection =
                node && node.sheets.filter(sheet => sheet).every(sheet => sheet?.sections?.length === 1);
              if (!isAllSheetsHaveOneSection) {
                data = recapReportContentsDoughnutDataSelector(state, reportId, null, false, false, false, false, true);
                recommendationCount = getRecommendationCountForAChart(
                  reportId,
                  content,
                  (data && data.percentages) || []
                );
              }
            }
          } else if (content === chartContent.CONNECTIVITY_WIDGET) {
            data = { title: i18n.t("connectivityWidgettitle") };
          }
          const shouldShowTotalSubText = chartName === "Investable Assets" && isDefaultChart === "true";
          const assetsTotal = recapDataTotalAssetsSelector(state);
          const investableAssetsTotal = recapDataTotalInvestableAssetsSelector(state);
          const investablePercentage = getPercentageValue(
            investableAssetsTotal && investableAssetsTotal.values[0].value,
            assetsTotal && assetsTotal.values[0].value
          );
          // const shouldShowChart = isDefaultChart === "true" ? data && data.labels.length >= 2 : true;
          if (content === chartContent.CONNECTIVITY_WIDGET) {
            chartsData.push({
              ...data,
              id: chartId,
              isChecked: isChecked === "true",
              chartStyle: chartParams.chart_style,
              recommendationCount: recommendationCount,
              isDisabled: isDisabled,
              chartContent: content
            });
          } else {
            const reportCurrentValue = recapReportValueSelector(
              state,
              chartParams.report_id,
              content === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION,
              content === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION,
              content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION,
              content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION
            );
            if (reportCurrentValue && data) {
              chartsData.push({
                ...data,
                id: chartId,
                isChecked: isChecked === "true",
                chartStyle: chartParams.chart_style,
                recommendationCount: recommendationCount,
                totalSubText: shouldShowTotalSubText ? `${investablePercentage}% of Assets` : null,
                isDisabled: isDisabled
              });
            } else {
              chartsData.push(null);
            }
          }
        } catch (e) {
          console.log("error", e);
        }
      }
      return chartsData;
    }
  );
});

export const dashboardChartsDataSelector = (state, chartIds) => dashboardChartsDataSelectorMemoized(chartIds)(state);

const checkIfRecapDataIsEmpty = state => {
  const recapData = recapDataSelector(state);
  return (
    recapData &&
    recapData.data &&
    recapData.data[chartTimeRange.DAILY] &&
    recapData.data[chartTimeRange.DAILY][recapChartOptions.NETWORTH.id] &&
    recapData.data[chartTimeRange.DAILY][recapChartOptions.NETWORTH.id][recapChartTypes.TOTALS] &&
    recapData.data[chartTimeRange.DAILY][recapChartOptions.NETWORTH.id][recapChartTypes.TOTALS][
      RECAP_CATEGORY_TYPE_NETWORTH
    ] &&
    recapData.data[chartTimeRange.DAILY][recapChartOptions.NETWORTH.id][recapChartTypes.TOTALS][
      RECAP_CATEGORY_TYPE_NETWORTH
    ][0].values.length === 0
  );
};

export const defaultDashboardChartIdsSelector = createSelector(
  [currentPortfolioSelector, recapDataSelector],
  (currentPortfolio, recapData) => {
    try {
      if (!recapData === true) {
        return [];
      }
      const timeRange = chartTimeRange.TODAY;

      const chartOption = recapChartOptions.SHEETS_AND_SECTIONS.id;
      const chartType = getRecapChartTypeForPortfolio(store.getState());
      if (checkIfRecapDataIsEmpty(store.getState())) {
        return [];
      }

      const reportTotals = recapData.data[timeRange][chartOption].totals;
      const assetTotals = reportTotals[RECAP_CATEGORY_TYPE_ASSET];
      const debtTotals = reportTotals[RECAP_CATEGORY_TYPE_DEBT];
      if (!assetTotals === true || !debtTotals === true) {
        return [];
      }

      const connectivityCenterDataForAPortfolio = connectivityCenterDataForPortfolioSelector(
        store.getState(),
        currentPortfolio.id
      );
      const showConnectivityWidget =
        connectivityCenterDataForAPortfolio && connectivityCenterDataForAPortfolio.length > 0;
      var chartIds = [];
      if (showConnectivityWidget) {
        chartIds.push(
          createChartId(
            null,
            null,
            null,
            null,
            null,
            chartStyle.OTHER,
            chartContent.CONNECTIVITY_WIDGET,
            showConnectivityWidget,
            true,
            showConnectivityWidget
          )
        );
      }
      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.ASSET_CLASSES.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.ASSET_CLASSES.id,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_ASSET,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }
      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.INVESTABLE.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.INVESTABLE.id,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.INVESTABLE.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.INVESTABLE.id,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION,
            true,
            true,
            true
          )
        );
      }
      if (
        !checkIfAChartOptionHasNoDataToShow(recapChartOptions.INVESTABLE_WITHOUT_CASH.id, timeRange, recapData.data)
      ) {
        chartIds.push(
          createChartId(
            recapChartOptions.INVESTABLE_WITHOUT_CASH.id,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS_WITHOUT_CASH,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.ASSETS_AND_CURRENCY.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.ASSETS_AND_CURRENCY.id,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_FIAT_ASSET,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.STOCKS_AND_GEOGRAPHY.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.STOCKS_AND_GEOGRAPHY.id,
            timeRange,
            chartType,
            null,
            "Stocks",
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.STOCKS_AND_SECTOR.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.STOCKS_AND_SECTOR.id,
            timeRange,
            chartType,
            null,
            "Stocks",
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.STOCKS_AND_MARKETCAP.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.STOCKS_AND_MARKETCAP.id,
            timeRange,
            chartType,
            null,
            "Stocks",
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (!checkIfAChartOptionHasNoDataToShow(recapChartOptions.CRYPTO.id, timeRange, recapData.data)) {
        chartIds.push(
          createChartId(
            recapChartOptions.CRYPTO.id,
            timeRange,
            chartType,
            null,
            "Crypto",
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );
      }

      if (assetTotals.values[assetTotals.values.length - 1] !== 0) {
        chartIds.push(
          createChartId(
            chartOption,
            timeRange,
            chartType,
            null,
            RECAP_CATEGORY_TYPE_ASSET,
            chartStyle.DOUGHNUT,
            chartContent.CONTENTS,
            true,
            true,
            true
          )
        );

        for (const sheet of assetTotals.sheets) {
          if (sheet) {
            chartIds.push(
              createChartId(
                chartOption,
                timeRange,
                chartType,
                `${RECAP_CATEGORY_TYPE_ASSET}/sheets`,
                sheet.id,
                chartStyle.DOUGHNUT,
                chartContent.CONTENTS,
                sheet.sections && sheet.sections.length > 1,
                true,
                sheet.sections && sheet.sections.length > 1
              )
            );
            if (sheet.sections.length > 1) {
              for (const section of sheet.sections) {
                if (section) {
                  chartIds.push(
                    createChartId(
                      chartOption,
                      timeRange,
                      chartType,
                      `${RECAP_CATEGORY_TYPE_ASSET}/sections`,
                      section.sectionId,
                      chartStyle.DOUGHNUT,
                      chartContent.CONTENTS,
                      false,
                      true,
                      false
                    )
                  );
                }
              }
            }
          }
        }
      }

      return chartIds;
    } catch (e) {
      console.log("e", e);
    }
  }
);

export const chartIdsForSelectedCustodianSelector = (
  selectedChartOptions,
  selectedTimeRange,
  selectedChartType,
  reportPath,
  reportNodeId,
  reportId,
  shouldShowContentsTab,
  shouldShowReportsTab,
  shouldShowInvestableAssetsBySheetsTab,
  shouldShowInvestableAssetsBySectionsTab,
  shouldShowInvestableAssetsWithoutCashBySheetsTab,
  shouldShowInvestableAssetsWithoutCashBySectionsTab,
  shouldShowAssetsBySectionsTab
) => {
  const chartIds = [];
  // line chart
  chartIds.push(
    createChartId(
      selectedChartOptions,
      selectedTimeRange,
      selectedChartType,
      reportPath,
      reportNodeId,
      chartStyle.LINE,
      null,
      true
    )
  );
  if (shouldShowContentsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.CONTENTS,
        true
      )
    );
  }
  if (shouldShowInvestableAssetsBySheetsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION,
        true,
        true
      )
    );
  }
  if (shouldShowInvestableAssetsBySectionsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION,
        true,
        false
      )
    );
  }
  if (shouldShowInvestableAssetsWithoutCashBySheetsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION,
        true,
        false
      )
    );
  }
  if (shouldShowInvestableAssetsWithoutCashBySectionsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION,
        true,
        false
      )
    );
  }
  if (shouldShowAssetsBySectionsTab) {
    chartIds.push(
      createChartId(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        chartStyle.DOUGHNUT,
        chartContent.ASSETS_GROUPED_BY_SECTIONS,
        true,
        false
      )
    );
  }
  if (shouldShowReportsTab) {
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (reportPath === reportPaths.ASSETS_ROW) {
        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        const shouldAddToSheetsChart = parentNode.type && parentNode.type === "header";
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            false
          )
        );
        if (shouldAddToSheetsChart) {
          chartIds.push(
            createChartId(
              selectedChartOptions,
              selectedTimeRange,
              selectedChartType,
              reportPath,
              reportNodeId,
              chartStyle.DOUGHNUT,
              chartContent.REPORTS,
              true,
              false,
              false,
              false,
              false,
              true
            )
          );
        }

        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            true,
            false
          )
        );
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            true
          )
        );
      } else if (reportPath === reportPaths.DEBTS_ROW) {
        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        const shouldAddToSheetsChart = parentNode.type && parentNode.type === "header";
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            false
          )
        );
        if (shouldAddToSheetsChart) {
          chartIds.push(
            createChartId(
              selectedChartOptions,
              selectedTimeRange,
              selectedChartType,
              reportPath,
              reportNodeId,
              chartStyle.DOUGHNUT,
              chartContent.REPORTS,
              true,
              false,
              false,
              false,
              false,
              true
            )
          );
        }
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            true
          )
        );
      } else if (reportPath === reportPaths.ASSETS_SECTION || reportPath === reportPaths.DEBTS_SECTION) {
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            false
          )
        );
        if (reportPath === reportPaths.ASSETS_SECTION) {
          chartIds.push(
            createChartId(
              selectedChartOptions,
              selectedTimeRange,
              selectedChartType,
              reportPath,
              reportNodeId,
              chartStyle.DOUGHNUT,
              chartContent.REPORTS,
              true,
              false,
              false,
              true,
              false
            )
          );
        }
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            true
          )
        );
      } else if (reportPath === reportPaths.ASSETS_SHEET || reportPath === reportPaths.DEBTS_SHEETS) {
        if (reportPath === reportPaths.ASSETS_SHEET) {
          chartIds.push(
            createChartId(
              selectedChartOptions,
              selectedTimeRange,
              selectedChartType,
              reportPath,
              reportNodeId,
              chartStyle.DOUGHNUT,
              chartContent.REPORTS,
              true,
              false,
              false,
              true,
              false
            )
          );
        }
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            false
          )
        );
      }
    } else {
      chartIds.push(
        createChartId(
          selectedChartOptions,
          selectedTimeRange,
          selectedChartType,
          reportPath,
          reportNodeId,
          chartStyle.DOUGHNUT,
          chartContent.REPORTS,
          true
        )
      );
      const node = recapReportNodeSelector(
        store.getState(),
        reportId,
        true,
        selectedTimeRange,
        shouldShowInvestableAssetsBySheetsTab,
        shouldShowInvestableAssetsBySectionsTab,
        shouldShowInvestableAssetsWithoutCashBySheetsTab,
        shouldShowInvestableAssetsWithoutCashBySectionsTab,
        shouldShowAssetsBySectionsTab
      );
      if (
        selectedChartOptions === recapChartOptions.INVESTABLE.id &&
        node &&
        (!node.type || node.type !== "header") &&
        reportNodeId !== "Investable Assets"
      ) {
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            true,
            false,
            false,
            false
          )
        );
      }
      if (
        selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
        node &&
        (!node.type || node.type !== "header") &&
        reportNodeId !== "Investable Assets ex Cash"
      ) {
        chartIds.push(
          createChartId(
            selectedChartOptions,
            selectedTimeRange,
            selectedChartType,
            reportPath,
            reportNodeId,
            chartStyle.DOUGHNUT,
            chartContent.REPORTS,
            true,
            false,
            false,
            false,
            false,
            false,
            true
          )
        );
      }
    }
  }
  return chartIds;
};

export const createChartIdUsingReportId = (
  reportId,
  chartStyle,
  chartContent,
  isChecked,
  isDefaultChart,
  chartName,
  shouldBeCheckedByDefault,
  shouldCompareWithInvestableAsset,
  shouldCompareWithTotalAssetsOrDebts,
  shouldCompareWithSheet,
  shouldCompareWithInvestableAssetsWithOutCash
) => {
  return [
    `report_id=${reportId}`,
    `chart_style=${chartStyle}`,
    `chart_content=${chartContent}`,
    `is_checked=${isChecked}`,
    `is_default_chart=${isDefaultChart}`,
    `chart_name=${encodeURIComponent(chartName)}`,
    `should_be_checked_by_default=${shouldBeCheckedByDefault}`,
    `should_compare_with_investable_assets=${shouldCompareWithInvestableAsset}`,
    `should_compare_with_total_assets_or_debts=${shouldCompareWithTotalAssetsOrDebts}`,
    `should_compare_with_sheet=${shouldCompareWithSheet}`,
    `should_compare_with_investable_assets_without_cash=${shouldCompareWithInvestableAssetsWithOutCash}`
  ].join("&");
};

export const getChartStyleFromChartId = chartId => {
  const chartParams = parseParams(chartId);
  return chartParams.chart_style;
};

const createChartId = (
  chartOption,
  chartTimeRange,
  chartType,
  reportPath,
  reportNodeId,
  chartStyles,
  chartContents,
  isChecked,
  isDefaultChart,
  shouldBeCheckedByDefault,
  shouldCompareWithInvestableAsset,
  shouldCompareWithTotalAssetsOrDebts,
  shouldCompareWithSheet,
  shouldCompareWithInvestableAssetsWithOutCash
) => {
  try {
    var reportIdParams = [
      `chart_option=${chartOption}`,
      `chart_timerange=${chartTimeRange}`,
      `chart_type=${chartType}`
    ];
    if (reportPath) {
      reportIdParams.push(`report_path=${encodeURIComponent(reportPath)}`);
    }
    reportIdParams.push(`report_node_id=${encodeURIComponent(reportNodeId)}`);
    const reportId = encodeURIComponent(reportIdParams.join("&"));
    let reportName;
    if (chartContents !== chartContent.CONNECTIVITY_WIDGET) {
      reportName = recapReportNameSelector(store.getState(), decodeURIComponent(reportId));
    }
    let chartName;
    if (chartContents === chartContent.CONTENTS) {
      chartName = getContentsTabTitle(chartOption, reportNodeId, reportPath, reportName, reportId, true);
    } else if (chartContents === chartContent.REPORTS) {
      chartName = getComparisonReportsTabTitle(
        reportName,
        reportNodeId,
        reportPath,
        chartOption,
        shouldCompareWithInvestableAsset,
        reportId,
        false,
        shouldCompareWithTotalAssetsOrDebts,
        shouldCompareWithSheet,
        shouldCompareWithInvestableAssetsWithOutCash
      );
    } else if (chartContents === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION) {
      chartName = "Investable Assets x Sheets";
    } else if (chartContents === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION) {
      chartName = "Investable Assets x Sections";
    } else if (chartContents === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION) {
      chartName = "Investable Assets ex Cash x Sheets";
    } else if (chartContents === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION) {
      chartName = "Investable Assets ex Cash x Sections";
    } else if (chartContents === chartContent.CONNECTIVITY_WIDGET) {
      chartName = "Connectivity";
    } else if (chartContents === chartContent.ASSETS_GROUPED_BY_SECTIONS) {
      chartName = "Assets x Sections";
    } else {
      chartName = reportName;
    }
    return createChartIdUsingReportId(
      reportId,
      chartStyles,
      chartContents,
      isChecked,
      isDefaultChart,
      chartName,
      shouldBeCheckedByDefault,
      shouldCompareWithInvestableAsset,
      shouldCompareWithTotalAssetsOrDebts,
      shouldCompareWithSheet,
      shouldCompareWithInvestableAssetsWithOutCash
    );
  } catch (e) {
    console.log("e", e);
  }
};

export const getColumnForChart = dashboardCharts => {
  try {
    const column1FilteredCharts = dashboardCharts.columns.column1.chartIds.filter(id => {
      const params = parseParams(id);
      return params[chartKeyParams.IS_CHECKED] === "true";
    });
    const column2FilteredCharts = dashboardCharts.columns.column2.chartIds.filter(id => {
      const params = parseParams(id);
      return params[chartKeyParams.IS_CHECKED] === "true";
    });
    if (
      column1FilteredCharts.length === column2FilteredCharts.length ||
      column2FilteredCharts.length > column1FilteredCharts.length
    ) {
      return "column1";
    } else {
      return "column2";
    }
  } catch (e) {
    console.log("e", e);
  }
};

const getCurrentPortfolioDataInNestedFormatMemoized = memoize((category, onlyInvestable = false) =>
  createSelector(
    [currentPortfolioSelector, state => currentPortfolioTotalForCategory(state, category)],
    (currentPortfolio, _) => {
      const portfolio = currentPortfolio;
      const details = portfolio ? portfolio.details : { sheet: [] };
      const portfolioTicker = getTickerUsingShortName(portfolio ? portfolio.currency : "USD");

      var portfolioData = {};
      var sheets = [];
      for (const sheet of details.sheet) {
        if (sheet.category !== category) {
          continue;
        }

        var sections = [];
        for (const section of details.section) {
          if (section.sheetId !== sheet.id) {
            continue;
          }
          var custodians = [];
          for (const custodian of details.custodian) {
            if (custodian.sectionId !== section.id || custodian.hidden === 1) {
              continue;
            }
            if (!custodian.value === false) {
              if (
                onlyInvestable === false ||
                (onlyInvestable === true && (custodian.type === 0 || custodian.type === 2))
              ) {
                custodians.push(custodian);
              }
            }
          }
          const sectionTotal = custodians.reduce(
            (total, temp) => total + getCustodianValue(temp, category, portfolioTicker),
            0
          );
          custodians.sort(
            (a, b) => getCustodianValue(b, category, portfolioTicker) - getCustodianValue(a, category, portfolioTicker)
          );

          const sectionData = { section: section, custodians: custodians, sectionTotal: sectionTotal };
          sections.push(sectionData);
        }
        sections = sections.filter(sectionData => sectionData.sectionTotal !== 0);
        sections.sort((a, b) => b.sectionTotal - a.sectionTotal);

        const sheetTotal = sections.reduce((total, temp) => total + temp.sectionTotal, 0);
        const sheetData = { sheet: sheet, sections: sections, sheetTotal: sheetTotal };
        sheets.push(sheetData);
      }
      sheets = sheets.filter(sheetData => sheetData.sheetTotal !== 0);
      sheets.sort((a, b) => b.sheetTotal - a.sheetTotal);

      const portfolioTotal = sheets.reduce((total, temp) => total + temp.sheetTotal, 0);
      portfolioData = { sheets: sheets, portfolioTotal: portfolioTotal };

      return portfolioData;
    }
  )
);

export const getCurrentPortfolioDataInNestedFormat = (state, category, onlyInvestable = false) => {
  return getCurrentPortfolioDataInNestedFormatMemoized(category, onlyInvestable)(state);
};

const doughnutDataForPortfolioAssetsMemoized = memoize((onlyInvestable = false) =>
  createSelector(
    [
      currentPortfolioSelector,
      state => getCurrentPortfolioDataInNestedFormat(state, categoryType.ASSET, onlyInvestable)
    ],
    (currentPortfolio, portfolioData) => {
      const category = categoryType.ASSET;
      const portfolio = currentPortfolio;
      const portfolioTicker = getTickerUsingShortName(portfolio ? portfolio.currency : "USD");
      if (portfolioData.portfolioTotal === 0) {
        return [];
      }

      var doughnutData = [];
      if (portfolioData.sheets.length > 1) {
        const data = getDoughnutData(
          `${category}s`,
          portfolioData.portfolioTotal,
          portfolioData.sheets.map(sheetData => sheetData.sheet.name),
          portfolioData.sheets.map(sheetData => sheetData.sheetTotal),
          portfolioTicker.shortName
        );
        doughnutData.push(data);
      }
      var sectionDoughnuts = [];
      var custodianDoughnuts = [];
      for (const sheetData of portfolioData.sheets) {
        if (sheetData.sections.length > 1) {
          const data = getDoughnutData(
            sheetData.sheet.name,
            sheetData.sheetTotal,
            sheetData.sections.map(sectionData => sectionData.section.name),
            sheetData.sections.map(sectionData => sectionData.sectionTotal),
            portfolioTicker.shortName
          );
          sectionDoughnuts.push(data);
        }

        for (const sectionData of sheetData.sections) {
          var custodianGroups = {};

          for (const custodian of sectionData.custodians) {
            const custodianValueTicker = getTickerUsingId(custodian.valueTickerId);

            if (custodianValueTicker.type === tickerTypes.FIAT) {
              const currentEntry = custodianGroups[custodian.name];

              if (!currentEntry === true) {
                custodianGroups[custodian.name] = {
                  name: custodian.name,
                  value: getCustodianValue(custodian, category, portfolioTicker)
                };
              } else {
                currentEntry.value += getCustodianValue(custodian, category, portfolioTicker);
              }
            } else {
              const currentEntry = custodianGroups[custodian.valueTickerId];

              if (!currentEntry === true) {
                custodianGroups[custodian.valueTickerId] = {
                  name: custodianValueTicker.name,
                  value: getCustodianValue(custodian, category, portfolioTicker)
                };
              } else {
                currentEntry.value += getCustodianValue(custodian, category, portfolioTicker);
              }
            }
          }
          const groups = Object.values(custodianGroups);
          groups.sort((a, b) => b.value - a.value);
          if (groups.length > 1) {
            const data = getDoughnutData(
              sheetData.sections.length === 1 ? sheetData.sheet.name : sectionData.section.name,
              sectionData.sectionTotal,
              groups.map(group => group.name),
              groups.map(group => group.value),
              portfolioTicker.shortName
            );
            custodianDoughnuts.push(data);
          }
        }
      }

      doughnutData.push(...sectionDoughnuts);
      doughnutData.push(...custodianDoughnuts);

      const greaterThanVal = onlyInvestable ? 0 : 1;
      const assetDoughnuts = doughnutData.filter(data => data.labels.length > greaterThanVal);
      if (assetDoughnuts.length > 0) {
        assetDoughnuts[0].title = onlyInvestable ? "Investable Assets" : "Assets";
      }
      return assetDoughnuts;
    }
  )
);

const doughnutDataForPortfolioAssetsOnlyInvestable = state => {
  return doughnutDataForPortfolioAssetsMemoized(true)(state);
};

const doughnutDataForPortfolioAssetsNotOnlyInvestable = state => {
  return doughnutDataForPortfolioAssetsMemoized(false)(state);
};

const doughnutDataForPortfolioDebts = createSelector(
  [currentPortfolioSelector, state => getCurrentPortfolioDataInNestedFormat(state, categoryType.DEBT)],
  (currentPortfolio, portfolioData) => {
    const category = categoryType.DEBT;
    const portfolio = currentPortfolio;
    const portfolioTicker = getTickerUsingShortName(portfolio ? portfolio.currency : "USD");

    var doughnutData = [];
    if (portfolioData.sheets.length > 1) {
      const data = getDoughnutData(
        `${category}s`,
        portfolioData.portfolioTotal,
        portfolioData.sheets.map(sheetData => sheetData.sheet.name),
        portfolioData.sheets.map(sheetData => sheetData.sheetTotal),
        portfolioTicker.shortName
      );
      doughnutData.push(data);
    }
    return doughnutData;
  }
);

export const doughnutDataForPortfolioSelector = createSelector(
  [
    doughnutDataForPortfolioAssetsNotOnlyInvestable,
    doughnutDataForPortfolioAssetsOnlyInvestable,
    doughnutDataForPortfolioDebts
  ],
  (doughnutDataForAssets, doughnutDataForInvestableAssets, doughnutDataForDebts) => {
    var doughnutData = doughnutDataForAssets;
    if (doughnutData.length > 0 && doughnutDataForInvestableAssets.length > 0) {
      if (Math.kuberaFloor(doughnutData[0].total) !== Math.kuberaFloor(doughnutDataForInvestableAssets[0].total)) {
        const investableAssetsTotal = doughnutDataForInvestableAssets[0].total;
        const assetsTotal = doughnutData[0].total;
        const investablePercentage = getPercentageValue(investableAssetsTotal, assetsTotal);
        doughnutDataForInvestableAssets[0].totalSubText = `${investablePercentage}% of Assets`;
        doughnutDataForInvestableAssets[0].isInvestableData = true;
        doughnutData.splice(1, 0, doughnutDataForInvestableAssets[0]);
      }
    }
    doughnutData.push(...doughnutDataForDebts);
    return doughnutData;
  }
);

export const getDoughnutData = (
  title,
  total,
  labels,
  totals,
  currency,
  maxSlices = 5,
  noOfDecimalsForPercentage = 1
) => {
  for (var index = totals.length - 1; index >= 0; index--) {
    if (totals[index] === 0) {
      labels.splice(index, 1);
      totals.splice(index, 1);
    }
  }

  if (labels.length > maxSlices) {
    const othersTotal = totals.reduce((total, temp, index) => {
      return index > maxSlices - 2 ? total + temp : total;
    }, 0);
    labels = labels.slice(0, maxSlices - 1);
    labels.push(i18n.t("reportCharts.label.theRemaining"));
    totals = totals.slice(0, maxSlices - 1);
    totals.push(othersTotal);
  }

  const minPercentage = 5;
  if (labels.length > 2) {
    var belowMinPercentageIndexes = [];
    for (index = 0; index <= totals.length - 1; index++) {
      const percentage = getPercentageValue(totals[index], total);
      if (percentage < minPercentage && labels[index] !== i18n.t("reportCharts.label.theRemaining")) {
        belowMinPercentageIndexes.push(index);
      }
    }

    if (belowMinPercentageIndexes.length > 1) {
      var belowMinPercentageTotal = 0;
      for (const index of belowMinPercentageIndexes) {
        belowMinPercentageTotal += totals[index];
      }
      totals = totals.filter(function(value, index) {
        return belowMinPercentageIndexes.includes(index) === false;
      });
      labels = labels.filter(function(value, index) {
        return belowMinPercentageIndexes.includes(index) === false;
      });
      if (belowMinPercentageTotal > 0) {
        const othersIndex = labels.findIndex(item => item === i18n.t("reportCharts.label.theRemaining"));

        if (othersIndex === -1) {
          labels.push(i18n.t("reportCharts.label.theRemaining"));
          totals.push(belowMinPercentageTotal);
        } else {
          totals[othersIndex] = totals[othersIndex] + belowMinPercentageTotal;
        }
      }
    }
  }

  const othersIndex = labels.findIndex(item => item === i18n.t("reportCharts.label.theRemaining"));
  if (othersIndex) {
    for (const [index, item] of totals.entries()) {
      const othersTotal = totals[othersIndex];
      if (othersTotal > item) {
        totals.splice(othersIndex, 1);
        labels.splice(othersIndex, 1);

        labels.splice(index, 0, i18n.t("reportCharts.label.theRemaining"));
        totals.splice(index, 0, othersTotal);
        break;
      }
    }
  }

  var percentages = [];
  for (index = 0; index < totals.length; index++) {
    percentages[index] = getPercentageValue(totals[index], total, false, noOfDecimalsForPercentage);
  }

  for (index = percentages.length - 1; index >= 0; index--) {
    if (percentages[index] === 0) {
      labels.splice(index, 1);
      totals.splice(index, 1);
      percentages.splice(index, 1);
    }
  }

  return {
    title: title,
    total: total,
    labels: labels,
    data: totals,
    currency: currency,
    percentages: percentages,
    showChart: true
  };
};

export const isCustodianEmpty = custodian => {
  return (
    !custodian.name === true &&
    (custodian.value === null || custodian.value === undefined) &&
    (custodian.cost === null || custodian.cost === undefined)
  );
};

export const getEmptyCustodiansInSection = (state, portfolioId, sectionId) => {
  const portfolio = portfolioSelector(state, portfolioId);
  const sectionCustodians = portfolio.details.custodian.filter(item => item.sectionId === sectionId);
  return sectionCustodians.filter(item => isCustodianEmpty(item) === true);
};

export const getNonEmptyCustodiansInSection = (state, portfolioId, sectionId) => {
  const portfolio = portfolioSelector(state, portfolioId);
  const sectionCustodians = portfolio.details.custodian.filter(item => item.sectionId === sectionId);
  return sectionCustodians.filter(item => isCustodianEmpty(item) === false);
};

export const checkIfDateIsGreaterThanPortfolioStartDate = (portfolioId, changeLabel) => {
  const portfolioStartDate = getNetWorthChartStartDateForPortfolio(store.getState());
  let changeLabelDate;

  if (changeLabel === pastValueInterval.DAY) {
    changeLabelDate = new Date().getTime() - 24 * 60 * 60 * 1000;
  } else if (changeLabel === pastValueInterval.WEEK) {
    changeLabelDate = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  } else if (changeLabel === pastValueInterval.MONTH) {
    changeLabelDate = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
  } else if (changeLabel === pastValueInterval.YEAR) {
    changeLabelDate = new Date().getTime() - 365 * 24 * 60 * 60 * 1000;
  }
  if (changeLabelDate > portfolioStartDate) {
    return true;
  } else {
    return false;
  }
};

export const portfolioStartDateTsSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  if (!portfolio) {
    return null;
  }
  const userPreferences = userPreferencesSelector(state);

  if (!portfolio.tsStartDate === false) {
    return portfolio.tsStartDate * 1000;
  } else if (!userPreferences.portfolioNetWorthChartStartDateTsMap === false) {
    return userPreferences.portfolioNetWorthChartStartDateTsMap[portfolio.id];
  } else {
    return null;
  }
};

const getPortfolioStartDateTsMemoized = memoize(
  portfolioStartDateTs => new Date(new Date(portfolioStartDateTs).setHours(0, 0, 0, 0))
);
const getPortfolioTsCreatedMemoized = memoize(tsCreated => new Date(tsCreated * 1000));

export const getNetWorthChartStartDateForPortfolio = createSelector(
  [
    currentPortfolioSelector,
    portfolioStartDateTsSelector,
    (state, portfolio) => {
      let _portfolio = portfolio;

      if (!_portfolio) {
        _portfolio = currentPortfolioSelector(state);
      }
      return _portfolio?.details?.networth;
    },
    (_, _1, latestNetWorthData) => latestNetWorthData
  ],
  (portfolio, portfolioStartDateTs, networthDetails, latestNetWorthData) => {
    if (!portfolioStartDateTs === true) {
      let netWorthData = networthDetails?.data;
      if (!latestNetWorthData === false) {
        netWorthData = latestNetWorthData;
      }
      if (netWorthData?.startDate) {
        return parseNetWorthDateString(netWorthData.startDate);
      }
      return getPortfolioTsCreatedMemoized(portfolio.tsCreated);
    }
    const startDate = getPortfolioStartDateTsMemoized(portfolioStartDateTs);
    if (startDate.getTime() >= new Date().getTime()) {
      return getPortfolioTsCreatedMemoized(portfolio.tsCreated);
    }
    return startDate;
  }
);

export const getNetWorthChartTimeRangeForPortfolio = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  const userPreferences = userPreferencesSelector(state);
  const timeRangePortfolioMap = userPreferences.portfolioNetWorthChartTimeRangeMap;

  if (!timeRangePortfolioMap === true || !timeRangePortfolioMap[portfolio.id] === true) {
    return chartTimeRange.MONTHLY;
  }
  return timeRangePortfolioMap[portfolio.id];
};

export const getRecapChartTimeRangeForPortfolio = state => {
  const portfolio = currentPortfolioSelector(state);
  const userPreferences = userPreferencesSelector(state);
  const timeRangePortfolioMap = userPreferences.portfolioRecapChartTimeRangeMap;

  if (!timeRangePortfolioMap === true || !timeRangePortfolioMap[portfolio.id] === true) {
    return chartTimeRange.TODAY;
  }
  return timeRangePortfolioMap[portfolio.id];
};

export const getRecapChartOptionForPortfolio = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  const userPreferences = userPreferencesSelector(state);
  const chartOptionsMap = userPreferences.portfolioRecapChartOptionsMap;
  if (!chartOptionsMap === true || !chartOptionsMap[(portfolio?.id)] === true) {
    return recapChartOptions.NETWORTH.id;
  }
  return chartOptionsMap[portfolio.id];
};

export const getRecapChartTypeForPortfolio = state => {
  const portfolio = currentPortfolioSelector(state);
  const userPreferences = userPreferencesSelector(state);
  const chartTypeMap = userPreferences.portfolioRecapChartTypeMap;
  if (!chartTypeMap === true || !chartTypeMap[(portfolio?.id)] === true) {
    return recapChartTypes.TOTALS;
  }
  return chartTypeMap[portfolio.id];
};

export const getRecapChartPercentageChangeFlagForPortfolio = state => {
  const portfolio = currentPortfolioSelector(state);
  const userPreferences = userPreferencesSelector(state);
  const percentageChangeMap = userPreferences.portfolioRecapPercentageChangeFlagMap;
  if (!percentageChangeMap === true || !percentageChangeMap[portfolio.id] === true) {
    return false;
  }
  return percentageChangeMap[portfolio.id];
};

export const getCustodianValueChartTimeRange = state => {
  const userPreferences = userPreferencesSelector(state);
  if (!userPreferences.custodianValueChartTimeRange === true) {
    return chartTimeRange.MONTHLY;
  }
  return userPreferences.custodianValueChartTimeRange;
};

export const getValueChangeContributionsForNetworth = (
  state,
  portfolioCurrency,
  networthCurrency,
  startValueCustodians,
  endValueCustodians,
  onlyInvestable = false
) => {
  var contributingCustodiansMap = {};

  const groupCustodian = (name, startValue, currentValue) => {
    const currentContributionEntry = contributingCustodiansMap[name];
    if (!currentContributionEntry === true) {
      contributingCustodiansMap[name] = {
        name: name,
        startValue: startValue,
        currentValue: currentValue,
        entries: 1
      };
    } else {
      currentContributionEntry.startValue += startValue;
      currentContributionEntry.currentValue += currentValue;
      currentContributionEntry.entries += 1;
    }
    return name;
  };

  for (const custodian of endValueCustodians) {
    if (!custodian.hidden === true) {
      if (onlyInvestable === true && custodian.type === 1) {
        continue;
      }

      const currentValue = convertCurrency(
        calcCustodianOwnershipValue(custodian.value, custodian.ownership),
        networthCurrency,
        portfolioCurrency
      );

      var startValue = 0;
      const startValueCustodian = startValueCustodians.find(
        item => item.id === custodian.id && item.portfolioId === custodian.portfolioId
      );
      if (!startValueCustodian === false) {
        startValue = convertCurrency(
          calcCustodianOwnershipValue(startValueCustodian.value, custodian.ownership),
          networthCurrency,
          portfolioCurrency
        );
      }
      groupCustodian(custodian.name, startValue, currentValue);
    }
  }

  // Add custodians that were there at the start but are no longer there in the portfolio
  for (const custodian of startValueCustodians) {
    const currentCustodian = endValueCustodians.find(
      item => item.id === custodian.id && item.portfolioId === custodian.portfolioId
    );
    if (!currentCustodian === true) {
      if (onlyInvestable === true && custodian.type === 1) {
        continue;
      }

      const startValue = convertCurrency(
        calcCustodianOwnershipValue(custodian.value, custodian.ownership),
        networthCurrency,
        portfolioCurrency
      );

      const groupingKey = groupCustodian(custodian.name, startValue, 0);

      // No matching group found
      if (contributingCustodiansMap[groupingKey].entries === 1) {
        contributingCustodiansMap[groupingKey].isArchived = true;
      }
    }
  }

  var contributingCustodians = Object.values(contributingCustodiansMap);
  contributingCustodians.sort(
    (a, b) => Math.abs(b.currentValue - b.startValue) - Math.abs(a.currentValue - a.startValue)
  );

  contributingCustodians = contributingCustodians.filter(
    item => parseFloat(item.currentValue - item.startValue).toFixed(2) != 0 // eslint-disable-line
  );
  return contributingCustodians;
};

export const getTotalForSection = (state, portfolio, section, onlyInvestable = false) => {
  const sectionCustodians = sectionCustodiansSelector(state, portfolio.id, section.id).filter(
    item => item.hidden !== 1 && (onlyInvestable === false || item.type === 0 || item.type === 2)
  );
  const sheet = sheetSelector(state, section.sheetId, (onlyInvestable = false));
  const sum = sectionCustodians.reduce((total, temp) => {
    const currentValue = getCustodianValue(temp, sheet.category, getTickerUsingShortName(portfolio.currency));
    return total + currentValue;
  }, 0);
  return sum;
};

export const getTotalForSheet = (state, portfolio, sheet, onlyInvestable = false) => {
  const sheetCustodians = sheetCustodiansSelector(state, portfolio.id, sheet.id).filter(
    item => item.hidden !== 1 && (onlyInvestable === false || item.type === 0 || item.type === 2)
  );
  const sum = sheetCustodians.reduce((total, temp) => {
    const currentValue = getCustodianValue(temp, temp.category, getTickerUsingShortName(portfolio.currency));
    return total + currentValue;
  }, 0);
  return sum;
};

export const portfolioSavedChartsSelector = portfolio => {
  if (portfolio && portfolio.diyChart && portfolio.diyChart.data) {
    return portfolio.diyChart.data;
  }
  return null;
};

export const dashboardChartIdsSelector = createSelector(
  [state => state],
  state => {
    const currentPortfolio = currentPortfolioSelector(state);
    const initialDashboardCharts = {
      columns: {
        column1: {
          chartIds: []
        },
        column2: {
          chartIds: []
        }
      },
      columnOrder: ["column1", "column2"]
    };

    if (currentPortfolio && currentPortfolio.diyChart && currentPortfolio.diyChart.data) {
      return currentPortfolio.diyChart.data;
    }
    const defaultChartIds = defaultDashboardChartIdsSelector(state);
    return getDIYDashboardCharts(defaultChartIds, initialDashboardCharts);
  }
);

export const getDIYDashboardCharts = memoize((chartIds, initialDashboardCharts) => {
  if (chartIds.length) {
    const diyDashBoardCharts = initialDashboardCharts;
    for (const id of chartIds) {
      const chartParams = parseParams(id);
      if (chartParams.is_checked !== "true") {
        continue;
      }
      const column1Length = diyDashBoardCharts.columns.column1.chartIds.length;
      const column2Length = diyDashBoardCharts.columns.column2.chartIds.length;

      if (column1Length === column2Length || column2Length > column1Length) {
        diyDashBoardCharts.columns.column1.chartIds.push(id);
      } else {
        diyDashBoardCharts.columns.column2.chartIds.push(id);
      }
    }
    return diyDashBoardCharts;
  }

  return initialDashboardCharts;
});

export const addedChartsSelector = state => {
  const dashboardChartIds = dashboardChartIdsSelector(state);
  const defaultChartIds = defaultDashboardChartIdsSelector(state);
  return dashboardChartIds.filter(dashboardChartId =>
    defaultChartIds.every(defaultChartId => defaultChartId !== dashboardChartId)
  );
};

export const suggestedChartsSelector = createSelector(
  [
    defaultDashboardChartIdsSelector,
    state => {
      const currentPortfolio = currentPortfolioSelector(state);
      return portfolioSavedChartsSelector(currentPortfolio);
    }
  ],
  (defaultDashboardChartIds, savedCharts) => {
    const charts = savedCharts;
    const suggestedCharts = [];

    if (charts) {
      const dashboardChartIds = [...charts.columns.column1.chartIds, ...charts.columns.column2.chartIds];
      for (const chartId of defaultDashboardChartIds) {
        const defaultChartParams = parseParams(chartId);
        const isDefaultChartChecked = defaultChartParams.is_checked;
        const chartIndex = dashboardChartIds.findIndex(dashboardChartId => {
          const dashboardChartParams = parseParams(dashboardChartId);
          if (parseParams(defaultChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
            return (
              parseParams(dashboardChartParams.report_id).chart_option ===
                parseParams(defaultChartParams.report_id).chart_option &&
              parseParams(dashboardChartParams.report_id).report_node_id ===
                parseParams(defaultChartParams.report_id).report_node_id &&
              parseParams(dashboardChartParams.report_id).report_path ===
                parseParams(defaultChartParams.report_id).report_path &&
              defaultChartParams.chart_style === dashboardChartParams.chart_style &&
              dashboardChartParams.chart_content === defaultChartParams.chart_content &&
              dashboardChartParams.should_compare_with_investable_assets ===
                defaultChartParams.should_compare_with_investable_assets &&
              dashboardChartParams.should_compare_with_sheet === defaultChartParams.should_compare_with_sheet &&
              dashboardChartParams.should_compare_with_total_assets_or_debts ===
                defaultChartParams.should_compare_with_total_assets_or_debts
            );
          }
          return (
            defaultChartParams.is_default_chart === dashboardChartParams.is_default_chart &&
            defaultChartParams.chart_style === dashboardChartParams.chart_style &&
            dashboardChartParams.chart_content === defaultChartParams.chart_content &&
            dashboardChartParams.chart_name === defaultChartParams.chart_name
          );
        });
        if (chartIndex !== -1) {
          const selectedChart = dashboardChartIds[chartIndex];
          const isChecked = parseParams(selectedChart).is_checked;
          if (isChecked === isDefaultChartChecked) {
            suggestedCharts.push(chartId);
          } else {
            suggestedCharts.push(selectedChart);
          }
        } else {
          suggestedCharts.push(chartId);
        }
      }
      return suggestedCharts;
    } else {
      return defaultDashboardChartIds;
    }
  }
);

export const portfolioQuantityForTicker = (state, portfolio, category = "Asset", tickerId, onlyInvestable = false) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  if (!portfolio) {
    return 0;
  }
  const categoryCustodians = portfolioCustodiansSelector(state, portfolio, category).filter(
    item => item.valueTickerId === tickerId && (onlyInvestable === false || item.type === 0 || item.type === 2)
  );
  const portfolioTicker = getTickerUsingShortName(portfolio.currency);

  var total = 0;
  for (const custodian of categoryCustodians) {
    const custodianValue = getCustodianValue(custodian, category, portfolioTicker, false);
    total = total + custodianValue;
  }
  return total;
};

export const connectivityCenterDataSelector = state => {
  return portfoliosStateSelector(state).connectivityCenterData;
};

export const connectivityCenterDataForPortfolioSelectorMemoized = memoize(portfolioId =>
  createSelector(
    [connectivityCenterDataSelector],
    connectivityCenterData => {
      const filteredFirstItem =
        connectivityCenterData && connectivityCenterData.filter(item => item.portfolioId === portfolioId)[0];

      return filteredFirstItem ? filteredFirstItem.connectivityCenter : [];
    }
  )
);

export const connectivityCenterDataForPortfolioSelector = (state, portfolioId) => {
  return connectivityCenterDataForPortfolioSelectorMemoized(portfolioId)(state);
};

export const portfolioFundScheduleSelector = state => {
  const portfolio = currentPortfolioSelector(state);
  return portfoliosStateSelector(state).portfolioFundScheduleMap[portfolio.id];
};

export const portfolioGroupedFundScheduleSelector = createSelector(
  [currentPortfolioSelector, portfolioFundScheduleSelector, portfolioUnfundedCapital],
  (portfolio, fundSchedule, _) => {
    if (!fundSchedule) {
      fundSchedule = [];
    }

    fundSchedule.sort((a, b) => a.date.localeCompare(b.date));

    const groupedFundSchedule = { data: {}, custodianIds: [] };

    const insertItem = (itemType, item, value, valueTickerId, duration) => {
      const valueInPortfoiloCurrency =
        (itemType === fundScheduleTypes.CAPITAL_CALL ? -1 : 1) *
        convertCurrency(value, getTickerUsingId(valueTickerId).shortName, portfolio.currency);

      const custodian = custodianSelector(store.getState(), item.custodianId || item.id);
      if (!custodian === true) {
        return;
      }
      item.custodianName = custodian.name;

      if (!groupedFundSchedule.data[duration]) {
        groupedFundSchedule.data[duration] = { total: 0, data: {} };
      }

      if (!groupedFundSchedule.data[duration].data[item.custodianId || item.id]) {
        groupedFundSchedule.data[duration].data[item.custodianId || item.id] = { total: 0, items: [] };
      }

      groupedFundSchedule.data[duration].total += valueInPortfoiloCurrency;
      groupedFundSchedule.data[duration].data[item.custodianId || item.id].total += valueInPortfoiloCurrency;
      groupedFundSchedule.data[duration].data[item.custodianId || item.id].items.push(item);
    };

    for (const item of fundSchedule) {
      const itemDate = parseKuberaDateString(item.date);

      if (itemDate.getTime() <= todayMidnight.getTime()) {
        insertItem(item.type, item, item.value, item.valueTickerId, fundScheduleDurations.OVERDUE);
      } else if (itemDate.getTime() <= addDaysToDate(todayMidnight, 30)) {
        insertItem(item.type, item, item.value, item.valueTickerId, fundScheduleDurations.IN_30_DAYS);
      } else if (itemDate.getTime() <= addDaysToDate(todayMidnight, 90)) {
        insertItem(item.type, item, item.value, item.valueTickerId, fundScheduleDurations.IN_90_DAYS);
      } else if (itemDate.getTime() > addDaysToDate(todayMidnight, 90)) {
        insertItem(item.type, item, item.value, item.valueTickerId, fundScheduleDurations.MORE_THAN_90_DAYS);
      }
    }

    const custodiansWithCommittedCapital = recapDataCustodiansWithCommitedCapitalSelector(store.getState()) || [];
    const uniqueCustodiansWithCommittedCapital = custodiansWithCommittedCapital.filter(
      (obj1, i, arr) => arr.findIndex(obj2 => obj2.id === obj1.id) === i
    );

    let unscheduledCustodians = uniqueCustodiansWithCommittedCapital;
    for (const item of unscheduledCustodians) {
      let value = getUnfundedCommitmentForCustodian(item);
      const scheduledCapitalCalls = fundSchedule.filter(
        schedule => schedule.custodianId === item.id && schedule.type === fundScheduleTypes.CAPITAL_CALL
      );
      const scheduledCapitalCallsTotal = scheduledCapitalCalls.reduce(
        (total, temp) =>
          total + convertCurrency(temp.value, getTickerUsingId(temp.valueTickerId).shortName, portfolio.currency),
        0
      );
      value -= scheduledCapitalCallsTotal;
      insertItem(
        fundScheduleTypes.CAPITAL_CALL,
        item,
        value,
        getTickerUsingShortName(portfolio.currency).id,
        fundScheduleDurations.UNSCHEDULED
      );
    }

    const custodianIds = new Set();
    for (const item of fundSchedule) {
      custodianIds.add(item.custodianId);
    }
    groupedFundSchedule.custodianIds = Array.from(
      new Set([...fundSchedule.map(item => item.custodianId), ...unscheduledCustodians.map(item => item.id)])
    );
    return groupedFundSchedule;
  }
);

export const getSheetsAndSectionsSankeyChartDataFromRecap = createSelector(
  [
    currentPortfolioSelector,
    currentPortfolioCurrencySelector,
    state => getCurrentPortfolioDataInNestedFormat(state, categoryType.ASSET),
    state => currentPortfolioTotalForCategory(state, categoryType.ASSET),
    state => currentPortfolioTotalForCategory(state, categoryType.DEBT)
  ],
  (currentPortfolio, currency, assetsSheetsAndSections, assetsTotal, debtsTotal) => {
    const portfolio = currentPortfolio;
    if (!portfolio === true) {
      return null;
    }

    const portfolioTicker = getTickerUsingShortName(currency);
    const chartData = { currency: currency, data: [] };
    const networth = assetsTotal - debtsTotal;
    var sheets = assetsSheetsAndSections.sheets.filter(item => !item === false);
    const totalSectionCount = sheets.reduce((total, sheetData) => total + sheetData.sections.length, 0);
    sheets = sheets.sort((current, next) => next.sheetTotal - current.sheetTotal);
    var realSectionCount = 0;

    if (sheets.length === 1) {
      const sections = sheets[0].sections.filter(item => item.sectionTotal !== 0);
      const totalRowCount = sections.reduce((total, sectionData) => total + sectionData.custodians.length, 0);
      const isSingleSectionPortfolio = sections.length === 1;

      for (const sectionData of sections) {
        if (isSingleSectionPortfolio === false) {
          chartData.data.push({
            source: {
              id: sectionData.section.id,
              label: sectionData.section.name,
              percent: getPercentageValue(sectionData.sectionTotal, sheets[0].sheetTotal, false)
            },
            target: { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET },
            value: sectionData.sectionTotal
          });
        }

        var rows = sectionData.custodians
          .filter(item => !item === false && getCustodianValue(item, categoryType.ASSET, portfolioTicker) !== 0)
          .sort(
            (current, next) =>
              getCustodianValue(next, categoryType.ASSET, portfolioTicker) -
              getCustodianValue(current, categoryType.ASSET, portfolioTicker)
          );

        const otherRows =
          totalRowCount > 10
            ? rows.filter(
                (item, index) =>
                  index >= 1 && getCustodianValue(item, categoryType.ASSET, portfolioTicker) / assetsTotal < 0.02
              )
            : [];
        rows = rows
          .filter(item => otherRows.find(other => other.id === item.id) === undefined)
          .sort(
            (current, next) =>
              getCustodianValue(next, categoryType.ASSET, portfolioTicker) -
              getCustodianValue(current, categoryType.ASSET, portfolioTicker)
          );
        realSectionCount += rows.length;

        for (const rowData of rows) {
          if (!rowData) {
            continue;
          }

          const custodianValue = getCustodianValue(rowData, categoryType.ASSET, portfolioTicker);
          chartData.data.push({
            source: {
              id: rowData.id,
              label: rowData.name,
              percent: getPercentageValue(custodianValue, sectionData.sectionTotal, false)
            },
            target: isSingleSectionPortfolio
              ? { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET }
              : { id: sectionData.section.id, label: sectionData.section.name },
            value: custodianValue
          });
        }

        if (otherRows.length > 0) {
          const othersValue = otherRows.reduce((total, temp) => {
            return total + getCustodianValue(temp, categoryType.ASSET, portfolioTicker);
          }, 0);

          chartData.data.push({
            source: {
              id: `${sectionData.section.id}-others`,
              label: otherRows.length === 1 ? otherRows[0].name : `${otherRows[0].name} +${otherRows.length - 1}`,
              isOthersLabel: otherRows.length > 1,
              description: otherRows
                .reduce((total, temp) => {
                  return `${total} ${temp.name},`;
                }, "")
                .slice(0, -1),
              percent: getPercentageValue(othersValue, sectionData.sectionTotal, false)
            },
            target: isSingleSectionPortfolio
              ? { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET }
              : { id: sectionData.section.id, label: sectionData.section.name },
            value: othersValue
          });
        }
      }
    } else {
      for (const sheetData of sheets) {
        chartData.data.push({
          source: {
            id: sheetData.sheet.id,
            label: sheetData.sheet.name,
            percent: getPercentageValue(sheetData.sheetTotal, assetsTotal, false)
          },
          target: { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET },
          value: sheetData.sheetTotal
        });

        if (sheetData.sheetTotal / assetsTotal > 0.05 && sheetData.sections.length > 1) {
          var sections = sheetData.sections
            .filter(item => !item === false && item.sectionTotal !== 0)
            .sort((current, next) => next.sectionTotal - current.sectionTotal);

          const otherSections =
            totalSectionCount > 10
              ? sections.filter((item, index) => index >= 1 && item.sectionTotal / assetsTotal < 0.02)
              : [];
          sections = sections
            .filter(item => otherSections.find(other => other.section.id === item.section.id) === undefined)
            .sort((current, next) => next.sectionTotal - current.sectionTotal);
          realSectionCount += sections.length;

          for (const sectionData of sections) {
            if (!sectionData) {
              continue;
            }

            chartData.data.push({
              source: {
                id: sectionData.section.id,
                label: sectionData.section.name,
                percent: getPercentageValue(sectionData.sectionTotal, sheetData.sheetTotal, false)
              },
              target: { id: sheetData.sheet.id, label: sheetData.sheet.name },
              value: sectionData.sectionTotal
            });
          }

          if (otherSections.length > 0) {
            const otherSectionsTotal = otherSections.reduce((total, temp) => {
              return total + temp.sectionTotal;
            }, 0);

            chartData.data.push({
              source: {
                id: `${sheetData.sheet.id}-others`,
                label:
                  otherSections.length === 1
                    ? otherSections[0].section.name
                    : `${otherSections[0].section.name} +${otherSections.length - 1}`,
                isOthersLabel: otherSections.length > 1,
                description: otherSections
                  .reduce((total, temp) => {
                    return `${total} ${temp.section.name},`;
                  }, "")
                  .slice(0, -1),
                percent: getPercentageValue(otherSectionsTotal, sheetData.sheetTotal, false)
              },
              target: { id: sheetData.sheet.id, label: sheetData.sheet.name },
              value: otherSectionsTotal
            });
          }
        }
      }
    }

    chartData.data.push({
      source: { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET },
      target: {
        id: RECAP_CATEGORY_TYPE_NETWORTH,
        label: RECAP_CATEGORY_TYPE_NETWORTH,
        position: "middle",
        linkColor: "target"
      },
      value: networth
    });

    if (debtsTotal !== 0) {
      chartData.data.push({
        source: { id: RECAP_CATEGORY_TYPE_ASSET, label: RECAP_CATEGORY_TYPE_ASSET },
        target: { id: RECAP_CATEGORY_TYPE_DEBT, label: RECAP_CATEGORY_TYPE_DEBT, linkColor: "target" },
        value: debtsTotal
      });
    }
    chartData.maxLayerDepth = Math.max(realSectionCount, assetsSheetsAndSections.sheets.length);
    return chartData;
  }
);

const constructTree = (tree, portfolioIdMap, links) => {
  if (tree.children.length === 0) {
    return tree;
  }

  tree.children.forEach(child => {
    child.children = links[child.id]
      .filter(childPortfolio => !portfolioIdMap[childPortfolio.id] === false)
      .map(data => {
        const portfolio = portfolioIdMap[data.id];
        return {
          name: portfolio.name,
          id: data.id,
          ownership: data.ownership
        };
      });
    constructTree(child, portfolioIdMap, links);
  });
  return tree;
};

export const portfolioTreeDataSelector = createSelector(
  [portfoliosSelector],
  portfolios => {
    const portfolioIdMap = {};
    portfolios.forEach(portfolio => {
      portfolioIdMap[portfolio.id] = portfolio;
    });

    const links = {};
    for (const portfolio of portfolios) {
      const connectedPortfolios = portfolio.details.custodian
        .filter(
          item =>
            !item.parentId === true &&
            item.linkType === accountLinkingService.KUBERA_PORTFOLIO &&
            item.linkContainer === "asset"
        )
        .map(item => {
          return { id: item.linkProviderAccountId, ownership: item.ownership };
        });
      links[portfolio.id] = [...new Set([...connectedPortfolios])];
    }

    const portfoliosWithIncomingLinks = [...new Set([...Object.values(links).flat()])];
    const portfoliosWithoutIncomingLinks = portfolios
      .map(item => item.id)
      .filter(id => portfoliosWithIncomingLinks.findIndex(item => item.id === id) === -1);

    const tree = constructTree(
      {
        name: "",
        children: portfoliosWithoutIncomingLinks.map(id => {
          return { name: portfolioIdMap[id].name, id: id };
        })
      },
      portfolioIdMap,
      links
    );
    return tree;
  }
);

export const portfolioTreeReverseLinkedIdMapSelector = createSelector(
  [portfolioTreeDataSelector, (_, allowDuplicates = false) => allowDuplicates],
  (treeData, allowDuplicates) => {
    const reversePortfolioLinkedIdMap = {};

    const mapChildToParentUsingHashMap = (reversePortfolioLinkedIdMap, parent, child) => {
      if (!parent === true) {
        return;
      }
      if (!reversePortfolioLinkedIdMap[child.id]) {
        reversePortfolioLinkedIdMap[child.id] = [];
      }
      if (allowDuplicates === true || reversePortfolioLinkedIdMap[child.id]?.includes(parent.id) === false) {
        reversePortfolioLinkedIdMap[child.id].push(parent.id);
      }
    };

    const constructReverseMap = (children, parent) => {
      if (children.length === 0) {
        return;
      }
      children.forEach(child => {
        mapChildToParentUsingHashMap(reversePortfolioLinkedIdMap, parent, child);
        constructReverseMap(child.children, child);
      });
    };
    constructReverseMap(treeData.children, null);
    return reversePortfolioLinkedIdMap;
  }
);

export const portfolioPredecessorsSelector = createSelector(
  [currentPortfolioSelector, portfolioTreeReverseLinkedIdMapSelector],
  (currentPortfolio, reverseLinkedIdMap) => {
    const predecessors = [];
    const findPredecessors = id => {
      if (!reverseLinkedIdMap[id] === true) {
        return;
      }
      for (const parentId of reverseLinkedIdMap[id]) {
        if (predecessors.find(item => item.id === parentId) === undefined) {
          predecessors.push({ id: parentId, childId: id });
          findPredecessors(parentId);
        }
      }
    };
    findPredecessors(currentPortfolio.id);
    return predecessors;
  }
);

export const connectedPortfoliosSelector = createSelector(
  [currentPortfolioSelector, portfolioTreeDataSelector, portfoliosSelector],
  (currentPortfolio, treeData, portfolios) => {
    const connectedPortfolioIds = [];
    const findChildPortfolios = (tree, isSubTree) => {
      if (tree.id === currentPortfolio.id) {
        isSubTree = true;
      }
      if (tree.children.length === 0) {
        return;
      }
      for (const child of tree.children) {
        if (isSubTree && connectedPortfolioIds.indexOf(child.id) === -1) {
          connectedPortfolioIds.push(child.id);
        }
        findChildPortfolios(child, isSubTree);
      }
    };
    findChildPortfolios(treeData, false);
    return portfolios.filter(portfolio => connectedPortfolioIds.includes(portfolio.id));
  }
);

export const shouldShowLoaderOnChartsModalSelector = state => {
  return portfoliosStateSelector(state).showLoaderOnChartsModal;
};

export const percentageAllocationDataFetchStatusSelector = state => {
  return portfoliosStateSelector(state).fetchingPercentageAllocationData;
};

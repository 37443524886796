import {
  getRecapChartOptionForPortfolio,
  getRecapChartTypeForPortfolio,
  getRecapChartTimeRangeForPortfolio,
  currentPortfolioIdSelector,
  fetchNetWorthDataForPortfolio,
  recapDataSelector,
  RECAP_CATEGORY_TYPE_NETWORTH,
  recapChartOptions,
  chartTimeRange,
  recapChartTypes,
  fetchPortfolios,
  getColumnarGridColumnHeaderString,
  getDateInKuberaFormat,
  RECAP_CATEGORY_TYPE_ASSET,
  RECAP_CATEGORY_TYPE_DEBT,
  RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS,
  RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS,
  RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
  RECAP_CATEGORY_TYPE_FIAT_ASSET,
  formatNumberWithCurrency,
  convertCurrency,
  recapDataCurrencySelector,
  currentPortfolioCurrencySelector,
  percentageAllocationDataFetchStatusSelector,
  fetchPercentageAllocationData,
  isiOSSafari
} from "@kubera/common";
import styled from "styled-components";
import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { downloadFile } from "utilities/FileUtils";
import Loader from "components/loader/Loader";
import i18n from "locale/i18n";

const LoaderOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-middle;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const LoaderMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto
  margin-top: 5px;
  background-color: ${props => props.theme.recapLoaderMessageBg};
  border: 1px solid ${props => props.theme.recapLoaderMessageBg};
  border-radius: 4px;
  width: 450px;
  height: 30px;
  align-self: center;
  font-size: 12px;
`;

const RecapCsvDownloadComponent = props => {
  const dispatch = useDispatch();
  const portfolioId = useSelector(currentPortfolioIdSelector);
  const selectedChartOptions = useSelector(getRecapChartOptionForPortfolio);
  const selectedChartType = useSelector(getRecapChartTypeForPortfolio);
  const selectedTimeRange = useSelector(getRecapChartTimeRangeForPortfolio);
  const recapData = useSelector(recapDataSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const currency = useSelector(recapDataCurrencySelector);
  const fectchingPercentageAllocationData = useSelector(percentageAllocationDataFetchStatusSelector);
  const reportToBeProcessedMap = new Map();
  reportToBeProcessedMap.set(selectedTimeRange, [selectedChartOptions]);

  useEffect(() => {
    dispatch(fetchNetWorthDataForPortfolio(portfolioId));
    //dispatch(processRecapDataFromCustodiansResponse(recapRawData, portfolioId, false, false, reportToBeProcessedMap));
  }, []);

  useEffect(() => {
    dispatch(fetchPortfolios());
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION &&
      recapData.data?.[selectedTimeRange]?.[selectedChartOptions]?.totals
    ) {
      dispatch(fetchPercentageAllocationData(selectedChartOptions, selectedTimeRange));
    }
  }, [fectchingPercentageAllocationData, JSON.stringify(recapData)]);

  const getChartTimeRangeMenuItems = () => {
    return [
      [
        { id: chartTimeRange.TODAY, label: "Today", type: "timeRange" },
        { id: chartTimeRange.DAILY, label: "Daily", type: "timeRange" },
        { id: chartTimeRange.WEEKLY, label: "Weekly", type: "timeRange" },
        { id: chartTimeRange.MONTHLY, label: "Monthly", type: "timeRange" },
        { id: chartTimeRange.QUARTERLY, label: "Quarterly", type: "timeRange" },
        { id: chartTimeRange.YEARLY, label: "Yearly", type: "timeRange" }
      ]
    ];
  };

  const getChartTypeMenuItems = () => {
    return [
      [
        { id: recapChartTypes.TOTALS, label: "Totals", type: "chartType" },
        { id: recapChartTypes.PERCENTAGE_ALLOCATION, label: "% Allocation", type: "chartType" }
      ]
    ];
  };

  const getDownloadFileName = () => {
    let reportOption = selectedChartOptions.charAt(0).toUpperCase() + selectedChartOptions.slice(1);
    const recapChartOptionsArray = Object.values(recapChartOptions);
    const filteredChartOption = recapChartOptionsArray.filter(
      recapChartOption => recapChartOption.id === selectedChartOptions
    );
    if (filteredChartOption.length) {
      reportOption = filteredChartOption[0].fileName;
    }

    const reportTimeRange = selectedTimeRange.charAt(0).toUpperCase() + selectedTimeRange.slice(1);
    const reportChartType = selectedChartType.charAt(0).toUpperCase() + selectedChartType.slice(1);

    const filename = "Recap_" + reportOption + "_" + reportTimeRange + "_" + reportChartType + ".csv";
    return filename;
  };

  const getCurrentlySelectedChartTypeText = () => {
    const menuItems = getChartTypeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartType) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedTimeRangeText = () => {
    const menuItems = getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedTimeRange) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedChartOptionsText = () => {
    const menuItems = getChartOptionsMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedChartOptions) {
        return item.label;
      }
    }
  };

  const getChartOptionsMenuItems = () => {
    let reportsMenuItems = Object.values(recapChartOptions);
    const assetsAndCurrencyIndex = reportsMenuItems.findIndex(
      item => item.id === recapChartOptions.ASSETS_AND_CURRENCY.id
    );
    // get label based on crypto report availablity
    reportsMenuItems[assetsAndCurrencyIndex].label = checkIfAChartOptionHasNoDataToShow(
      recapChartOptions.CRYPTO.id,
      chartTimeRange.TODAY,
      recapData?.data
    )
      ? "Assets x Currency"
      : i18n.t("supportedReports.assetsAndCurrency.label");
    return [reportsMenuItems];
  };

  const checkIfTimeRangeHasNoDataToShow = (chartOption, timeRange) => {
    if (timeRange === chartTimeRange.TODAY) {
      return (
        recapData?.data?.[timeRange]?.[chartOption]?.totals?.[RECAP_CATEGORY_TYPE_NETWORTH][0]?.values?.length === 0
      );
    } else {
      return (
        recapData?.data?.[timeRange]?.[chartOption]?.totals?.[RECAP_CATEGORY_TYPE_NETWORTH]?.[0]?.values?.length < 2
      );
    }
  };

  const getrecapDataBasedOnFilter = () => {
    //get data based on selected time range
    if (recapData) {
      let chartData = recapData?.data?.[selectedTimeRange];
      chartData = chartData?.[selectedChartOptions]?.[selectedChartType];
      return chartData;
    }

    return {};
  };

  const getColumnHeader = selectedChartOptions => {
    switch (selectedChartOptions) {
      case recapChartOptions.SHEETS_AND_SECTIONS.id:
        return ",Asset/Debt,Sheet,Section";
      case recapChartOptions.ASSETS_AND_CURRENCY.id:
        return ",Currency";
      case recapChartOptions.CRYPTO.id:
        return ",Category";
      case recapChartOptions.NETWORTH.id:
        return ",Asset/Debt";
      case recapChartOptions.ASSET_CLASSES.id:
      case recapChartOptions.INVESTABLE.id:
      case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
        return ",Asset Class";
      case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
        return ",Geography";
      case recapChartOptions.STOCKS_AND_SECTOR.id:
        return ",Sector";
      case recapChartOptions.STOCKS_AND_MARKETCAP.id:
        return ",Market Cap";
      case recapChartOptions.BROKERAGES.id:
        return ",Brokerage";
      case recapChartOptions.TAXABLE_ASSETS.id:
        return ",Taxablity";
      default:
        return ",";
    }
  };

  const convertToCSVColumnHeaderRow = (selectedChartOptions, values) => {
    let columnHeaderRow = getColumnHeader(selectedChartOptions);
    for (const value of values) {
      columnHeaderRow =
        columnHeaderRow + "," + getColumnarGridColumnHeaderString(getDateInKuberaFormat(value.date), selectedTimeRange);
    }
    return columnHeaderRow;
  };

  const doCSVEscaping = str => {
    str = str.toString();
    if (str.includes(",") || str.includes(`"`)) {
      let newStr = str.replaceAll(`"`, `""`);
      newStr = `"` + newStr + `"`;
      return newStr;
    } else return str;
  };

  const csvColumnNameMap = {
    Assets: "Asset",
    Debts: "Debt",
    Investments: "Investment",
    Stocks: "Stock",
    Bonds: "Bond",
    "Cash Equivalents": "Cash Equivalent",
    Funds: "Fund",
    Derivatives: "Derivative",
    "Precious Metals": "Precious Metal"
  };

  const convertToCSVDataRow = (
    selectedChartOptions,
    name,
    values,
    isSegmentRow,
    currentSheetName,
    currentSectionName,
    currentCategoryName
  ) => {
    const neverShowedInPercentage = [
      RECAP_CATEGORY_TYPE_ASSET,
      RECAP_CATEGORY_TYPE_DEBT,
      RECAP_CATEGORY_TYPE_NETWORTH,
      RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
      RECAP_CATEGORY_TYPE_FIAT_ASSET,
      RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS,
      RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS,
      "Cash on hand",
      "Stocks",
      "Crypto",
      "Brokerages"
    ];

    // isSegmentRow flag is required, in case user has an asset, whose name is same as above array
    const isNeverShowedInPercentage = isSegmentRow && neverShowedInPercentage.includes(name);
    let dataRow = "";
    dataRow = dataRow + doCSVEscaping(name);
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (currentCategoryName) {
        dataRow = dataRow + "," + currentCategoryName;
      } else {
        dataRow = dataRow + ",";
      }
      if (currentSheetName) {
        dataRow = dataRow + "," + currentSheetName;
      } else {
        dataRow = dataRow + ",";
      }
      if (currentSectionName) {
        dataRow = dataRow + "," + currentSectionName;
      } else {
        dataRow = dataRow + ",";
      }
    } else {
      if (currentSheetName) {
        let sheetNameToAdd = csvColumnNameMap[currentSheetName] ? csvColumnNameMap[currentSheetName] : currentSheetName;
        dataRow = dataRow + "," + sheetNameToAdd;
      }
    }
    for (const value of values) {
      let convertedValue;
      let formattedValue;
      if (isNeverShowedInPercentage) {
        convertedValue = convertCurrency(value.value, currency, portfolioCurrency);
        formattedValue = doCSVEscaping(
          formatNumberWithCurrency(convertedValue, currency, true, 2, false, false, false, false)
        );
        // formattedValue = parseFloat(convertedValue).toFixed(2);
      } else {
        if (selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION) {
          convertedValue = doCSVEscaping(
            formatNumberWithCurrency(value.value, currency, true, 2, false, false, false, false)
          );
          formattedValue = convertedValue + "%";
        } else {
          convertedValue = convertCurrency(value.value, currency, portfolioCurrency);
          formattedValue = doCSVEscaping(
            formatNumberWithCurrency(convertedValue, currency, true, 2, false, false, false, false)
          );
          // formattedValue = parseFloat(convertedValue).toFixed(2);
        }
      }
      dataRow = dataRow + "," + formattedValue;
    }
    return dataRow;
  };

  const getRecapCSVReportHeader = () => {
    let reportHeaderRow = "Recap report : ";

    const chartOptions = Object.values(recapChartOptions);
    const chartOption = chartOptions.filter(option => option.id === selectedChartOptions);
    const chartOptionLabel = chartOption.length > 0 ? chartOption[0].label : "";

    reportHeaderRow =
      reportHeaderRow +
      chartOptionLabel +
      " / " +
      getCurrentlySelectedTimeRangeText() +
      " / " +
      getCurrentlySelectedChartTypeText() +
      " (" +
      portfolioCurrency +
      ")";
    return reportHeaderRow;
  };

  const changeSegmentOrder = filteredCSVData => {
    let staticSegments = [RECAP_CATEGORY_TYPE_NETWORTH, "Stocks", "Crypto"];
    let topSegments = {};
    let remainingSegments = {};
    for (let [key, segment] of Object.entries(filteredCSVData)) {
      if (staticSegments.includes(key) || segment.length > 1) {
        topSegments[key] = segment;
      } else {
        remainingSegments[key] = segment;
      }
    }
    const orderedSegments = { ...topSegments, ...remainingSegments };
    return orderedSegments;
  };

  const getFilteredRecapDataInCSV = filteredCSVData => {
    let recapDataForCSV = filteredCSVData;
    if (
      selectedChartOptions === recapChartOptions.STOCKS_AND_SECTOR.id ||
      selectedChartOptions === recapChartOptions.CRYPTO.id
    ) {
      recapDataForCSV = changeSegmentOrder(filteredCSVData);
    }
    let isColumnHeaderRow = false;
    let isCustodianDataRow = false;
    const csvRows = [];
    const reportHeaderRow = getRecapCSVReportHeader();
    csvRows.push(reportHeaderRow);
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      for (let [key, segment] of Object.entries(recapDataForCSV)) {
        if (key === RECAP_CATEGORY_TYPE_NETWORTH) {
          continue;
        }

        if (
          segment &&
          segment.name &&
          segment.values &&
          segment.values.length &&
          segment.sheets &&
          segment.sheets.length
        ) {
          if (!isColumnHeaderRow) {
            const columnHeaderRow = convertToCSVColumnHeaderRow(selectedChartOptions, segment.values);
            isColumnHeaderRow = true;
            csvRows.push(columnHeaderRow);
          }

          const isArchivedSegment =
            segment.name === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS || segment.name === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS
              ? true
              : false;
          const dataRow = convertToCSVDataRow(selectedChartOptions, segment.name, segment.values, true);
          csvRows.push(dataRow);
          if (!isArchivedSegment) {
            csvRows.push("");
          }
          let currentCategory;
          let currentSheetName = null;
          let currentSectionName = null;
          for (const sheet of segment.sheets) {
            currentCategory = sheet.ctr === "Assets" ? "Asset" : "Debt";
            if (sheet && sheet.name && sheet.values && sheet.values.length && sheet.sections && sheet.sections.length) {
              currentSheetName = sheet.name;
              if (!isArchivedSegment) {
                const dataRow = convertToCSVDataRow(selectedChartOptions, sheet.name, sheet.values);
                csvRows.push(dataRow);
                csvRows.push("");
              }

              for (const section of sheet.sections) {
                currentSectionName = section.name;
                if (
                  section &&
                  section.name &&
                  section.values &&
                  section.values.length &&
                  section.rows &&
                  section.rows.length
                ) {
                  if (sheet.sections.length > 1) {
                    const dataRow = convertToCSVDataRow(
                      selectedChartOptions,
                      section.name,
                      section.values,
                      false,
                      currentSheetName,
                      currentSectionName,
                      currentCategory
                    );
                    csvRows.push(dataRow);
                  } else {
                    if (!isArchivedSegment) csvRows.pop();
                  }
                  for (const row of section.rows) {
                    if (row && row.name && row.values && row.values.length) {
                      const dataRow = convertToCSVDataRow(
                        selectedChartOptions,
                        row.name,
                        row.values,
                        false,
                        currentSheetName,
                        currentSectionName,
                        currentCategory
                      );
                      csvRows.push(dataRow);
                      isCustodianDataRow = true;
                    }
                  }
                  csvRows.push("");
                }
              }
            }
          }
        }
      }
    } else {
      for (let [key, segment] of Object.entries(recapDataForCSV)) {
        if (key === RECAP_CATEGORY_TYPE_NETWORTH && selectedChartOptions !== recapChartOptions.NETWORTH.id) {
          continue;
        }
        let isSegmentRow = true;
        let currentSegmentRowName;
        if (segment && segment.length) {
          for (const row of segment) {
            if (isSegmentRow) {
              currentSegmentRowName = row.name;
            }
            if (row.name && row.values && row.values.length) {
              if (!isColumnHeaderRow) {
                const columnHeaderRow = convertToCSVColumnHeaderRow(selectedChartOptions, row.values);
                isColumnHeaderRow = true;
                csvRows.push(columnHeaderRow);
              }

              const dataRow = convertToCSVDataRow(
                selectedChartOptions,
                row.name,
                row.values,
                isSegmentRow,
                currentSegmentRowName
              );
              isSegmentRow = false;
              csvRows.push(dataRow);
              isCustodianDataRow = true;
            }
          }
          csvRows.push("");
        }
      }
    }
    if (csvRows.length && isCustodianDataRow) {
      const csvData = [...csvRows].join("\n");
      return csvData;
    }

    return null;
  };

  const isRecapDataPresent =
    recapData.data?.[selectedTimeRange]?.[selectedChartOptions]?.[selectedChartType]?.[
      RECAP_CATEGORY_TYPE_NETWORTH
    ]?.[0]?.values?.length > 0;

  if (!isRecapDataPresent) {
    return (
      <LoaderOverlay>
        <RefreshLoader />
        <LoaderMessage>{i18n.t("recap.csv.download.loader.message")}</LoaderMessage>
      </LoaderOverlay>
    );
  } else {
    let filteredCSVData = getrecapDataBasedOnFilter();
    const csvData = getFilteredRecapDataInCSV(filteredCSVData);
    const filename = getDownloadFileName();
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, filename, "_self", () => {
      if (!isiOSSafari()) {
        window.close();
      }
    });
    return (
      <LoaderOverlay>
        <LoaderMessage>{i18n.t("download.success.message")}</LoaderMessage>
      </LoaderOverlay>
    );
  }
};
export default RecapCsvDownloadComponent;

import React, { useState, useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import { hashParams } from "routes";
import {
  accountCardDetailsSelector,
  upgradeSubscription,
  updateSubscriptionCard,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR,
  isMobile,
  detachSubscriptionCard,
  userDiscountSelector,
  useHistory,
  isBlackUserSelector,
  hasLinkedPortfolioAsCustodianSelector,
  SUBSCRIPTION_PLANS,
  showToastTip
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import DeferredPromise from "utilities/DeferredPromise";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { useConfirmCardSetup } from "utilities/CustomHooks";

import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement from "./AccountCardElement";
import MobileAccountUpdateSubscription from "./MobileAccountUpdateSubscription";
import AccountSubscriptionOptions from "./AccountSubscriptionOptions";
import useDefaultSelectedFrequency from "./useDefaultSelectedFrequency";
import UpdateSubscriptionMessage from "./UpdateSubscriptionMessage";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 400px;
  height: 45px;
  background: ${props => props.theme.accountCancelledCardCardContainerBG};
  border: ${props => props.theme.accountCancelledCardCardContainerBR};
  padding: 11px 20px;
  box-sizing: border-box;
  margin-bottom: 25px;
  overflow: hidden;
`;

const CardContainer = styled(AccountCardElement)`
  width: 400px;
  margin-bottom: 25px;
`;

const CardDetails = styled.div`
  font-size: 14px;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: -36px;
  transform: translateY(-50%);
  font-size: 10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  width: 100px;
  font-weight: normal;
  color: ${props => props.theme.accountCancelledChangeActionCLR};
  text-transform: uppercase;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 136px;
  height: 43px;
  margin-right: 18px;
`;

const CancelBtn = styled(SecondaryButton)`
  width: 136px;
  height: 43px;
`;

const FormContainer = styled.form`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 31px;
`;

const ConfirmPersonalDialog = styled(ConfirmationDialog)`
  width: 680px;

  > div {
    margin: 50px 56px 50px 50px;
  }

  p {
    margin: 0;
  }
`;

const ConfirmPersonalDialogDescStyle = css`
  font-size: 14px;
  margin-bottom: 23px;
`;

const options = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const isMobileDevice = isMobile();
const CONFIRM_CARD_OPTIONS = {
  usePaymentMethodId: false
};

let confirmPersonalDialogShownDeferred = new DeferredPromise();
const AccountUpdateSubscription = ({
  setUpdateMode = () => null,
  setIsSubmitted = () => null,
  captureError = () => null
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const updateCardDeclinedPromptPromise = useRef(new DeferredPromise());

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const userDiscount = useSelector(userDiscountSelector);
  const isBlackUser = useSelector(isBlackUserSelector);
  const hasLinkedPortfolioAsCustodian = useSelector(hasLinkedPortfolioAsCustodianSelector);
  const defaultSelectedFrequency = useDefaultSelectedFrequency();
  const confirmCardSetupWithPaymentMethodId = useConfirmCardSetup();
  const confirmCardSetupWithCardElement = useConfirmCardSetup(CONFIRM_CARD_OPTIONS);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateCardMode, setUpdateCardMode] = useState(!!accountCardDetails.cardLast4 === false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(defaultSelectedFrequency);
  const [showConfirmPersonalDialog, setShowConfirmPersonalDialog] = useState(false);

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      const updatedSubscription = await dispatch(updateSubscriptionCard(paymentMethodId)).catch(err => {
        setIsLoading(false);
        if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
          setIsDeclinedError(true);
        }
      });
      if (updatedSubscription) {
        await dispatch(upgradeSubscription(selectedFrequencyValue)).catch(() => {
          dispatch(showToastTip("TIP", i18n.t("somethingWentWrong"), null, 3000));
        });
        setUpdateMode(false);
      }
    },
    [dispatch, selectedFrequencyValue, setUpdateMode]
  );

  const handleUpdateClick = useCallback(
    async e => {
      e.preventDefault();

      if (isBlackUser && hasLinkedPortfolioAsCustodian && selectedFrequencyValue !== SUBSCRIPTION_PLANS.YEARLY_BLACK) {
        setShowConfirmPersonalDialog(true);
        const allowUpdate = await confirmPersonalDialogShownDeferred;

        confirmPersonalDialogShownDeferred = new DeferredPromise();
        if (!allowUpdate) return;
      }

      const removeActionFromURL = () => {
        const myUrl = new URL(window.location);
        const hash = new URLSearchParams(myUrl.hash.substring(1));
        hash.delete(hashParams.ACTION);

        history.push({
          ...location,
          hash: hash.toString()
        });
      };

      setIsLoading(true);

      const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
        setIsLoading(false);
      });
      if (!isUpdateCardMode) {
        confirmCardSetupWithPaymentMethodId({
          paymentIntent,
          onResolve: async result => {
            if (result.error) {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
              setPaymentErrorMsg(result.error.message);
              setIsLoading(false);
              setIsDeclinedError(true);
            } else if (result.setupIntent.status === "succeeded") {
              await dispatch(upgradeSubscription(selectedFrequencyValue)).catch(() => {
                dispatch(showToastTip("TIP", i18n.t("somethingWentWrong"), null, 3000));
              });
              removeActionFromURL();
              setUpdateMode(false);
            } else {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
              setPaymentErrorMsg(result.setupIntent.last_setup_error);
              setIsDeclinedError(true);
            }
          }
        });
      } else if (paymentIntent) {
        await dispatch(detachSubscriptionCard(selectedFrequencyValue));
        confirmCardSetupWithCardElement({
          paymentIntent,
          onResolve: async result => {
            if (result.error) {
              setIsLoading(false);
              if (selectedFrequencyValue !== defaultSelectedFrequency || !!userDiscount) {
                setPaymentErrorMsg(result.error.message);
                setIsDeclinedError(true);
              } else {
                setPaymentErrorMsg(result.error.message);
                setIsDeclinedError(true);
                updateCardDeclinedPromptPromise.current = new DeferredPromise();
                await updateCardDeclinedPromptPromise.current;
                setUpdateMode(false);
              }
            } else if (result.setupIntent.status === "succeeded") {
              onSubscriptionChange(result.setupIntent.payment_method);
            } else {
              console.log("result.setupIntent.status", result.setupIntent.status);
              setIsDeclinedError(true);
            }
          }
        });
      }

      setIsSubmitted(true);
    },
    [
      dispatch,
      selectedFrequencyValue,
      isUpdateCardMode,
      confirmCardSetupWithPaymentMethodId,
      confirmCardSetupWithCardElement,
      onSubscriptionChange,
      setIsSubmitted,
      setUpdateMode,
      captureError,
      defaultSelectedFrequency,
      userDiscount,
      history,
      location,
      isBlackUser,
      hasLinkedPortfolioAsCustodian
    ]
  );

  const handleRemoveCardAction = e => {
    e.preventDefault();
    setUpdateCardMode(true);
  };

  const onCancelAction = e => {
    e.preventDefault();
    if (!isUpdateCardMode || !!accountCardDetails.cardLast4 === false) {
      setUpdateMode(false);
    } else {
      setUpdateCardMode(false);
    }
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
    updateCardDeclinedPromptPromise.current.resolve();
  };

  const handleRadioButtonSelection = value => {
    setSelectedFrequencyValue(value);
  };

  const handleDialogPositiveButtonClick = () => {
    confirmPersonalDialogShownDeferred.resolve(true);
    setShowConfirmPersonalDialog(false);
  };

  const handleDialogNegativeButtonClick = () => {
    confirmPersonalDialogShownDeferred.resolve(false);
    setShowConfirmPersonalDialog(false);
  };

  return (
    <>
      <FormContainer onSubmit={handleUpdateClick}>
        <AccountSubscriptionOptions
          selectedFrequencyValue={selectedFrequencyValue}
          handleRadioButtonSelection={handleRadioButtonSelection}
        />
        {!isUpdateCardMode ? (
          <Container>
            <CardDetails>
              <CardInfo>
                {accountCardDetails.cardBrand} **** {accountCardDetails.cardLast4} • Exp{" "}
                {accountCardDetails.cardExpiryMonth}/{accountCardDetails.cardExpiryYear}
              </CardInfo>
            </CardDetails>
            <ChangeAction title={i18n.t("removeCard")} data-cy="removeCardBtn" onClick={handleRemoveCardAction} />
          </Container>
        ) : (
          <CardContainer options={options} />
        )}
        <ActionButtonContainer>
          <SubscribeBtn
            title={i18n.t("update")}
            data-cy="subscribeNowButton"
            onClick={handleUpdateClick}
            isLoading={isLoading}
          />
          <CancelBtn title={i18n.t("cancel")} data-cy="cancelButton" onClick={onCancelAction} />
        </ActionButtonContainer>
        <UpdateSubscriptionMessage accountPlan={selectedFrequencyValue} />
      </FormContainer>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
      {showConfirmPersonalDialog && (
        <ConfirmPersonalDialog
          descriptionStyle={ConfirmPersonalDialogDescStyle}
          title={i18n.t("blackPaywalls.continueBlackToPersonal.title")}
          description={i18n.t("blackPaywalls.continueBlackToPersonal.desc")}
          positiveButtonTitle={`${i18n.t("yes")}, ${i18n.t("remove")}`}
          negativeButtonTitle={i18n.t("goBackLink")}
          handleNegativeButtonClick={handleDialogNegativeButtonClick}
          handlePositiveButtonClick={handleDialogPositiveButtonClick}
        />
      )}
    </>
  );
};

export default isMobileDevice ? MobileAccountUpdateSubscription : AccountUpdateSubscription;

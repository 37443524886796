import React, { useImperativeHandle } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  userPriceOptionSelector,
  accountAllPlansSelector,
  accountPlanSelector,
  accountSubscriptionStatusSelector
} from "@kubera/common";
import RadioButton from "components/inputs/RadioButton";

import useOptionsRadioButtonSelectionHandler from "./useOptionsRadioButtonSelectionHandler";

const RadioButtonsContainer = styled.div``;

const RadioOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
`;

const RadioOption = styled(RadioButton)`
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding: 18px;
  padding-right: 33px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;

  svg {
    margin-top: 5px;
  }

  div {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-left: 0px;
  }

  .price-note {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    font-feature-settings: "ss01" on, "calt" off;
    color: #00000099;
    margin-top: 4px;
    white-space: pre-wrap;
    max-width: 279px;

    a {
      color: #00000099;
    }
  }
`;

const AccountSubscriptionOptions = React.forwardRef(({ selectedFrequencyValue, handleRadioButtonSelection }, ref) => {
  const userPrice = useSelector(userPriceOptionSelector);
  const accountAllPlans = useSelector(accountAllPlansSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);

  useImperativeHandle(ref, () => ({
    onSubmitShowModals: () => {
      return true;
    }
  }));

  useOptionsRadioButtonSelectionHandler(handleRadioButtonSelection);

  if (accountAllPlans.length === 1) {
    return null;
  }

  return (
    <RadioButtonsContainer>
      <RadioOptionContainer>
        {accountAllPlans.map(plan => {
          const subscriptionPlan = SUBSCRIPTION_PLANS[plan.toUpperCase()];
          const planLabel = `
            ${i18n.t(`accountSettings.subscriptionOption_${plan}`).replace("%s%", userPrice[plan])}<br/>
            <div class="price-note">${i18n.t(`accountSettings.mobileSubsciptionNote_${plan}`)}</div>
          `;
          return (
            <div key={plan}>
              <RadioOption
                label={planLabel}
                value={subscriptionPlan}
                checked={selectedFrequencyValue === subscriptionPlan}
                disabled={
                  accountPlan !== SUBSCRIPTION_PLANS.TRIAL &&
                  accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
                  userPrice[plan] < userPrice[accountPlan]
                }
                onChange={handleRadioButtonSelection}
              />
            </div>
          );
        })}
      </RadioOptionContainer>
    </RadioButtonsContainer>
  );
});

export default AccountSubscriptionOptions;

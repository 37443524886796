import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "theme";
import ColumnarChartMenuComponent from "./ColumnarChartMenuComponent";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import ColumnarChartCellComponent from "./ColumnarChartCellComponent";
import { getGridDataForRecap, GridColumnData, GridCellData, cellType } from "components/grid/GridDataModel";
import {
  recapDataSelector,
  currentPortfolioCurrencySelector,
  recapDataCurrencySelector,
  getRecapChartTimeRangeForPortfolio,
  getRecapChartTypeForPortfolio,
  getRecapChartOptionForPortfolio,
  getRecapChartPercentageChangeFlagForPortfolio,
  getColumnarGridColumnHeaderString,
  getDateInKuberaFormat,
  RECAP_CELL_CATEGORY_TYPE_DATE,
  RECAP_CELL_CATEGORY_TYPE_TEXT,
  RECAP_CATEGORY_TYPE_NETWORTH,
  recapChartOptions,
  getHashParams,
  userPreferencesSelector,
  currentPortfolioSelector,
  updateUserPreferences,
  shouldShowLoaderOnPortfolioAndRecapCurrencyMismatch,
  recapChartTypes
} from "@kubera/common";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import Loader from "components/loader/Loader";
import RecapGridVisibilityContext from "./RecapGridVisibilityContext";
import i18n from "locale/i18n";

const ColumnarChartContainer = styled.div`
  @page {
    size: auto;
    margin: 0 !important;
  }

  @media print {
    width: 100% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 50px !important; // Additional margin so that the bottom row doesn't truncate
  }

  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    @media print {
      width: 95% !important;
      margin-left: 0px !important;
      margin-right: 50px !important;
    }
  }
`;

const ColumnarChartMenuContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 5px;
  margin-top: 30px;
`;

const Grid = styled(GridComponentWrapper)`
  margin-top: -1px;
  * {
    @media print {
      background-color: #fff;
    }
  }
`;

const ColumnarChartGridHeaderRowContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 3px;
`;

const ColumnarChartGridHeaderRow = styled.div`
  display: inline-flex;
  flex: 1;
  height: 40px;
  position: relative;
`;

const ColumnarGridHeaderSection = styled.div`
  position: sticky;
  top: 0px;
  z-index: 500;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-middle;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const ColumnarChartGridHeaderCell = styled(ColumnarChartCellComponent)``;

const LoaderMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto
  margin-top: 5px;
  background-color: ${props => props.theme.recapLoaderMessageBg};
  border: 1px solid ${props => props.theme.recapLoaderMessageBg};
  border-radius: 4px;
  width: 450px;
  height: 30px;
  align-self: center;
  font-size: 12px;
`;

const getrecapDataBasedOnFilter = (
  recapData,
  selectedChartType,
  selectedChartOptions,
  selectedTimeRange,
  noOfColumns
) => {
  //get data based on selected time range
  if (recapData.data) {
    let chartData = recapData.data[selectedTimeRange];
    chartData = window.kbStructuredClone(chartData[selectedChartOptions][selectedChartType]);
    for (const [key, section] of Object.entries(chartData)) {
      if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
        if (key === RECAP_CATEGORY_TYPE_NETWORTH) {
          continue;
        } else {
          chartData[key].sheets = filterNonZeroRowsForSheetsAndSections(section.sheets, noOfColumns, selectedChartType);
        }
      } else {
        chartData[key] = filterNonZeroRows(section, selectedChartOptions, selectedChartType, noOfColumns);
      }
    }
    return chartData;
  }
};

const filterNonZeroRows = (section, selectedChartOptions, selectedChartType, noOfColumns) => {
  const headerRow = section.filter(row => row.type === "header");
  if (headerRow.length === 1) {
    //check if all values in header is zeroo
    const areAllValuesZero = headerRow[0].values.slice(0, noOfColumns).every(cell => cell.value === 0);
    const isUnknownMarketCapRow = headerRow[0].name === "Unknown";
    if (areAllValuesZero) {
      // show the header row even if the values are zero for the belo reports
      if (
        (selectedChartOptions === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
          selectedChartOptions === recapChartOptions.STOCKS_AND_SECTOR.id ||
          selectedChartOptions === recapChartOptions.CRYPTO.id ||
          selectedChartOptions === recapChartOptions.ASSET_CLASSES.id ||
          selectedChartOptions === recapChartOptions.STOCKS_AND_GEOGRAPHY.id ||
          selectedChartOptions === recapChartOptions.TAXABLE_ASSETS.id) &&
        !isUnknownMarketCapRow
      ) {
        return section.slice(0, 1);
      } else {
        return [];
      }
    } else {
      return section.filter(row => {
        if (row.type === "header") {
          return true;
        } else {
          return !row.values
            .slice(0, noOfColumns)
            .every(cell =>
              selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION ? cell.actualValue === 0 : cell.value === 0
            );
        }
      });
    }
  } else {
    return section;
  }
};
const filterNonZeroRowsForSheetsAndSections = (sheets, noOfColumns, selectedChartType) => {
  return sheets
    .filter(sheet => sheet !== null)
    .map(sheet => {
      const sections = sheet.sections
        .filter(section => !section === false)
        .map(section => {
          const rows = section.rows.filter(row => {
            return (
              !row === false &&
              !row.values
                .slice(0, noOfColumns)
                .every(cell =>
                  selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION
                    ? cell.actualValue === 0
                    : cell.value === 0
                )
            );
          });
          return { ...section, rows: rows };
        });
      return { ...sheet, sections: sections };
    });
};

const getGridHeaderRow = (recapNetWorthData, noOfColumns, selectedTimeRange) => {
  let gridHeaderRow = recapNetWorthData.slice(0, noOfColumns).map(data => {
    return {
      value: getColumnarGridColumnHeaderString(getDateInKuberaFormat(data.date), selectedTimeRange),
      type: RECAP_CELL_CATEGORY_TYPE_DATE
    };
  });
  gridHeaderRow = [
    { value: "", type: cellType.RECAP_CELL, isExpandCollapseIconCell: true },
    { value: "", type: cellType.RECAP_CELL, isChartsCell: true },
    { value: "", type: RECAP_CELL_CATEGORY_TYPE_TEXT },
    ...gridHeaderRow
  ];
  return gridHeaderRow;
};

const sortGridSections = (gridData, selectedChartOptions) => {
  const sections = gridData.sheets[0].sections;
  if (sections.length > 1 && selectedChartOptions !== recapChartOptions.STOCKS_AND_MARKETCAP.id) {
    const sortedSections = sections.sort((currentSection, previousSection) => {
      const topRowOfTheSection = currentSection.rows[0];
      const valuesArray = topRowOfTheSection.cells.slice(3);

      const topRowOfThePreviousSection = previousSection.rows[0];
      const valuesArrayOfPreviousSection = topRowOfThePreviousSection.cells.slice(3);
      const isAllValuesZeroForPreviousSection = valuesArrayOfPreviousSection.every(data => data.value === 0);

      const isAllValuesZero = valuesArray.every(data => data.value === 0);

      if (
        (topRowOfThePreviousSection && topRowOfThePreviousSection.category !== "header") ||
        (isAllValuesZero && isAllValuesZeroForPreviousSection)
      ) {
        return 0;
      }

      if (isAllValuesZero && !isAllValuesZeroForPreviousSection) {
        return 1;
      }
      if (!isAllValuesZero && isAllValuesZeroForPreviousSection) {
        return -1;
      }
      return 0;
    });
    gridData.sheets[0].sections = sortedSections;
    return gridData;
  } else {
    return gridData;
  }
};

const ColumnarChartComponent = ({ noOfColumns, setNoOfColumns, history, location, userMaskAllValues, portfolioId }) => {
  const theme = useTheme();
  const scrollLeftOnExpand = useRef(null);
  const dispatch = useDispatch();
  const urlHashParams = getHashParams(location);
  const recapData = useSelector(recapDataSelector);
  const currency = useSelector(recapDataCurrencySelector);
  const userPreferences = useSelector(userPreferencesSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const selectedChartOptions = useSelector(getRecapChartOptionForPortfolio);
  const selectedTimeRange = useSelector(getRecapChartTimeRangeForPortfolio);
  const selectedChartType = useSelector(getRecapChartTypeForPortfolio);
  const isPercentageChangeShown = useSelector(getRecapChartPercentageChangeFlagForPortfolio);
  const showLoaderOnPortfolioAndRecapCurrencyMismatch = useSelector(
    shouldShowLoaderOnPortfolioAndRecapCurrencyMismatch
  );

  const isExpandedView = noOfColumns >= 6;
  const isRecapSelectedFromMenu = userPreferences.isRecapSelectedFromMenu;
  useEffect(() => {
    const urlChartOption = urlHashParams[hashParams.CHART_OPTION];
    const urlTimeRange = urlHashParams[hashParams.CHART_TIMERANGE];
    const urlChartType = urlHashParams[hashParams.CHART_TYPE];
    var propertiesToUpdate = {};
    if (!urlChartOption === false && selectedChartOptions !== urlChartOption) {
      const map = userPreferences.portfolioRecapChartOptionsMap;
      map[portfolio.id] = urlChartOption;
      propertiesToUpdate["portfolioRecapChartOptionsMap"] = map;
    }
    if (!urlTimeRange === false && selectedTimeRange !== urlTimeRange) {
      const map = userPreferences.portfolioRecapChartTimeRangeMap;
      map[portfolio.id] = urlTimeRange;
      propertiesToUpdate["portfolioRecapChartTimeRangeMap"] = map;
    }
    if (!urlChartType === false && selectedChartType !== urlChartType) {
      const map = userPreferences.portfolioRecapChartTypeMap;
      map[portfolio.id] = urlChartType;
      propertiesToUpdate["portfolioRecapChartTypeMap"] = map;
    }

    if (Object.keys(propertiesToUpdate).length > 0) {
      history.replace({
        ...location,
        hash: `${hashParams.CHART_OPTION}=${urlChartOption}&${hashParams.CHART_TIMERANGE}=${urlTimeRange}&${hashParams.CHART_TYPE}=${urlChartType}`
      });
      dispatch(updateUserPreferences(propertiesToUpdate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSelectedFromChartsModal = urlHashParams[hashParams.SELECTED_FROM_CHARTS_MODAL] === "true";
    const chartOption = isSelectedFromChartsModal ? urlHashParams[hashParams.CHART_OPTION] : selectedChartOptions;
    if (chartOption === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      const selectedSectionId = urlHashParams[hashParams.SELECTED_SECTION_ID];
      const selectedSheetId = urlHashParams[hashParams.SELECTED_SHEET_ID];
      const category = urlHashParams[hashParams.CATEGORY];
      const selectedRowCategory = urlHashParams[hashParams.ROW_CATEGORY];
      history.replace({
        ...location,
        hash: `${hashParams.CHART_OPTION}=${chartOption}&${hashParams.CHART_TIMERANGE}=${selectedTimeRange}&${
          hashParams.CHART_TYPE
        }=${selectedChartType}&${hashParams.SELECTED_SECTION_ID}=${
          isRecapSelectedFromMenu ? null : selectedSectionId
        }&${hashParams.SELECTED_SHEET_ID}=${isRecapSelectedFromMenu ? null : selectedSheetId}&${
          hashParams.CATEGORY
        }=${category}&${hashParams.ROW_CATEGORY}=${selectedRowCategory}`
      });
    } else {
      const selectedSectionName = urlHashParams[hashParams.SELECTED_SECTION_NAME];
      history.replace({
        ...location,
        hash: `${hashParams.CHART_OPTION}=${chartOption}&${hashParams.CHART_TIMERANGE}=${selectedTimeRange}&${
          hashParams.CHART_TYPE
        }=${selectedChartType}&${hashParams.SELECTED_SECTION_NAME}=${
          isRecapSelectedFromMenu ? null : selectedSectionName
        }`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChartOptions, selectedTimeRange, selectedChartType, history, portfolio.id]);

  let filteredRecapData = getrecapDataBasedOnFilter(
    { ...recapData },
    selectedChartType,
    selectedChartOptions,
    selectedTimeRange,
    noOfColumns
  );

  const recapDataForSelectedTimeRange = { ...recapData.data[selectedTimeRange] };
  const recapNetWorthData = recapDataForSelectedTimeRange
    ? recapDataForSelectedTimeRange[selectedChartOptions].totals &&
      recapDataForSelectedTimeRange[selectedChartOptions].totals["Net Worth"] &&
      recapDataForSelectedTimeRange[selectedChartOptions].totals["Net Worth"][0] &&
      recapDataForSelectedTimeRange[selectedChartOptions].totals["Net Worth"][0].values
    : [];
  const gridHeaderRow = getGridHeaderRow(recapNetWorthData, noOfColumns, selectedTimeRange);
  const columns = gridHeaderRow.map(column => {
    return new GridColumnData(column.value, true, false, false);
  });

  const handleOpenChartsModalClick = (sheetIndex, sectionIndex, rowIndex) => {
    const chartsHash = `${hashParams.MODAL}=${modalValues.RECAP_CHARTS}&${hashParams.REPORT_ID}=${getReportId(
      sheetIndex,
      sectionIndex,
      rowIndex
    )}`;
    history.push({ ...location, hash: chartsHash });
  };

  const getReportId = (sheetIndex, sectionIndex, rowIndex) => {
    const section = gridDataForRecap.sheets[sheetIndex].sections[sectionIndex];
    const row = section.rows[rowIndex];
    var reportId = `${hashParams.CHART_OPTION}=${selectedChartOptions}&${hashParams.CHART_TIMERANGE}=${selectedTimeRange}&${hashParams.CHART_TYPE}=${selectedChartType}`;
    var id = null;
    var path = null;

    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (row.category === "categoryHeader") {
        id = section.name;
      } else if (row.category === "sheetHeader") {
        id = row.sheetId;
        path = `${encodeURIComponent(section.name)}/sheets`;
      } else if (row.category === "sectionHeader") {
        id = row.sectionId;
        path = `${encodeURIComponent(section.name)}/sections`;
      } else {
        path = `${encodeURIComponent(section.name)}/rows`;
        id = row.id;
      }
    } else {
      if (!row.category === true) {
        path = encodeURIComponent(section.name);
      }
      id =
        row.clubbingKey && row.clubbingKey === "id"
          ? row.id
          : selectedChartOptions === recapChartOptions.ASSETS_AND_CURRENCY.id && row.category === "AssetsTotal"
          ? "Fiat Assets"
          : encodeURIComponent(row.cells[2].value);
    }

    if (!path === false) {
      reportId = reportId + `&${hashParams.REPORT_PATH}=${path}`;
    }
    reportId = reportId + `&${hashParams.REPORT_NODE_ID}=${id}`;
    return encodeURIComponent(reportId);
  };

  const getGridData = filterOptions => {
    let gridData = getGridDataForRecap(
      filteredRecapData,
      portfolioCurrency,
      selectedChartOptions,
      currency,
      selectedChartType,
      columns,
      isPercentageChangeShown,
      urlHashParams[hashParams.SELECTED_SECTION_NAME],
      noOfColumns,
      portfolioId,
      urlHashParams[hashParams.SELECTED_SECTION_ID],
      urlHashParams[hashParams.SELECTED_SHEET_ID],
      urlHashParams[hashParams.CATEGORY],
      urlHashParams[hashParams.ROW_CATEGORY]
    );
    sortGridSections(gridData, selectedChartOptions);
    return gridData;
  };

  const handleSectionUpdate = (sheetIndex, sectionIndex, updatedSection) => {
    const newGridData = gridDataForRecap;
    newGridData.sheets[sheetIndex].sections[sectionIndex] = updatedSection;
    setGridDataForRecap(newGridData);
    setToggleCollapse(!toggleCollapse);
  };

  const handleChange = newGridData => {
    setGridDataForRecap(newGridData);
  };

  const maintainScrollStateOnGridChange = useCallback(() => {
    scrollLeftOnExpand.current = document.getElementById("recapContainer")?.scrollLeft || null;
  }, []);

  const [isPrintView, setIsPrintView] = useState(false);
  const [gridDataForRecap, setGridDataForRecap] = useState(getGridData());
  const [toggleCollapse, setToggleCollapse] = useState(false);

  // When columns are added `noOfColumns + 3`, gridDataForRecap takes some time to update. This is to avoid jerk when loading new columns
  const numberOfColumnsOnGridDataUpdate = useMemo(() => {
    return noOfColumns;
  }, [gridDataForRecap]);
  const totalColumnsCurrentlyDisplayed = numberOfColumnsOnGridDataUpdate + 3;

  const gridOptions = useMemo(
    () => ({
      getRowStyle: (row, rowIndex, sectionIndex, isLastRow, isLastSection, category) => {
        if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
          return {
            borderTop: `${
              category === "categoryHeader" && sectionIndex !== 0 && !row.isArchivedCategory
                ? "6px solid #636363"
                : category === "sheetHeader" || row.isArchivedCategory
                ? "4px solid #636363"
                : category === "sectionHeader"
                ? "2px solid #636363"
                : row.isCollapsed || !isLastSection
                ? `1px solid ${theme.gridSectionBorderColor}`
                : "none"
            }`,
            borderBottom: `${
              isLastSection
                ? category === "sheetHeader" ||
                  category === "sectionHeader" ||
                  (category === "categoryHeader" && row.isArchivedCategory)
                  ? row.isCollapsed
                    ? "2px solid #636363"
                    : category &&
                      (category === "sectionHeader" || category === "categoryHeader" || category === "sheetHeader")
                    ? `1px solid ${theme.gridSectionBorderColor}`
                    : "none"
                  : category !== "sheetHeader" && category !== "categoryHeader"
                  ? `1px solid ${theme.gridSectionBorderColor}`
                  : "none"
                : "none"
            }`,

            minHeight: `${row.isHeaderRow ? "50px" : "40px"} `,
            display: "flex",
            flex: 1,
            // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
            isSeparateStyle: true
            //boxSizing: "border-box"
          };
        } else {
          return {
            borderTop: `${sectionIndex === 0 && rowIndex === 0 ? `1px solid ${theme.gridSectionBorderColor}` : "none"}`,
            borderBottom: `${
              (sectionIndex === 0 && rowIndex === 0) || (isLastRow && !isLastSection && category !== "header")
                ? "4px solid #636363"
                : rowIndex === 0 && sectionIndex !== 0
                ? "2px solid #636363"
                : `1px solid ${theme.gridSectionBorderColor}`
            }`,
            minHeight: `${row.isHeaderRow ? "50px" : "40px"} `,
            display: "flex",
            flex: 1,
            // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
            isSeparateStyle: true
            //boxSizing: "border-box"
          };
        }
      },
      getSectionStyle: (section, isLastSection, totalSections, sectionIndex) => {
        return {
          display: "flex",
          flexDirection: "column",
          flex: "1",
          //the condition to check total sections greater than two is for Investable Assets
          //borderTop: isLastSection && totalSections > 2 ? "6px solid #636363" : "none",
          //borderTop: `${sectionIndex === 0 ? `1px solid ${theme.gridSectionBorderColor}` : "6px solid #636363"}`,
          // Below boolean value to mark these styles to override the existing styles. Otherwise it adds to the existing styles
          isSeparateStyle: true
        };
      }
    }),
    [theme, selectedChartOptions]
  );

  useEffect(() => {
    setGridDataForRecap(getGridData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPercentageChangeShown,
    selectedChartOptions,
    selectedChartType,
    selectedTimeRange,
    portfolio.id,
    recapData,
    noOfColumns
  ]);

  useEffect(() => {
    setGridDataForRecap(getGridData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMaskAllValues, noOfColumns]);

  useEffect(() => {
    localStorage.removeItem("latestCellIndexes");
  }, [selectedTimeRange, selectedChartOptions, selectedChartType]);

  useEffect(() => {
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      let firstCellInGrid;
      const selectedSectionId = urlHashParams[hashParams.SELECTED_SECTION_ID];
      const selectedSheetId = urlHashParams[hashParams.SELECTED_SHEET_ID];
      const category = urlHashParams[hashParams.CATEGORY];
      const rowCategory = urlHashParams[hashParams.ROW_CATEGORY];
      const sectionIndex = gridDataForRecap.sheets[0].sections.findIndex(section => section.name === category);
      const selectedSectionIndex =
        rowCategory === "sheetHeader"
          ? gridDataForRecap.sheets[0].sections[sectionIndex !== -1 ? sectionIndex : 0]?.rows.findIndex(
              row => row.category === "sheetHeader" && row.sheetId === selectedSheetId
            )
          : gridDataForRecap.sheets[0].sections[sectionIndex !== -1 ? sectionIndex : 0]?.rows.findIndex(
              row => row.category === "sectionHeader" && row.sectionId === selectedSectionId
            );
      if (selectedSectionIndex !== -1) {
        const cellId = GridCellData.getCellId(
          gridDataForRecap && gridDataForRecap.id,
          0,
          sectionIndex !== -1 ? sectionIndex : 0,
          selectedSectionIndex,
          2
        );
        firstCellInGrid = document.getElementById(cellId);
      } else {
        const latestCellIndexes = localStorage.getItem("latestCellIndexes");
        const sheetIndex = latestCellIndexes ? latestCellIndexes.split(",")[0] : 0;
        const sectionIndex = latestCellIndexes ? latestCellIndexes.split(",")[1] : 0;
        const rowIndex = latestCellIndexes ? latestCellIndexes.split(",")[2] : 0;
        const cellIndex = latestCellIndexes ? latestCellIndexes.split(",")[3] : 2;
        const cellId = GridCellData.getCellId(
          gridDataForRecap && gridDataForRecap.id,
          sheetIndex,
          sectionIndex,
          rowIndex,
          cellIndex
        );
        firstCellInGrid = document.getElementById(cellId);
      }
      if (firstCellInGrid) {
        firstCellInGrid.focus();
      }
    } else {
      const selectedSectionName = urlHashParams[hashParams.SELECTED_SECTION_NAME];
      let firstCellInGrid;
      if (selectedSectionName !== "undefined" && selectedSectionName !== "null") {
        const selectedSectionIndex = gridDataForRecap.sheets[0].sections.findIndex(
          section => section.name === selectedSectionName
        );
        if (selectedSectionIndex) {
          const cellId = GridCellData.getCellId(gridDataForRecap && gridDataForRecap.id, 0, selectedSectionIndex, 0, 2);
          firstCellInGrid = document.getElementById(cellId);
        }
      } else {
        const latestCellIndexes = localStorage.getItem("latestCellIndexes");
        const sheetIndex = latestCellIndexes ? latestCellIndexes.split(",")[0] : 0;
        const sectionIndex = latestCellIndexes ? latestCellIndexes.split(",")[1] : 0;
        const rowIndex = latestCellIndexes ? latestCellIndexes.split(",")[2] : 0;
        const cellIndex = latestCellIndexes ? latestCellIndexes.split(",")[3] : 2;
        const cellId = GridCellData.getCellId(
          gridDataForRecap && gridDataForRecap.id,
          sheetIndex,
          sectionIndex,
          rowIndex,
          cellIndex
        );
        firstCellInGrid = document.getElementById(cellId);
      }
      if (firstCellInGrid) {
        firstCellInGrid.focus();
      }

      // Preserve the scroll state when any action that doesn't require focused element to show instead maintains the scroll state
      if (scrollLeftOnExpand.current) {
        const recapContainer = document.getElementById("recapContainer");
        recapContainer.scrollLeft = scrollLeftOnExpand.current;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeRange, selectedChartOptions, selectedChartType, gridDataForRecap, isPercentageChangeShown]);

  useEffect(() => {
    if (scrollLeftOnExpand.current) {
      scrollLeftOnExpand.current = null;
    }
  }, [gridDataForRecap]);

  useEffect(() => {
    if (isPrintView === true) {
      window.print();
      setIsPrintView(false);
    }
  }, [isPrintView]);

  useEffect(() => {
    // Recap Expanded > Scroll recapContainer max possible to the right
    const recapContainer = document.getElementById("recapContainer");
    if (recapContainer && numberOfColumnsOnGridDataUpdate > 6) {
      recapContainer.scrollTo({ left: recapContainer.scrollWidth, behavior: "smooth" });
    }
  }, [numberOfColumnsOnGridDataUpdate]);

  const renderColumnarChartComponent = () => {
    return (
      <>
        {recapData.data && (
          <ColumnarChartContainer
            isExpandedView={isExpandedView}
            isMoreColumnsAvailable={noOfColumns <= recapNetWorthData.length}
          >
            <ColumnarChartMenuContainer>
              <ColumnarChartMenuComponent
                selectedChartType={selectedChartType}
                selectedChartOptions={selectedChartOptions}
                selectedTimeRange={selectedTimeRange}
                isPercentageChangeShown={isPercentageChangeShown}
                recapData={recapData.data}
                isExpandedView={isExpandedView}
                currency={currency}
                portfolioCurrency={portfolioCurrency}
                setIsPrintView={setIsPrintView}
                maintainScrollStateOnGridChange={maintainScrollStateOnGridChange}
              />
            </ColumnarChartMenuContainer>
            <ColumnarGridHeaderSection>
              <ColumnarChartGridHeaderRowContainer>
                <ColumnarChartGridHeaderRow>
                  {gridHeaderRow.map((cell, index) => {
                    // Show only the date columns for which that are currently displayed. To avoid jerk when loading new columns
                    if (index < totalColumnsCurrentlyDisplayed) {
                      return (
                        <ColumnarChartGridHeaderCell
                          cell={cell}
                          isPercentageChangeShown={isPercentageChangeShown}
                          isExpandedView={isExpandedView}
                          setNoOfColumns={setNoOfColumns}
                          shouldShowIcon={index === totalColumnsCurrentlyDisplayed - 1}
                          isExpandIconDisabled={noOfColumns !== numberOfColumnsOnGridDataUpdate ? 1 : 0}
                          selectedChartOptions={selectedChartOptions}
                          isGridHeader={true}
                          isLastCell={index === totalColumnsCurrentlyDisplayed - 1}
                          isMoreColumnsAvailable={numberOfColumnsOnGridDataUpdate <= recapNetWorthData.length}
                          key={index}
                          noOfColumns={numberOfColumnsOnGridDataUpdate}
                          maintainScrollStateOnGridChange={maintainScrollStateOnGridChange}
                        />
                      );
                    }

                    return null;
                  })}
                </ColumnarChartGridHeaderRow>
              </ColumnarChartGridHeaderRowContainer>
            </ColumnarGridHeaderSection>
            <RecapGridVisibilityContext.Provider value={isPrintView}>
              <Grid
                gridData={gridDataForRecap}
                gridOptions={gridOptions}
                isPercentageChangeShown={isPercentageChangeShown}
                sheetHeaderCells={gridHeaderRow}
                noOfColumns={numberOfColumnsOnGridDataUpdate}
                selectedChartOptions={selectedChartOptions}
                selectedChartType={selectedChartType}
                onOpenChartsModalClick={handleOpenChartsModalClick}
                onSectionUpdate={handleSectionUpdate}
                onChange={handleChange}
              />
            </RecapGridVisibilityContext.Provider>
            {showLoaderOnPortfolioAndRecapCurrencyMismatch && (
              <LoaderOverlay>
                <RefreshLoader />
                <LoaderMessage>{i18n.t("recap.currencyChange.loader.message")}</LoaderMessage>
              </LoaderOverlay>
            )}
          </ColumnarChartContainer>
        )}
      </>
    );
  };

  return renderColumnarChartComponent();
};

function comparePathname(prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.location.search === nextProps.location.search &&
    prevProps.noOfColumns === nextProps.noOfColumns &&
    prevProps.userMaskAllValues === nextProps.userMaskAllValues &&
    prevProps.location.hash === nextProps.location.hash
  );
}
export default withRouter(React.memo(ColumnarChartComponent, comparePathname));

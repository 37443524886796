import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  blackFeatureTriggeredPromiseSelector,
  isNewUserInTrialSelector,
  isAppInWhiteLabelMode,
  isBlackOptinAllowedSelector,
  Routes
} from "@kubera/common";
import { modalValues, hashParams } from "routes";

import UpgradeToBlackPaywall from "./Upgrade";
import BlackHeadsUpDialog from "./HeadsUp";
import OptInPaywall from "./OptIn";
import WhiteLabelLinkPortfolios from "./WhiteLabelLinkPortfolios";

const RoutePaywalls = [];

const BlackPaywalls = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const blackFeatureTriggeredPromise = useSelector(blackFeatureTriggeredPromiseSelector);
  const isNewUserInTrial = useSelector(isNewUserInTrialSelector);
  const isBlackOptinAllowed = useSelector(isBlackOptinAllowedSelector);

  // For blocking any route eg. /planning, /networth, etc.
  // const RoutePaywalls = useMemo(() => [{ name: "FastForward", route: Routes.PLANNING, shouldBlock: blockFF }], [
  //   blockFF
  // ]);

  const [showPaywall, setShowPaywall] = useState(false);
  const [featurePaywall, setFeaturePaywall] = useState(null);

  const handlePositiveButtonClick = () => {
    if (!featurePaywall) blackFeatureTriggeredPromise.resolve(true);
    setShowPaywall(false);
  };

  const handleDismiss = () => {
    setShowPaywall(false);
    if (featurePaywall) {
      navigate(Routes.NET_WORTH);
    } else {
      blackFeatureTriggeredPromise.resolve(false);
    }
  };

  const handleMoreDetailsClick = () => {
    window.open("https://www.kubera.com/kubera-black", "_blank");
  };

  // promise behavior for action based paywall
  useEffect(() => {
    if (blackFeatureTriggeredPromise === null) return;

    setShowPaywall(true);
  }, [blackFeatureTriggeredPromise]);

  // paywall behavior for component initialization
  useEffect(() => {
    const isInAccountSettings = location.hash.startsWith(`#${hashParams.MODAL}=${modalValues.ACCOUNT_SETTINGS}`); // if user clicks on subscripton settings, don't show paywall because they may be trying to upgrade

    let matchedFeature = RoutePaywalls.find(
      feature => feature.shouldBlock && location.pathname.startsWith(feature.route)
    );

    if (!isInAccountSettings && matchedFeature) {
      setShowPaywall(true);
      setFeaturePaywall(matchedFeature.name);
    } else {
      setShowPaywall(false);
      setFeaturePaywall(null);
    }
  }, [location.pathname, location.hash, RoutePaywalls]);

  if (!showPaywall) return null;

  if (isAppInWhiteLabelMode()) {
    return <WhiteLabelLinkPortfolios onPositiveButtonClick={handleDismiss} />;
  }

  if (isBlackOptinAllowed) {
    return (
      <OptInPaywall
        onDismiss={handleDismiss}
        onPositiveButtonClick={handlePositiveButtonClick}
        onMoreDetailsClick={handleMoreDetailsClick}
      />
    );
  }

  if (isNewUserInTrial) {
    return (
      <BlackHeadsUpDialog
        onPositiveButtonClick={handlePositiveButtonClick}
        onMoreDetailsClick={handleMoreDetailsClick}
        featurePaywall={featurePaywall}
      />
    );
  }

  return <UpgradeToBlackPaywall onDismiss={handleDismiss} onMoreDetailsClick={handleMoreDetailsClick} />;
};

export default BlackPaywalls;

import React from "react";
import styled from "styled-components";
import RichTextArea from "components/inputs/RichTextArea";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { getTickerUsingId, getTickerUsingShortName, convertCurrency } from "@kubera/common";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

const CurrencyValue = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const OriginalCurrencyValue = styled(CurrencyLabel)`
  height: ${props => (props.visible === true ? "auto" : "0px")}
  visibility: ${props => (props.visible === true ? "visible" : "hidden")}
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
`;

const TextValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  margin-bottom: 25px;
`;

const InputTextArea = styled(RichTextArea)`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  padding: 0;

  ::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }
`;

class CustodianInsuranceDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  getCost() {
    const custodianInfo = this.props.details.info;
    if (
      !custodianInfo.costTickerId === true ||
      !custodianInfo.costExchangeRate === true ||
      !custodianInfo.cost === null
    ) {
      return null;
    }

    const costTickerId = custodianInfo.costTickerId;
    var costExchangeRateDetails = null;

    if (!custodianInfo.costExchangeRate === false) {
      try {
        costExchangeRateDetails = JSON.parse(custodianInfo.costExchangeRate);
      } catch (e) {}
    }

    if (
      costExchangeRateDetails &&
      costExchangeRateDetails.tickerId === getTickerUsingShortName(this.props.currency).id
    ) {
      return custodianInfo.cost * costExchangeRateDetails.rate;
    }
    return convertCurrency(custodianInfo.cost, getTickerUsingId(costTickerId).shortName, this.props.currency);
  }

  getValue() {
    const custodianInfo = this.props.details.info;
    if (custodianInfo.value === null) {
      return null;
    }
    const valueTicker = getTickerUsingId(custodianInfo.valueTickerId);
    return convertCurrency(custodianInfo.value, valueTicker.shortName, this.props.currency);
  }

  render() {
    const custodianInfo = this.props.details.info;
    const cost = this.getCost();
    const value = this.getValue();
    const costTickerShortName = custodianInfo.costTickerId
      ? getTickerUsingId(custodianInfo.costTickerId).shortName
      : null;
    const valueTickerShortName = custodianInfo.valueTickerId
      ? getTickerUsingId(custodianInfo.valueTickerId).shortName
      : null;

    return (
      <Container className={this.props.className}>
        {!value === false && <Title>{"Insured Value"}</Title>}
        {!value === false && <CurrencyValue value={value} currency={this.props.currency} />}
        {!value === false && (
          <OriginalCurrencyValue
            visible={this.props.currency !== valueTickerShortName}
            value={custodianInfo.value}
            currency={valueTickerShortName}
            showCurrencyAsSymbol={false}
          />
        )}
        {!cost === false && <Title>{"Annual premium"}</Title>}
        {!cost === false && <CurrencyValue value={cost} currency={this.props.currency} />}
        {!cost === false && (
          <OriginalCurrencyValue
            visible={this.props.currency !== costTickerShortName}
            value={custodianInfo.cost}
            currency={costTickerShortName}
            showCurrencyAsSymbol={false}
          />
        )}
        <Title>{"Policy number"}</Title>
        <TextValue>{this.props.details.info.accountNumber}</TextValue>
        <Title>{"Notes"}</Title>
        <InputTextArea
          data-private
          readOnly={this.props.isReadOnly}
          value={this.props.details.info.note}
          placeholder={this.props.placeholder}
          onChange={this.handleInputChange}
        />
      </Container>
    );
  }
}

export default CustodianInsuranceDetailsComponent;

import React from "react";
import { hashParams, modalValues } from "routes";

import { getHashParams } from "@kubera/common";

import PortfolioFilesDownloadComponent from "./PortfolioFilesDownloadComponent";
import RecapCsvDownloadComponent from "./RecapCsvDownloadComponent";

const urlHashParams = getHashParams(window.location);
const action = urlHashParams[hashParams.ACTION];

const CsvDownloadComponent = props => {
  if (action === modalValues.DOWNLOAD_PORTFOLIO) {
    return <PortfolioFilesDownloadComponent {...props} />;
  }

  return <RecapCsvDownloadComponent {...props} />;
};

export default CsvDownloadComponent;

import { hashParams, modalValues } from "routes";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import LinkAccountCommonExports from "components/link_account/LinkAccountCommonExports";

import {
  custodianSelector,
  accountLinkingService,
  store,
  custodianLinkNeedsRefresh,
  custodianLinkNeedsReconnect,
  refreshAllConnectedCustodians,
  isZaboReconnectionCandidate,
  isZaboManualEntryCandidate,
  custodianSheetSelector
} from "@kubera/common";

import ZaboReconnectDialog from "./ZaboReconnectDialog";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";

export const linkAccountMode = {
  LINK: "add",
  EDIT: "edit",
  REFRESH: "refresh",
  SELECT_ACCOUNTS: "select_account",
  RECONNECT: "reconnect",
  MANAGE: "manage"
};

const staticMethods = {
  linkAccount: (
    history,
    location,
    custodianId,
    linkMode = linkAccountMode.LINK,
    shouldReplace = false,
    regionCode = null,
    category = DashboardComponentExports.getCurrentCategory(),
    isReconnectedFromConnectivityCenter = false,
    showAccountList = false
  ) => {
    console.log("show Account list", showAccountList);
    const regionCodeParam = regionCode ? `${hashParams.LINK_REGION}=${regionCode}` : "";
    if (shouldReplace === true) {
      history.replace({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${regionCodeParam}&${hashParams.IS_RECONNECTED_FROM_CONNECTIVITY_CENTER}=${isReconnectedFromConnectivityCenter}&${hashParams.SHOW_ACCOUNT_LIST}=${showAccountList}`
      });
    } else {
      history.push({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${regionCodeParam}&${hashParams.IS_RECONNECTED_FROM_CONNECTIVITY_CENTER}=${isReconnectedFromConnectivityCenter}&${hashParams.SHOW_ACCOUNT_LIST}=${showAccountList}`
      });
    }
  },

  linkAccountWithService: (
    history,
    location,
    custodianId,
    linkMode = linkAccountMode.LINK,
    linkingService = null,
    shouldReplace = false,
    category = DashboardComponentExports.getCurrentCategory()
  ) => {
    const linkingServiceParam = linkingService ? `${hashParams.LINK_SERVICE}=${linkingService}` : "";
    if (shouldReplace === true) {
      history.replace({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${linkingServiceParam}`
      });
    } else {
      history.push({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${linkingServiceParam}`
      });
    }
  },

  selectAccounts: (history, location, custodianId, category = DashboardComponentExports.getCurrentCategory()) => {
    const custodian = custodianSelector(store.getState(), custodianId);

    if (custodian && custodian.linkingScreenClosed === true) {
      staticMethods.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.SELECT_ACCOUNTS,
        undefined,
        undefined,
        category
      );
    } else {
      LinkAccountCommonExports.reopen(custodianId);
    }
  },

  refreshAccountValues: (history, location, custodianId, onSuccess = null, onError = null, flags) => {
    var isRefreshing = false;
    const custodian = custodianSelector(store.getState(), custodianId);

    if (!custodian === true) {
      return;
    }

    const isUneditableConnection = [
      accountLinkingService.CARS,
      accountLinkingService.DOMAINS,
      accountLinkingService.ZILLOW
    ].includes(custodian.linkType);

    if (custodian.statusInfo === "FORCE_RECONNECT") {
      staticMethods.reconnect(history, location, custodianId);
    } else if (isZaboReconnectionCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, true);
    } else if (isZaboManualEntryCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, false);
    } else if (custodianLinkNeedsRefresh(custodianId) === true) {
      if (isUneditableConnection) {
        GenericMessageDialog.show(history, location);
        return;
      }
      staticMethods.linkAccount(history, location, custodianId, linkAccountMode.REFRESH);
    } else if (custodianLinkNeedsReconnect(custodianId) === true) {
      if (isUneditableConnection) {
        GenericMessageDialog.show(history, location);
        return;
      }
      staticMethods.linkAccount(history, location, custodianId, linkAccountMode.EDIT);
    } else {
      store.dispatch(refreshAllConnectedCustodians(custodianId, onSuccess, onError, flags));
      isRefreshing = true;
    }
    return isRefreshing;
  },

  resolveLinkError: (history, location, custodianId, isReconnectedFromConnectivityCenter = false) => {
    const custodian = custodianSelector(store.getState(), custodianId);

    if (
      [accountLinkingService.CARS, accountLinkingService.DOMAINS, accountLinkingService.ZILLOW].includes(
        custodian.linkType
      )
    ) {
      GenericMessageDialog.show(history, location);
      return;
    }

    const custodianSheet = custodianSheetSelector(store.getState(), custodianId);
    const category = custodianSheet ? custodianSheet.category : undefined;

    if (isZaboReconnectionCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, true);
    } else if (isZaboManualEntryCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, false);
    } else if (custodian.statusInfo === "FORCE_RECONNECT") {
      staticMethods.reconnect(history, location, custodianId, false);
    } else if (custodianLinkNeedsRefresh(custodianId) === true) {
      staticMethods.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.REFRESH,
        undefined,
        undefined,
        category,
        isReconnectedFromConnectivityCenter
      );
    } else {
      staticMethods.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.EDIT,
        undefined,
        undefined,
        category,
        isReconnectedFromConnectivityCenter
      );
    }
  },

  reconnect: (history, location, custodianId, isReconnectedFromConnectivityCenter = false, showAccountList = false) => {
    const custodianSheet = custodianSheetSelector(store.getState(), custodianId);
    const category = custodianSheet ? custodianSheet.category : undefined;

    staticMethods.linkAccount(
      history,
      location,
      custodianId,
      linkAccountMode.RECONNECT,
      false,
      null,
      category,
      isReconnectedFromConnectivityCenter,
      showAccountList
    );
  }
};

export default staticMethods;

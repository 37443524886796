import React, { useCallback } from "react";
import styled from "styled-components";
import {
  chartTimeRange,
  updateUserPreferences,
  userPreferencesSelector,
  currentPortfolioSelector,
  recapChartTypes,
  recapChartOptions,
  RECAP_CATEGORY_TYPE_NETWORTH,
  checkIfAChartOptionHasNoDataToShow,
  fetchPercentageAllocationData
} from "@kubera/common";
import { routes } from "routes";
import ContextMenu from "components/contextmenu/ContextMenu";
import { useSelector, useDispatch } from "react-redux";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import i18n from "i18next";
import { ReactComponent as DownloadIcon } from "assets/images/download_icon.svg";
import { ReactComponent as PrintIcon } from "assets/images/printer.svg";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction : column
  align-items: flex-start;
`;
const MenuContainer = styled.div`
  display : flex;
  flex : 1
  position: sticky;
  position: -webkit-sticky;
  left: 60px;
  top: 0px;
  z-index: 1000;

  @media print {
    left: 0;
  }
`;
const Menu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const NonMenuContainer = styled.div`
  display: flex;
  position: relative
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  @media print {
    display: none !important;
  }
`;

const DropDownButton = styled.div`
  cursor: ${props => (props.isClickable === true ? "pointer" : "auto")};
  margin-right: 16px;
  background-color: transparent
  background-repeat: no-repeat;
  background-position: right;
  background-size: ${props => (props.isClickable === true ? "9px 9px" : "0px")};
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)});
  padding-right: 15px;
  font-size: 14px;
  `;

const ChartTimeRangeDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ChartOptionsDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;
const ChartTypeDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const PercentageChangeButton = styled.div`
  width: 100px;
  height: 19px;
  background: ${props => (props.isPercentageChangeShown ? "#8a8a8a" : props.theme.percentageChangeButtonBG)};
  color: ${props => (props.isPercentageChangeShown ? "#ffffff" : "#8a8a8a")};  border: 1px solid #939393
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top : -30px
  padding : 0px 3px;
  cursor: ${props => (props.isLoading ? "auto" : "pointer")};
`;

const RecapHeaderButton = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-top : -30px
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const CSVDownloadButton = styled(RecapHeaderButton)``;

const CSVDownloadIcon = styled(DownloadIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
    stroke: #8a8a8a;
    stroke-width: 0.3;
  }
`;

const ContextMenuDefault = styled(ContextMenu)`
  @media print {
    display: none !important;
  }
`;

const ChartOptionsContextMenu = styled(ContextMenuDefault)`
  width: 220px;
`;
const TimeRangeContextMenu = styled(ContextMenuDefault)``;
const ChartTypeContextMenu = styled(ContextMenuDefault)``;

// const ExportToPdfButton = styled.div`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 10px;
//   line-height: 12px;
//   text-transform: uppercase;
//   font-feature-settings: "ss01" on;
//   color: #ffffff;
//   min-width: 26px;
//   height: 16px;
//   background: #ffffff;
//   border: 1px solid #8a8a8a;
//   box-sizing: border-box;
//   color: #8a8a8a;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   &:hover {
//     cursor: pointer;
//   }
//   padding: 5px;
//   position: relative;

//   &::after {
//     content: "";
//     position: absolute;
//     top: -4px;
//     width: 100%;
//     height: 19px;
//   }
// `;

const PrintButton = styled(RecapHeaderButton)`
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 16px;
  box-sizing: border-box;
`;

const PrintActionIcon = styled(PrintIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
    stroke: #8a8a8a;
    stroke-width: 0.3;
  }
`;

const ColumnarChartMenuComponent = props => {
  const {
    selectedChartOptions,
    selectedTimeRange,
    selectedChartType,
    isPercentageChangeShown,
    recapData,
    currency,
    portfolioCurrency,
    isExpandedView,
    isLoading,
    setIsPrintView = () => {},
    maintainScrollStateOnGridChange = () => null
  } = props;
  const dispatch = useDispatch();
  const chartOptionsContextMenuRef = React.createRef();
  const timeRangeContextMenuRef = React.createRef();
  const chartTypeContextMenuRef = React.createRef();
  const userPreferences = useSelector(userPreferencesSelector);
  const portfolio = useSelector(currentPortfolioSelector);

  const getChartTimeRangeMenuItems = () => {
    return [
      [
        { id: chartTimeRange.TODAY, label: "Today", type: "timeRange" },
        { id: chartTimeRange.DAILY, label: "Daily", type: "timeRange" },
        { id: chartTimeRange.WEEKLY, label: "Weekly", type: "timeRange" },
        { id: chartTimeRange.MONTHLY, label: "Monthly", type: "timeRange" },
        { id: chartTimeRange.QUARTERLY, label: "Quarterly", type: "timeRange" },
        { id: chartTimeRange.YEARLY, label: "Yearly", type: "timeRange" }
      ]
    ];
  };

  const getChartTypeMenuItems = () => {
    return [
      [
        { id: recapChartTypes.TOTALS, label: "Totals", type: "chartType" },
        { id: recapChartTypes.PERCENTAGE_ALLOCATION, label: "% Allocation", type: "chartType" }
      ]
    ];
  };

  const getCurrentlySelectedChartTypeText = () => {
    const menuItems = getChartTypeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartType) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedTimeRangeText = () => {
    const menuItems = getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedTimeRange) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedChartOptionsText = () => {
    const menuItems = getChartOptionsMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedChartOptions) {
        return item.label;
      }
    }
  };

  const getChartOptionsMenuItems = () => {
    let reportsMenuItems = Object.values(recapChartOptions);
    const assetsAndCurrencyIndex = reportsMenuItems.findIndex(
      item => item.id === recapChartOptions.ASSETS_AND_CURRENCY.id
    );
    // get label based on crypto report availablity
    reportsMenuItems[assetsAndCurrencyIndex].label = checkIfAChartOptionHasNoDataToShow(
      recapChartOptions.CRYPTO.id,
      chartTimeRange.TODAY,
      recapData
    )
      ? "Assets x Currency"
      : i18n.t("supportedReports.assetsAndCurrency.label");
    return [reportsMenuItems];
  };

  const checkIfTimeRangeHasNoDataToShow = (chartOption, timeRange) => {
    if (timeRange === chartTimeRange.TODAY) {
      return recapData?.[timeRange]?.[chartOption]?.totals?.[RECAP_CATEGORY_TYPE_NETWORTH][0]?.values?.length === 0;
    } else {
      return recapData?.[timeRange]?.[chartOption]?.totals?.[RECAP_CATEGORY_TYPE_NETWORTH]?.[0]?.values?.length < 2;
    }
  };

  const handleChartTimeRangeDropDownClick = event => {
    if (timeRangeContextMenuRef.current.isVisible()) {
      timeRangeContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedTimeRange) {
        item.selected = true;
      }
      if (checkIfTimeRangeHasNoDataToShow(selectedChartOptions, item.id)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    }
    timeRangeContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleChartOptionsDropDownClick = event => {
    if (chartOptionsContextMenuRef.current.isVisible()) {
      chartOptionsContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartOptionsMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartOptions) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      if (checkIfAChartOptionHasNoDataToShow(item.id, selectedTimeRange, recapData)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    }
    chartOptionsContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleChartTypesDropDownClick = event => {
    if (chartTypeContextMenuRef.current.isVisible()) {
      chartTypeContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartTypeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartType) {
        item.selected = true;
      }
    }
    chartTypeContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleContextMenuSelection = item => {
    if (item.type === "timeRange") {
      const map = userPreferences.portfolioRecapChartTimeRangeMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartTimeRangeMap: map }));
      if (selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION) {
        dispatch(fetchPercentageAllocationData(selectedChartOptions, item.id));
      }
    } else if (item.type === "chartType") {
      const map = userPreferences.portfolioRecapChartTypeMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartTypeMap: map }));
      dispatch(fetchPercentageAllocationData(selectedChartOptions, selectedTimeRange));
    } else {
      const map = userPreferences.portfolioRecapChartOptionsMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartOptionsMap: map, isRecapSelectedFromMenu: true }));
      if (selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION) {
        dispatch(fetchPercentageAllocationData(item.id, selectedTimeRange));
      }
    }
  };

  const handleDownloadButtonClick = () => {
    window.kuberaOpen(`${process.env.PUBLIC_URL}${routes.DOWNLOAD_FILE}`);
  };

  const togglePercentageChange = () => {
    maintainScrollStateOnGridChange();
    const map = userPreferences.portfolioRecapPercentageChangeFlagMap;
    map[portfolio.id] = !map[portfolio.id];
    dispatch(updateUserPreferences({ portfolioRecapPercentageChangeFlagMap: map }));
  };

  const printPage = useCallback(() => {
    setIsPrintView(true);
  }, []);

  return (
    <Container>
      <MenuContainer>
        <Menu>
          <ChartOptionsDropDownButton
            isClickable={!isLoading}
            id="reports-dropdwn"
            onClick={event => {
              if (!isLoading) {
                handleChartOptionsDropDownClick(event);
              }
            }}
          >
            {getCurrentlySelectedChartOptionsText()}
            <ChartOptionsContextMenu
              ref={chartOptionsContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", left: "0px", top: "30px" }}
            ></ChartOptionsContextMenu>
          </ChartOptionsDropDownButton>

          <ChartTimeRangeDropDownButton
            isClickable={!isLoading}
            onClick={event => {
              if (!isLoading) {
                handleChartTimeRangeDropDownClick(event);
              }
            }}
          >
            {getCurrentlySelectedTimeRangeText()}
            <TimeRangeContextMenu
              ref={timeRangeContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", left: "auto", top: "30px" }}
            />
          </ChartTimeRangeDropDownButton>

          <ChartTypeDropDownButton
            isClickable={!isLoading}
            onClick={event => {
              if (!isLoading) {
                handleChartTypesDropDownClick(event);
              }
            }}
          >
            {getCurrentlySelectedChartTypeText()}
            <ChartTypeContextMenu
              ref={chartTypeContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", top: "30px", left: "auto" }}
            />
          </ChartTypeDropDownButton>
        </Menu>
      </MenuContainer>
      <NonMenuContainer>
        {!isExpandedView && !isLoading && (
          <PrintButton onClick={printPage} title={i18n.t("print")}>
            <PrintActionIcon />
          </PrintButton>
        )}
        {!isLoading && (
          <CSVDownloadButton onClick={handleDownloadButtonClick} title={i18n.t("download.csv")}>
            <CSVDownloadIcon />
          </CSVDownloadButton>
        )}
        <PercentageChangeButton
          isLoading={isLoading}
          show={true}
          onClick={() => {
            if (!isLoading) {
              togglePercentageChange();
            }
          }}
          isPercentageChangeShown={isPercentageChangeShown}
          id="percentage_change_btn"
        >
          {"Show Change"}
        </PercentageChangeButton>
      </NonMenuContainer>
    </Container>
  );
};
export default ColumnarChartMenuComponent;

import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  capitalizeStringWithSpaces,
  uploadAiImportDocument,
  currentPortfolioSelector,
  getAiImportExtractedData,
  getSortKeyBetween,
  getUuid,
  getTickerUsingId,
  store,
  custodianSelector,
  sheetSelector,
  sectionSelector,
  sheetSectionsSelector,
  updateCustodian,
  getTickerUsingShortName,
  insertCustodianAtEndOfSection,
  updateDashboardAction,
  insertSection,
  updateUserPreferences,
  userPreferencesSelector,
  exchangeCodeToNameMap,
  costType,
  getExchangeRate,
  PVST_VALUE_TICKER_ID,
  getExchangeRateDetails,
  aiSupportedFileTypesSelector,
  formatNumberWithCurrency,
  getDateInDDMMMYYYY,
  formatAMPM,
  isSheetEmpty,
  updateAiImportDocumentIgnoredStatus,
  rescanAiImportDocument,
  tickerTypes,
  AI_DOCUMENT_IMPORT_PAYWALL_ACTION,
  apiErrorCodes,
  showToastTip,
  fetchTickerDetails,
  convertPVSTRateToValueExchangeRate,
  getCustodianHistoryFormattedDateString
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import { ReactComponent as AiDocumentImportIcon } from "assets/images/ai_document_import.svg";
import { ReactComponent as AiDocumentImportErrorIcon } from "assets/images/ai_document_import_error.svg";
import DragDropInput from "components/inputs/DragDropInput";
import FileUploadInput from "components/inputs/FileUploadInput";
import SecondaryButton from "components/button/SecondaryButton";
import FancyLoader from "components/loader/FancyLoader";
import { getFileNameWithoutExtension } from "utilities/FileUtils";
import PrimaryButton from "components/button/PrimaryButton";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import TickersPickerDialog from "components/planning/variable_dialogs/TickersPickerDialog";
import CustodiansPickerDialog from "components/planning/variable_dialogs/CustodiansPickerDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { hashParams, modalValues } from "routes";

const FILE_UNSUPPORTED_ERROR = "FILE_UNSUPPORTED_ERROR";

const MAX_FILE_SIZE = 1024 * 1024 * 25;

const itemState = {
  UPDATE: "UPDATE",
  SAME: "SAME",
  NEW: "NEW",
  IGNORED: "IGNORED"
};

const ImportDialog = styled(Dialog)`
  position: relative;
  width: 1030px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 35px
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Title = styled.div`
  max-width: 666px;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorPreview = styled.div`
  width: 666px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: #0074fc;
  cursor: pointer;
`;

const Preview = styled.div`
  margin-left: 5px;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13.31px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: #0074fc;
  text-transform: uppercase;
  cursor: pointer;
`;

const Description = styled.div`
  max-width: 650px;
  flex: 1;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const AiDocumentImportIconComponent = styled(AiDocumentImportIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const AiDocumentImportErrorIconComponent = styled(AiDocumentImportErrorIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DocumentUploadContainer = styled(DragDropInput)`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 25px 25px 50px 25px;
`;

const CommandArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.documentsDetailsCommandAreaBG};
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === false ? "visible" : "hidden")};
`;

const UploadButtonContainer = styled.div`
  margin: ${props => props.margin};
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UploadButton = styled(FileUploadInput)`
  top: 168px;
  left: 0;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: #ffffff;
  background: #000000;
  cursor: pointer;
  outline: 0;
  border: 0;
`;

const UploadHint = styled.div`
  margin-top: 8px;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  align-self: center;
  white-space: pre-wrap;
  font-size: 11px;
  font-weight: 800;
  line-height: 13.31px;
  text-align: center;
  opacity: 0.5;
  text-transform: uppercase;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SubTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AbortButton = styled(SecondaryButton)`
  min-width: 40px;
  padding-left: 20px;
  padding-right: 20px;
  height: 33px;
`;

const SubTitle = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
`;

const UploadLoader = styled(FancyLoader)`
  flex: 1;
  margin-top: 10px;
`;

const ExtractedDataDescription = styled.div`
  margin-top: 6px;
  color: #7a7a7a;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ImportButton = styled(PrimaryButton)`
  min-width: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background: #029600;
`;

const RescanButton = styled(SecondaryButton)`
  min-width: 50px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ResultsContainer = styled.div`
  margin-top: 22px;
`;

const GridContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  margin: ${props => props.margin};
`;

const PartialResultMessage = styled.div`
  color: #333333;
  padding: 10px 20px 10px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0%;
  background: #ffe600;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const CellDropDownContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 4px;
`;

const TickerCellPlaceholder = styled.div`
  opacity: 0.5;
  margin-right: 4px;
  margin-left: -4px;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -22px;
  margin-bottom: 9px;
`;

const BadgeText = styled.div`
  display: flex;
  height: 15px;
  background: ${props => props.bgColor};
  color: ${props => (props.itemState === itemState.NEW ? "#FFFFFF" : "#00000099")};
  border: ${props => (props.itemState === itemState.IGNORED ? "1px solid #BCBCBC" : "none")};
  width: 100%;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  text-align: right;
  font-weight: 400;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0%;
  text-transform: uppercase;
`;

class AiDocumentImportComponent extends React.Component {
  static show = (history, location, custodianId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.AI_DOCUMENT_IMPORT}&${hashParams.ID}=${custodianId}`
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      isUploading: false,
      files: null,
      error: null,
      dataExtracted: null,
      documentApiData: null,
      assetGridData: null,
      debtGridData: null,
      showTickerPickerForAssetRow: null,
      showLocationPickerForAssetRow: null,
      showLocationPickerForDebtRow: null
    };

    this.locationMap = {};
    this.defaultLocationMap = {};
    this.ignoredItemIds = [];

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleAssetRowContextMenuSelection = this.handleAssetRowContextMenuSelection.bind(this);
    this.handleDebtRowContextMenuSelection = this.handleDebtRowContextMenuSelection.bind(this);
    this.handleTickerSelection = this.handleTickerSelection.bind(this);
    this.handleTickerPickerDismiss = this.handleTickerPickerDismiss.bind(this);
    this.handleAssetLocationSelection = this.handleAssetLocationSelection.bind(this);
    this.handleDebtLocationSelection = this.handleDebtLocationSelection.bind(this);
    this.handleLocationPickerDismiss = this.handleLocationPickerDismiss.bind(this);
    this.handleImportButtonClick = this.handleImportButtonClick.bind(this);
    this.handleRescanButtonClick = this.handleRescanButtonClick.bind(this);
    this.handleSuccessfulImportDialogDismiss = this.handleSuccessfulImportDialogDismiss.bind(this);
    this.handleAssetRowUpdate = this.handleAssetRowUpdate.bind(this);

    if (!this.props.files === false) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.files !== this.props.files) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  handleOverlayDismiss(showConfirmation = true) {
    if (showConfirmation) {
      this.setState({ showDismissConfirmation: true });
    } else {
      this.props.onDismiss();
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragStop() {
    this.setState({ isDragging: false });
  }

  getLocationPath(isAsset, location) {
    if (!location === true) {
      return isAsset ? i18n.t("aiImport.defaultAssetPath") : i18n.t("aiImport.defaultDebtPath");
    }
    if (location.isCustodian) {
      const isSingleSectionSheet =
        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, location.sheet.id).length === 1;
      return isSingleSectionSheet ? location.sheet.name : `${location.sheet.name} / ${location.section.name}`;
    } else if (location.isSection) {
      const isSingleSectionSheet =
        !location.createNewSection === true &&
        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, location.sheet.id).length === 1;
      return isSingleSectionSheet ? location.sheet.name : `${location.sheet.name} / ${location.name}`;
    } else if (location.isSheet) {
      return `${location.name}`;
    }
    return null;
  }

  getDefaultSectionName() {
    return `${i18n.t("aiDocumentImportDialog.title")} ${getDateInDDMMMYYYY(new Date())} at ${formatAMPM(new Date())}`;
  }

  handleExtractedDataResponse(apiData) {
    let defaultAssetLocation = null;
    let defaultDebtLocation = null;

    const setDefaultLocation = (itemCategory, item, areAllItemsNew) => {
      let matchedCustodian = item.matchedCustodianId
        ? custodianSelector(store.getState(), item.matchedCustodianId, this.props.currentPortfolio.id)
        : null;
      let matchedSection =
        (areAllItemsNew === false || !this.props.defaultTargetSheet === true) && item.matchedSectionId
          ? sectionSelector(store.getState(), item.matchedSectionId)
          : null;

      if (!matchedCustodian === false) {
        const custodian = custodianSelector(store.getState(), item.matchedCustodianId, this.props.currentPortfolio.id);
        const section = sectionSelector(store.getState(), custodian?.sectionId);
        const sheet = sheetSelector(store.getState(), section?.sheetId);
        if (!custodian || !section || !sheet) {
          return;
        }
        this.locationMap[item.id] = {
          isCustodian: true,
          section: section,
          sheet: sheet,
          ...custodian
        };
      } else if (!matchedSection === false) {
        this.locationMap[item.id] = {
          isSection: true,
          sheet: sheetSelector(store.getState(), matchedSection.sheetId),
          ...matchedSection
        };
      } else if (itemCategory === category.ASSET && !defaultAssetLocation === false) {
        this.locationMap[item.id] = defaultAssetLocation;
      } else if (itemCategory === category.DEBT && !defaultDebtLocation === false) {
        this.locationMap[item.id] = defaultDebtLocation;
      } else {
        const sheet =
          this.props.defaultTargetSheet?.category === itemCategory
            ? this.props.defaultTargetSheet
            : this.props.currentPortfolio.details.sheet.filter(item => item.category === itemCategory)[0];
        const isEmptySheet = isSheetEmpty(store.getState(), this.props.currentPortfolio.id, sheet.id);
        const sections = sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, sheet.id);
        const section = {
          id: getUuid(),
          name: item.institution || this.getDefaultSectionName(),
          sheetId: sheet.id,
          sortKey: isEmptySheet
            ? getSortKeyBetween(null, sections[0]?.sortKey)
            : getSortKeyBetween(sections[sections.length - 1]?.sortKey, null)
        };

        const defaultLocation = {
          isSection: true,
          createNewSection: true,
          section: section,
          sheet: sheet,
          ...section
        };

        if (itemCategory === category.ASSET) {
          defaultAssetLocation = defaultLocation;
          this.locationMap[item.id] = defaultAssetLocation;
        } else {
          defaultDebtLocation = defaultLocation;
          this.locationMap[item.id] = defaultDebtLocation;
        }
      }
    };

    this.ignoredItemIds = [];
    const areAllAssetsNew = apiData.dataExtracted.asset.filter(item => !item.matchedCustodianId === false).length === 0;
    for (const asset of apiData.dataExtracted.asset) {
      if (asset.tickerId === PVST_VALUE_TICKER_ID) {
        asset.tickerId = undefined;
      }
      if (asset.quantity && asset.tickerId && getTickerUsingId(asset.tickerId).type === tickerTypes.FIAT) {
        if (!asset.value === true) {
          asset.value = asset.quantity;
          asset.valueTickerId = asset.tickerId;
        }
        asset.quantity = undefined;
      }
      if (asset.ignore === true) {
        this.ignoredItemIds.push(asset.id);
      }
      setDefaultLocation(category.ASSET, asset, areAllAssetsNew);
    }
    const areAllDebtsNew = apiData.dataExtracted.debt.filter(item => !item.matchedCustodianId === false).length === 0;
    for (const debt of apiData.dataExtracted.debt) {
      if (debt.tickerId === PVST_VALUE_TICKER_ID) {
        debt.tickerId = undefined;
      }
      if (debt.ignore === true) {
        this.ignoredItemIds.push(debt.id);
      }
      setDefaultLocation(category.DEBT, debt, areAllDebtsNew);
    }

    this.defaultLocationMap = { ...this.locationMap };
    this.setState({
      isUploading: false,
      isDataPartial: apiData.statusInfo === "MAX_TOKENS",
      dataExtracted: apiData.dataExtracted,
      assetGridData: this.getAssetGridData(this.props.currentPortfolio.currency, apiData.dataExtracted.asset),
      debtGridData: this.getDebtGridData(this.props.currentPortfolio.currency, apiData.dataExtracted.debt)
    });
  }

  async handleFileUpload(files) {
    if (!files === true || files.length === 0) {
      return;
    }
    if (files[0].size > MAX_FILE_SIZE || !this.props.aiSupportedFileTypes.includes(files[0].type)) {
      this.setState({ error: FILE_UNSUPPORTED_ERROR });
      return;
    }

    this.setState({ files, isUploading: true, dataExtracted: null });
    this.props
      .uploadAiImportDocument(this.props.currentPortfolio.id, files[0])
      .then(apiData => {
        this.setState({ documentApiData: apiData });
        return this.props.getAiImportExtractedData(apiData.id);
      })
      .then(apiData => {
        this.handleExtractedDataResponse(apiData);
      })
      .catch(apiError => {
        console.log(apiError);
        this.setState({ isUploading: false, error: apiError });
      });
  }

  getFileName(file, removeExtension = false) {
    return capitalizeStringWithSpaces(removeExtension ? getFileNameWithoutExtension(file.name) : file.name);
  }

  getRowCountToImport() {
    return (
      this.state.assetGridData.sheets[0].sections[0].rows.filter(row => row.cells[0].itemState !== itemState.IGNORED)
        .length +
      this.state.debtGridData.sheets[0].sections[0].rows.filter(row => row.cells[0].itemState !== itemState.IGNORED)
        .length
    );
  }

  handleAssetChange(newGridData) {
    this.setState({ assetGridData: newGridData });
  }

  updateGridRow(gridData, newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleAssetRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (!updatedRow.cells[3].value === false && updatedRow.cells[3].value !== updatedRow.cells[5].value) {
      updatedRow.cells[5].value = updatedRow.cells[3].value;
      this.setState({ gridData: this.state.assetGridData });
    }
  }

  handleCellInvalidTickerAdded(gridData, sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      let invalidInputText = cell.invalidInputText;
      if (cell.getTickerId() === PVST_VALUE_TICKER_ID) {
        const rateParsed = cell.rate || JSON.parse(this.props.detailsInfo.rate || null);
        invalidInputText = rateParsed ? getTickerUsingId(rateParsed.t).shortName : cell.currency;
      }
      cell.loading = true;
      this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
      this.props.fetchTickerDetails(
        invalidInputText,
        new Date(),
        result => {
          const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          if (cell.getTickerId() === PVST_VALUE_TICKER_ID) {
            cell.exchangeRateDetails = this.props.convertPVSTRateToValueExchangeRate(
              cell.rate,
              getCustodianHistoryFormattedDateString(new Date().getTime())
            );
            cell.currency = "PVST";
            cell.invalidInputText = null;
          } else {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;
          }

          const newRow = row.clone();
          this.updateGridRow(gridData, newRow, sheetIndex, sectionIndex, rowIndex);
        },
        error => {
          const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  getSrcNameFromExtractedData(item) {
    return (item?.institution ? `${item.institution} - ` : "") + item?.name;
  }

  getAssetDetails(asset) {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);
    if (asset.quantity !== undefined && asset.tickerId) {
      return {
        value: asset.quantity,
        valueTickerId: asset.tickerId,
        currency: getTickerUsingId(asset.tickerId).shortName
      };
    } else if (asset.quantity !== undefined && !asset.tickerId && asset.price !== undefined && asset.priceTickerId) {
      return {
        value: asset.quantity,
        valueTickerId: PVST_VALUE_TICKER_ID,
        currency: getTickerUsingId(PVST_VALUE_TICKER_ID).shortName,
        valueExchangeRate: getExchangeRateDetails(
          portfolioTicker.id,
          getExchangeRate(getTickerUsingId(asset.priceTickerId).shortName, portfolioTicker.shortName)
        ),
        rate: JSON.stringify({ u: 1, t: asset.priceTickerId, p: asset.price })
      };
    } else if (asset.quantity !== undefined && !asset.tickerId && !asset.price && !asset.value) {
      return {
        value: asset.quantity,
        valueTickerId: PVST_VALUE_TICKER_ID,
        currency: getTickerUsingId(PVST_VALUE_TICKER_ID).shortName,
        valueExchangeRate: getExchangeRateDetails(portfolioTicker.id, 1),
        rate: JSON.stringify({ u: 1, t: portfolioTicker.id, p: 0 })
      };
    } else if (asset.value !== undefined && asset.valueTickerId) {
      return {
        value: asset.value,
        valueTickerId: asset.valueTickerId,
        currency: getTickerUsingId(asset.valueTickerId).shortName
      };
    }
  }

  isAssetRowSameAsCurrentCustodian(row) {
    const location = this.locationMap[row.id];
    if (location.isCustodian === true) {
      const custodian = custodianSelector(store.getState(), location.id, this.props.currentPortfolio.id);

      if (!custodian === true) {
        return false;
      }
      if (row.cells[4].value && custodian.cost) {
        if (row.cells[4].value !== custodian.cost) {
          return false;
        }
        if (
          row.cells[4].currency &&
          custodian.costTickerId &&
          row.cells[4].currency !== getTickerUsingId(custodian.costTickerId).shortName
        ) {
          return false;
        }
      }
      if ((row.cells[5].value || custodian.value) && row.cells[5].value !== custodian.value) {
        return false;
      }
      if (
        (row.cells[5].currency || custodian.valueTickerId) &&
        row.cells[5].currency !== getTickerUsingId(custodian.valueTickerId).shortName
      ) {
        return false;
      }
      if (
        (row.cells[5].rate || custodian.rate) &&
        custodian.valueTickerId === PVST_VALUE_TICKER_ID &&
        row.cells[5].rate !== custodian.rate
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  populatePreviousValuesForAssetRow(row, asset) {
    const location = this.locationMap[row.id];
    if (location.isCustodian === true) {
      const extractedAsset = asset || this.state.dataExtracted.asset.find(item => item.id === row.id);
      const custodian = custodianSelector(store.getState(), location.id, this.props.currentPortfolio.id);
      const custodianValueTicker = getTickerUsingId(custodian.valueTickerId);
      const showPreviousValue = !extractedAsset.quantity === true || custodianValueTicker.type === tickerTypes.FIAT;
      if (!custodian === true) {
        return;
      }

      if (showPreviousValue) {
        row.cells[2].previousValue = undefined;
        row.cells[3].previousValue = undefined;
      } else {
        if (custodian.rate && custodian.valueTickerId === PVST_VALUE_TICKER_ID) {
          const rateParsed = JSON.parse(custodian.rate);
          row.cells[2].previousValue = formatNumberWithCurrency(rateParsed.p, getTickerUsingId(rateParsed.t).shortName);
        } else {
          row.cells[2].previousValue = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        row.cells[3].previousValue = custodian.value;
      }
      row.cells[4].previousValue = custodian.cost;
      row.cells[4].previousCurrency = custodian.costTickerId
        ? getTickerUsingId(custodian.costTickerId).shortName
        : undefined;

      // Only show previous value when quantity is not there
      if (showPreviousValue) {
        row.cells[5].previousValue = custodian.value;
        row.cells[5].previousCurrency = getTickerUsingId(custodian.valueTickerId).shortName;
      }
    }
  }

  setAssetGridRowItemState(row, asset) {
    if (this.ignoredItemIds.includes(row.id)) {
      row.cells[0].itemState = itemState.IGNORED;
    } else if (this.isAssetRowSameAsCurrentCustodian(row)) {
      row.cells[0].itemState = itemState.SAME;
    } else {
      row.cells[0].itemState =
        this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true ? itemState.UPDATE : itemState.NEW;
      this.populatePreviousValuesForAssetRow(row, asset);
    }
  }

  populateAssetGridRow(row, asset) {
    row.id = asset.id;
    row.cells[1].value = asset.name;
    row.cells[1].description = this.getLocationPath(true, this.locationMap[asset.id]);
    row.cells[1].secondaryDescription =
      this.locationMap[asset.id]?.isCustodian === true ? this.locationMap[asset.id]?.name : undefined;
    row.cells[2].isEditable = asset.quantity !== undefined;
    row.cells[2].value = asset.quantity && asset.tickerId ? getTickerUsingId(asset.tickerId).shortName : undefined;
    row.cells[2].description = asset.tickerId
      ? exchangeCodeToNameMap[getTickerUsingId(asset.tickerId).market]
      : undefined;
    row.cells[3].isEditable = asset.quantity !== undefined;
    row.cells[3].value = asset.quantity;
    row.cells[4].value = asset.cost;
    row.cells[4].currency = asset.costTickerId ? getTickerUsingId(asset.costTickerId).shortName : undefined;
    row.cells[4].setExchangeRateDetails(
      this.props.currentPortfolio.currency,
      getExchangeRate(row.cells[4].currency, this.props.currentPortfolio.currency)
    );

    const assetDetails = this.getAssetDetails(asset);
    row.cells[5].isEditable = asset.quantity === undefined;
    row.cells[5].value = assetDetails.value;
    row.cells[5].currency = assetDetails.currency;
    row.cells[5].exchangeRateDetails = assetDetails.valueExchangeRate;
    row.cells[5].rate = assetDetails.rate;

    if (assetDetails.rate && assetDetails.valueTickerId === PVST_VALUE_TICKER_ID) {
      const rateParsed = JSON.parse(assetDetails.rate);
      row.cells[5].useRateFromExchangeRateDetails = true;
      row.cells[5].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
      row.cells[2].value = formatNumberWithCurrency(rateParsed.p, getTickerUsingId(rateParsed.t).shortName);
    }
    this.setAssetGridRowItemState(row, asset);
  }

  getAssetGridData(currency, assets) {
    var rows = [];
    let nextSortKey = null;
    for (const asset of assets) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyAssetRow(nextSortKey, asset);
      this.populateAssetGridRow(row, asset);
      rows.push(row);
    }

    const section = this.getAssetEmptySection(0, "1");
    section.rows = rows;

    const noQuantityPresent = assets.filter(item => item.quantity === undefined).length === assets.length;
    const noCostPresent = assets.filter(item => item.cost === undefined).length === assets.length;
    if (noQuantityPresent) {
      section.columns[2].hide = true;
      section.columns[3].hide = true;
    }
    if (noCostPresent) {
      section.columns[4].hide = true;
    }

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getAssetEmptySection(forIndex, sortKey) {
    const badgeColumn = new GridColumnData("", false, false, true);
    badgeColumn.width = "20px";
    const nameColumn = new GridColumnData(i18n.t("asset"), false, false, false);
    const tickerColumn = new GridColumnData(i18n.t("aiImport.tickerPrice"), false, false, false);
    const quantityColumn = new GridColumnData(i18n.t("qty"), true, true, false);
    quantityColumn.width = "90px";
    const costColumn = new GridColumnData(i18n.t("cost"), true, true, false);
    const valueColumn = new GridColumnData(i18n.t("value"), true, true, false);
    const optionsColumn = new GridColumnData(null, false, false, true);
    const columns = [badgeColumn, nameColumn, tickerColumn, quantityColumn, costColumn, valueColumn, optionsColumn];

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 4, 5, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getBadgeColorForItemState(state) {
    if (state === itemState.NEW) {
      return "#FF0202";
    }
    if (state === itemState.UPDATE) {
      return "#AFF2D8";
    }
    if (state === itemState.SAME) {
      return "#DCDCDC";
    }
    if (state === itemState.IGNORED) {
      return "##EBEBEB";
    }
  }

  getEmptyAssetRow(sortKey, asset) {
    const badgeCell = new GridCellData(cellType.CLICKABLE_TEXT, "", null);
    badgeCell.width = "20px";
    badgeCell.textAlignment = "right";
    badgeCell.textVerticalAlignment = "top";
    badgeCell.getCellStyles = (rowIndex, cellIndex) => {
      return { marginTop: "3px" };
    };
    badgeCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <BadgeContainer>
          <BadgeText bgColor={this.getBadgeColorForItemState(cell.itemState)} itemState={cell.itemState}>
            {cell.itemState}
          </BadgeText>
        </BadgeContainer>
      );
    };
    const nameCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("asset"), null);
    nameCell.textAlignment = "left";
    nameCell.textVerticalAlignment = "top";
    nameCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      return (
        <CellDropDownContainer>
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    nameCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showLocationPickerForAssetRow: this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };

    const tickerCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("aiImport.tickerPrice"), null);
    tickerCell.textAlignment = "left";
    tickerCell.textVerticalAlignment = "top";
    tickerCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <CellDropDownContainer>
          {cell.value ? null : <TickerCellPlaceholder>{i18n.t("select")}</TickerCellPlaceholder>}
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    tickerCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showTickerPickerForAssetRow: this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };
    const quantityCell = new GridCellData(cellType.NUMBER, i18n.t("qty"), null);
    quantityCell.textVerticalAlignment = "top";
    quantityCell.width = "90px";
    quantityCell.emptyValueIndicator = "---";
    const costCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("cost"),
      null,
      this.props.currentPortfolio.currency
    );
    costCell.textVerticalAlignment = "top";
    costCell.emptyValueIndicator = "---";
    costCell.getCellStyles = (rowIndex, cellIndex) => {
      return { marginTop: "-2px", minHeight: "17px" };
    };
    const valueCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("value"),
      null,
      this.props.currentPortfolio.currency
    );
    valueCell.allowPVST = true;
    valueCell.textVerticalAlignment = "top";
    valueCell.getCellStyles = (rowIndex, cellIndex) => {
      return { marginTop: "-2px" };
    };
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [badgeCell, nameCell, tickerCell, quantityCell, costCell, valueCell, optionsCell];

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      const disableClearTicker = row.cells[3].value === undefined || row.cells[2].value === undefined;
      const disableCost = row.cells[4].value === undefined;
      const disableCopy =
        (this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true) ||
        rowIndex === this.state.assetGridData.sheets[0].sections[0].rows.length - 1;
      return [
        [
          { ...contextMenuItemType.CLEAR_TICKER, disabled: disableClearTicker },
          { ...contextMenuItemType.CLEAR_COST, disabled: disableCost },
          { ...contextMenuItemType.COPY_LOCATION_BELOW, disabled: disableCopy }
        ],
        [contextMenuItemType.RESET_CHANGES],
        [row.cells[0].itemState === itemState.IGNORED ? contextMenuItemType.UNIGNORE : contextMenuItemType.IGNORE]
      ];
    };
    return rowData;
  }

  handleDebtChange(newGridData) {
    this.setState({ debtGridData: newGridData });
  }

  isDebtRowSameAsCurrentCustodian(row) {
    const location = this.locationMap[row.id];
    if (location.isCustodian === true) {
      const custodian = custodianSelector(store.getState(), location.id, this.props.currentPortfolio.id);
      if (!custodian === true) {
        return false;
      }
      if ((row.cells[2].value || custodian.value) && row.cells[2].value !== custodian.value) {
        return false;
      }
      if (
        (row.cells[2].currency || getTickerUsingId(custodian.valueTickerId).shortName) &&
        row.cells[2].currency !== getTickerUsingId(custodian.valueTickerId).shortName
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  populatePreviousValuesForDebtRow(row) {
    const location = this.locationMap[row.id];
    if (location.isCustodian === true) {
      const custodian = custodianSelector(store.getState(), location.id, this.props.currentPortfolio.id);
      if (!custodian === true) {
        return;
      }
      row.cells[2].previousValue = custodian.value;
      row.cells[2].previousCurrency = getTickerUsingId(custodian.valueTickerId).shortName;
    }
  }

  setDebtGridRowItemState(row) {
    if (this.ignoredItemIds.includes(row.id)) {
      row.cells[0].itemState = itemState.IGNORED;
    } else if (this.isDebtRowSameAsCurrentCustodian(row)) {
      row.cells[0].itemState = itemState.SAME;
    } else {
      row.cells[0].itemState =
        this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true ? itemState.UPDATE : itemState.NEW;
      this.populatePreviousValuesForDebtRow(row);
    }
  }

  populateDebtGridRow(row, debt) {
    row.id = debt.id;
    row.cells[1].value = debt.name;
    row.cells[1].description = this.getLocationPath(false, this.locationMap[debt.id]);
    row.cells[1].secondaryDescription =
      this.locationMap[debt.id]?.isCustodian === true ? this.locationMap[debt.id]?.name : undefined;

    const debtDetails = this.getDebtDetails(debt);
    row.cells[2].value = debtDetails.value;
    row.cells[2].currency = debtDetails.currency;
    this.setDebtGridRowItemState(row);
  }

  getDebtDetails(debt) {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);
    if (debt.quantity !== undefined && debt.tickerId) {
      return {
        value: debt.quantity,
        currency: getTickerUsingId(debt.tickerId).shortName
      };
    } else if (debt.quantity !== undefined && !debt.tickerId && debt.price !== undefined && debt.priceTickerId) {
      return {
        value: debt.quantity * debt.price,
        currency: getTickerUsingId(debt.priceTickerId).shortName
      };
    } else if (debt.quantity !== undefined && !debt.tickerId && !debt.price && !debt.value) {
      return {
        value: debt.quantity,
        currency: portfolioTicker.shortName
      };
    } else if (debt.value !== undefined && debt.valueTickerId) {
      return {
        value: debt.value,
        currency: getTickerUsingId(debt.valueTickerId).shortName
      };
    }
  }

  getDebtGridData(currency, debts) {
    var rows = [];
    let nextSortKey = null;
    for (const debt of debts) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyDebtRow(nextSortKey, debt);
      this.populateDebtGridRow(row, debt);
      rows.push(row);
    }

    const section = this.getDebtEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getDebtEmptySection(forIndex, sortKey) {
    const badgeColumn = new GridColumnData("", false, false, true);
    badgeColumn.width = "20px";
    const nameColumn = new GridColumnData(i18n.t("debt"), false, false, false);
    const valueColumn = new GridColumnData(i18n.t("balance"), true, true, false);
    const optionsColumn = new GridColumnData(null, false, false, true);
    const columns = [badgeColumn, nameColumn, valueColumn, optionsColumn];

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 2, 2, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyDebtRow(sortKey, debt) {
    const badgeCell = new GridCellData(cellType.CLICKABLE_TEXT, "", null);
    badgeCell.width = "20px";
    badgeCell.textAlignment = "right";
    badgeCell.textVerticalAlignment = "top";
    badgeCell.getCellStyles = (rowIndex, cellIndex) => {
      return { marginTop: "3px" };
    };
    badgeCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.debtGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <BadgeContainer>
          <BadgeText bgColor={this.getBadgeColorForItemState(cell.itemState)} itemState={cell.itemState}>
            {cell.itemState}
          </BadgeText>
        </BadgeContainer>
      );
    };
    const nameCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("debt"), null);
    nameCell.textAlignment = "left";
    nameCell.textVerticalAlignment = "top";
    nameCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      return (
        <CellDropDownContainer>
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    nameCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showLocationPickerForDebtRow: this.state.debtGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };

    const valueCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("balance"),
      null,
      this.props.currentPortfolio.currency
    );
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [badgeCell, nameCell, valueCell, optionsCell];

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      const disableCopy =
        (this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true) ||
        rowIndex === this.state.debtGridData.sheets[0].sections[0].rows.length - 1;
      return [
        [{ ...contextMenuItemType.COPY_LOCATION_BELOW, disabled: disableCopy }],
        [contextMenuItemType.RESET_CHANGES],
        [row.cells[0].itemState === itemState.IGNORED ? contextMenuItemType.UNIGNORE : contextMenuItemType.IGNORE]
      ];
    };
    return rowData;
  }

  handleAssetRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.CLEAR_TICKER.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[5].isEditable = true;
      row.cells[5].currency = this.props.currentPortfolio.currency;
      row.cells[2].value = undefined;
      row.cells[2].description = undefined;
      row.cells[3].isEditable = false;
      row.cells[3].value = undefined;
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.CLEAR_COST.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[4].isEditable = false;
      row.cells[4].currency = undefined;
      row.cells[4].value = undefined;
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.RESET_CHANGES.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      const asset = this.state.dataExtracted.asset.find(item => item.id === row.id);
      this.locationMap[row.id] = this.defaultLocationMap[row.id];
      for (const cell of row.cells) {
        cell.previousValue = undefined;
        cell.previousCurrency = undefined;
      }
      this.populateAssetGridRow(row, asset);
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.IGNORE.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[0].itemState = itemState.IGNORED;
      this.ignoredItemIds.push(row.id);
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.UNIGNORE.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      this.ignoredItemIds = this.ignoredItemIds.filter(item => item !== row.id);
      this.setAssetGridRowItemState(row);
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.COPY_LOCATION_BELOW.id) {
      const currentRowLocation = this.locationMap[row.id];
      const newGridData = this.state.assetGridData;
      const rowsBelow = newGridData.sheets[sheetIndex].sections[sectionIndex].rows
        .slice(rowIndex + 1)
        .filter(item => item.cells[0].itemState === itemState.NEW);
      for (const row of rowsBelow) {
        this.locationMap[row.id] = currentRowLocation;
        row.cells[1].description = this.getLocationPath(true, currentRowLocation);
      }
      this.setState({ assetGridData: newGridData });
    }
  }

  handleTickerPickerDismiss() {
    this.setState({ showTickerPickerForAssetRow: null });
  }

  handleTickerSelection(value) {
    const row = this.state.showTickerPickerForAssetRow;
    const ticker = getTickerUsingId(value.items[0].id);
    row.cells[2].value = ticker.shortName;
    row.cells[2].description = exchangeCodeToNameMap[ticker.market];
    row.cells[5].currency = ticker.shortName;
    this.setState({ showTickerPickerForAssetRow: null });
  }

  handleLocationPickerDismiss() {
    this.setState({ showLocationPickerForAssetRow: null, showLocationPickerForDebtRow: null });
  }

  handleAssetLocationSelection(row, value) {
    if (value.category === category.DEBT || value.sheet?.category === category.DEBT) {
      const newDataExtracted = this.state.dataExtracted;
      const debt = newDataExtracted.asset.find(item => item.id === row.id);
      newDataExtracted.asset = newDataExtracted.asset.filter(item => item.id !== row.id);
      newDataExtracted.debt.push(debt);

      const newDebtGridData = this.state.debtGridData;
      const debtRows = newDebtGridData.sheets[0].sections[0].rows;
      const sortKey = getSortKeyBetween(debtRows[debtRows.length - 1]?.sortKey, null);
      const debtRow = this.getEmptyDebtRow(sortKey, debt);
      this.populateDebtGridRow(debtRow, debt);
      debtRows.push(debtRow);

      const newAssetGridData = this.state.assetGridData;
      newAssetGridData.sheets[0].sections[0].rows = newAssetGridData.sheets[0].sections[0].rows.filter(
        item => item.id !== row.id
      );

      this.setState({
        dataExtracted: newDataExtracted,
        debtGridData: newDebtGridData,
        assetGridData: newAssetGridData
      });

      this.handleDebtLocationSelection(debtRow, value);
      return;
    }

    for (const cell of row.cells) {
      cell.previousValue = undefined;
      cell.previousCurrency = undefined;
    }

    if (value.isSheet) {
      const isEmptySheet = isSheetEmpty(store.getState(), this.props.currentPortfolio.id, value.id);
      const item = this.state.dataExtracted.asset.find(item => item.id === row.id);
      const sections = sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, value.id);
      const section = {
        id: getUuid(),
        name: item.institution || this.getDefaultSectionName(),
        sheetId: value.id,
        sortKey: isEmptySheet
          ? getSortKeyBetween(null, sections[0]?.sortKey)
          : getSortKeyBetween(sections[sections.length - 1]?.sortKey, null)
      };
      const sectionLocation = {
        isSection: true,
        createNewSection: true,
        section: section,
        sheet: value,
        ...section
      };
      const existingLocationBasedOnPath = this.findLocationBasedOnPath(true, sectionLocation);
      if (!existingLocationBasedOnPath === false) {
        value = existingLocationBasedOnPath;
      } else {
        value = sectionLocation;
      }
    }
    this.locationMap[row.id] = value;

    row.cells[1].value = this.state.dataExtracted.asset.find(item => item.id === row.id)?.name;
    row.cells[1].description = this.getLocationPath(true, value);
    row.cells[1].secondaryDescription =
      this.locationMap[row.id]?.isCustodian === true ? this.locationMap[row.id]?.name : undefined;

    this.setAssetGridRowItemState(row);
    this.setState({ assetGridData: this.state.assetGridData });
  }

  handleDebtRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.IGNORE.id) {
      const newGridData = this.state.debtGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[0].itemState = itemState.IGNORED;
      this.ignoredItemIds.push(row.id);
      this.setState({ debtGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.UNIGNORE.id) {
      const newGridData = this.state.debtGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      this.ignoredItemIds = this.ignoredItemIds.filter(item => item !== row.id);
      this.setDebtGridRowItemState(row);
      this.setState({ debtGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.COPY_LOCATION_BELOW.id) {
      const currentRowLocation = this.locationMap[row.id];
      const newGridData = this.state.debtGridData;
      const rowsBelow = newGridData.sheets[sheetIndex].sections[sectionIndex].rows
        .slice(rowIndex + 1)
        .filter(item => item.cells[0].itemState === itemState.NEW);
      for (const row of rowsBelow) {
        this.locationMap[row.id] = currentRowLocation;
        row.cells[1].description = this.getLocationPath(false, currentRowLocation);
      }
      this.setState({ debtGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.RESET_CHANGES.id) {
      const newGridData = this.state.debtGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      const debt = this.state.dataExtracted.debt.find(item => item.id === row.id);
      this.locationMap[row.id] = this.defaultLocationMap[row.id];
      for (const cell of row.cells) {
        cell.previousValue = undefined;
        cell.previousCurrency = undefined;
      }
      this.populateDebtGridRow(row, debt);
      this.setState({ debtGridData: newGridData });
    }
  }

  findLocationBasedOnPath(isAsset, location) {
    const locations = Object.values(this.locationMap);
    return locations.find(item => this.getLocationPath(isAsset, item) === this.getLocationPath(isAsset, location));
  }

  handleDebtLocationSelection(row, value) {
    if (value.category === category.ASSET || value.sheet?.category === category.ASSET) {
      const newDataExtracted = this.state.dataExtracted;
      const asset = newDataExtracted.debt.find(item => item.id === row.id);
      newDataExtracted.debt = newDataExtracted.debt.filter(item => item.id !== row.id);
      newDataExtracted.asset.push(asset);

      const newAssetGridData = this.state.assetGridData;
      const assetRows = newAssetGridData.sheets[0].sections[0].rows;
      const sortKey = getSortKeyBetween(assetRows[assetRows.length - 1]?.sortKey, null);
      const assetRow = this.getEmptyAssetRow(sortKey, asset);
      this.populateAssetGridRow(assetRow, asset);
      assetRows.push(assetRow);

      const newDebtGridData = this.state.debtGridData;
      newDebtGridData.sheets[0].sections[0].rows = newDebtGridData.sheets[0].sections[0].rows.filter(
        item => item.id !== row.id
      );

      this.setState({
        dataExtracted: newDataExtracted,
        debtGridData: newDebtGridData,
        assetGridData: newAssetGridData
      });

      this.handleAssetLocationSelection(assetRow, value);
      return;
    }

    for (const cell of row.cells) {
      cell.previousValue = undefined;
      cell.previousCurrency = undefined;
    }
    if (value.isSheet) {
      const item = this.state.dataExtracted.debt.find(item => item.id === row.id);
      const isEmptySheet = isSheetEmpty(store.getState(), this.props.currentPortfolio.id, value.id);
      const sections = sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, value.id);
      const section = {
        id: getUuid(),
        name: item.institution || this.getDefaultSectionName(),
        sheetId: value.id,
        sortKey: isEmptySheet
          ? getSortKeyBetween(null, sections[0]?.sortKey)
          : getSortKeyBetween(sections[sections.length - 1]?.sortKey, null)
      };
      const sectionLocation = {
        isSection: true,
        createNewSection: true,
        section: section,
        sheet: value,
        ...section
      };
      const existingLocationBasedOnPath = this.findLocationBasedOnPath(false, sectionLocation);
      if (!existingLocationBasedOnPath === false) {
        value = existingLocationBasedOnPath;
      } else {
        value = sectionLocation;
      }
    }
    this.locationMap[row.id] = value;

    row.cells[1].value = this.state.dataExtracted.debt.find(item => item.id === row.id)?.name;
    row.cells[1].description = this.getLocationPath(false, value);
    row.cells[1].secondaryDescription =
      this.locationMap[row.id]?.isCustodian === true ? this.locationMap[row.id]?.name : undefined;

    this.setDebtGridRowItemState(row);
    this.setState({ debtGridData: this.state.debtGridData });
  }

  handleRescanButtonClick(e) {
    this.setState({ isUploading: true, dataExtracted: null });

    this.props
      .rescanAiImportDocument(this.state.documentApiData.id)
      .then(apiData => {
        this.setState({ documentApiData: apiData });
        return this.props.getAiImportExtractedData(apiData.id);
      })
      .then(apiData => {
        this.handleExtractedDataResponse(apiData);
      })
      .catch(apiError => {
        console.log(apiError);
        this.setState({ isUploading: false, error: apiError });
      });
  }

  handleImportButtonClick(e) {
    let custodianIdsToUpdate = [];
    let sectionIdsCreated = [];
    let ignoredHashes = [];
    let usedHashes = [];

    const assetRows = this.state.assetGridData.sheets[0].sections[0].rows.filter(
      item => !this.locationMap[item.id] === false && item.cells[5].value !== undefined
    );
    const debtRows = this.state.debtGridData.sheets[0].sections[0].rows.filter(
      item => !this.locationMap[item.id] === false && item.cells[1].value !== undefined
    );

    const updateOrInsertRow = (extractedData, row, nameCell, costCell, valueCell) => {
      if (extractedData.ignore === true && row.cells[0].itemState !== itemState.IGNORED) {
        usedHashes.push(extractedData.hash);
      } else if (!extractedData.ignore === true && row.cells[0].itemState === itemState.IGNORED) {
        ignoredHashes.push(extractedData.hash);
      }

      if (row.cells[0].itemState === itemState.IGNORED) {
        return;
      }

      const location = this.locationMap[row.id];
      if (location.isCustodian === true) {
        const propertiesToUpdate = {
          value: valueCell.value,
          valueTickerId: getTickerUsingShortName(valueCell.currency).id,
          srcName: this.getSrcNameFromExtractedData(extractedData)
        };
        if (valueCell.exchangeRateDetails) {
          propertiesToUpdate.valueExchangeRate = valueCell.exchangeRateDetails;
        }
        if (valueCell.rate) {
          propertiesToUpdate.rate = valueCell.rate;
        }
        if (costCell && costCell.value !== undefined) {
          propertiesToUpdate.cost = costCell.value;
          propertiesToUpdate.costTickerId = getTickerUsingShortName(costCell.currency).id;
          propertiesToUpdate.costExchangeRate = costCell.exchangeRateDetails;
          propertiesToUpdate.costType = costType.MANUAL;
        }
        this.props.updateCustodian(false, location.id, propertiesToUpdate, false);
        custodianIdsToUpdate.push(location.id);
      } else {
        const section = location.isSection
          ? location
          : sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, location.id)[0];
        const newCustodian = {
          id: getUuid(),
          name: nameCell.value,
          sectionId: section.id,
          value: valueCell.value,
          valueTickerId: getTickerUsingShortName(valueCell.currency).id,
          srcName: this.getSrcNameFromExtractedData(extractedData)
        };
        if (valueCell.exchangeRateDetails) {
          newCustodian.valueExchangeRate = valueCell.exchangeRateDetails;
        }
        if (valueCell.rate) {
          newCustodian.rate = valueCell.rate;
        }
        if (costCell && costCell.value !== undefined) {
          newCustodian.cost = costCell.value;
          newCustodian.costTickerId = getTickerUsingShortName(costCell.currency).id;
          newCustodian.costExchangeRate = costCell.exchangeRateDetails;
          newCustodian.costType = costType.MANUAL;
        }
        this.props.insertCustodianAtEndOfSection(
          this.props.currentPortfolio.id,
          newCustodian.sectionId,
          newCustodian.id,
          undefined,
          newCustodian,
          false,
          true
        );

        if (location.createNewSection === true && sectionIdsCreated.includes(section.id) === false) {
          sectionIdsCreated.push(location.id);
          this.props.insertSection(this.props.currentPortfolio.id, section.sheetId, {
            id: location.id,
            name: location.name,
            sortKey: location.sortKey,
            sheetId: section.sheetId
          });
        }
        this.props.updateCustodian(true, newCustodian.id, newCustodian, false);
        custodianIdsToUpdate.push(newCustodian.id);
      }
    };

    for (const row of assetRows) {
      updateOrInsertRow(
        this.state.dataExtracted.asset.find(item => item.id === row.id),
        row,
        row.cells[1],
        row.cells[4],
        row.cells[5]
      );
    }
    for (const row of debtRows) {
      updateOrInsertRow(
        this.state.dataExtracted.debt.find(item => item.id === row.id),
        row,
        row.cells[1],
        null,
        row.cells[2]
      );
    }
    this.props.updateDashboard(custodianIdsToUpdate);
    if (this.props.userPreferences.isAiDocumentImportTipShown === false) {
      this.props.updateUserPreferences({ isAiDocumentImportTipShown: true });
    }
    if (ignoredHashes.length > 0 || usedHashes.length > 0) {
      this.props
        .updateAiImportDocumentIgnoredStatus(this.props.currentPortfolio.id, ignoredHashes, usedHashes)
        .then(() => {})
        .catch(() => {});
    }
    this.handleSuccessfulImportDialogDismiss();
  }

  handleSuccessfulImportDialogDismiss() {
    this.props.history.replace({
      ...this.props.location,
      hash: `${this.props.location.hash}&${hashParams.CLOSE_ADD_MODAL}=true`
    });
    setTimeout(() => {
      this.props.onDismiss();
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }, 50);
  }

  isGenericError(error) {
    return error.errorCode !== apiErrorCodes.RATE_LIMIT_EXCEEDED && error.errorCode !== apiErrorCodes.EMPTY_RESPONSE;
  }

  getErrorTitle(error) {
    if (this.state.error === FILE_UNSUPPORTED_ERROR) {
      return i18n.t("aiImport.fileNotSupportedTitle");
    }
    return this.isGenericError(error)
      ? i18n.t("aiImport.genericErrorTitle")
      : i18n.t("aiImport.talkToHumansErrorTitle");
  }

  getErrorDescription(error) {
    if (this.state.error === FILE_UNSUPPORTED_ERROR) {
      return i18n.t("aiImport.fileNotSupportedDesc");
    }
    const isPdfFile = this.state.files[0]?.type === "application/pdf";
    if (this.isGenericError(error)) {
      return isPdfFile ? i18n.t("aiImport.genericPdfErrorDesc") : i18n.t("aiImport.genericErrorDesc");
    }
    return isPdfFile ? i18n.t("aiImport.talkToHumansPdfErrorDesc") : i18n.t("aiImport.talkToHumansErrorDesc");
  }

  getNewPathSuggestions() {
    var suggestions = {};
    var newLocations = [];
    const locations = Object.values(this.locationMap).filter(item => item.createNewSection === true);
    for (const location of locations) {
      if (newLocations.find(item => item.section.id === location.section.id) === undefined) {
        newLocations.push(location);
      }
    }
    suggestions[category.ASSET] = newLocations.filter(item => item.sheet?.category === category.ASSET);
    suggestions[category.DEBT] = newLocations.filter(item => item.sheet?.category === category.DEBT);
    return suggestions;
  }

  render() {
    if (this.state.dataExtracted) {
      const gridOptions = {
        getRowStyle: (row, rowIndex, sectionIndex) => {
          return {
            alignItems: "flex-start",
            paddingTop: "10px",
            paddingBottom: "10px",
            height: "auto",
            backgroundColor: row.cells[0].itemState === itemState.IGNORED ? "#EBEBEB" : "white"
          };
        }
      };

      const rowCountToImport = this.getRowCountToImport();
      const importButtonString = i18n.t("aiImport.importRows").replace("%s1%", rowCountToImport);
      const totalResults = this.state.dataExtracted.asset.length + this.state.dataExtracted.debt.length;

      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Header>
                      <Title>{this.getFileName(this.state.files[0], true)}</Title>
                      <Preview
                        onClick={e => {
                          const fileWindow = window.open(URL.createObjectURL(this.state.files[0]), "_blank");
                          const name = this.state.files[0].name;
                          fileWindow.addEventListener("load", function() {
                            fileWindow.document.title = name;
                          });
                        }}
                      >
                        {i18n.t("preview")}
                      </Preview>
                    </Header>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss()} title={i18n.t("abort")} />
                </HeaderContainer>
                <ExtractedDataDescription
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("aiImport.importRowsDesc")
                  }}
                />
                <ActionButtonsContainer>
                  <ImportButton
                    onClick={this.handleImportButtonClick}
                    title={rowCountToImport > 1 ? importButtonString : importButtonString.slice(0, -1)}
                  />
                  <RescanButton onClick={this.handleRescanButtonClick} title={i18n.t("rescan")} />
                </ActionButtonsContainer>
                <ResultsContainer>
                  {this.state.isDataPartial === true && (
                    <PartialResultMessage>
                      {i18n.t("aiImport.partialResultMessage").replace("%s1%", Math.floor((totalResults - 8) / 5) * 5)}
                    </PartialResultMessage>
                  )}
                  {this.state.assetGridData && this.state.assetGridData.sheets[0].sections[0].rows.length > 0 && (
                    <GridContainer>
                      <Grid
                        gridOptions={gridOptions}
                        gridData={this.state.assetGridData}
                        getEmptyRow={this.getEmptyAssetRow}
                        onChange={this.handleAssetChange.bind(this)}
                        onRowUpdate={this.handleAssetRowUpdate}
                        onCellInvalidTickerAdded={(sheetIndex, sectionIndex, rowIndex, cellIndex) =>
                          this.handleCellInvalidTickerAdded(
                            this.state.assetGridData,
                            sheetIndex,
                            sectionIndex,
                            rowIndex,
                            cellIndex
                          )
                        }
                        onRowContextMenuSelection={this.handleAssetRowContextMenuSelection}
                      />
                    </GridContainer>
                  )}
                  {this.state.debtGridData && this.state.debtGridData.sheets[0].sections[0].rows.length > 0 && (
                    <GridContainer margin={"20px 0px 0px 0px"}>
                      <Grid
                        gridOptions={gridOptions}
                        gridData={this.state.debtGridData}
                        getEmptyRow={this.getEmptyDebtRow}
                        onChange={this.handleDebtChange.bind(this)}
                        onCellInvalidTickerAdded={(sheetIndex, sectionIndex, rowIndex, cellIndex) =>
                          this.handleCellInvalidTickerAdded(
                            this.state.debtGridData,
                            sheetIndex,
                            sectionIndex,
                            rowIndex,
                            cellIndex
                          )
                        }
                        onRowContextMenuSelection={this.handleDebtRowContextMenuSelection}
                      />
                    </GridContainer>
                  )}
                </ResultsContainer>
              </ContentContainer>
              {!this.state.showTickerPickerForAssetRow === false && (
                <TickersPickerDialog
                  fetchTickersWithoutRate={true}
                  hidePrivateCustodians={true}
                  hideTickerPrice={true}
                  data={{ props: { allowUnusedTickers: true } }}
                  onVariableUpdate={this.handleTickerSelection}
                  onDismiss={this.handleTickerPickerDismiss}
                />
              )}
              {!this.state.showLocationPickerForAssetRow === false && (
                <CustodiansPickerDialog
                  category={category.ASSET}
                  subTitle={
                    this.state.dataExtracted.asset.find(item => item.id === this.state.showLocationPickerForAssetRow.id)
                      ?.name
                  }
                  allowCategoryChange={true}
                  showSuggestions={true}
                  newPathSuggestions={this.getNewPathSuggestions()}
                  assetDescription={i18n.t("aiImport.assetPickerDesc")}
                  debtDescription={i18n.t("aiImport.debtPickerDesc")}
                  filterResults={result => {
                    if (result.isCustodian) {
                      return !result.linkType === true;
                    } else if (result.isSection) {
                      return (
                        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, result.sheetId).length >
                        1
                      );
                    }
                    return true;
                  }}
                  returnResultWithDetails={true}
                  onVariableUpdate={data =>
                    this.handleAssetLocationSelection(this.state.showLocationPickerForAssetRow, data.items[0])
                  }
                  onDismiss={this.handleLocationPickerDismiss}
                />
              )}
              {!this.state.showLocationPickerForDebtRow === false && (
                <CustodiansPickerDialog
                  category={category.DEBT}
                  subTitle={
                    this.state.dataExtracted.debt.find(item => item.id === this.state.showLocationPickerForDebtRow.id)
                      ?.name
                  }
                  allowCategoryChange={true}
                  showSuggestions={true}
                  newPathSuggestions={this.getNewPathSuggestions()}
                  assetDescription={i18n.t("aiImport.assetPickerDesc")}
                  debtDescription={i18n.t("aiImport.debtPickerDesc")}
                  filterResults={result => {
                    if (result.isCustodian) {
                      return !result.linkType === true;
                    } else if (result.isSheet) {
                      return (
                        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, result.id).length === 1
                      );
                    }
                    return true;
                  }}
                  returnResultWithDetails={true}
                  onVariableUpdate={data =>
                    this.handleDebtLocationSelection(this.state.showLocationPickerForDebtRow, data.items[0])
                  }
                  onDismiss={this.handleLocationPickerDismiss}
                />
              )}
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    if (this.state.isUploading) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Title>{this.getFileName(this.state.files[0], true)}</Title>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss(true)} title={i18n.t("abort")} />
                </HeaderContainer>
                <UploadLoader
                  initialMessage={i18n.t("aiImport.waitMessage")}
                  footerMessage={i18n.t("aiImport.waitHint")}
                />
              </ContentContainer>
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    return (
      <DialogOverlay onDismiss={() => this.handleOverlayDismiss(false)}>
        <ImportDialog>
          <Container>
            <DocumentUploadContainer
              onDragStart={this.handleDragStart}
              onDragStop={this.handleDragStop}
              onFileDrop={this.handleFileUpload}
              disabled={this.props.isReadOnly === true}
            >
              <CommandArea isDragging={this.state.isDragging}>
                <ContentContainer>
                  {!this.state.error === true && (
                    <>
                      <AiDocumentImportIconComponent />
                      <Title>{i18n.t("aiDocumentImportDialog.title")}</Title>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: this.props.userPreferences.isAiDocumentImportTipShown
                            ? i18n.t("aiDocumentImportDialog.desc")
                            : i18n.t("aiDocumentImportDialog.firstTimeDesc")
                        }}
                      />
                      <UploadButtonContainer margin={"44px 0px 0px 0px"}>
                        <UploadButton label={i18n.t("uploadFile")} multiple={false} onChange={this.handleFileUpload} />
                        <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                      </UploadButtonContainer>
                    </>
                  )}
                  {!this.state.error === false && (
                    <>
                      {this.state.error === FILE_UNSUPPORTED_ERROR ? (
                        <AiDocumentImportIconComponent />
                      ) : (
                        <AiDocumentImportErrorIconComponent />
                      )}
                      <SubTitle>{i18n.t("aiImport")}</SubTitle>
                      <Title>{this.getErrorTitle(this.state.error)}</Title>
                      {this.state.error !== FILE_UNSUPPORTED_ERROR && (
                        <ErrorPreview
                          onClick={e => {
                            const fileWindow = window.open(URL.createObjectURL(this.state.files[0]), "_blank");
                            const name = this.state.files[0].name;
                            fileWindow.addEventListener("load", function() {
                              fileWindow.document.title = name;
                            });
                          }}
                        >
                          {this.state.files[0].name}
                        </ErrorPreview>
                      )}
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: this.getErrorDescription(this.state.error)
                        }}
                      />
                      <ActionButtonsContainer>
                        <UploadButtonContainer margin={"0px 0px 0px 0px"}>
                          <UploadButton
                            label={
                              this.state.error === FILE_UNSUPPORTED_ERROR
                                ? i18n.t("uploadFile")
                                : i18n.t("uploadAnotherFile")
                            }
                            multiple={false}
                            onChange={this.handleFileUpload}
                          />
                          <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                        </UploadButtonContainer>
                        <RescanButton onClick={this.handleRescanButtonClick} title={i18n.t("rescan")} />
                      </ActionButtonsContainer>
                    </>
                  )}
                </ContentContainer>
                <DropAreaIndicator isDragging={this.state.isDragging}>
                  <DropAreaHint>{i18n.t("aiImportDropFileHint")}</DropAreaHint>
                </DropAreaIndicator>
              </CommandArea>
            </DocumentUploadContainer>
          </Container>
        </ImportDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  defaultTargetSheet: sheetSelector(state, props.defaultSheetId),
  userPreferences: userPreferencesSelector(state),
  aiSupportedFileTypes: aiSupportedFileTypesSelector(state)
});

const mapDispatchToProps = {
  uploadAiImportDocument,
  getAiImportExtractedData,
  updateCustodian,
  insertCustodianAtEndOfSection,
  insertSection,
  updateDashboard: updateDashboardAction,
  updateUserPreferences,
  updateAiImportDocumentIgnoredStatus,
  rescanAiImportDocument,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  convertPVSTRateToValueExchangeRate: convertPVSTRateToValueExchangeRate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AiDocumentImportComponent));

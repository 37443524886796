import React, { Children, useEffect, useState, useContext } from "react";

import GridRowsInVisibleArea from "./GridRowsInVisibleArea";
import GridRowsInViewPort from "./GridRowsInViewPortArea";
import RecapGridVisibilityContext from "../recap/RecapGridVisibilityContext";

const GridRowsInViewPortWrapper = ({ children, rowHeight = 40 }) => {
  const arrayChildren = Children.toArray(children);

  const rowsVisibleByDefault = useContext(RecapGridVisibilityContext);

  const [observer, setObserver] = useState();
  const [draggableId, setDraggableId] = useState(null);

  useEffect(() => {
    // Handling drag event listeners
    const handleBeforeCaptureEvent = event => {
      setDraggableId(event.detail.draggableId);
    };

    const handleDragEndEvent = () => {
      setDraggableId(null);
    };

    window.addEventListener("onBeforeCaptureEvent", handleBeforeCaptureEvent);
    window.addEventListener("onDragEndEvent", handleDragEndEvent);

    // Intersection observer setup
    const intersectionCallback = entries => {
      entries.forEach(entry => {
        entry.target.setItemVisibility(entry.isIntersecting);
      });
    };

    var observerRef;
    const handleSheetsObserver = () => {
      const observerOptions = {
        rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`
      };
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        observerRef = new IntersectionObserver(intersectionCallback, observerOptions);
      }
    };

    handleSheetsObserver();
    setObserver(observerRef);

    return () => {
      observerRef.disconnect();
      window.removeEventListener("onBeforeCaptureEvent", handleBeforeCaptureEvent);
      window.removeEventListener("onDragEndEvent", handleDragEndEvent);
    };
  }, []);

  const fromBottomFifthRowIndex = children.length > 6 ? children.length - 6 : -1;

  return (
    <>
      {Children.map(arrayChildren, (child, index) =>
        child.props.isWithinVisibleArea || child.props?.entry?.viewId === draggableId || rowsVisibleByDefault ? (
          <GridRowsInVisibleArea {...child.props}>{child}</GridRowsInVisibleArea>
        ) : (
          <GridRowsInViewPort
            {...child.props}
            index={index}
            observer={observer}
            fromBottomFifthRowIndex={fromBottomFifthRowIndex}
            rowHeight={rowHeight}
          >
            {child}
          </GridRowsInViewPort>
        )
      )}
    </>
  );
};

export default React.memo(GridRowsInViewPortWrapper);

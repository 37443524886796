import React from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import {
  currentPortfolioNameSelector,
  getDateForPdfFooter,
  getCustodianValue,
  getCurrentPortfolioDataInNestedFormat,
  store,
  currentPortfolioCurrencySelector,
  formatNumberWithCurrency,
  shortFormatNumberWithCurrency,
  isCryptoCurrency,
  getTickerUsingShortName,
  printElement
} from "@kubera/common";
import { useSelector } from "react-redux";
import { ReactComponent as PrintIcon } from "assets/images/printer.svg";
import { ReactComponent as NetWorthStamp } from "assets/images/kubera_stamp.svg";

const Container = styled.div`
    display: flex;
    flex: 1;
    background: #F8F8F8;
    justify-content: center;
    @media print {
       @page {
        size: A4 portrait;
        margin-bottom: 60px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 60px !important;
       }
  `;
const NetWorthStatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 816px;
  min-height: 1000px
  background: #ffffff;
  border: 1px solid #dadada;
  margin-top: 29px;
  margin-bottom: 29px;
  @media print {
    border: none;
    height: auto;
    margin-top: 0px;
    min-height: 0
  }
`;

const PrintIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow:  0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-top: 86px
    margin-left: -23px;
  @media print {
    display: none;
}
`;

const UserName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-top: 60px;
  @media print {
    margin-top: 0px;
    font-size: 14px;
  }
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-top: 5px;
  @media print {
    font-size: 14px;
    margin-top: 3px;
  }
`;

const Date = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 7px;
  @media print {
    font-size: 10px;
    margin-top: 3px;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  @media print {
    page-break-after: avoid;
  }
`;
const CategoryName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  font-feature-settings: "ss01" on, "calt" off;

  color: #000000;
  margin-left: 70px;
  @media print {
    font-size: 12px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-left: 143px;
  margin-right: 71px;
  @media print {
    margin-top: 10px;
  }
`;

const SheetContainer = styled(RowContainer)`
  margin-top: 16px;
  @media print {
    page-break-after: avoid;
    margin-top: 10px;
  }
`;

const SectionContainer = styled(RowContainer)`
  margin-top: 12px;
  @media print {
    margin-top: 10px;
  }
`;

const SheetName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  @media print {
    font-size: 10px;
  }
`;

const SectionName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  @media print {
    font-size: 10px;
  }
`;

const TotalValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  @media print {
    font-size: 10px;
  }
`;

const CategoryTotalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 143px;
  margin-right: 71px;
  margin-top: 10px;
`;

const CategoryTotal = styled.div`
  align-self: flex-end;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;

  color: #000000;
  @media print {
    font-size: 12px;
  }
`;

const CategoryDivider = styled(RowContainer)`
  border: 1px solid #000000;
  margin-top: 12px;
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
  }
`;
const NetworthRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 71px;
  margin-top: 41px;
  margin-bottom: 16px;
`;
const NetWorthDivider = styled.div`
  border: 1px solid #000000;
  display: flex;
  justify-content: space-between;
  margin-left: 70px;
  margin-right: 71px;
  margin-top: 2px;
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
  }
`;
const NetWorthStampComponent = styled(NetWorthStamp)`
width: 110px
height: 110px;
margin-left: 72px;
margin-top:-17px;
margin-bottom: 60px;`;

export const NetWorthStatementComponent = () => {
  const portfolioName = useSelector(currentPortfolioNameSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const portfolioTicker = getTickerUsingShortName(portfolioCurrency);
  const date = getDateForPdfFooter();
  const netWorthStatementData = {};
  netWorthStatementData.Assets = getCurrentPortfolioDataInNestedFormat(store.getState(), "Asset");
  netWorthStatementData.Debts = getCurrentPortfolioDataInNestedFormat(store.getState(), "Debt");
  const netWorth = netWorthStatementData.Assets.portfolioTotal - netWorthStatementData.Debts.portfolioTotal;

  const renderNetWorthStatement = () => {
    return Object.keys(netWorthStatementData).map((category, categoryIndex) => {
      const categoryData = netWorthStatementData[category];
      return (
        <CategoryContainer key={categoryIndex}>
          <CategoryName>{category}</CategoryName>
          {categoryData.sheets.length === 1 && categoryData.sheets[0].sections.length > 1 ? (
            <>
              {categoryData.sheets[0].sections.map((sectionData, index) => {
                return (
                  <div key={index}>
                    <SheetContainer key={index}>
                      <SheetName>{sectionData.section.name}</SheetName>
                    </SheetContainer>
                    <>
                      {sectionData.custodians.map((custodianData, custodianIndex) => {
                        const custodianValue = getCustodianValue(custodianData, category, portfolioTicker);
                        return (
                          <SectionContainer key={custodianIndex}>
                            <SectionName>{custodianData.name}</SectionName>
                            <TotalValue>
                              {Math.kuberaFloor(custodianValue).toString().length > 10
                                ? shortFormatNumberWithCurrency(
                                    Math.kuberaFloor(custodianValue),
                                    portfolioCurrency,
                                    true,
                                    true
                                  )
                                : formatNumberWithCurrency(
                                    Math.kuberaFloor(custodianValue),
                                    portfolioCurrency,
                                    true,
                                    0
                                  )}
                            </TotalValue>
                          </SectionContainer>
                        );
                      })}
                    </>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {categoryData.sheets.map((sheetData, sheetIndex) => {
                return (
                  <div key={sheetIndex}>
                    <SheetContainer key={sheetIndex}>
                      <SheetName>{sheetData.sheet.name}</SheetName>
                    </SheetContainer>
                    <>
                      {sheetData.sections.length > 1 ? (
                        <>
                          {sheetData.sections.map((sectionData, index) => {
                            return (
                              <SectionContainer key={index}>
                                <SectionName>{sectionData.section.name}</SectionName>
                                <TotalValue>
                                  {Math.kuberaFloor(sectionData.sectionTotal).toString().length > 10
                                    ? shortFormatNumberWithCurrency(
                                        Math.kuberaFloor(sectionData.sectionTotal),
                                        portfolioCurrency,
                                        true,
                                        true
                                      )
                                    : formatNumberWithCurrency(
                                        Math.kuberaFloor(sectionData.sectionTotal),
                                        portfolioCurrency,
                                        true,
                                        0
                                      )}
                                </TotalValue>
                              </SectionContainer>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {sheetData.sections &&
                            sheetData.sections[0] &&
                            sheetData.sections[0].custodians.map((custodianData, index) => {
                              const custodianValue = getCustodianValue(custodianData, category, portfolioTicker);
                              return (
                                <SectionContainer key={index}>
                                  <SectionName>{custodianData.name}</SectionName>
                                  <TotalValue>
                                    {Math.kuberaFloor(custodianValue).toString().length > 10
                                      ? shortFormatNumberWithCurrency(
                                          Math.kuberaFloor(custodianValue),
                                          portfolioCurrency,
                                          true,
                                          true
                                        )
                                      : formatNumberWithCurrency(
                                          Math.kuberaFloor(custodianValue),
                                          portfolioCurrency,
                                          true,
                                          0
                                        )}
                                  </TotalValue>
                                </SectionContainer>
                              );
                            })}
                        </>
                      )}
                    </>
                  </div>
                );
              })}
            </>
          )}
          <CategoryDivider />
          <CategoryTotalRow>
            <CategoryTotal>
              {formatNumberWithCurrency(
                Math.kuberaFloor(categoryData.portfolioTotal),
                portfolioCurrency,
                false,
                isCryptoCurrency(portfolioCurrency) ? 4 : undefined,
                true
              )}
            </CategoryTotal>
          </CategoryTotalRow>
        </CategoryContainer>
      );
    });
  };

  return (
    <Container id="networth-statement">
      <NetWorthStatementContainer>
        <UserName>{portfolioName}</UserName>
        <Title>Net Worth Statement</Title>
        <Date>{`As of ${date}`}</Date>
        {renderNetWorthStatement()}
        <CategoryContainer>
          <NetworthRow>
            <CategoryName>{"Net Worth"}</CategoryName>
            <CategoryTotal>
              {formatNumberWithCurrency(
                Math.kuberaFloor(netWorth),
                portfolioCurrency,
                false,
                isCryptoCurrency(portfolioCurrency) ? 4 : undefined,
                true
              )}
            </CategoryTotal>
          </NetworthRow>
          <NetWorthDivider />
          <NetWorthDivider />
        </CategoryContainer>
        <NetWorthStampComponent />
      </NetWorthStatementContainer>
      <PrintIconContainer
        title={i18n.t("print")}
        onClick={() => {
          printElement("networth-statement");
        }}
      >
        <PrintIcon />
      </PrintIconContainer>
    </Container>
  );
};

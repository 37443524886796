import React from "react";
import styled, { css } from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import { websiteUrls } from "routes";
import i18n from "i18next";
import headsUpDialogIcon from "assets/images/ladybug.svg";

const Dialog = styled(GenericMessageDialog)`
  width: 100%;
  height: fit-content;
  max-width: 627px;
  margin-top: 80px;
  box-sizing: border-box;

  button {
    display: none;
  }

  ol {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 22px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.linkColor};
  }
`;

const DescriptionStyle = css`
  width: 460px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  margin-top: 10px;
`;

const IconStyle = css`
  position: relative;
  top: 12px;
  right: 14px;
  width: 57px;
  height: 77px;
`;

class ContactUsDialog extends React.Component {
  render() {
    return (
      <Dialog
        className={this.props.className}
        title={i18n.t("contactUs")}
        icon={headsUpDialogIcon}
        iconStyle={IconStyle}
        description={i18n.t("contactUsDialog.description").replace("%s1%", websiteUrls.SECURITY)}
        descriptionStyle={DescriptionStyle}
        canUserDismiss={true}
        onOverlayDismissClick={this.props.onDismiss}
      />
    );
  }
}

export default ContactUsDialog;

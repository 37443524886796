import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import i18n from "locale/i18n";

import { ApiClient, currentPortfolioSelector, hasValidPortfolioSelector, isiOSSafari } from "@kubera/common";

import { downloadFile } from "utilities/FileUtils";
import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const LoaderOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-middle;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const LoaderMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto
  margin-top: 5px;
  background-color: ${props => props.theme.recapLoaderMessageBg};
  border: 1px solid ${props => props.theme.recapLoaderMessageBg};
  border-radius: 4px;
  width: 460px;
  min-height: 30px;
  padding: 5px;
  align-self: center;
  font-size: 12px;
  box-sizing: border-box;
`;

const PortfolioFilesDownloadComponent = props => {
  const portfolio = useSelector(currentPortfolioSelector);
  const hasValidPortfolio = useSelector(hasValidPortfolioSelector);

  const [isDownloadUrlFetching, setIsDownloadUrlFetching] = useState(true);
  const [showEmptyPortfolioDialog, setShowEmptyPortfolioDialog] = useState(false);

  const handleEmptyPortfolioDialogDismiss = () => {
    window.close();
  };

  useEffect(() => {
    if (hasValidPortfolio) {
      ApiClient.getPortfolioDownloadDataUrl(portfolio.id)
        .then(apiResponse => {
          downloadFile(apiResponse.payload.url, portfolio.name, "_self", () => {
            setIsDownloadUrlFetching(false);
          });
        })
        .catch(apiError => {
          throw apiError;
        });
    } else {
      setShowEmptyPortfolioDialog(true);
    }
  }, []);

  useEffect(() => {
    if (isDownloadUrlFetching === false && !isiOSSafari()) {
      setTimeout(() => {
        window.close();
      }, 1500);
    }
  }, [isDownloadUrlFetching]);

  if (showEmptyPortfolioDialog === true) {
    return (
      <ConfirmationDialog
        canUserDismiss={true}
        title={i18n.t("beneficiary.emptyPortfolioTitle")}
        description={i18n.t("beneficiary.emptyPortfolioDesc")}
        positiveButtonTitle={i18n.t("ok")}
        onDismiss={handleEmptyPortfolioDialogDismiss}
        handlePositiveButtonClick={handleEmptyPortfolioDialogDismiss}
      />
    );
  }

  if (isDownloadUrlFetching === true) {
    return (
      <LoaderOverlay>
        <RefreshLoader />
        <LoaderMessage>{i18n.t("download.loader.message")}</LoaderMessage>
      </LoaderOverlay>
    );
  }

  return (
    <LoaderOverlay>
      <LoaderMessage>{i18n.t("download.success.message")}</LoaderMessage>
    </LoaderOverlay>
  );
};
export default PortfolioFilesDownloadComponent;

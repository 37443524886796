import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  getTickerUsingId,
  searchPortfolioForText,
  currentPortfolioSelector,
  getTickerUsingShortName,
  getCustodianItems,
  getMonthAndYearFromDate,
  parseKuberaDateString,
  PVST_VALUE_TICKER_ID,
  sheetSectionsSelector,
  store
} from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { category } from "components/dashboard/DashboardComponentExports";
import CurrencyLabel from "components/labels/CurrencyLabel";
import SearchInput from "components/inputs/SearchInput";
import { ReactComponent as LinkedIcon } from "assets/images/linked_account_icon.svg";

const PickerDialog = styled(Dialog)`
  position: relative;
  width: 870px;
  min-height: 700px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px 60px 60px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 15.73px;
  letter-spacing: 0%;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Category = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: ${props => props.editable === true && "underline"};
  text-underline-offset: 4px;
  cursor: ${props => props.editable === true && "pointer"};
`;

const Description = styled.div`
  opacity: 0.6;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
`;

const SearchBox = styled(SearchInput)`
  height: 45px;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  line-height: 17px;
  background-color: #ffffff;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Result = styled.div`
  display: flex;
  padding: 12px 18px 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "#ffffff")};
  cursor: pointer;
`;

const EmptyResult = styled(Result)`
  cursor: auto;
  font-size: 14px;
  padding-left: 10px;
`;

const ResultDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ResultName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultMeta = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const LinkedCustodianIcon = styled(LinkedIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
`;

const ResultDescription = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultPath = styled.div`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ResultValue = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultValueOriginalCurrency = styled(CurrencyLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const NewCustodianHeader = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-left: 1px;
`;

const NewAssetRow = styled.div`
  background-color: white;
  height: 59px;
  padding-left: 17px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${props => (props.isFirst ? "5px" : "-1px")};
  cursor: pointer;
`;

const SuggestionsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const SuggestionTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0%;
  color: #00000099;
  white-space: pre-wrap;
`;

const SuggestionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  border: 1px solid #0000001a;
`;

const Suggestion = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  letter-spacing: 0%;
  padding: 12px 20px 12px 20px;
  border-top: ${props => (props.isFirst ? "none" : "1px solid #0000001a")};
  cursor: pointer;
`;

class CustodiansPickerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      searchResults: null,
      currentlySelectedResultIndex: null,
      selectedItems: this.getDefaultItems(),
      selectedCategory: props.category,
      suggestions: this.getSuggestions(props.category)
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResultInteraction = this.handleResultInteraction.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCategoryChangeClick = this.handleCategoryChangeClick.bind(this);
  }

  getSuggestions(category) {
    if (!this.props.showSuggestions === true) {
      return [];
    }

    let suggestions = [];
    for (const sheet of this.props.currentPortfolio.details.sheet) {
      if (sheet.category !== category) {
        continue;
      }
      suggestions.push({
        isSheet: true,
        ...sheet
      });

      const sections = sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, sheet.id);
      if (sections.length === 1) {
        continue;
      }
      for (const section of sections) {
        suggestions.push({
          isSection: true,
          sheet: sheet,
          ...section
        });
      }
    }
    return suggestions;
  }

  getDefaultItems() {
    if (!this.props.data === true || !this.props.data.items === true) {
      return [];
    }
    return getCustodianItems(this.props.data.items);
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      this.selectPreviousResult();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown") {
      this.selectNextResult();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      if (!this.state.searchResults || this.state.searchResults.length === 0) {
        if (!this.state.currentlySelectedResultIndex === false && !this.props.recentSearches === false) {
          this.handleRecentSearchResultClick(e, this.props.recentSearches[this.state.currentlySelectedResultIndex]);
        } else if (this.state.selectedItems?.length) {
          this.handleSaveClick(e);
        }
      } else if (this.state.currentlySelectedResultIndex !== null) {
        const result = this.state.searchResults[this.state.currentlySelectedResultIndex];
        this.handleResultInteraction(result);
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  handleSearchInputChange(searchText) {
    this.setState({ searchText: searchText });

    if (!this.searchTimer === false) {
      clearTimeout(this.searchTimer);
    }

    if (!searchText === true) {
      this.setState({ searchText: searchText, searchResults: null });
      return;
    } else {
      this.setState({ searchText: searchText });
    }

    this.searchTimer = setTimeout(() => {
      this.props.searchPortfolioForText(searchText, false, this.state.selectedCategory, true, data => {
        if (data.searchText !== searchText) {
          return;
        }
        let results =
          this.onlyCustodians() === true ? data.results.filter(item => item.isCustodian === true) : data.results;
        if (this.props.filterResults) {
          results = results.filter(item => this.props.filterResults(item));
        }
        this.setState({ searchResults: results });
      });
      this.searchTimer = null;
    }, 300);
  }

  handleResultInteraction(result) {
    if (!this.state.selectedItems.find(item => item.id === result.id) === true) {
      var selectedItems = this.props.allowMultiple === true ? this.state.selectedItems : [];
      selectedItems.push(result);
      this.setState({ selectedItems: selectedItems }, this.handleSaveClick);
    }

    this.handleSearchInputChange("");
  }

  handleSaveClick(e) {
    const items = this.state.selectedItems
      .filter(item => !!item)
      .map(item => {
        if (!this.props.returnResultWithDetails === false) {
          return item;
        }

        const entry = { id: item.id };
        if (item.isNewCustodian === true) {
          entry.isNewCustodian = true;
          entry.isCustodian = true;
        } else if (item.isCustodian === true) {
          entry.isCustodian = true;
          entry.sectionId = item.sectionId;
          entry.sheetId = item.sheet.id;
          entry.valueTickerId = item.valueTickerId;
        } else if (item.isSection === true) {
          entry.isSection = true;
          entry.sheetId = item.sheetId;
          entry.sectionId = item.id;
        } else if (item.isSheet === true) {
          entry.isSheet = true;
        }
        return entry;
      });

    this.props.onVariableUpdate({ ...(this.props.data || {}), items: items });
    this.props.onDismiss();
  }

  handleCategoryChangeClick(e) {
    if (this.props.allowCategoryChange === true) {
      const newCategory = this.state.selectedCategory === category.ASSET ? category.DEBT : category.ASSET;
      this.setState({
        selectedCategory: newCategory,
        suggestions: this.getSuggestions(newCategory)
      });
      this.handleSearchInputChange("");
    }
  }

  getResultPath(result) {
    if (result.isCustodian === true && result.sheet && result.section) {
      if (!result.parentId === false && result.hidden === 1 && result.parent) {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        } / ${result.parent.name}`;
      } else {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        }`;
      }
    } else if (result.isSection === true && result.sheet) {
      return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name}`;
    } else if (result.isSheet === true && result.category) {
      return `${this.getCategoryNameForCategory(result.category)}`;
    }
    return "";
  }

  getSuggestionPath(suggestion) {
    if (suggestion.isSection) {
      return `${suggestion.sheet.name} / ${suggestion.name}`;
    } else if (suggestion.isSheet) {
      return `${suggestion.name}`;
    }
    return "";
  }

  getCategoryNameForCategory(categoryName) {
    if (categoryName === category.ASSET) {
      return i18n.t("assets");
    } else if (categoryName === category.DEBT) {
      return i18n.t("debts");
    } else if (categoryName === category.INSURANCE) {
      return i18n.t("insurance");
    }
    return null;
  }

  selectNextResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;

    if (!this.state.searchResults === true) {
      if (currentIndex === this.props.recentSearches.length - 1) {
        return;
      }
    } else {
      if (currentIndex === this.state.searchResults.length - 1) {
        return;
      }
    }
    this.setState({ currentlySelectedResultIndex: currentIndex === null ? 0 : currentIndex + 1 });
  }

  selectPreviousResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;
    if (currentIndex === null || currentIndex === 0) {
      return;
    }
    this.setState({ currentlySelectedResultIndex: currentIndex - 1 });
  }

  selectResult(index) {
    this.setState({ currentlySelectedResultIndex: index });
  }

  onlyCustodians() {
    return (
      !this.props.data === false && !this.props.data.props === false && this.props.data.props.onlyCustodians === true
    );
  }

  render() {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <PickerDialog>
          <Container>
            {this.props.subTitle && <SubTitle>{this.props.subTitle}</SubTitle>}
            <Title>
              {this.onlyCustodians() === true ? (
                i18n
                  .t("custodiansOnlyPickerTitle")
                  .replace("%s1%", this.getCategoryNameForCategory(this.state.selectedCategory).slice(0, -1))
              ) : (
                <>
                  <span>{i18n.t("select")}</span>
                  <Category editable={this.props.allowCategoryChange} onClick={this.handleCategoryChangeClick}>
                    {this.getCategoryNameForCategory(this.state.selectedCategory).slice(0, -1)}
                  </Category>
                  <span>{i18n.t("custodiansPickerTitle")}</span>
                </>
              )}
            </Title>
            {this.props.assetDescription && this.props.debtDescription && (
              <Description>
                {this.state.selectedCategory === category.ASSET
                  ? this.props.assetDescription
                  : this.props.debtDescription}
              </Description>
            )}
            <SearchBox
              autoFocus={true}
              value={this.state.searchText}
              onChange={this.handleSearchInputChange}
              placeholder={(this.onlyCustodians() === true
                ? i18n.t("custodiansOnlySearchPlaceholder")
                : i18n.t("custodiansSearchPlaceholder")
              ).replace("%s1%", this.getCategoryNameForCategory(this.state.selectedCategory))}
            />
            {!this.state.searchResults === false && (
              <ResultsContainer>
                {this.state.searchResults.map((result, index) => {
                  return (
                    <div key={index}>
                      <Result
                        isFocused={this.state.currentlySelectedResultIndex === index}
                        onClick={e => this.handleResultInteraction(result)}
                        onMouseEnter={() => {
                          this.selectResult(index);
                        }}
                      >
                        <ResultDetailsContainer>
                          <ResultNameContainer>
                            <ResultName>{result.name}</ResultName>
                            {!result.linkType === false && <LinkedCustodianIcon />}
                            {result.isSection === true && <ResultMeta>{"(Section)"}</ResultMeta>}
                            {result.isSheet === true && <ResultMeta>{"(Sheet)"}</ResultMeta>}
                          </ResultNameContainer>
                          {!result.description === false && <ResultDescription>{result.description}</ResultDescription>}
                          <ResultPath>{this.getResultPath(result)}</ResultPath>
                        </ResultDetailsContainer>
                        <ResultValueContainer>
                          <ResultValue
                            currency={this.props.currentPortfolio.currency}
                            value={result.total}
                            abbreviate={false}
                            roundDown={true}
                          />
                          {result.isCustodian === true &&
                            portfolioTicker.id !== result.valueTickerId &&
                            result.valueTickerId !== PVST_VALUE_TICKER_ID && (
                              <ResultValueOriginalCurrency
                                currency={getTickerUsingId(result.valueTickerId).shortName}
                                value={result.value}
                                abbreviate={false}
                                roundDown={true}
                                showCurrencyAsSymbol={false}
                              />
                            )}
                        </ResultValueContainer>
                      </Result>
                    </div>
                  );
                })}
                {this.state.searchResults.length === 0 && <EmptyResult>{i18n.t("assetNotFound")}</EmptyResult>}
              </ResultsContainer>
            )}
            {!this.state.searchText && this.props.newCustodians && this.props.newCustodians.length > 0 && (
              <>
                <NewCustodianHeader>
                  {i18n.t(this.state.selectedCategory === category.ASSET ? "futureAssets" : "futureDebts")}
                </NewCustodianHeader>
                {this.props.newCustodians.map((result, index) => (
                  <div key={result.id}>
                    <NewAssetRow
                      onClick={e =>
                        this.handleResultInteraction({ id: result.id, isCustodian: true, isNewCustodian: true })
                      }
                      isFirst={index === 0}
                    >
                      <ResultDetailsContainer>
                        <ResultNameContainer>
                          <ResultName>{result.name}</ResultName>
                        </ResultNameContainer>
                        <ResultPath>{`${i18n.t("from")} ${getMonthAndYearFromDate(
                          parseKuberaDateString(result.date)
                        )}`}</ResultPath>
                      </ResultDetailsContainer>
                    </NewAssetRow>
                  </div>
                ))}
              </>
            )}
            {!this.state.searchResults === true && this.props.showSuggestions === true && (
              <SuggestionsContainer>
                <SuggestionTitle>
                  {i18n.t("custodiansPickerSuggestionsTitle").replace(
                    "%s1%",
                    this.getCategoryNameForCategory(this.state.selectedCategory)
                      .toLocaleLowerCase()
                      .slice(0, -1)
                  )}
                </SuggestionTitle>
                <SuggestionList>
                  {this.props.newPathSuggestions[this.state.selectedCategory].map((suggestion, index) => (
                    <Suggestion
                      key={index}
                      isFirst={index === 0}
                      onClick={e => this.handleResultInteraction(suggestion)}
                    >
                      {`${this.getSuggestionPath(suggestion)} (${i18n.t("new")})`}
                    </Suggestion>
                  ))}
                  {this.state.suggestions.map((suggestion, index) => (
                    <Suggestion
                      key={index}
                      isFirst={
                        index +
                          (
                            (this.props.newPathSuggestions &&
                              this.props.newPathSuggestions[this.state.selectedCategory]) ||
                            []
                          ).length ===
                        0
                      }
                      onClick={e => this.handleResultInteraction(suggestion)}
                    >
                      {this.getSuggestionPath(suggestion)}
                    </Suggestion>
                  ))}
                </SuggestionList>
              </SuggestionsContainer>
            )}
          </Container>
        </PickerDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  searchPortfolioForText: searchPortfolioForText
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodiansPickerDialog);

import React from "react";
import styled from "styled-components";
import RichTextArea from "components/inputs/RichTextArea";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InputTextArea = styled(RichTextArea)`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  padding: 35px 25px 35px 25px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }
`;

class CustodianNotesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
    this.timer = null;

    this.handleInputChange = this.handleInputChange.bind(this);
    addKeyboardEventListener(this.consumeEvent);
  }

  consumeEvent(event) {
    //consumes every keyboard event except Escape to prevent it from being consumed by GridComponent, which could trigger a shortcut
    return event.key !== "Escape";
  }

  handleInputChange(value) {
    this.setState({ ...this.state, value });

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
      this.timer = null;
    }, 300);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.consumeEvent);
  }

  render() {
    const value = this.state.value;

    return (
      <Container data-private className={`${this.props.className}`}>
        <InputTextArea
          data-cy="custodianNotesText"
          data-private
          readOnly={this.props.isReadOnly}
          value={value}
          placeholder={this.props.placeholder}
          onChange={this.handleInputChange}
        />
      </Container>
    );
  }
}

export default CustodianNotesComponent;

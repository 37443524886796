import React from "react";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { CHART_SETTINGS_TABS } from "./ChartSettingsDialogExports";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import Loader from "components/loader/Loader";
import NetWorthChartDatePreferencesTabComponent from "./NetWorthChartDatePreferencesTabComponent";
import SuggestedChartsComponent from "./SuggestedChartsTabComponent";
import AddedChartsTabComponent from "./AddedChartsTabComponent";

const PreferenceDialog = styled(Dialog)`
  height: 686px;
  width : 450px
  // max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: fit-content;
  margin-top: 100px;
  overflow : auto
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 50px 40px 50px;
`;

const PreferenceTabs = styled(Tabs)`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const PreferenceTabList = styled(TabList)`
  display: flex
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
  //margin-bottom : 20px;
`;

const PreferencesTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const PreferencesTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PreferencesTabLoader = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

class ChartSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (this.props.showModal === true && this.props.tabValue !== oldProps.tabValue) {
      switch (this.props.tabValue) {
        // case CHART_SETTINGS_TABS.NET_WORTH_CHART:
        //   this.setState({ ...this.state, tabIndex: 0 });
        //   break;
        case CHART_SETTINGS_TABS.SUGGESTED_CHARTS:
          this.setTab(1);
          break;
        // case CHART_SETTINGS_TABS.ADDED_CHARTS:
        //   this.setState({ ...this.state, tabIndex: 2 });
        //   break;
      }
    }
  }

  handleOverlayDismiss = () => {
    if (this.props.onDismiss) {
      this.setTab(0);
      this.props.onDismiss();
    }
  };
  setTab = tabIndex => {
    this.setState({ ...this.state, tabIndex });
  };

  getTabs = () => {
    const netWorthChartTabPanel = <NetWorthChartDatePreferencesTabComponent {...this.props} />;
    const suggestedChartsTabPanel = (
      <SuggestedChartsComponent
        dashboardChartIds={this.props.dashboardChartIds}
        handleSuggestedChartsTabCheckBoxChange={this.props.handleSuggestedChartsTabCheckBoxChange}
        handleResetToDefaultLink={this.props.handleResetToDefaultLink}
      />
    );
    const addedChartsTabPanel = (
      <AddedChartsTabComponent
        dashboardChartIds={this.props.dashboardChartIds}
        handleSuggestedChartsTabCheckBoxChange={this.props.handleSuggestedChartsTabCheckBoxChange}
      />
    );

    const tabs = [];
    tabs.push({
      title: "NET WORTH CHART",
      key: "a",
      panelComponent: netWorthChartTabPanel
    });
    tabs.push({
      title: "suggested charts",
      key: "b",
      panelComponent: suggestedChartsTabPanel
    });
    tabs.push({
      title: "charts you added",
      key: "c",
      panelComponent: addedChartsTabPanel
    });
    return tabs;
  };

  render() {
    if (this.props.showModal !== true) {
      return null;
    }

    const tabs = this.getTabs();
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <PreferenceDialog className={this.props.className}>
          <Container>
            <PreferenceTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={this.setTab}
            >
              <PreferenceTabList>
                {tabs.map((tab, index) => (
                  <PreferencesTab data-cy={"preferencesTab" + index} key={index}>
                    {tab.title}
                  </PreferencesTab>
                ))}
              </PreferenceTabList>
              {tabs.map((tab, index) => (
                <PreferencesTabPanel key={`preferencesTabPanel${index}`}>{tab.panelComponent}</PreferencesTabPanel>
              ))}
              {this.isTabsLoading && (
                <PreferencesTabLoader>
                  <Loader />
                </PreferencesTabLoader>
              )}
            </PreferenceTabs>
          </Container>
        </PreferenceDialog>
      </DialogOverlay>
    );
  }
}

export default ChartSettingsDialog;

import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CurrencyLabel from "components/labels/CurrencyLabel";
import CurrencyInput from "components/inputs/CurrencyInput";
import PrimaryButton from "components/button/PrimaryButton";
import { convertCurrency, getTickerUsingId, getTickerUsingShortName, planningVariables } from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import ExpandingTaxInput from "components/inputs/ExpandingTaxInput";
import { connect } from "react-redux";

const AmountDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const CurrentAmountTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const CurrentAmountValue = styled(CurrencyLabel)`
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const CurrentValueTickerOriginalCurrency = styled(CurrencyLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.4);
`;

const ExpectedAmountTitle = styled.div`
  margin-top: ${props => (props.hasCurrentValue === true ? "30px" : "0px")};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;
const DisabledText = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: "ss01" on;
  margin-top: 10px;
  background-color: #efefef;
  padding: 10px 13px;
  border: 1px solid rgba(0, 0, 0, 0.4);
`;

const AmountInput = styled(CurrencyInput)`
  margin-top: 10px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: ${props => (props.hasCurrentValue === true ? "43px" : "35px")};
`;

class ExpectedAmountDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expectedValue: props.data ? props.data.value : null,
      expectedTickerId: props.data ? props.data.tickerId : null,
      [planningVariables.TAX]: props.data ? props.data[planningVariables.TAX] : undefined,
      [planningVariables.TAX_DEDUCTION_AMOUNT]: props.data
        ? props.data[planningVariables.TAX_DEDUCTION_AMOUNT]
        : undefined,
      [planningVariables.TAX_DEDUCTION_RATE]: props.data ? props.data[planningVariables.TAX_DEDUCTION_RATE] : undefined
    };

    this.handleExpectedInputOnChange = this.handleExpectedInputOnChange.bind(this);
    this.updateTaxParams = this.updateTaxParams.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        this.handleSaveClick();
      }, 0);
    }
  }

  handleExpectedInputOnChange(params) {
    this.setState({ expectedValue: params.value, expectedTickerId: params.tickerId });
  }

  updateTaxParams(params) {
    this.setState({ ...params });
  }

  handleSaveClick(e) {
    this.props.onVariableUpdate({
      value: this.state.expectedValue,
      tickerId: this.state.expectedTickerId,
      [planningVariables.TAX]: this.state[planningVariables.TAX],
      [planningVariables.TAX_DEDUCTION_AMOUNT]: this.state[planningVariables.TAX_DEDUCTION_AMOUNT],
      [planningVariables.TAX_DEDUCTION_RATE]: this.state[planningVariables.TAX_DEDUCTION_RATE]
    });
    this.props.onDismiss();
  }

  getCurrentValue() {
    if (!this.props.currentValue === true && this.props.currentValue !== 0) {
      return null;
    }
    return this.props.currentValue;
  }

  getCurrentValueInOriginalCurrency() {
    if (
      !this.props.currentValueTickerId === true ||
      this.props.portfolioCurrency === getTickerUsingShortName(this.props.portfolioCurrency).currency
    ) {
      return null;
    }

    const currentValueTicker = getTickerUsingId(this.props.currentValueTickerId);
    return convertCurrency(1, currentValueTicker.shortName, currentValueTicker.currency);
  }

  render() {
    const currentValueInOriginalCurrency = this.getCurrentValueInOriginalCurrency();

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <AmountDialog>
          <Container>
            {this.props.hasCurrentValue === true && (
              <div>
                <CurrentAmountTitle>{`${i18n.t("current")} ${
                  this.props.isPrice ? i18n.t("price") : i18n.t("value")
                }`}</CurrentAmountTitle>
                <CurrentAmountValue value={this.getCurrentValue()} currency={this.props.portfolioCurrency} />

                {!currentValueInOriginalCurrency === false && (
                  <CurrentValueTickerOriginalCurrency
                    value={currentValueInOriginalCurrency}
                    currency={getTickerUsingId(this.props.tickerIdObj.id).currency}
                    showCurrencyAsSymbol={false}
                  />
                )}
              </div>
            )}

            <ExpectedAmountTitle hasCurrentValue={this.props.hasCurrentValue}>
              {this.props.hasCurrentValue === true
                ? i18n.t("expected") + ` ${this.props.isPrice ? i18n.t("price") : i18n.t("value")}`
                : this.props.title || i18n.t("amount")}
            </ExpectedAmountTitle>
            {this.props.isAutoAmount ? (
              <DisabledText>As needed to cover expenses</DisabledText>
            ) : (
              <AmountInput
                portfolioCurrency={this.props.portfolioCurrency}
                value={this.state.expectedValue}
                tickerId={this.state.expectedTickerId}
                placeholder={i18n.t("amount")}
                inputStyle={{ display: "flex", flex: 1, height: "45px", border: "1px solid rgba(0, 0, 0, 0.4);" }}
                hideExchangeRateBubble={true}
                onChange={this.handleExpectedInputOnChange}
                autoFocus={true}
                isInSingleCellMode={true}
              />
            )}
            <ExpandingTaxInput
              tax={this.state[planningVariables.TAX]}
              taxDeductionAmount={this.state[planningVariables.TAX_DEDUCTION_AMOUNT]}
              taxDeductionRate={this.state[planningVariables.TAX_DEDUCTION_RATE]}
              defaultVar={planningVariables.TAX_DEDUCTION_AMOUNT}
              updateParams={this.updateTaxParams}
              portfolioCurrency={this.props.portfolioCurrency}
              tickerId={this.state.expectedTickerId}
              onGainTitle={this.props.onGainTitle}
              deductionDescriptionNoun={this.props.deductionDescriptionNoun}
            />
            <SaveButton
              hasCurrentValue={this.props.hasCurrentValue}
              title={i18n.t("save")}
              onClick={this.handleSaveClick}
            />
          </Container>
        </AmountDialog>
      </DialogOverlay>
    );
  }
}

export default ExpectedAmountDialog;

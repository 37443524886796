import React from "react";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import i18n from "i18next";
import {
  unmarkCustodianAsLinking,
  custodianLinkingFailed,
  custodianLinkingAccountsDataFetched,
  showToastTip,
  linkAccountsWithCustodian,
  reauthCustodian,
  dismissToastAction,
  markCustodianAsLinking,
  custodianLinkingProviderChange,
  currentPortfolioSelector,
  store,
  portfolioSelector,
  custodianLinkingScreenClosed,
  deleteProviderAccount,
  utilityStatus,
  accountLinkingService,
  getAccountLinkingService,
  insertTickerCustodianInSection,
  custodianSelector,
  expandHoldingsForCustodian,
  reconnectAccounts,
  addTickerInfoAction,
  fetchUser,
  setLinkingProviderStatus,
  editAccountsInExistingConnection,
  getConnectivityCenterData,
  updateShowLoaderStatusForAConnection
} from "@kubera/common";
import LinkAccountCommonExports from "components/link_account/LinkAccountCommonExports";
import LinkAccountComponentExports, { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import * as Sentry from "@sentry/browser";
import DiscountPopup from "components/popups/DiscountPopupComponent";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import AiDocumentImportComponent from "./AiDocumentImportComponent";

export const linkAccountMessageSource = "link_account";

export const linkAccountMessageAction = {
  EXIT: "EXIT",
  PROVIDER_PAGE_SUCCESS: "PROVIDER_PAGE_SUCCESS",
  PROVIDER_PAGE_FAILURE: "PROVIDER_PAGE_FAILURE",
  ACCOUNTS_SELECTED: "ACCOUNTS_SELECTED",
  REFRESH_CUSTODIAN: "REFRESH_CUSTODIAN",
  RETRY: "RETRY",
  PAGE_RELOAD: "PAGE_RELOAD",
  PROVIDER_CHANGE: "PROVIDER_CHANGE",
  SENTRY_EVENT: "SENTRY_EVENT",
  MANUAL_ENTRY: "MANUAL_ENTRY",
  RECONNECT_ACCOUNTS: "RECONNECT_ACCOUNTS",
  ADD_TICKERS: "ADD_TICKERS",
  SET_PROVIDER_STATUS: "SET_PROVIDER_STATUS",
  OPEN_AI_IMPORT: "OPEN_AI_IMPORT"
};

class LinkAccountProviderPageListener extends React.Component {
  static sendMessage = (receiverWindow, providerPageDetails, action, payload) => {
    receiverWindow.postMessage(
      {
        source: linkAccountMessageSource,
        action: action,
        providerPageDetails: providerPageDetails,
        payload: payload
      },
      receiverWindow.origin
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      discountAccount: null,
      zeroValueAccount: false
    };

    this.handleMessageEvent = this.handleMessageEvent.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("message", this.handleMessageEvent);
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    console.log("componentWillUnmount");

    window.removeEventListener("message", this.handleMessageEvent);
  }

  releaseCustodianFromLinkingFlow(portfolioId, custodian, providerPageDetails, ignoreProviderName = false) {
    this.props.unmarkCustodianAsLinking(
      portfolioId,
      custodian,
      ignoreProviderName ? null : this.getProviderName(providerPageDetails),
      providerPageDetails.category
    );
  }

  handleUtilityStatus(event, pageDetails) {
    if (pageDetails.linkingService === accountLinkingService.YODLEE) {
      this.props.utilityStatus({
        action: event.data.action,
        errorMessage: event.data.errorMessage,
        ...(event.data.providerPageDetails && event.data.providerPageDetails.selectedProviderDetails),
        linkType: getAccountLinkingService(pageDetails.linkingService),
        ...event.data.payload
      });
    }
  }

  async handleMessageEvent(event) {
    if (
      event.origin !== window.location.origin ||
      event.data.source !== linkAccountMessageSource ||
      !event.data === true
    ) {
      return;
    }

    let stringifiedData = null;
    try {
      stringifiedData = JSON.stringify(event.data);
      console.log(stringifiedData);
    } catch (error) {
      console.log("Error when stringifying event data");
    }

    const pageDetails = event.data.providerPageDetails;
    if (!pageDetails === true) {
      return;
    }

    switch (event.data.action) {
      case linkAccountMessageAction.EXIT:
        this.handlePageExit(pageDetails, event.data.payload);
        break;
      case linkAccountMessageAction.PROVIDER_PAGE_FAILURE:
        this.handlePageFailure(pageDetails, event.data.payload);
        this.handleUtilityStatus(event, pageDetails);
        break;
      case linkAccountMessageAction.PROVIDER_PAGE_SUCCESS:
        this.handlePageSuccess(pageDetails, event.data.payload);
        this.handleUtilityStatus(event, pageDetails);
        break;
      case linkAccountMessageAction.ACCOUNTS_SELECTED:
        this.handleUtilityStatus(event, pageDetails);
        const isDiscountApplied = this.handleLinkComponentDiscounts(
          event.data.payload.selectedAccounts,
          stringifiedData
        );
        this.handleZeroValueAccount(event.data.payload.selectedAccounts);
        await this.handleSelectAccounts(pageDetails, event.data.payload);

        if (isDiscountApplied) {
          this.props.fetchUser();
        }
        break;
      case linkAccountMessageAction.REFRESH_CUSTODIAN:
        this.handleCustodianRefresh(pageDetails);
        break;
      case linkAccountMessageAction.RETRY:
        this.handleRetry(pageDetails);
        break;
      case linkAccountMessageAction.PAGE_RELOAD:
        this.handlePageReload(pageDetails);
        break;
      case linkAccountMessageAction.PROVIDER_CHANGE:
        this.handleProviderChange(pageDetails, event.data.payload);
        break;
      case linkAccountMessageAction.SENTRY_EVENT:
        this.handleSentryEvent(pageDetails, event.data.payload.eventName, event.data.payload.eventProperties);
        break;
      case linkAccountMessageAction.MANUAL_ENTRY:
        this.handleManualEntry(
          pageDetails,
          event.data.payload.name,
          event.data.payload.value,
          event.data.payload.ticker,
          event.data.payload.tickerInput
        );
        break;
      case linkAccountMessageAction.RECONNECT_ACCOUNTS:
        this.handleReconnectAccounts(
          pageDetails,
          event.data.payload.existingCustodians,
          event.data.payload.incomingAccounts,
          event.data.payload.linkingAccountsData
        );
        break;
      case linkAccountMessageAction.ADD_TICKERS:
        this.handleAddTickers(pageDetails, event.data.payload);
        break;
      case linkAccountMessageAction.SET_PROVIDER_STATUS:
        this.handleSetProviderStatus(pageDetails, event.data.payload);
        break;
      case linkAccountMessageAction.OPEN_AI_IMPORT:
        AiDocumentImportComponent.show(this.props.history, this.props.location, pageDetails.custodian.id);
      default:
        break;
    }
  }

  handleAddTickers(providerPageDetails, newTickers) {
    for (var tickerInfo of newTickers) {
      this.props.addTickerInfoAction(tickerInfo.info, tickerInfo.rate);
    }
  }

  handleSetProviderStatus(providerPageDetails, statusDetails) {
    this.props.setLinkingProviderStatus(
      statusDetails.linkType,
      statusDetails.groupId,
      statusDetails.providerId,
      statusDetails.status
    );
  }

  handlePageExit(providerPageDetails, pageState) {
    if (pageState.linkingFailed === true || pageState.accountsFetched === false) {
      this.releaseCustodianFromLinkingFlow(
        providerPageDetails.portfolioId,
        providerPageDetails.custodian,
        providerPageDetails
      );
      this.props.updateShowLoaderStatusForAConnection(
        { custodianId: providerPageDetails.custodian.id },
        providerPageDetails.portfolioId,
        false
      );
    }
    if (pageState.linkingCompleted === false) {
      this.props.dismissToastAction();
      this.props.custodianLinkingScreenClosed(providerPageDetails.portfolioId, providerPageDetails.custodian);

      if (!pageState.connectionId === false) {
        this.props.deleteProviderAccount(providerPageDetails.linkingService, pageState.connectionId);
      }
    }

    if (pageState.noAccountsAvailableToLink === true) {
      this.props.dismissToastAction();
    }

    if (pageState.openSearch === true) {
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        providerPageDetails.custodian.id
      );
    }
  }

  getProviderName(providerPageDetails) {
    if (!providerPageDetails.selectedProvider === true) {
      return providerPageDetails.custodian.name || providerPageDetails.category === "Asset"
        ? i18n.t("linkAccount.asset.defaultProviderName")
        : i18n.t("linkAccount.debt.defaultProviderName");
    }
    return providerPageDetails.selectedProvider.name;
  }

  handlePageFailure(providerPageDetails, error) {
    this.props.custodianLinkingFailed(providerPageDetails.portfolioId, providerPageDetails.custodian);

    if (!providerPageDetails.inline === true) {
      this.props.showToastTip(
        "TIP",
        i18n.t("linkAccount.linkingAttentionNeededToast").replace("%s1%", this.getProviderName(providerPageDetails)),
        null,
        -1,
        i18n.t("view"),
        () => {
          LinkAccountCommonExports.reopen(providerPageDetails.custodian.id);
        },
        null,
        true,
        true
      );
    }
  }

  handlePageSuccess(providerPageDetails, linkingAccountsData) {
    const providerName = this.getProviderName(providerPageDetails);
    this.props.custodianLinkingAccountsDataFetched(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian,
      linkingAccountsData,
      providerName,
      providerPageDetails.category
    );
    if (providerPageDetails.inline === false) {
      this.props.showToastTip(
        "TIP",
        i18n.t("linkAccount.linkingSelectAccounts").replace("%s1%", this.getProviderName(providerPageDetails)),
        null,
        -1,
        i18n.t("selectAccounts"),
        () => {
          LinkAccountComponentExports.selectAccounts(
            this.props.history,
            this.props.location,
            providerPageDetails.custodian.id,
            providerPageDetails.category
          );
        },
        null,
        true,
        true,
        false
      );
    }
  }

  handleSelectAccounts(providerPageDetails, linkingAccountsData) {
    return new Promise(resolve => {
      this.props.dismissToastAction();
      var linkWithCustodian = providerPageDetails.custodian;
      var linkCompletionCallback = () => {
        resolve();
      };
      const onCompletion = () => {
        LinkAccountCommonExports.close(providerPageDetails.custodian.id);
        resolve();
      };

      if (providerPageDetails.mode === linkAccountMode.LINK) {
        if (linkingAccountsData.shouldAutoExpand === true) {
          linkCompletionCallback = linkedCustodian => {
            if (linkedCustodian.holdingsCount === 0) {
              onCompletion();
              return;
            }

            this.props.expandHoldingsForCustodian(
              linkedCustodian.id,
              holdingsSectionId => {
                document.scrollToElement(holdingsSectionId);
                onCompletion();
              },
              apiError => {
                onCompletion();
              },
              true,
              linkingAccountsData.providerName
            );
          };
        }
      } else if (!linkWithCustodian.parentId === false) {
        linkWithCustodian = custodianSelector(store.getState(), linkWithCustodian.parentId);

        linkCompletionCallback = linkedCustodian => {
          this.props.reauthCustodian(
            linkWithCustodian.id,
            () => {
              resolve();
            },
            () => {}
          );
        };
      }

      if (providerPageDetails.mode === linkAccountMode.SELECT_ACCOUNTS) {
        this.props.editAccountsInExistingConnection(
          providerPageDetails.portfolioId,
          linkingAccountsData.category,
          linkWithCustodian,
          linkingAccountsData.linkingService,
          linkingAccountsData.providerId,
          linkingAccountsData.providerName,
          linkingAccountsData.selectedAccounts
        );
      } else {
        this.props.linkAccountsWithCustodian(
          providerPageDetails.portfolioId,
          linkingAccountsData.category,
          linkingAccountsData.selectedAccounts,
          linkingAccountsData.accounts,
          linkWithCustodian,
          linkingAccountsData.linkingService,
          linkingAccountsData.providerId,
          linkingAccountsData.providerName,
          linkingAccountsData.isRelinkFlow,
          linkedCustodian => {
            linkCompletionCallback(linkedCustodian);
            this.props.getConnectivityCenterData();
          },
          providerPageDetails.mode !== linkAccountMode.MANAGE,
          true,
          true
        );
      }

      const linkedElem = document.getElementById(providerPageDetails.custodian.id);
      if (linkedElem) {
        linkedElem.scrollIntoView({ block: "center" });
      }

      if (currentPortfolioSelector(store.getState()).id !== providerPageDetails.portfolioId) {
        const linkingPortfolio = portfolioSelector(store.getState(), providerPageDetails.portfolioId);

        const message = i18n
          .t("linkAccount.accountsAddedInDifferentProtfolio")
          .replace("%s1%", providerPageDetails.selectedProvider.name)
          .replace("%s2%", linkingPortfolio.name);
        this.props.showToastTip(
          "TIP",
          message,
          null,
          -1,
          i18n.t("view"),
          () => {
            DashboardComponentExports.showPortfolio(this.props.history, this.props.location, linkingPortfolio.id);
          },
          null,
          true,
          true
        );
      }
    });
  }

  handleCustodianRefresh(providerPageDetails) {
    this.props.reauthCustodian(providerPageDetails.custodian.id, () => {}, apiError => {});
  }

  handleRetry(providerPageDetails) {
    const providerName = this.getProviderName(providerPageDetails);
    this.props.markCustodianAsLinking(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian,
      providerName,
      providerPageDetails.category
    );
    this.props.dismissToastAction();
  }

  handlePageReload(providerPageDetails) {
    if (providerPageDetails.mode === linkAccountMode.LINK) {
      const providerName = this.getProviderName(providerPageDetails);
      this.props.markCustodianAsLinking(
        providerPageDetails.portfolioId,
        providerPageDetails.custodian,
        false,
        providerName,
        providerPageDetails.category
      );
    }
  }

  handleProviderChange(providerPageDetails, payload) {
    this.props.custodianLinkingProviderChange(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian,
      providerPageDetails.selectedProvider.name,
      payload.previousProviderName,
      providerPageDetails.category
    );
  }

  handleSentryEvent(providerPageDetails, eventName, eventProperties) {
    Sentry.withScope(scope => {
      const extras = {
        ...eventProperties,
        linkType: providerPageDetails.linkingService
      };

      scope.setExtras(extras);
      Sentry.captureMessage(eventName);
    });
  }

  handleManualEntry(providerPageDetails, name, value, ticker, tickerInput) {
    this.releaseCustodianFromLinkingFlow(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian,
      providerPageDetails
    );
    this.props.insertTickerCustodianInSection(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian.sectionId,
      providerPageDetails.custodian.id,
      { name: name, value: value, valueTickerId: !ticker === true ? null : ticker.id },
      null,
      null,
      tickerInput
    );
  }

  handleReconnectAccounts(providerPageDetails, existingCustodians, incomingAccounts, linkingAccountsData) {
    this.props.dismissToastAction();
    this.releaseCustodianFromLinkingFlow(
      providerPageDetails.portfolioId,
      providerPageDetails.custodian,
      providerPageDetails,
      true
    );

    var linkWithCustodian = providerPageDetails.custodian;
    if (!linkWithCustodian.parentId === false) {
      linkWithCustodian = custodianSelector(store.getState(), linkWithCustodian.parentId);
    }

    this.props.reconnectAccounts(
      providerPageDetails.portfolioId,
      linkingAccountsData.category,
      linkWithCustodian,
      linkingAccountsData.linkingService,
      linkingAccountsData.providerId,
      linkingAccountsData.providerName,
      existingCustodians,
      incomingAccounts
    );
  }

  handleDiscountDismiss = () => {
    this.setState({
      discountAccount: null
    });
  };

  handleZeroValueDismiss = () => {
    this.setState({
      zeroValueAccount: false
    });
  };

  handleLinkComponentDiscounts(accounts, stringifiedData) {
    if (!/['|"]discount['|"]/.test(stringifiedData)) {
      return;
    }

    let isDiscountApplied = false;
    for (var i = accounts.length - 1; i >= 0; i--) {
      if (accounts[i].discount) {
        isDiscountApplied = true;
        this.setState({
          discountAccount: accounts[i]
        });

        break;
      }
    }

    return isDiscountApplied;
  }

  handleZeroValueAccount(accounts) {
    let total = 0;
    let isCryptoWallet = false;
    for (var i = accounts.length - 1; i >= 0; i--) {
      if (accounts[i].container === "wallet") {
        isCryptoWallet = true;
        // below test ether adresss is hardcoded for test, dialog will always be displayed for below ether address, whenever added
        if (accounts[i].accountNumber === "0x74e5996a87a587987e7347d154c568a92a0b1f19") {
          total = 0;
          break;
        }
      }

      if (accounts[i].balance) {
        total = total + accounts[i].balance;
      }
    }
    if (total === 0 && isCryptoWallet) {
      this.setState({
        zeroValueAccount: true
      });
    }
  }

  render() {
    const { discountAccount } = this.state;
    const { zeroValueAccount } = this.state;
    if (discountAccount) {
      return <DiscountPopup onDismiss={this.handleDiscountDismiss} discountAccount={discountAccount} />;
    } else if (zeroValueAccount) {
      return (
        <GenericMessageDialog
          title={i18n.t("cryptoZeroWalletPopup.title")}
          description={i18n.t("cryptoZeroWalletPopup.desc")}
          positiveButtonTitle={i18n.t("cryptoZeroWalletPopup.positiveBtn")}
          onPositiveButtonClick={this.handleZeroValueDismiss}
          dialogWidth={"800px"}
          canUserDismiss={false}
        />
      );
    }

    return null;
  }
}

const mapDispatchToProps = {
  markCustodianAsLinking: markCustodianAsLinking,
  unmarkCustodianAsLinking: unmarkCustodianAsLinking,
  custodianLinkingFailed: custodianLinkingFailed,
  custodianLinkingAccountsDataFetched: custodianLinkingAccountsDataFetched,
  showToastTip: showToastTip,
  linkAccountsWithCustodian: linkAccountsWithCustodian,
  reauthCustodian: reauthCustodian,
  dismissToastAction: dismissToastAction,
  custodianLinkingProviderChange: custodianLinkingProviderChange,
  custodianLinkingScreenClosed: custodianLinkingScreenClosed,
  deleteProviderAccount: deleteProviderAccount,
  utilityStatus,
  insertTickerCustodianInSection: insertTickerCustodianInSection,
  expandHoldingsForCustodian: expandHoldingsForCustodian,
  reconnectAccounts: reconnectAccounts,
  addTickerInfoAction: addTickerInfoAction,
  fetchUser: fetchUser,
  setLinkingProviderStatus: setLinkingProviderStatus,
  editAccountsInExistingConnection: editAccountsInExistingConnection,
  getConnectivityCenterData: getConnectivityCenterData,
  updateShowLoaderStatusForAConnection: updateShowLoaderStatusForAConnection
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LinkAccountProviderPageListener));

import React from "react";
import styled, { css } from "styled-components";
import ContextMenu from "components/contextmenu/ContextMenu";
import GridCell from "components/grid/GridCell";
import { GridCellData, cellType } from "components/grid/GridDataModel";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import dragGrabBarIcon from "assets/images/drag_drop_grabbar.svg";
import dragGrabBarIconDark from "assets/images/drag_drop_grabbar_dark.svg";
import { convertCurrency, getTickerDescription, getTickerUsingShortName } from "@kubera/common";
import { addContextMenuEventListener, removeContextMenuEventListener } from "utilities/EventManager";
import defaultTheme from "theme";

const TICKER_CONTEXT_MENU_ITEM_ID_PREFIX = "select_ticker_";

const Entry = styled.div`
  ${props => {
    const rowStyle = props.rowStyle;
    if (rowStyle && rowStyle.isSeparateStyle) {
      return css`
        ${props.rowStyle}
      `;
    }
    return css`
      display: ${props => (props.isVisible ? "flex" : "none")};
      height: 39px;
      border-top: ${props => (props.hideTopBorder ? 0 : "1px solid " + props.theme.gridSectionBorderColor)};
      border: ${props => (props.isDragging === true ? `1px solid ${props => props.theme.gridSectionBorderColor}` : "")};
      ${rowStyle}
    `;
  }}

  ${props =>
    props.rowStyle
      ? css`
          @media print {
            border-color: ${defaultTheme.gridSectionBorderColor} !important;
          }
        `
      : css`
          @media print {
            height: 28px !important;
            border-top: ${props => (props.hideTopBorder ? 0 : `1px solid ${defaultTheme.gridSectionBorderColor}`)};
            border: ${props => (props.isDragging === true ? `1px solid ${defaultTheme.gridSectionBorderColor}` : "")};
          }
        `}
`;

const Container = styled.div`
  ${props => {
    if (props.rowStyle) {
      return css`
        display: flex;
        flex: 1;
        background-color: ${props => props.rowStyle.backgroundColor || props.theme.gridRowUpdatedBackgroundColor};
      `;
    } else {
      return css`
        display: flex;
        position: relative;
        flex: 1;
        background-color: ${props =>
          props.isUpdated === true
            ? props.theme.gridRowUpdatedBackgroundColor
            : props.theme.gridRowUnUpdatedBackgroundColor};

        @media print {
          background-color: #ffffff !important;
        }
      `;
    }
  }}
`;

const DragGrabBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 23px;
  height: 100%;
  bottom: 0;
  z-index: 100;
  background-color: transparent;
  background-image: url(${props => (props.theme.mode === "default" ? dragGrabBarIcon : dragGrabBarIconDark)});
  background-repeat: no-repeat;
  background-position: 6px center;
  background-size: 6px 10px;
  visibility: hidden;

  ${Container}:hover & {
    visibility: ${props => (props.canShow === true ? "visible" : "hidden")};
  }
`;

class GridRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleCellUpdate = this.handleCellUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleUserLeavingCellEmpty = this.handleUserLeavingCellEmpty.bind(this);
    this.handleLinkErrorClick = this.handleLinkErrorClick.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleShowTickerSelection = this.handleShowTickerSelection.bind(this);
    this.handleHideTickerSelection = this.handleHideTickerSelection.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.state = {
      mode: "select",
      showHint: false,
      isInitialEdit: this.props.entry.isEmpty()
    };

    this.containerRef = React.createRef();
    this.contextMenuRef = React.createRef();
  }

  componentDidMount() {
    this.isComponentMounted = true;
    addContextMenuEventListener(this.handleRightClick);
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    removeContextMenuEventListener(this.handleRightClick);
  }

  handleRightClick(e) {
    if (!this.containerRef) {
      return;
    }
    if (this.containerRef.current.contains(e.target) === false) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    if (this.props.entry.getContextMenuItems) {
      const contextMenuItems = this.props.entry.getContextMenuItems(this.props.entry, this.props.entryIndex);
      if (contextMenuItems && contextMenuItems.length > 0) {
        this.contextMenuRef.current.show(contextMenuItems, e.clientX, e.clientY, true, null);
      }
    }
    e.preventDefault();
  }

  handleLinkErrorClick(e) {
    let proceedErrorClick = false;
    if (this.props.onLinkErrorClick) {
      proceedErrorClick = this.props.onLinkErrorClick(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex,
        this.props.entry
      );
    }
    if (!proceedErrorClick || this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      this.props.entry.getContextMenuItems(this.props.entry, this.props.entryIndex),
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
  }

  async handleContextMenuSelection(item) {
    if (this.props.onRowContextMenuSelection) {
      const promise = this.props.onRowContextMenuSelection(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex,
        this.props.entry,
        item
      );

      if (promise && typeof promise.then === "function") {
        const isConfirm = await promise.catch(err => null);
        if (!isConfirm) {
          return;
        }
      }
    }

    // If user is trying to link account to the row
    // don't remove the row if its empty and user clicks
    // outside which he will have to in order to link account.
    if (item.id === contextMenuItemType.LINK.id || item.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      this.props.entry.removeIfEmpty = false;
    }

    if (
      item.id === contextMenuItemType.ARCHIVE.id ||
      item.id === contextMenuItemType.UNARCHIVE.id ||
      item.id === contextMenuItemType.DELETE.id ||
      item.id === contextMenuItemType.REMOVE.id ||
      item.id === contextMenuItemType.DELETE_PERMANENTLY.id
    ) {
      this.props.onRemoveRow(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex);
    } else if (item.id === contextMenuItemType.INSERT_BELOW.id) {
      this.props.onAddNewRow(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex + 1,
        undefined,
        true,
        true
      );
    } else if (item.id === contextMenuItemType.INSERT_ABOVE.id) {
      this.props.onAddNewRow(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex === 0 ? 0 : this.props.entryIndex,
        undefined,
        true,
        true
      );
    } else if (item.id === contextMenuItemType.MARK_UPDATED.id) {
      const newEntry = this.props.entry;
      newEntry.isUpdated = true;
      this.props.onRowUpdate(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, newEntry, false);
    } else if (item.id === contextMenuItemType.MARK_UNUPDATED.id) {
      const newEntry = this.props.entry;
      newEntry.isUpdated = false;
      this.props.onRowUpdate(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, newEntry, false);
    } else if (item.id && item.id.startsWith(TICKER_CONTEXT_MENU_ITEM_ID_PREFIX)) {
      const cellIndex = item.cellIndex;
      const tickerData = item.tickerData;
      const ticker = tickerData.info;
      const newCell = this.props.entry.cells[cellIndex];
      const supportedTickerTypes = newCell.supportedTickerTypes;
      const wasRowEmpty = this.props.entry.isEmpty();

      newCell.invalidInputText = null;
      newCell.tickerInfo = null;
      if (supportedTickerTypes.includes(ticker.type)) {
        newCell.currency = ticker.shortName;
        newCell.setExchangeRateDetails(this.props.currency);
      } else {
        newCell.value = convertCurrency(newCell.value, ticker.shortName, this.props.currency);
        newCell.currency = this.props.currency;
        newCell.setExchangeRateDetails(this.props.currency);
        this.handleCellInvalidTickerAdded(cellIndex);
      }
      this.handleCellUpdate(cellIndex, newCell, wasRowEmpty);
    }
  }

  handleCellUpdate(cellIndex, newCell, wasRowEmpty) {
    const isFirstEdit = wasRowEmpty && !this.props.entry.tsModified === true;

    if (cellIndex === this.props.columnIndexForCost || cellIndex === this.props.columnIndexForValue) {
      this.props.entry.isUpdated = true;
    }

    if (cellIndex === this.props.columnIndexForValue) {
      if (!newCell.currency === false) {
        this.props.entry.valueTickerId = getTickerUsingShortName(newCell.currency).id;
      } else {
        this.props.entry.valueTickerId = null;
      }
    }

    if (!this.props.entry.cells[cellIndex].invalidInputText === true) {
      const tickerErrorCellIndex = this.props.entry.cells.findIndex(cell => cell.type === cellType.TICKER_ERROR);
      if (tickerErrorCellIndex) {
        if (this.props.entry.cells[tickerErrorCellIndex]?.isCashflowInvalid) {
          const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
          this.props.entry.cells[tickerErrorCellIndex] = optionsCell;
        } else {
          const detailsCell = new GridCellData(cellType.DETAILS, "", null);
          detailsCell.showHint = false;
          this.props.entry.cells[tickerErrorCellIndex] = detailsCell;
        }
      }
    }

    if (this.state.showHint === true && cellIndex === this.props.entry.cellIndexToStretch && !newCell.value === false) {
      this.setState({ ...this.state, showHint: false });
    }

    this.props.entry.cells[cellIndex] = newCell;

    this.props.onRowUpdate(
      this.props.sheetIndex,
      this.props.sectionIndex,
      this.props.entryIndex,
      this.props.entry,
      isFirstEdit
    );
  }

  handleAddNewRow(addBelow, removeIfEmpty) {
    this.props.onAddNewRow(
      this.props.sheetIndex,
      this.props.sectionIndex,
      addBelow === true ? this.props.entryIndex + 1 : this.props.entryIndex,
      undefined,
      removeIfEmpty,
      true
    );
  }

  handleRemoveRow() {
    this.props.onRemoveRow(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex);
  }

  handleBlur(e, cellIndex) {
    setTimeout(() => {
      if (this.isRowFocused() === false) {
        this.handleRowFocusLoss();
      }
    }, 0);

    if (this.props.onCellBlur) {
      this.props.onCellBlur(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, cellIndex);
    }
  }

  isRowFocused() {
    if (this.containerRef.current === null) {
      return false;
    }
    return this.containerRef.current.contains(document.activeElement);
  }

  handleRowFocusLoss() {
    if (this.isComponentMounted === false) {
      return;
    }
    this.setState({ ...this.state, isInitialEdit: this.props.entry.isEmpty(), showHint: false });
  }

  handleFocus(e) {
    if (!this.props.entry.cells[this.props.entry.cellIndexToStretch].value === true) {
      this.setState({ ...this.state, showHint: true });
    }
  }

  handlePaste(content) {
    if (this.state.showHint === true) {
      this.setState({ showHint: false });
    }

    return this.props.onPaste(content);
  }

  handleOptionsClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      this.props.entry.getContextMenuItems(this.props.entry, this.props.entryIndex),
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
    if (this.props.onOptionsClick) {
      this.props.onOptionsClick(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex,
        this.props.entry
      );
    }
  }

  handleDetailsClick(e) {
    this.props.onDetailsClick(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, this.props.entry);
  }

  getCellId(columnIndex) {
    return GridCellData.getCellId(
      this.props.gridId,
      this.props.sheetIndex,
      this.props.sectionIndex,
      this.props.entryIndex,
      columnIndex
    );
  }

  getDataCyId(columnIndex) {
    return GridCellData.getDataCyId(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, columnIndex);
  }

  handleUserLeavingCellEmpty(cellIndex) {
    if (this.props.onUserLeavingCellEmpty) {
      this.props.onUserLeavingCellEmpty(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex,
        cellIndex
      );
    }
  }

  handleCellInvalidTickerAdded(cellIndex) {
    if (this.props.onCellInvalidTickerAdded) {
      this.props.onCellInvalidTickerAdded(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.entryIndex,
        cellIndex
      );
    }
  }

  handleRowClick(e) {
    // Always allow user to click on options cell
    const optionsIndex = this.props.entry.cells.findIndex(cell => cell.type === cellType.OPTIONS);
    if (!optionsIndex === false) {
      const optionsCell = document.getElementById(this.getCellId(optionsIndex));
      if (optionsCell && optionsCell.contains(e.target)) {
        return;
      }
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(this.props.sheetIndex, this.props.sectionIndex, this.props.entryIndex, this.props.entry);
    }
  }

  handleShowTickerSelection(e, cellIndex) {
    this.contextMenuRef.current.dismiss();
    cellIndex = cellIndex || this.props.columnIndexForValue;

    const cell = this.props.entry.cells[cellIndex];
    const tickerInfo = cell.tickerInfo;
    const targetPosition = e.target.getBoundingClientRect();
    var contextMenuItems = [contextMenuItemType.INVALID_TICKER];
    if (cell.isCashflowInvalid) {
      contextMenuItems = [contextMenuItemType.INVALID_CASHFLOW_TICKER];
    }

    if (!tickerInfo === true) {
      return;
    }

    if (tickerInfo.length > 1) {
      contextMenuItems = [contextMenuItemType.PICK_TICKER];

      for (const tickerEntry of tickerInfo) {
        const ticker = tickerEntry.info;
        const menuItem = {
          id: TICKER_CONTEXT_MENU_ITEM_ID_PREFIX + ticker.id,
          label: ticker.shortName,
          description: getTickerDescription(ticker),
          tickerData: tickerEntry,
          cellIndex: cellIndex
        };
        contextMenuItems.push(menuItem);
      }
    }

    this.contextMenuRef.current.show(
      [contextMenuItems],
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
  }

  handleHideTickerSelection(e) {
    this.contextMenuRef.current.dismiss();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.entry !== nextProps.entry ||
      this.props.entryIndex !== nextProps.entryIndex ||
      this.props.isVisible !== nextProps.isVisible ||
      this.props.entry.isCollapsed === nextProps.entry.isCollapsed ||
      this.props.entry.isEmpty() === true
    );
  }

  get row() {
    const isComplete = this.props.entry.isComplete();
    const currency = this.props.currency;
    const isUpdated = this.props.entry.isUpdated;
    const isPercentageChangeShown = this.props.isPercentageChangeShown;

    return this.props.columns.map((column, index) => {
      const currentValue = this.props.entry.cells[index].value;
      const previousValueIndex = this.props.entry.isColumnsInDecreasingOrder ? index + 1 : index - 1;
      const previousValue =
        this.props.entry && this.props.entry.cells[previousValueIndex]
          ? this.props.entry.cells[previousValueIndex].value
          : null;

      if (column.hide === true) {
        return null;
      }

      return (
        <GridCell
          key={this.props.entry.id + index}
          isEditable={column.isEditable === true && this.props.entry.cells[index].isEditable === true}
          cellId={this.getCellId(index)}
          dataCyId={this.getDataCyId(index)}
          cell={this.props.entry.cells[index]}
          cellIndex={index}
          cellIndexToStretch={this.props.entry.cellIndexToStretch}
          currency={currency}
          sectionId={this.props.sectionId}
          allowAddRow={
            index === this.props.lastEditableCellIndex &&
            this.state.isInitialEdit === true &&
            this.props.isLastRow === true &&
            this.props.entry.isEmpty() === false
          }
          showHint={this.state.showHint === true && this.props.entry.showHint === true}
          allowRemoveRow={this.props.entry.removeIfEmpty === true && this.props.entry.isEmpty() === true}
          isInitialRowEdit={this.state.isInitialEdit === true}
          isRowLinked={this.props.entry.isLinked === true}
          isRowVerified={this.props.entry.isVerified === true}
          disableUserInput={this.props.entry.isLinking === true}
          isLinking={!this.props.entry.isLinking === false}
          linkedHoldingsCount={this.props.entry.linkedHoldingsCount}
          isRowComplete={isComplete}
          wasRowEverComplete={this.props.entry.wasRowEverComplete === true || isComplete === true}
          isRowEmpty={this.props.entry.isEmpty()}
          isRowUpdated={isUpdated}
          onCellUpdate={this.handleCellUpdate}
          onMoveToNextCell={this.props.onMoveToNextCell}
          onAddNewRow={this.handleAddNewRow}
          onRemoveRow={this.handleRemoveRow}
          onOptionsClick={this.handleOptionsClick}
          onLinkErrorClick={this.handleLinkErrorClick}
          onDetailsClick={this.handleDetailsClick}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onPaste={this.handlePaste}
          onUserLeavingCellEmpty={this.handleUserLeavingCellEmpty}
          onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
          onShowTickerSelection={this.handleShowTickerSelection}
          onHideTickerSelection={this.handleHideTickerSelection}
          userPreferences={this.props.userPreferences}
          linkContainer={this.props.entry.linkContainer}
          linkProviderAccountId={this.props.entry.linkProviderAccountId}
          availableCredit={this.props.entry.availableCredit}
          sheetIndex={this.props.sheetIndex}
          sectionIndex={this.props.sectionIndex}
          rowIndex={this.props.entryIndex}
          isSingleCellRow={this.props.entry.cells.length === 1}
          isSelectable={column.isSelectable}
          isPercentageChangeShown={isPercentageChangeShown}
          currentValue={currentValue}
          previousValue={previousValue}
          isFirstCell={index === 0}
          isLastCell={index === this.props.columns.length - 1}
          gridOptions={this.props.gridOptions}
          noOfColumns={this.props.noOfColumns}
          onOpenChartsModalClick={this.props.onOpenChartsModalClick}
          category={this.props.entry.category}
          handleRecapExpandOrCollapse={this.props.handleRecapExpandOrCollapse}
          isCollapsed={this.props.isCollapsed}
          sectionName={this.props.sectionName}
          row={this.props.entry}
        />
      );
    });
  }

  render() {
    const isComplete = this.props.entry.isComplete();
    const hideTopBorder = this.props.entryIndex === 0 && isComplete;
    const isDragEnabled = this.props.isDragEnabled === true;
    const rowStyles =
      this.props.gridOptions !== undefined &&
      this.props.gridOptions.getRowStyle !== undefined &&
      this.props.gridOptions.getRowStyle(
        this.props.entry,
        this.props.entryIndex,
        this.props.sectionIndex,
        this.props.isLastRow,
        this.props.isLastSection,
        this.props.entry.category
      );
    const isUpdated = this.props.entry.isUpdated;
    window.renderlog("Render: GridRow");

    return (
      <Entry
        hideTopBorder={hideTopBorder === true}
        isDragging={this.props.snapshot?.isDragging}
        isVisible={this.props.isVisible}
        rowStyle={rowStyles}
        sectionIndex={this.props.sectionIndex}
        rowIndex={this.props.entryIndex}
        isDummyEntry={this.props.entry.isDummyEntry}
      >
        <Container
          ref={this.containerRef}
          isUpdated={isUpdated}
          id={this.props.entry.id}
          onClick={this.handleRowClick}
          rowStyle={rowStyles}
        >
          {this.row}
          {this.props.provided && <DragGrabBar {...this.props.provided.dragHandleProps} canShow={isDragEnabled} />}
        </Container>
        <ContextMenu
          width={233}
          ref={this.contextMenuRef}
          onSelection={this.handleContextMenuSelection}
          preventGlobalScroll
        />
      </Entry>
    );
  }
}

export default GridRow;
